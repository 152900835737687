export default {
    "index": "Classificação",
    "nickname": "Nome",
    "invite_num": "Novos Convidados",
    "expect_give": "Recompensa Antecipada",
    "total_reward": "Pool de Prêmios de Gemas",
    "recommend_rank_title": "Classificação de Embaixadores",
    "my_success_invite": "Convite Bem-Sucedido",
    "my_rank": "Meu rank",
    "undefined": "Minha Recompensa",
    "recommend_rank_rule_title": "Detalhes da regra",
    "recommend_rank_rule_1": "1.Compartilhe seus livros favoritos ou convide seus amigos com seu link de referência nas redes sociais. Se um novo usuário, que nunca usou o StoryGo antes, se inscrever através do seu link, desbloquear 5 capítulos, você terá convidado com sucesso um novo leitor. Observação: Versão do aplicativo 1.8+ requerida.",
    "rule_how_to_share": "Como Compartilhar Seus Livros Favoritos--〉〉",
    "rule_how_to_invite": "Como Visualizar/Encontrar seu Link de Convite--〉〉",
    "recommend_rank_rule_2": "2.O evento começa com um prêmio inicial de 1400 gemas. Para cada novo usuário convidado com sucesso, o prêmio aumenta em 10 gemas, até um máximo de 50.000 gemas. ",
    "recommend_rank_rule_3": "3.O evento classificará os embaixadores com base no número de novos usuários que eles convidarem. Os 20 principais embaixadores dividirão o pool de prêmios da seguinte forma:\n·1Lugar: Recebe 30% do pool de prêmios em gemas.\n·2Lugar: Recebe 20% do pool de prêmios em gemas.\n·3 Lugar: Recebe 10% do pool de prêmios em gemas.\n·4-10Lugar: Cada pessoa recebe 4% do pool de prêmios em gemas.\n·11-20Lugar: Cada pessoa recebe 1.2% do pool de prêmios em gemas.\nAs recompensas acima serão distribuídas após o término do evento.",
    "recommend_rank_rule_4": "4.Qualquer forma de falsificação, trapaça ou manipulação de dados é estritamente proibida neste evento. Uma vez detectada, o participante será desqualificado. A interpretação final deste evento pertence ao StoryGo.",
    "act_rule_btn": "Verificar os detalhes da regra",
    "act_time": "Tempo",
    "recommend_rank_page_title": "Classificação de Embaixadores da StoryGo",
    "recommend_part1_title": "Parte 1: Recomendar Livros, Ganhar Gemas!",
    "recommend_part1_desc": "Compartilhe os livros que mais recomendaria aos seus amigos no Facebook. Adicione sua recomendação pessoal, marque @Storygo Publish e, se atender aos nossos critérios, ganhe recompensas de gemas!",
    "how_to_join": "Como Participar?",
    "recommend_part1_tips1": "1.Abra seu livro favorito, então apenas clique no botão de compartilhar no canto superior direito e escolha Facebook ou Twitter.",
    "recommend_part1_tips2": "2.Compartilhe e marque @Storygo Publish em sua postagem ou comentários. (Observação: As recompensas são elegíveis apenas para postagens compartilhadas publicamente.)",
    "recommend_part1_tips3": "3.Durante o evento, se sua postagem receber mais de 30 curtidas, você ganhará 50 gemas. Cada livro ou postagem é elegível para recompensas apenas uma vez, com um máximo de 200 gemas por pessoa. As recompensas serão distribuídas dentro de 3 dias úteis após o término do evento.",
    "recommend_part2_title": "Parte 2:Traga Novos Leitores, Divida o Prêmio!",
    "recommend_part2_desc": "Se você trouxer um novo usuário para o StoryGo através do seu livro compartilhado ou link de convite de registro, e eles desbloquearem qualquer 5 capítulos premium, você pode participar do Rank de Embaixadores da StoryGo e dividir o grande prêmio de gemas!",
    "recommend_part2_tips1": "1.Compartilhe seus livros favoritos ou o link de convite de amigos nas redes sociais. Requer atualização do aplicativo para a versão 1.8 ou superior.",
    "recommend_part2_tips2": "2.Se um novo usuário, que nunca usou o StoryGo antes, baixar e se registrar no StoryGo através do seu link, e desbloquear qualquer 5 capítulos, consideraremos que você convidou com sucesso um novo leitor.",
    "recommend_part2_tips3": "3.Cada usuário pode participar do ranking com base no número de novos leitores convidados com sucesso. Os top 20 irão compartilhar o grande prêmio de gemas.",
    "recommend_part2_tips4": "4.A StoryGo reserva-se o direito de interpretação final do evento. Em caso de fraude ou comportamento fraudulento para manipular as classificações, a StoryGo reserva-se o direito de desqualificar os participantes do evento.",
    "see_rank": "Verificar Classificação de Embaixadores",
    "share_act": "Compartilhar o evento",
    "join_watsapp": "Junte-se ao Grupo WhatsApp",
    "recommend_page_title": "Embaixadores do StoryGo, Grandes Recompensas!",
    "recommend_banner_title": "Embaixadores do StoryGo, Grandes Recompensas!",
    "how_to_share_title": "Como compartilhar seus livros favoritos",
    "how_to_share_content_1": "Abra o seu livro favorito e simplesmente toque no botão de compartilhar localizado no canto superior direito.",
    "how_to_share_content_2": "Ou, abra um livro, em seguida, mantenha pressionado um parágrafo para selecioná-lo e compartilhe-o nas redes sociais."
}