import {genPayOrder, genVipOrder, queryPayOrder, showPayerMax} from "@storygo/api/app";
import {eventBus} from "@storygo/utils/emitter";
import {showToast} from "@/components/toast/index";
import {$t} from "@storygo/lang";
import {dateFormat, sdkPromise, sleep, v1gtv2} from "@storygo/utils";
import {showFeedbackToast} from "@/components/feedbackToast/index";
import {novel} from "@storygo/types/novel_baseTars.d";
import {onMounted, watch, ref} from 'vue'
import {useDocumentVisibility} from "@vueuse/core";
import {useStorage} from "@/stores/storage";
import {getPayMethods, PAYER_COUNTRYCODE} from "@/utils";
// import Cookies from "js-cookie";

export const useBack = () => {
    // utils router = useRouter()
    // router.back()
    // return []
}

interface PayParams {
    // payFrom: string
    orderFrom?: string | number
    // packageId: string
    // method: 'payermax' | 'system'
    // type: 'recharge' | 'vip'
    // payItem: Record<string, any>
    // payerMaxTargetOrg?: string
    prePayDone: (res:any) => void
    payDone: (res: boolean) => void
}

export interface PayTypeItem {
    name: () => string,
    img: string,
    type: string,
    show: string[],
    countryCodes: string[]
}
interface PayOptions {
    // payFrom: string
    // orderFrom: string
    packageId: number
    method: 'payermax' | 'system'
    payType: 'recharge' | 'vip'
    payItem: Record<string, any>
    // payerMaxTargetOrg?: string

}
type PayType = 'recharge' | 'vip'
export const usePay = (payParams: PayParams) => {
    const visibility = useDocumentVisibility()
    let mcOrderId = ''
    const storage = useStorage()
    let payType = 'recharge'
    let packageId = 0
    let method = 'system'
    let payItem: Record<string, any> = {org: ''}
    let paying = false
    let t = 0
    // onMounted(() => {
    //
    // })
    //
    watch(visibility, (value) => {
        if(value === 'visible') {
            // if (window.visibleListener !== payParam.payFrom) return
            if(method === 'system') return;
            if(!mcOrderId) return;
            eventBus.$emit('loading', true)
            queryOrder(mcOrderId)

        }
    })
    const pay = async (payOption: PayOptions ) => {
        try {
            payType = payOption.payType
            packageId = payOption.packageId
            payItem = payOption.payItem
            method = payOption.method
            if(!packageId) return;
            if(paying) return;
            // utils payItem = payTypes.value[selectPayTypeIndex.value]
            paying = true
            clearTimeout(t)
            t = window.setTimeout(() => {
                paying = false
            }, 3000)
            if (method === 'payermax') {
                eventBus.$emit('loading', true)
                try {
                    if (payOption.payType === 'vip') {
                        const res = await genVipOrder({
                            payChannel: 6,
                            packageId: Number(packageId),
                            params: {
                                payerMaxTargetOrg: payItem.org || ''
                            } as novel.PayParameters
                        })
                        payParams.prePayDone && payParams.prePayDone(res)
                        const order = res.newOrder
                        mcOrderId = order.mcOrderId
                        // sessionStorage.setItem('mc_order', order.mcOrderId)
                        // sessionStorage.setItem('pay_status', 'ing')
                        
                        if (order.sdkParams) {
                            console.log(order.sdkParams.payermaxRedirectUrl)
                            await sdkPromise('forwardLink', {
                                url: order.sdkParams.payermaxRedirectUrl
                            })
                        }
                        eventBus.$emit('loading', false)
                    } else {
                        sdkPromise('emitH5RechargePackageStart', {
                            pid: String(packageId)
                        })
                        const res = await genPayOrder({
                            payChannel: 6,
                            packageId: packageId
                        })
                        payParams.prePayDone && payParams.prePayDone(res)
                        const order = res.waitingPayOrder.mcOrderId ? res.waitingPayOrder : res.newOrder
                        mcOrderId = order.mcOrderId
                        // sessionStorage.setItem('mc_order', order.mcOrderId)
                        // sessionStorage.setItem('pay_status', 'ing')
                        if (order.sdkParams) {
                            console.log(order.sdkParams.payermaxRedirectUrl)
                            await sdkPromise('forwardLink', {
                                url: order.sdkParams.payermaxRedirectUrl
                            })
                        }
                        eventBus.$emit('loading', false)
                    }

                } catch (e) {
                    eventBus.$emit('loading', false)

                    sdkPromise('emitEvent', {
                        eid: 'user_click_rechargepackages_package',
                        edata: JSON.stringify({
                            time: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
                            packageid: String(packageId),
                            result: '0',
                            orderid: '',
                            from: String(payParams.orderFrom)
                        })
                    });
                }
            } else {
                // 原生支付
                // sessionStorage.setItem('pay_status', 'ing')
                // 月卡
                if (payType === 'vip') {
                    // 小于1.5.0版本购买月卡就提示升级
                    if(!v1gtv2(window.appVersion, '1.5.0')) {
                        return showToast($t('app_update_title'))
                    }
                    await sdkPromise('emitPayMonthlyCard', {
                        pid: String(packageId),
                        from: String(payParams.orderFrom)
                    })
                    payParams.payDone && payParams.payDone(true)
                } else {
                    // 小于1.5.0版本 并且是首充礼包 就提示升级
                    if(storage.firstRechargePack.packageId == packageId && !v1gtv2(window.appVersion, '1.5.0')){
                        return showToast($t('app_update_title'))
                    }
                    //充值普通套餐
                    await sdkPromise('emitRechargePackage', {
                        pid: String(packageId),
                        from: payParams.orderFrom
                    })
                    payParams.payDone && payParams.payDone(true)
                }
                sessionStorage.setItem('pay_success', '1')
                // sessionStorage.removeItem('pay_status')
                // emits('paySuccess', res.order)
                // eventBus.$emit('loading', false)
                eventBus.$emit('paySuccess', {
                    packageId: String(packageId)
                })
            }

            paying = false
        }catch (e){
            paying = false
            console.log(e)
        }
    }

    const queryOrder = async (mcOrderId: string, retry = 5) => {
        const res = await queryPayOrder({
            mcOrderId
        })
        console.log(res.order.status)
        switch (res.order.status) {
            case 0:
                eventBus.$emit('loading', false)
                // sessionStorage.removeItem('pay_status')
                sessionStorage.setItem('pay_success', '1')
                showToast($t('label_pay_success'), 'success', 3000)
                if(payType === 'vip'){
                    sdkPromise('emitH5MonthlyCardPaid')
                }else {
                    sdkPromise('emitH5RechargePackageDone', {
                        pid: String(packageId)
                    })
                }
                sdkPromise('emitEvent', {
                    eid: 'user_click_rechargepackages_package',
                    edata: JSON.stringify({
                        time: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
                        packageid: String(packageId),
                        result: '1',
                        orderid: String(mcOrderId),
                        from: String(payParams.orderFrom)
                    })
                });
                // emits('paySuccess', res.order)
                eventBus.$emit('paySuccess', {
                    packageId: String(packageId)
                })
                mcOrderId = ''

                payParams.payDone && payParams.payDone(true)
                // localStorage.removeItem('pay_done')
                break
            case 1:
                // sessionStorage.removeItem('mc_order')
                eventBus.$emit('loading', false)
                sdkPromise('emitEvent', {
                    eid: 'user_click_rechargepackages_package',
                    edata: JSON.stringify({
                        time: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
                        packageid: String(packageId),
                        result: '0',
                        orderid: String(mcOrderId),
                        from: String(payParams.orderFrom)
                    })
                });
                mcOrderId = ''

                payParams.payDone && payParams.payDone(false)
                // showToast($t('no_coins'), 'warning')
                break
            case 2:
                await sleep(1000)
                eventBus.$emit('loading', false)
                showFeedbackToast(5000)
                mcOrderId = ''
                // await queryOrder(mcOrderId, --retry)
                // showToast('待支付', 'success')
                payParams.payDone && payParams.payDone(false)
                break
            case 3:
                // sessionStorage.removeItem('mc_order')
                eventBus.$emit('loading', false)
                sdkPromise('emitEvent', {
                    eid: 'user_click_rechargepackages_package',
                    edata: JSON.stringify({
                        time: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
                        packageid: String(packageId),
                        result: '0',
                        orderid: String(mcOrderId),
                        from: String(payParams.orderFrom)
                    })
                });
                mcOrderId = ''
                payParams.payDone && payParams.payDone(false)
                // showToast('用户主动取消', 'warning')
                break
            case 4:
                eventBus.$emit('loading', false)
                sdkPromise('emitEvent', {
                    eid: 'user_click_rechargepackages_package',
                    edata: JSON.stringify({
                        time: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
                        packageid: String(packageId),
                        result: '0',
                        orderid: String(mcOrderId),
                        from: String(payParams.orderFrom)
                    })
                });
                mcOrderId = ''
                payParams.payDone && payParams.payDone(false)
                // showToast('订单超时取消', 'warning')
                break
            default:
                eventBus.$emit('loading', false)
                payParams.payDone && payParams.payDone(false)
        }
    }


    // closeGuesture()
    return {
        pay,
        queryOrder
    }
}

export const useShowPayer =  (callback: (hasPayer: boolean)=>void) => {

    const storage = useStorage()
    const osPays: PayTypeItem[] = [
        {
            name: () => $t('apple_pay'),
            img: '/images/wallet/apple.webp',
            type: 'system',
            show: ['iOS'],
            countryCodes: [] as string[]
        },
        {
            name: () => $t('google_pay'),
            img: '/images/wallet/google.webp',
            type: 'system',
            show: ['Android'],
            countryCodes: []  as string[]
        },
    ].filter(item => item.show.includes(window.os))
    const payTypes = ref<PayTypeItem[]>([])
    const showPayer = ref(false)
    payTypes.value = [...osPays]

    const getShowPayerMax = async () => {
        if(!window.appUserInfo?.token){
            return {
                payTypes,
                showPayer
            }
        };
        payTypes.value = [...osPays]
        const res = await showPayerMax()
        if (res.show) {
            storage.setShowLayerPay(true)
            // window.showLayer = true
            const countryCodes = PAYER_COUNTRYCODE
            if (window.appUserInfo && countryCodes.includes(window.appUserInfo.countryCode)) {
                showPayer.value = res.show
                payTypes.value.unshift(
                    {
                        name: () => $t('other_pay'),
                        img: '/images/payItem/other.png',
                        type: 'payermax',
                        show: ['Android', 'iOS'],
                        countryCodes: PAYER_COUNTRYCODE
                    },
                )
                const items = getPayMethods(window.appUserInfo.countryCode)
                items.reverse().map(item => {
                    payTypes.value.unshift(
                        {
                            name: () => item.name,
                            img: item.icon,
                            org: item.org,
                            type: 'payermax',
                            show: ['Android', 'iOS'],
                            countryCodes: PAYER_COUNTRYCODE
                        },
                    )
                })
                callback(true)
            } else {
                // selectPayTypeIndex.value = 0
                // emits('update:payItem', 0)
                callback(false)
            }
        } else {
            storage.setShowLayerPay(false)
            // window.showLayer = false
            // selectPayTypeIndex.value = 0
            // emits('update:payItem', 0)
            callback(false)
        }
    }


    // if (showPayer.value) {
    //
    // } else {
    //
    // }

    // loaded.value = true
    return {
        payTypes,
        showPayer,
        getShowPayerMax
    }
}