<template>
  <div class="container">
    <div class="title" v-if="title">
      {{ title }}
    </div>
    <div class="content no-scrollbar">
      <VanList
          @load="onLoad"
          :immediate-check="false"
          :offset="300"
          :finished="finished"
      >
        <van-cell v-for="(item, index) in items" :key="index">
          <div  class="items-center card-item">
            <slot name="left" :item="item" :index="index"></slot>
            <van-skeleton class="cover" :loading="loading">
              <header style="display: none;">
                <nav>
                  <a style="display: none;" :rel="item.name" :href="getBookUrl(item)" :title="item.name"></a>
                </nav>
              </header>
              <Image @click="clickItem(item)" class="cover not-flip" :rel="item.name" fit="cover" lazy-load :src="getSrc(item.cover)"  :alt="item.name+'-StoryGo'"></Image>
            </van-skeleton>
            <div class="item-info" @click="clickItem(item)">
              <slot :item="item" :index="index">
                <van-skeleton title :row="3" :loading="loading">
                  <div class="ellipsis card-title" v-html="item.name"></div>
<!--                  <div class="ellipsis author">{{ item.author }}</div>-->
                  <div class="ellipsis card-subtitle" v-if="item.categories">
                  <span v-for="(tag, tagIndex) in item.categories.value.slice(0,2)" :key="tagIndex"
                        class="tag">{{ tag.name }}</span>
                  </div>
                  <div class="ellipsis2 card-description" v-html="item.description"></div>
                </van-skeleton>

              </slot>
            </div>
          </div>
        </van-cell>
      </VanList>

    </div>

    <download2></download2>
  </div>
</template>
<script setup lang="ts">
import {ref} from 'vue'
import {Image, List as VanList, Cell as VanCell} from "vant"
import {novel} from "@storygo/types/novel_baseTars.d";
import {getBookUrl, getSrc} from "@storygo/utils";
import {$t} from "@storygo/lang"
import download2 from '@/components/download2/index.vue'
interface Props {
  items: novel.Book[]
  title?: string
  loading: boolean
  placeHolderSize: number
}

// import {} from "vue"
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  items = [] as novel.Book[],
  title = '',
  loading = false,
  placeHolderSize = 0
} = defineProps<Props>()
const emit = defineEmits<{
  (e: 'clickItem', item: any): void
  (e: 'onLoad'): void
}>()
const clickItem = (item: any) => {
  emit('clickItem', item)
}
const finished = ref(false)

const onLoad = () => {
  emit('onLoad')
}

console.log(items)
</script>
<style scoped lang="less" src="./index.less"></style>
