<template>
  <div v-if="visible" class="download-container">
    <div class="center download-text">
      <div class="text-view">
        🔥{{$t('download_app_tips')}}
      </div>
    </div>
    <div class="center download-btn">
      <div class="center btn" @click="downloadApp">
        {{$t('download')}}
      </div>
    </div>

  </div>
  <div v-if="visible" class="download-holder"></div>
</template>
<script lang="ts" setup>
import {ref, watch} from "vue"
import {handleDownload, importScript} from "@storygo/utils";
import {$t} from "@storygo/lang"

interface Props {
  closable: boolean
  position: string
  offset?: string
  theme: 'light' | 'dark'
  schema: string
}

const style = ref({})
const visible = ref(true)
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  closable = true,
  position = 'bottom',
  offset = '0px',
  theme = 'light',
  schema = 'main?tab=1'
} = defineProps<Props>()
watch(() => offset, (value) => {
  if (position === 'bottom') {
    style.value = `bottom: ${offset};`
  }
  if (position === 'top') {
    style.value = `top: ${offset};`
  }
}, {
  immediate: true,
})


const closeDownload = () => {
  visible.value = false
}
const downloadApp = () => {
    // handleDownload(schema)
  importScript('https://www.storygo.cc/lib/onelink.js').then(res => {
    var oneLinkURL = "https://storygo.onelink.me/dDXW";
    var mediaSource = {keys:["utm_source"],overrideValues:{"facebookwebee":"facebook2"},defaultValue:"click_download_btn"};
    var campaign = {keys:["utm_campaign"]};
    var adSet = {keys:["utm_adset"]};
    var ad = {keys:["utm_ad"]};
    var custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};
    var af_dp = {
      paramKey: "af_dp",
      keys: ["custom_scheme"],
      // defaultValue: `storygo%3A%2F%2F%2Fbook%3Fbid%3D1192`
      defaultValue: encodeURIComponent(`storygo:///${schema}`)
    };
    var result = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: oneLinkURL,
      afParameters: {
        mediaSource: mediaSource,
        afCustom: [
          af_dp,
          custom_ss_ui,
        ],
        campaign: campaign,
        adSet: adSet,
        ad: ad
      }
    });
    console.log(result)
    window.open(result.clickURL)
    // window.location.href = result.clickURL
    // console.log(e)
  }).catch(e => {
    console.log(e)
  })
}

</script>
<style scoped lang="less">
.download-holder {
  height: 300px;
  //background: #fff;
}
.download-container {
    position: absolute;
    z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
}
.download-text {
  width: 100%;
  height: 320px;
  background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, #FFFFFF 50%, #FFFFFF 100%);
  text-align: center;
  font-size: 30px;
  color: #FD3A6C;
  line-height: 44px;
  .text-view {
    width: 80%;
    margin-bottom: -140px;
  }
}
.download-btn {
  background: #FFFFFF;
  padding: 0 0 70px;
  .btn {
    width: 471px;
    height: 88px;
    background: linear-gradient( 270deg, #D400F1 0%, #FF4A50 100%);
    border-radius: 44px;
    font-weight: 500;
    font-size: 30px;
    color: #FFFFFF;
    line-height: 32px;
  }
}

</style>
