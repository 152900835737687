export default {
    "index": "Sıralama",
    "nickname": "Adı",
    "invite_num": "Yeni Davet Sayısı",
    "expect_give": "Beklenen Mücevher Ödülü",
    "total_reward": "Mevcut Mücevher Havuzu",
    "recommend_rank_title": "StoryGo Elçileri Sıralaması",
    "my_success_invite": "Başarıyla davet ettim",
    "my_rank": "Benim sıram",
    "undefined": "Beklenen Mücevher Ödülü",
    "recommend_rank_rule_title": "Kural detayları",
    "recommend_rank_rule_1": "1.Favori kitaplarınızı paylaşın veya arkadaşlarınızı davet edin, referans bağlantınızı sosyal medyada paylaşın. Daha önce StoryGo kullanmamış yeni bir kullanıcı, bağlantınız aracılığıyla kaydolursa, 5 bölüm kilidi açarsa, başarılı bir şekilde bir yeni okuyucu davet etmiş olursunuz. Not: Uygulama sürümü 1.8+ gereklidir.",
    "rule_how_to_share": "Favori Kitaplarınızı Nasıl Paylaşılır--〉〉",
    "rule_how_to_invite": "Davet Bağlantınızı Nasıl Görüntüleyebilir/Bulabilirsiniz--〉〉",
    "recommend_rank_rule_2": "2.Etkinlik, 1400 mücevherlik bir ödül havuzu ile başlar. Her başarılı davet edilen yeni kullanıcı için havuz 10 mücevher artar, maksimum 50.000 mücevher'e kadar. ",
    "recommend_rank_rule_3": "3.Etkinlik, davet ettikleri yeni kullanıcıların sayısına göre elçileri sıralayacak. İlk 20 elçi, ödül havuzunu aşağıdaki şekilde paylaşacak:\n·1Sıra: Mücevher cinsinden ödül havuzunun %30'unu alır.\n·2Sıra: Mücevher cinsinden ödül havuzunun %20'sini alır.\n·3Sıra: Mücevher cinsinden ödül havuzunun %10'unu alır.\n·4-10. Sıra: Her kişi, mücevher cinsinden ödül havuzunun %4'ünü alır.\n·11-20. Sıra: Her kişi, mücevher cinsinden ödül havuzunun %1.2'sini alır.\nYukarıdaki ödüller etkinlik sona erdikten sonra dağıtılacaktır. ",
    "recommend_rank_rule_4": "4.Bu etkinlikte her türlü sahtecilik, hile veya veri manipülasyonu kesinlikle yasaktır. Tespit edildiğinde, katılımcı diskalifiye edilecektir. Bu etkinliğin nihai yorumu StoryGo'ya aittir.",
    "act_rule_btn": "Kural detaylarını kontrol et",
    "act_time": "Zaman",
    "recommend_rank_page_title": "StoryGo Elçileri Sıralaması",
    "recommend_part1_title": "Bölüm 1: Harika Kitaplar Tavsiye Et, Mücevher Kazan!",
    "recommend_part1_desc": "Facebook'da arkadaşlarınıza en çok tavsiye ettiğiniz kitapları paylaşın. Kişisel onayınızı ekleyin, @Storygo Publish'i etiketleyin ve eğer kriterlerimize uygunsa, mücevher ödülleri kazanın!",
    "how_to_join": "Nasıl Katılabilirim?",
    "recommend_part1_tips1": "1.Favori kitabınızı açın, ardından sadece sağ üst köşedeki paylaş düğmesine basın ve Facebook veya Twitter'ı seçin.",
    "recommend_part1_tips2": "2.Paylaş ve gönderinde veya yorumlarında @Storygo Publish'i etiketle. (Not: Ödüller yalnızca kamuoyuna açık olarak paylaşılan gönderiler için geçerlidir.)",
    "recommend_part1_tips3": "3.Etkinlik sırasında, gönderiniz 30 beğeniden fazla alırsa 50 mücevher kazanırsınız. Her kitap veya gönderi yalnızca bir kez ödül almaya hak kazanır, kişi başına maksimum 200 mücevher. Ödüller, etkinlik sona erdikten sonra 3 iş günü içinde dağıtılacaktır.",
    "recommend_part2_title": "Kısım 2: Yeni okuyucuları getir, ödülü paylaş!",
    "recommend_part2_desc": "Kitap veya davet bağlantınız aracılığıyla yeni bir kullanıcı getirin. Kullanıcı, 5 premium bölümü kilitleyerek kaydolduğunda, StoryGo Elçileri Sıralaması'na katılabilir ve büyük mücevher ödülünü paylaşabilirsiniz! ",
    "recommend_part2_tips1": "1.Sosyal medyada favori kitaplarınızı veya arkadaş davet bağlantınızı paylaşın. Uygulamanın sürüm 1.8 veya üzerine yükseltilmesi gerekmektedir.",
    "recommend_part2_tips2": "2.Daha önce hiç StoryGo kullanmamış olan yeni bir kullanıcı, bağlantınız aracılığıyla StoryGo'yu indirir ve kaydolursa, ve herhangi 5 bölümü kilitleyerek, bunun bir yeni okuyucuyu başarıyla davet ettiğinizi düşüneceğiz.",
    "recommend_part2_tips3": "3.Her kullanıcı, başarılı bir şekilde davet edilen yeni okuyucu sayısına göre sıralamaya katılabilir. İlk 20 kişi büyük mücevher ödülünü paylaşacak.",
    "recommend_part2_tips4": "4.StoryGo etkinliğin nihai yorum hakkını saklı tutar. Hile veya sıralamayı manipüle etmek için sahtekarlık durumunda, StoryGo katılımcıları etkinlikten diskalifiye etme hakkını saklı tutar.",
    "see_rank": "Elçiler Sıralamasını Kontrol Et",
    "share_act": "Etkinliği Paylaş",
    "join_watsapp": "Ekle WhatsApp Grubuna",
    "recommend_page_title": "StoryGo Elçisi Ol, Büyük Ödüller Kazan!",
    "recommend_banner_title": "StoryGo Elçisi Ol, Büyük Ödüller Kazan!",
    "how_to_share_title": "En sevdiğiniz kitapları nasıl paylaşılır",
    "how_to_share_content_1": "Favori kitabınızı açın ve sağ üst köşede bulunan paylaş düğmesine basın.",
    "how_to_share_content_2": "Veya, bir kitap açın, ardından bir paragrafı seçmek için uzun basın ve sosyal medyada paylaşın."
}