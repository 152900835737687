export default {
    "next": "Suivant",
    "login": "Connexion",
    "login_tips": "Nous espérons que vous pourrez profiter d'un voyage romantique ici",
    "set_password": "Définir le mot de passe",
    "enter_password": "Veuillez entrer votre mot de passe",
    "verify_code": "Code de vérification",
    "enter_code": "Veuillez entrer le code de vérification numérique de 6 emails",
    "let_start": "Commençons",
    "let_start_tips": "Nous espérons que vous pourrez profiter d'un voyage romantique ici",
    "sign_facebook": "Se connecter avec FaceBook",
    "sign_google": "Se connecter avec Google",
    "sign_email": "Se connecter avec Email",
    "language_title": "Définir les préférences de langue de lecture",
    "current_lang": "langue actuelle",
    "en": "EN",
    "indonesian": "Indonésien",
    "personal_data": "Données personnelles",
    "save": "Sauvegarder",
    "nickname": "Pseudo",
    "sign_out": "Se déconnecter",
    "loading": "chargement",
    "success": "Réussi",
    "reading_record": "Enregistrement de lecture",
    "empty": "Vide",
    "last_read": "Dernière lecture",
    "chapter": "chapitre",
    "remove": "Supprimer",
    "search": "Recherche",
    "search_empty": "Aucun roman lié à “{{var}}” ne peut être trouvé",
    "login_tips_2": "Connectez-vous pour accéder à plus de fonctionnalités.",
    "register_or_login": "Inscription/Connexion",
    "more": "Plus",
    "reads": "Lectures",
    "read": "Lire",
    "last_chapter": "Dernier chapitre",
    "all_chapter": "Tous les Chapitres",
    "contents": "Contenus",
    "all": "Tout",
    "completed": "Terminé",
    "ongoing": "En cours",
    "most_popular": "Le plus populaire",
    "new_arrival": "Nouvelle arrivée",
    "invite_friends": "Partagez ce code d'invitation avec vos amis. Une fois qu'ils l'activent sur cette page, vous recevez tous les deux {{coins}} gemmes. Chaque code d'invitation peut être utilisé jusqu'à 50 fois.",
    "invite_friends_nums": "<span class=\"blue\">J'ai invité {{n}} amis et fait </span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>",
    "guide_title": "Projet d'auteur StoryGo",
    "guide_content": 0,
    "active_code": "Activer le code d'invitation",
    "active_tips": "Obtenez un code d'invitation de vos amis et, après activation réussie, vous recevrez {{coins}} gemmes. Astuce: Vous ne pouvez pas activer votre propre code d'invitation; vous devez en obtenir un auprès des autres!",
    "login_now": "Connectez-vous maintenant",
    "task_1_title": "Cadeau de bienvenue",
    "task_1_desc": "Un cadeau de bienvenue pour les nouveaux venus, qui a été automatiquement déposé dans votre portefeuille",
    "view_detail": "Voir les détails",
    "claim": "Réclamer",
    "invite_friend": "Inviter des amis",
    "my_invite_code": "Mon code d'invitation",
    "copy": "Copier",
    "task_2_title": "Tous les jours Check-in",
    "task_2_desc": "Connectez-vous tous les jours et obtenez le bonus.",
    "task_3_title": "Lire pendant plus de {{min}} minutes",
    "task_3_desc": "Lisez un total de {{min}} minutes aujourd'hui pour gagner des récompenses.",
    "task_page_label": "Récompenses",
    "label_wallet_purchase_coins": "Acheter des pièces",
    "go": "Aller",
    "done": "Fait",
    "copy_text": "Mon code d'invitation StoryGo est [ {{code}} ], activez-le et gagnez 50 pièces : [https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "Le code d'invitation a été copié.",
    "active_success": "Activation réussie. La récompense de 50 pièces a été envoyée à votre portefeuille.",
    "error_code_limit": "Le code d'activation a atteint sa limite d'utilisation.",
    "error_had_activated": "Ce compte a déjà été activé avec un code",
    "error_invalid_code": "Code d'activation invalide",
    "download_tips": "Histoires sans limites dans l'APP",
    "download": "Télécharger",
    "label_book_praise": "Évaluation",
    "label_book_prologue": "Prologue",
    "login_success": "Connexion réussie",
    "msg_reset_password_repeat": "Veuillez répéter le nouveau mot de passe",
    "msg_reset_password_email": "Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de passe",
    "label_reset_password": "Réinitialiser le mot de passe",
    "label_send_email": "Envoyer un e-mail",
    "msg_login_email_code_sent": "Le code de vérification a été envoyé avec succès à votre e-mail",
    "msg_feedback_error": "Échec de l'envoi, veuillez réessayer plus tard",
    "save_userinfo": "Enregistré avec succès",
    "label_load_image_fail": "Échec du chargement de l'image",
    "paid_chapters": "Chapitres Payants",
    "unlock_price": "Le déblocage du chapitre nécessite {{coin}}",
    "download_claim_coin": "Téléchargez l'application pour réclamer {{coin}} et débloquer plus de chapitres passionnants.",
    "label_book_chapters": "{{num}} Chapitres",
    "label_cancel": "Annuler",
    "msg_common_state_empty": "Vide ici",
    "clear_reading_record": "Êtes-vous sûr de vouloir effacer l'historique de lecture ?",
    "clear_successful": "Enregistrement effacé avec succès.",
    "label_search_trending": "Recherches tendances",
    "task_login_tips": "Connectez-vous pour obtenir un bonus de pièces",
    "label_new_arrival": "Nouveautés Chaudes",
    "had_activated": "Ce compte a déjà été activé avec un code",
    "activate": "Activer",
    "task_5_title": "Partager sur Facebook",
    "task_5_desc": "Partager n'importe quel livre sur la plateforme Facebook",
    "bigwheel_redeem_label": "Recharger",
    "coin_tips_1": "Pas assez de pièces, veuillez recharger.",
    "coin_tips_2": "Compte : {{n}} pièces (nécessite {{m}} pièces)",
    "bigwheel_rule_1": "1. Participez au tirage de la Roue de la chance avec une garantie à 100% de gagner des prix.",
    "bigwheel_rule_2": "2. Un seul tour de roue coûte 10 pièces d'or, dix tours coûtent 100 pièces d'or, et cinquante tours coûtent 500 pièces d'or. Plus il y a de tours consécutifs, plus la chance de gagner des prix précieux augmente !",
    "bigwheel_rule_3": "3. Les prix obtenus pendant le jeu sont automatiquement distribués. Les pièces d'or sont directement créditées sur votre portefeuille de pièces d'or.",
    "bigwheel_rule_4": "4. Ce jeu n'est pas affilié à Apple Inc. Tous les droits d'interprétation finale appartiennent à StoryGo.",
    "bigwheel_balance_label": "Solde",
    "no_data_1": "Pas de données",
    "msg_room_coin_notice": "Félicitations ! [{{who}}] gagne {{coin}} pièces !",
    "top_up": "Recharger",
    "sign": "S'inscrire",
    "sign_desc": "Un cadeau de bienvenue pour les nouveaux venus, qui a été automatiquement déposé dans votre portefeuille",
    "first_recharge": "Offre à durée limitée",
    "first_recharge_desc": "Terminez l'offre à durée limitée pour obtenir un bonus",
    "unlock_chapter": "Débloquer {{n}} chapitre",
    "unlock_chapter_desc": "Débloquez un chapitre premium pour recevoir une récompense.",
    "comment_book": "Envoyer un commentaire sur un livre",
    "comment_book_desc": "Rédigez une critique pour votre livre préféré et ayez la chance de gagner des récompenses!",
    "my_coins": "Mes pièces",
    "recharge_title": "Pack cadeau recommandé",
    "bonus": "Bonus",
    "buy_coins": "Acheter des pièces",
    "my_balance": "Mon solde",
    "apple_pay": "Apple Pay",
    "other_pay": "Choix Principal",
    "google_pay": "Google Pay",
    "pay_now": "Payer maintenant",
    "label_balance": "pièces",
    "select_payment": "Veuillez sélectionner une méthode de paiement",
    "select_package": "Veuillez sélectionner un forfait de recharge",
    "wallet_title": "Portefeuille",
    "coins_balance": "Solde",
    "new_comer": "Nouvel arrivant",
    "extra_coins": "Extra {{n}} pièces",
    "daily_task_label": "Tâche Quotidienne",
    "label_pay_success": "Paiement réussi!",
    "app_update_title": "Veuillez mettre à jour vers la dernière version.",
    "restore_label": "Restaurer",
    "history_label": "Historique",
    "newcomer_pack": "Offre à durée limitée",
    "recommend_pack": "Forfait recommandé",
    "collection_month": "Total/Mois",
    "now_get_icons": "Obtenez {{n}}  à la fois",
    "monthly_get_icons": "Obtenez {{n}} Gemmes/Mois",
    "recharge_feedback_msg": "Des problèmes pour recharger?",
    "feedback_label": "Commentaires",
    "coin_label": "Pièces",
    "gem_label": "Gemmes",
    "restore_tips": "Aucun abonnement restaurable",
    "recharge_label": "Recharger",
    "sign_days": "Enregistré {{n}} jour(s)",
    "vip_daily_task": "Bonus de forfait mensuel",
    "other": "Autre",
    "limited_recharge_pack": "Offre à durée limitée",
    "expire_tips": "Expire le {{time}}",
    "hot_pack": "Forfait populaire",
    "continuous_checkin_day": "Enregistré {{n}} jour(s)",
    "svip_login_reward": "Bonus premium de 9,9 $",
    "vip_login_reward": "Bonus premium de 19,9 $",
    "dailysign_popup_btn": "Obtenez plus du Centre de Récompenses",
    "checked_in": "Vérifié",
    "limit_time_recharge_title": "Offre à durée limitée",
    "recharge_pack_tips": "Terminez l'offre à durée limitée pour obtenir un bonus",
    "balance_nsufficient_tips": "Votre solde est insuffisant, veuillez recharger",
    "had_coins_tips": "Vous avez : {{n}} Pièces | {{m}} Gemmes",
    "dailysign_popup_title": "Félicitations",
    "pay_method_label": "Méthode de paiement",
    "at_once": "Immédiatement",
    "daily_login": "Connexion quotidienne",
    "card_coin_suffix": "Total/Mois",
    "card_gems_suffix": "Mois",
    "vips_rule_title": "À propos du forfait mensuel :",
    "vips_rule_1": "1. Lorsque vous achetez avec succès le forfait mensuel de 9,9 $, vous recevrez immédiatement 1 000 pièces d'or, et vous pourrez recevoir 20 gemmes chaque jour pendant 30 jours consécutifs (600 gemmes au total); Lorsque vous achetez avec succès le forfait mensuel de 19,9 $, vous recevrez immédiatement 2 000 pièces d'or, et vous pourrez recevoir 40 gemmes chaque jour (600 gemmes au total) pendant 30 jours consécutifs.",
    "vips_rule_2": "2. Si vous achetez différents forfaits mensuels, les récompenses en gemmes que vous recevez chaque jour se superposeront; si vous achetez les mêmes forfaits mensuels, les récompenses en gemmes que vous recevez chaque jour ne se superposeront pas, mais prolongeront seulement la durée de la carte mensuelle correspondante.",
    "vips_rule_3": "3. Les gemmes obtenues quotidiennement sont valables pendant 30 jours, veuillez noter.",
    "vips_rule_4": "",
    "vips_rule_5": "1. Le forfait mensuel sera valide dans les 24 heures suivant l'achat et vous pourrez profiter des avantages correspondants.",
    "check_in": "Enregistrement",
    "day_index": "No.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Gemmes </span> sont sur le point d'expirer",
    "balance_label": "Solde",
    "wallet_rule_title": "À propos des gemmes",
    "wallet_rule_1": "1. Les gemmes peuvent être utilisées pour débloquer des chapitres. Lors du déverrouillage des chapitres, les pièces et les gemmes ont la même valeur, 1 pièce = 1 gemme.",
    "wallet_rule_2": "2. Les gemmes expireront. Veuillez noter que les gemmes reçues par recharge et forfait mensuel sont valables pendant 30 jours et les gemmes réclamées par les tâches quotidiennes sont valables pendant 7 jours.",
    "wallet_rule_3": "3. Lorsque vous déverrouillez des chapitres, les gemmes sont utilisées en premier.",
    "read_task_title": "Tâche de lecture",
    "share_task_title": "Tâches de partage",
    "comment_task_title": "Tâche de commentaire",
    "vip_task_title": "Bonus premium de 9,99 $",
    "svip_task_title": "Bonus premium de 19,99 $",
    "vip_page_title": "Forfait mensuel",
    "pay_bonus_label": "Bonus",
    "daily_gems": "Quotidiennement {{n}} Gemmes",
    "history_page_title": "Historique",
    "recharge_title_1": "Recharger",
    "gems_will_expire_btn": "Détails",
    "gems_history_tips": "Trier par date d'expiration",
    "unlock_coins": "Besoin de: {{n}} Pièces ou Gemmes",
    "history_recharge_title": "Recharge",
    "history_game_title": "Tirage au sort chanceux",
    "history_recharge_given_title": "Bonus de recharge",
    "extar_gems_btn": "Gemmes Supplémentaires",
    "watch_ad_task_title": "Regarder des vidéos",
    "watch_ad_task_desc": "Recevoir une récompense pour chaque vidéo regardée ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Récompense réclamée avec succès.",
    "task_finish_title": "La tâche a été accomplie.",
    "draw_width_ad_toast": "Félicitations! Vous avez obtenu  {{n}} et  {{m}} supplémentaires.",
    "draw_toast": "Félicitations ! Vous avez obtenu  {{n}}.",
    "draw_dialog_btn1": "Réclamez simplement  {{n}}.",
    "draw_dialog_btn2": "Réclamez  {{n}} et  {{m}} supplémentaires.",
    "history_ads_given_title": "Regarder des vidéos",
    "ad_extra_rewards_title": "Bonus pour annonces après tâches.",
    "label_editor_picks": "Choix de l'éditeur",
    "label_genre": "Genre",
    "label_main_tab_home": "Découvrir",
    "label_reader_choice": "Hautement Évalué",
    "invite_bonuses": "Invitez un nouvel utilisateur et obtenez jusqu'à {{n}} bonus.",
    "invite_friend_btn": "Invitez votre ami",
    "how_get_bonuses": "Comment obtenir plus de bonus",
    "invite_tips_1": "Votre ami a débloqué et lu 5 chapitres",
    "invite_tips_2": "Lorsque vos amis rechargent, vous obtenez 10% en retour en gemmes, jusqu'à 150 gemmes par ami.",
    "invite_tips_3": "Chaque fois que votre ami termine une action, vous recevrez une récompense immédiate.",
    "how_invite_friend": "Comment inviter un ami",
    "invite_tips_5": "Cliquez sur le bouton ci-dessous pour générer votre lien d'invitation exclusif et le partager avec vos amis",
    "invite_tips_4": "Votre ami a téléchargé StoryGo via le lien que vous avez partagé",
    "reward_history_title": "Historique des récompenses",
    "reward_history_tips_1": "Vous avez totalement obtenu {{n}} gemmes de l'activité d'invitation.",
    "reward_history_tips_2": "Vous avez invité avec succès {{n}} amis et {{m}} gemmes.",
    "reward_history_tips_3": "Vous avez gagné {{n}} gemmes de la tâche de déverrouillage de 5 chapitres réalisée par vos amis invités.",
    "reward_history_tips_4": "Vous avez reçu {{n}} gemmes en récompense du rechargement de vos amis",
    "daily_invite_friend_title": "Inviter des amis",
    "daily_invite_friend_desc": "Invitez vos amis à rejoindre StoryGo et à gagner de grandes récompenses.",
    "recharge_panel_ad_tips": "Rechargez votre forfait StoryGo pour obtenir de nombreuses pièces de livre et gemmes. Débloquez facilement les chapitres premium et profitez de la lecture sans publicité pendant 7 à 30 jours après l'achat, avec activation instantanée.",
    "invite_more_friend": "Invitez plus d'amis et obtenez plus de Gemmes !",
    "history_invite_unlock_title": "L'utilisateur invité a déverrouillé 5 chapitres",
    "history_invite_recharge_title": "L'utilisateur invité a effectué une recharge.",
    "days": "Jours",
    "continue_read_app": "Continuer la Lecture dans l'Application",
    "download_app_tips": "Pour plus de contenu passionnant, veuillez télécharger StoryGo",
    "invite_tips_6": " Invitez un nouveau lecteur à réclamer 15 gemmes. Vous pouvez gagner jusqu'à 300 gemmes en récompense.",
    "history_invite_success_title": "Un utilisateur a été invité avec succès",
    "evaluation_task_title": "Évaluez StoryGo",
    "evaluation_task_desc": "3 Étapes pour obtenir les récompenses",
    "invite_page_title": "Invitez de nouveaux utilisateurs à l'utiliser ensemble et recevez des récompenses en gemmes !",
    "invite_page_title_2": "Pour chaque nouvel utilisateur que vous invitez, vous recevrez.",
    "invite_page_title_3": "Pour les paiements des nouveaux utilisateurs, vous pouvez également recevoir une récompense en gemmes de {{n}}%. Plus vous invitez, plus vous gagnez.",
    "my_invite_record": "Mon Historique d'Invitations",
    "successfully_invited": "Invitation Réussie",
    "earnings_gem": "Mes Gains (Gemmes)",
    "how_invite_title_1": "Inviter des Amis",
    "how_invite_title_2": "L'ami termine l'inscription",
    "how_invite_title_3": "Les récompenses arrivent",
    "invite_h5_title": "Nous vous invitons à utiliser StoryGo ensemble",
    "got_gem_label": " Avantages pour les nouveaux",
    "accept_invitation": "Accepter l'invitation",
    "join_people": "Les gens ont participé à l'événement",
    "recommended_content": " Contenu recommandé",
    "to_app_tip": "Plus de contenu excitant sur StoryGo",
    "turn_on_notify_title": "Ouvrir la notification",
    "turn_on_notify_desc": "Ouvrez les notifications de message pour recevoir des récompenses",
    "label_help_center": "Centre d'Aide",
    "ai_become_writer_btn": "Devenir Écrivain  ",
    "ai_offer_title": "Ce Que Nous Offrons  ",
    "ai_offer_item_label_1": "Outils de Création  ",
    "ai_offer_item_value_1": "Quota d'Essai Gratuit pour Outils de Création  ",
    "ai_offer_item_label_2": "Récompenses en Espèces  ",
    "ai_offer_item_value_2": "Participez à des concours de création pour gagner un prix en espèces de 200 $  ",
    "ai_offer_item_label_3": "Écrivain Sous Contrat  ",
    "ai_offer_item_value_3": "Devenez écrivain sous contrat sur la plateforme et recevez des parts de souscription mensuelles généreuses  ",
    "ai_authors_say_title": "Ce Que Disent Nos Auteurs  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"Cet outil d'écriture IA est comme un assistant puissant, simple et facile à utiliser, permettant à chaque utilisateur de commencer facilement. Une sortie de haute qualité rend la création agréable, et l'expérience utilisateur est sans aucun doute le plaisir ultime de l'écriture.\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "Cet outil d'écriture IA est incroyable ! Il est facile à utiliser, simple à manipuler et produit des œuvres de haute qualité. Il me permet d'exprimer ma créativité facilement et de l'apprécier !  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "Cet outil d'écriture IA est une bénédiction pour les créateurs. Son interface simple et intuitive le rend facile à utiliser pour les novices. La qualité des histoires produites est surprenante. Écrire avec cet outil est vraiment un agréable voyage d'inspiration.  ",
    "ai_why_choose_title": "Pourquoi Nous Choisir  ",
    "ai_why_choose_item_label_1": "Équipe éditoriale passionnée  ",
    "ai_why_choose_item_value_1": "Une équipe éditoriale professionnelle et efficace vous offre une période de protection de 6 mois pour les écrivains novices, pendant laquelle les manuscrits créés peuvent bénéficier du canal de soumission garanti.  ",
    "ai_why_choose_item_label_2": "Outils de création efficaces  ",
    "ai_why_choose_item_value_2": "Facile à créer, cycle de création court, haute efficacité de monétisation  ",
    "ai_why_choose_item_label_3": "Garantie de sécurité  ",
    "ai_why_choose_item_value_3": "Une équipe juridique professionnelle protège les droits de vos œuvres  ",
    "ai_create_story_btn": "Créez vos histoires  ",
    "ai_contact_btn": "Contacter l'équipe éditoriale  ",
    "ai_dialog_title": "Bienvenue à StoryGo !  ",
    "ai_dialog_desc": "En utilisant les outils de création de StoryGo, vous pouvez également devenir un écrivain à succès, venez nous rejoindre !  ",
    "ai_dialog_part1_title": "Essai Gratuit  ",
    "ai_dialog_part1_item1": "Veuillez vous connecter à notre site Web PC https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "Rejoignez le groupe WhatsApp et contactez l'administrateur pour obtenir un essai gratuit de l'outil de création.  ",
    "ai_dialog_part2_title": "Concours Créatif  ",
    "ai_dialog_part2_item1": "Veuillez vous connecter à notre site Web PC https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "Participez au concours créatif, gagnez des prix en espèces, de nouvelles opportunités.  ",
    "ai_dialog_part2_item3": "Si vous avez des questions, veuillez nous contacter à Contact@storygo.cc  ",
    "ai_dialog_copy_email": "Copiez le lien du site officiel  ",
    "ai_url_copy_tips": "Lien copié avec succès  "
}