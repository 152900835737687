export default {
    "index": "Peringkat",
    "nickname": "Nama",
    "invite_num": "Pembaca diundang baru",
    "expect_give": "Total hadiah permata",
    "total_reward": "Total hadiah permata sekarang",
    "recommend_rank_title": "Peringkat Duta StoryGo",
    "my_success_invite": "Saya berhasil undang",
    "my_rank": "Peringkat saya",
    "undefined": "Hadiah permata yang diharapkan",
    "recommend_rank_rule_title": "Detail aturan",
    "recommend_rank_rule_1": "1.Bagikan buku favorit Anda atau undang teman dengan tautan referral Anda di media sosial. Jika pengguna baru, yang belum pernah menggunakan StoryGo sebelumnya, mendaftar melalui tautan Anda, membuka 5 bab, Anda telah berhasil mengundang satu pembaca baru. Catatan: Versi aplikasi 1.8+ diperlukan.",
    "rule_how_to_share": "Cara Berbagi Buku Favorit Anda--〉〉",
    "rule_how_to_invite": "Cara Melihat/Menemukan Tautan Undangan Anda--〉〉",
    "recommend_rank_rule_2": "2.Acara dimulai dengan hadiah awal 1400 permata. Untuk setiap pengguna baru yang berhasil diundang, hadiah akan bertambah sebanyak 10 permata, hingga maksimum 50.000 permata.",
    "recommend_rank_rule_3": "3.Acara akan menilai duta berdasarkan jumlah pengguna baru yang mereka undang. 20 duta teratas akan membagi hadiah sebagai berikut:\n·Posisi 1: Menerima 30% dari pool hadiah dalam bentuk permata.\n·Posisi 2: Menerima 20% dari pool hadiah dalam bentuk permata.\n·Posisi 3: Menerima 10% dari pool hadiah dalam bentuk permata.\n·Posisi 4-10: Setiap orang menerima 4% dari pool hadiah dalam bentuk permata.\n·Posisi 11-20: Setiap orang menerima 1.2% dari pool hadiah dalam bentuk permata.\nHadiah di atas akan didistribusikan setelah acara berakhir.",
    "recommend_rank_rule_4": "4.Setiap bentuk pemalsuan, kecurangan, atau manipulasi data dilarang ketat dalam acara ini. Begitu terdeteksi, peserta akan didiskualifikasi. Interpretasi akhir acara ini milik StoryGo.",
    "act_rule_btn": "Periksa detail aturan",
    "act_time": "Waktu",
    "recommend_rank_page_title": "Peringkat Duta StoryGo",
    "recommend_part1_title": "Bagian 1: Sarankan Buku-buku Hebat, Menangkan Permata!",
    "recommend_part1_desc": "Bagikan buku-buku yang paling Anda rekomendasikan kepada teman di Facebook. Tambahkan rekomendasi pribadi Anda, tandai @Storygo Publish, dan jika memenuhi kriteria kami, dapatkan hadiah permata!",
    "how_to_join": "Cara Berpartisipasi?",
    "recommend_part1_tips1": "1.Buka buku favorit Anda, lalu cukup tekan tombol bagikan di sudut kanan atas dan pilih Facebook atau Twitter.",
    "recommend_part1_tips2": "2.Bagikan dan tandai @Storygo Publish dalam kiriman atau komentar Anda. (Catatan: Hadiah hanya berlaku untuk kiriman yang dibagikan secara publik.)",
    "recommend_part1_tips3": "3.Selama acara, jika kiriman Anda mendapat lebih dari 30 suka, Anda akan mendapatkan 50 permata. Setiap buku atau kiriman hanya berhak atas hadiah sekali, dengan maksimum 200 permata per orang. Hadiah akan didistribusikan dalam waktu 3 hari kerja setelah acara berakhir.",
    "recommend_part2_title": "Bagian 2: Bawa pembaca baru, bagi hadiah besar!",
    "recommend_part2_desc": "Jika Anda mengajak pengguna baru ke StoryGo melalui buku yang Anda bagikan atau tautan undangan pendaftaran, dan mereka membuka 5 bab premium apa pun, Anda dapat berpartisipasi dalam Peringkat Duta StoryGo dan membagi hadiah permata besar!",
    "recommend_part2_tips1": "1.Bagikan buku favorit Anda atau tautan undangan teman Anda di media sosial. Membutuhkan upgrade aplikasi ke versi 1.8 atau di atasnya.",
    "recommend_part2_tips2": "2.Jika pengguna baru, yang belum pernah menggunakan StoryGo sebelumnya, mengunduh dan mendaftar di StoryGo melalui tautan Anda, dan membuka 5 bab apa pun, kami akan menganggap bahwa Anda telah berhasil mengundang satu pembaca baru.",
    "recommend_part2_tips3": "3.Setiap pengguna dapat berpartisipasi dalam peringkat berdasarkan jumlah pembaca baru yang berhasil diundang. 20 teratas akan membagi hadiah permata besar.",
    "recommend_part2_tips4": "4.StoryGo memiliki hak untuk menafsirkan ulang acara ini. Dalam kasus penipuan atau perilaku curang untuk memanipulasi peringkat, StoryGo berhak untuk mendiskualifikasi peserta dari acara tersebut.",
    "see_rank": "Periksa Peringkat Duta",
    "share_act": "Bagikan acara ini",
    "join_watsapp": "Gabung grup WhatsApp",
    "recommend_page_title": "Jadilah Duta StoryGo, Menangkan Hadiah Besar!",
    "recommend_banner_title": "Jadilah Duta StoryGo, Menangkan Hadiah Besar!",
    "how_to_share_title": "Bagaimana cara berbagi buku favorit Anda",
    "how_to_share_content_1": "Buka buku favorit Anda dan cukup ketuk tombol bagikan yang terletak di pojok kanan atas.",
    "how_to_share_content_2": "Atau, buka sebuah buku, lalu tekan lama sebuah paragraf untuk memilihnya, dan bagikan di media sosial."
}