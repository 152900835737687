export default {
    "next": "التالي",
    "login": "تسجيل الدخول",
    "login_tips": "نأمل أن تستمتع برحلة رومانسية هنا",
    "set_password": "تعيين كلمة المرور",
    "enter_password": "الرجاء إدخال كلمة المرور الخاصة بك",
    "verify_code": "رمز التحقق",
    "enter_code": "الرجاء إدخال رمز التحقق الرقمي المكون من 6 أرقام الخاص بالبريد الإلكتروني",
    "let_start": "لنبدأ",
    "let_start_tips": "نأمل أن تستمتع برحلة رومانسية هنا",
    "sign_facebook": "تسجيل الدخول باستخدام فيسبوك",
    "sign_google": "تسجيل الدخول باستخدام جوجل",
    "sign_email": "تسجيل الدخول باستخدام البريد الإلكتروني",
    "language_title": "تعيين تفضيلات لغة القراءة",
    "current_lang": "اللغة الحالية",
    "en": "EN",
    "indonesian": "الأندونيسية",
    "personal_data": "البيانات الشخصية",
    "save": "حفظ",
    "nickname": "الكنية",
    "sign_out": "تسجيل الخروج",
    "loading": "جار التحميل",
    "success": "ناجح",
    "reading_record": "سجل القراءة",
    "empty": "فارغ",
    "last_read": "آخر قراءة",
    "chapter": "الفصل",
    "remove": "إزالة",
    "search": "بحث",
    "search_empty": "لا يمكن العثور على روايات متعلقة بـ \"{{var}}\"",
    "login_tips_2": "تسجيل الدخول للوصول إلى المزيد من الميزات.",
    "register_or_login": "تسجيل / تسجيل الدخول",
    "more": "المزيد",
    "reads": "قراءات",
    "read": "اقرأ",
    "last_chapter": "الفصل الأخير",
    "all_chapter": "كل الفصول",
    "contents": "المحتويات",
    "all": "الكل",
    "completed": "مكتمل",
    "ongoing": "جاري",
    "most_popular": "الأكثر شعبية",
    "new_arrival": "الوافد الجديد",
    "invite_friends": "شارك رمز الدعوة هذا مع أصدقائك. بمجرد تنشيطهم على هذه الصفحة ، ستحصل كل منكما على {{coins}} جواهر. يمكن استخدام كل رمز دعوة حتى 50 مرة.",
    "invite_friends_nums": "<span class=\"blue\">دعوت {{n}} صديقًا وحصلت على </span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>",
    "guide_title": "مشروع المؤلف ستوريجو",
    "guide_content": 0,
    "active_code": "تنشيط رمز الدعوة",
    "active_tips": "احصل على رمز دعوة من أصدقائك، وعند تنشيطه بنجاح، ستتلقى {{coins}} جواهر. نصيحة: لا يمكنك تنشيط رمز الدعوة الخاص بك ؛ يجب أن تحصل على واحد من الآخرين!",
    "login_now": "تسجيل الدخول الآن",
    "task_1_title": "هدية ترحيب",
    "task_1_desc": "هدية ترحيب للقادمين الجدد، تم إيداعها تلقائيًا في محفظتك",
    "view_detail": "عرض التفاصيل",
    "claim": "مطالبة",
    "invite_friend": "دعوة الأصدقاء",
    "my_invite_code": "رمز الدعوة الخاص بي",
    "copy": "نسخ",
    "task_2_title": "كل يوم تسجيل الدخول",
    "task_2_desc": "تسجيل الدخول كل يوم، واحصل على المكافأة.",
    "task_3_title": "اقرأ لأكثر من {{min}} دقائق",
    "task_3_desc": "اقرأ لمدة {{min}} دقائق اليوم لكسب المكافآت.",
    "task_page_label": "المكافآت",
    "label_wallet_purchase_coins": "شراء العملات",
    "go": "اذهب",
    "done": "تم",
    "copy_text": "رمز دعوتي في StoryGo هو [ {{code}} ]، قم بتنشيطه واكسب 50 عملة:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "تم نسخ رمز الدعوة.",
    "active_success": "تم التفعيل بنجاح. تم إرسال مكافأة العملة 50 إلى محفظتك.",
    "error_code_limit": "لقد وصل رمز التفعيل إلى حد استخدامه.",
    "error_had_activated": "تم تفعيل هذا الحساب بالفعل برمز",
    "error_invalid_code": "رمز التفعيل غير صالح",
    "download_tips": "قصص لا حدود لها في التطبيق",
    "download": "تحميل",
    "label_book_praise": "التقييم",
    "label_book_prologue": "المقدمة",
    "login_success": "تسجيل الدخول نجح",
    "msg_reset_password_repeat": "الرجاء تكرار كلمة المرور الجديدة",
    "msg_reset_password_email": "الرجاء إدخال عنوان بريدك الإلكتروني لإعادة تعيين كلمة المرور",
    "label_reset_password": "إعادة تعيين كلمة المرور",
    "label_send_email": "إرسال بريد إلكتروني",
    "msg_login_email_code_sent": "تم إرسال رمز التحقق بنجاح إلى بريدك الإلكتروني",
    "msg_feedback_error": "فشل الإرسال، يرجى المحاولة مرة أخرى لاحقا",
    "save_userinfo": "تم الحفظ بنجاح",
    "label_load_image_fail": "فشل تحميل الصورة",
    "paid_chapters": "الفصول المدفوعة",
    "unlock_price": "{{coin}}إلغاء قفل الفصل يتطلب",
    "download_claim_coin": "قم بتنزيل التطبيق للمطالبة بـ {{coin}} وفتح المزيد من الفصول المثيرة.",
    "label_book_chapters": "{{num}}  فصول",
    "label_cancel": "إلغاء",
    "msg_common_state_empty": "فارغ هنا",
    "clear_reading_record": "هل أنت متأكد أنك تريد مسح سجل القراءة؟",
    "clear_successful": "تم مسح السجل بنجاح.",
    "label_search_trending": "البحث الشائع",
    "task_login_tips": "تسجيل الدخول للحصول على مكافأة النقود",
    "label_new_arrival": "إصدارات جديدة وساخنة",
    "had_activated": "تم تفعيل هذا الحساب بالفعل برمز",
    "activate": "تفعيل",
    "task_5_title": "شارك على الفيسبوك",
    "task_5_desc": "مشاركة أي كتاب على منصة الفيسبوك",
    "bigwheel_redeem_label": "تعبئة رصيد",
    "coin_tips_1": "العملات المعدنية غير كافية، يرجى إعادة الشحن.",
    "coin_tips_2": "الحساب: {{n}} عملات معدنية (يحتاج إلى {{m}} عملات معدنية)",
    "bigwheel_rule_1": "1. شارك في سحب عجلة الحظ مع فرصة مضمونة للفوز بالجوائز بنسبة 100%.",
    "bigwheel_rule_2": "2. السحب على عجلة واحدة يكلف 10 عملات ذهبية، والسحب على عشرة يكلف 100 عملة ذهبية، والسحب على الخمسين يكلف 500 عملة ذهبية. كلما زاد عدد السحوبات المتتالية، زادت فرصة الفوز بجوائز قيمة!",
    "bigwheel_rule_3": "3. يتم توزيع الجوائز التي تم الحصول عليها أثناء اللعبة تلقائيًا. يتم إضافة العملات الذهبية مباشرة إلى محفظتك الخاصة بالعملات الذهبية.",
    "bigwheel_rule_4": "4. هذه اللعبة ليست تابعة لشركة Apple Inc. جميع حقوق الإصدار النهائي مملوكة لشركة StoryGo.",
    "bigwheel_balance_label": "توازن",
    "no_data_1": "لايوجد بيانات",
    "msg_room_coin_notice": "تهاني! [{{who}}] يربح {{coin}} عملات معدنية!",
    "top_up": "فوق حتى",
    "sign": "اشتراك",
    "sign_desc": "هدية ترحيب للقادمين الجدد، تم إيداعها تلقائيًا في محفظتك",
    "first_recharge": "عرض لفترة محدودة",
    "first_recharge_desc": "انهاء العرض المحدود للحصول على مكافأة",
    "unlock_chapter": "فتح الفصل {{n}}.",
    "unlock_chapter_desc": "قم بفتح فصل متميز لتحصل على مكافأة.",
    "comment_book": "إرسال تعليق على كتاب",
    "comment_book_desc": "اكتب مراجعة لكتابك المفضل واحصل على فرصة للفوز بجوائز!",
    "my_coins": "عملاتي المعدنية",
    "recharge_title": "حزمة الهدايا الموصى بها",
    "bonus": "علاوة",
    "buy_coins": "شراء عملات معدنية",
    "my_balance": "رصيدي",
    "apple_pay": "أبل الدفع",
    "other_pay": "الخيار الأول",
    "google_pay": "جوجل الدفع",
    "pay_now": "ادفع الآن",
    "label_balance": "عملات",
    "select_payment": "الرجاء اختيار طريقة الدفع",
    "select_package": "الرجاء تحديد باقة إعادة الشحن",
    "wallet_title": "محفظة",
    "coins_balance": "توازن",
    "new_comer": "الوافد الجديد",
    "extra_coins": "عملات معدنية إضافية بقيمة {{n}}.",
    "daily_task_label": "مهام يومية",
    "label_pay_success": "تم الدفع بنجاح!",
    "app_update_title": "يرجى التحديث إلى الإصدار الأحدث",
    "restore_label": "استعادة",
    "history_label": "التاريخ",
    "newcomer_pack": "عرض لفترة محدودة",
    "recommend_pack": "الحزمة الموصى بها",
    "collection_month": "الإجمالي/الشهر",
    "now_get_icons": "احصل على {{n}} عملة مرة واحدة",
    "monthly_get_icons": "احصل على {{n}} جواهر/الشهر",
    "recharge_feedback_msg": "هل تواجه مشكلة في الشحن؟",
    "feedback_label": "ردود الفعل",
    "coin_label": "العملات",
    "gem_label": "الجواهر",
    "restore_tips": "لا يوجد اشتراكات يمكن استعادتها",
    "recharge_label": "شحن",
    "sign_days": "تسجيل الدخول {{n}} يومًا",
    "vip_daily_task": "مكافأة الحزمة الشهرية",
    "other": "آخر",
    "limited_recharge_pack": "عرض لفترة محدودة",
    "expire_tips": "تنتهي في {{time}}",
    "hot_pack": "الحزمة الشعبية",
    "continuous_checkin_day": "تسجيل الدخول {{n}} يومًا",
    "svip_login_reward": "مكافأة بريميوم بقيمة 9.9 دولار",
    "vip_login_reward": "مكافأة بريميوم بقيمة 19.9 دولار",
    "dailysign_popup_btn": "احصل على المزيد من مركز المكافآت",
    "checked_in": "تم التحقق",
    "limit_time_recharge_title": "عرض لفترة محدودة",
    "recharge_pack_tips": "انهاء العرض المحدود للحصول على مكافأة",
    "balance_nsufficient_tips": "رصيدك غير كاف، يرجى الشحن",
    "had_coins_tips": "لديك: {{n}} عملة | {{m}} جواهر",
    "dailysign_popup_title": "تهانينا",
    "pay_method_label": "طريقة الدفع",
    "at_once": "مرة واحدة",
    "daily_login": "تسجيل الدخول اليومي",
    "card_coin_suffix": "الإجمالي/الشهر",
    "card_gems_suffix": "شهر",
    "vips_rule_title": "حول الحزمة الشهرية:",
    "vips_rule_1": "1. قم بشراء حزمة شهر بقيمة 9.9 دولارًا بنجاح، وستتلقى على الفور 1000 عملة ذهبية، ويمكنك الحصول على 20 جوهرة كل يوم لمدة 30 يومًا متتاليًا (إجمالي 600 جوهرة)؛ إذا نجحت في شراء باقة شهر بقيمة 19.9 دولارًا، فستحصل على 2000 على الفور عملات ذهبية، ويمكنك الحصول على 40 جوهرة كل يوم (إجمالي 600 جوهرة) لمدة 30 يومًا متتاليًا.",
    "vips_rule_2": "2. إذا اشتريت باقات شهرية مختلفة، فسيتم تطبيق مكافآت الجواهر التي تحصل عليها كل يوم، وإذا اشتريت باقات الشهر نفسه، فلن يتم تطبيق مكافآت الجواهر التي تحصل عليها كل يوم، ولكنها ستؤدي فقط إلى تمديد مدة بطاقة الشهر المقابل .",
    "vips_rule_3": "3.الجواهر التي يتم الحصول عليها يوميًا صالحة لمدة 30 يومًا، يرجى الانتباه.",
    "vips_rule_4": "",
    "vips_rule_5": "سيكون الباقة الشهرية صالحة خلال 24 ساعة من الشراء ويمكنك الاستمتاع بالفوائد المقابلة.",
    "check_in": "تسجيل الدخول",
    "day_index": "رقم {{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} جوهرة </span> على وشك الانتهاء",
    "balance_label": "الرصيد",
    "wallet_rule_title": "عن الجواهر",
    "wallet_rule_1": "يمكن استخدام الجواهر لفتح الفصول. عند فتح الفصول ، يكون للعملات والجواهر نفس القيمة ، 1 عملة = 1 جوهرة.",
    "wallet_rule_2": "الجواهر ستنتهي صلاحيتها. يرجى ملاحظة أن الجواهر التي تتلقاها من شحن الرصيد والباقة الشهرية صالحة لمدة 30 يومًا والجواهر التي تطالب بها المهام اليومية صالحة لمدة 7 أيام.",
    "wallet_rule_3": "عند فتح الفصول ، يتم استخدام الجواهر أولاً.",
    "read_task_title": "المهمة القرائية",
    "share_task_title": "مهام المشاركة",
    "comment_task_title": "مهمة التعليق",
    "vip_task_title": "مكافأة بريميوم بقيمة 9.99 دولار",
    "svip_task_title": "مكافأة بريميوم بقيمة 19.99 دولار",
    "vip_page_title": "الباقة الشهرية",
    "pay_bonus_label": "مكافأة",
    "daily_gems": "الجواهر اليومية {{n}}",
    "history_page_title": "التاريخ",
    "recharge_title_1": "شحن الرصيد",
    "gems_will_expire_btn": "التفاصيل",
    "gems_history_tips": "ترتيب حسب تاريخ الانتهاء",
    "unlock_coins": "بحاجة إلى: {{n}} عملات أو جواهر",
    "history_recharge_title": "إعادة شحن",
    "history_game_title": "سحب الحظ",
    "history_recharge_given_title": "مكافأة الشحن",
    "extar_gems_btn": "جواهر إضافية",
    "watch_ad_task_title": "شاهد الفيديوهات",
    "watch_ad_task_desc": "استلام الجائزة مقابل كل فيديو تم مشاهدته ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "تمت المطالبة بالمكافأة بنجاح.",
    "task_finish_title": "تم إكمال المهمة.",
    "draw_width_ad_toast": "مبروك! لقد حصلت على  {{n}} وإضافية  {{m}}.",
    "draw_toast": "مبروك! لقد حصلت على  {{n}}.",
    "draw_dialog_btn1": "مطالبة فقط  {{n}}.",
    "draw_dialog_btn2": " المطالبة بـ  {{n}} و  {{m}} إضافي.",
    "history_ads_given_title": "شاهد الفيديوهات",
    "ad_extra_rewards_title": " مكافأة بعد إكمال المهام",
    "label_editor_picks": "اختيارات المحرر",
    "label_genre": "النوع",
    "label_main_tab_home": "اكتشف",
    "label_reader_choice": "أعلى التقييمات",
    "invite_bonuses": "ادعو مستخدمًا جديدًا واحصل على ما يصل إلى {{n}} مكافآت.",
    "invite_friend_btn": "ادعو صديقك",
    "how_get_bonuses": "كيفية الحصول على المزيد من المكافآت",
    "invite_tips_1": "لقد فتح صديقك وقرأ 5 فصول",
    "invite_tips_2": "عندما يقوم أصدقاؤك بإعادة الشحن ، ستحصل على 10٪ من العملات المعاد تحميلها مرة أخرى في شكل جواهر ، مع حد أقصى 150 جوهرة لكل صديق.",
    "invite_tips_3": "في كل مرة يكتمل فيها صديقك لإجراء، ستتلقى مكافأة فورية.",
    "how_invite_friend": "كيفية دعوة الصديق",
    "invite_tips_5": "انقر فوق الزر أدناه لإنشاء رابط الدعوة الخاص بك ومشاركته مع الأصدقاء",
    "invite_tips_4": "صديقك قام بتنزيل StoryGo عبر الرابط الذي شاركته.",
    "reward_history_title": "تاريخ الجوائز",
    "reward_history_tips_1": "لقد حصلت على مجموع {{n}} جوهرة من نشاط الدعوة.",
    "reward_history_tips_2": "لقد نجحت في دعوة {{n}} أصدقاء و {{m}} جوهرة.",
    "reward_history_tips_3": "لقد حصلت على {{n}} جوهرة من مهمة أصدقائك المدعوين فتح 5 فصول.",
    "reward_history_tips_4": "لقد تلقيت {{n}} جوهرة كمكافأة من شحن أصدقائك",
    "daily_invite_friend_title": "دعوة الأصدقاء",
    "daily_invite_friend_desc": "قم بدعوة أصدقائك للانضمام إلى StoryGo وكسب مكافآت كبيرة.",
    "recharge_panel_ad_tips": "أعد شحن حزمتك StoryGo للحصول على عملات كتابية وأحجار كريمة وفيرة. فتح الفصول المميزة بسهولة والاستمتاع بالقراءة خالية من الإعلانات لمدة 7 إلى 30 يومًا بعد الشراء، مع التنشيط الفوري.",
    "invite_more_friend": "قم بدعوة المزيد من الأصدقاء واحصل على المزيد من الجواهر!",
    "history_invite_unlock_title": "المستخدم المدعو فتح 5 فصول",
    "history_invite_recharge_title": "قام المستخدم المدعو بإعادة الشحن",
    "days": "أيام",
    "continue_read_app": "متابعة القراءة في التطبيق",
    "download_app_tips": "للحصول على محتوى مثير للمزيد ، يرجى تنزيل StoryGo",
    "invite_tips_6": "قم بدعوة قارئ جديد للمطالبة بـ 15 جواهر. يمكنك كسب ما يصل إلى 300 جوهرة كمكافأة.",
    "history_invite_success_title": "تمت دعوة المستخدم بنجاح",
    "evaluation_task_title": "قيّم StoryGo",
    "evaluation_task_desc": "3 خطوات للحصول على المكافآت",
    "invite_page_title": "ادعو المستخدمين الجدد لاستخدامه معًا واستلام مكافآت الجوهرة!",
    "invite_page_title_2": "لكل مستخدم جديد تقوم بدعوته، ستحصل.",
    "invite_page_title_3": "بالنسبة لمدفوعات المستخدمين الجدد ، يمكنك أيضًا الحصول على مكافأة جوهرية بنسبة ٪ 10. كلما دعوت أكثر ، كلما حصلت على المزيد.",
    "my_invite_record": "سجل الدعوة الخاص بي",
    "successfully_invited": "دعوة ناجحة",
    "earnings_gem": "أرباحي (جوهرة)",
    "how_invite_title_1": "دعوة الأصدقاء",
    "how_invite_title_2": "الصديق يكمل التسجيل",
    "how_invite_title_3": "تصل الجوائز",
    "invite_h5_title": "ندعوك لاستخدام StoryGo معًا",
    "got_gem_label": "فوائد للمبتدئين",
    "accept_invitation": " قبول الدعوة",
    "join_people": "شارك الناس في الحدث",
    "recommended_content": "المحتوى الموصى به",
    "to_app_tip": "محتوى مثير للإعجاب أكثر على StoryGo",
    "turn_on_notify_title": "افتح الإشعارات",
    "turn_on_notify_desc": "افتح إشعارات الرسائل لتلقي المكافآت",
    "label_help_center": "مركز المساعدة",
    "ai_become_writer_btn": "أصبح كاتباً  ",
    "ai_offer_title": "ماذا نقدم  ",
    "ai_offer_item_label_1": "أدوات الإبداع  ",
    "ai_offer_item_value_1": "حصة تجربة مجانية لأدوات الإبداع  ",
    "ai_offer_item_label_2": "جوائز نقدية  ",
    "ai_offer_item_value_2": "شارك في مسابقات الإبداع للفوز بجائزة نقدية قدرها 200 دولار  ",
    "ai_offer_item_label_3": "كاتب متعاقد  ",
    "ai_offer_item_value_3": "كن كاتبًا متعاقدًا على المنصة واحصل على حصة سخية من الاشتراكات الشهرية  ",
    "ai_authors_say_title": "ماذا يقول مؤلفونا  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"هذه الأداة الكتابية المدعومة بالذكاء الاصطناعي تشبه مساعدًا قويًا، بسيطة وسهلة الاستخدام، مما يسمح لكل مستخدم بالبدء بسهولة. الإخراج عالي الجودة يجعل الإبداع ممتعًا، وتجربة المستخدم بلا شك هي أعلى متعة في الكتابة.\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "هذه الأداة الكتابية المدعومة بالذكاء الاصطناعي مذهلة! إنها سهلة الاستخدام، بسيطة في التشغيل، وتنتج أعمالاً عالية الجودة. إنها تتيح لي التعبير عن إبداعي بسهولة والاستمتاع بذلك!  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "هذه الأداة الكتابية المدعومة بالذكاء الاصطناعي نعمة للمبدعين. تجعل واجهتها البسيطة والحدسية من السهل على المبتدئين استخدامها. جودة القصص الناتجة مدهشة. الكتابة بها هي حقًا رحلة ممتعة من الإلهام.  ",
    "ai_why_choose_title": "لماذا تختارنا  ",
    "ai_why_choose_item_label_1": "فريق التحرير الشغوف  ",
    "ai_why_choose_item_value_1": "يوفر لك فريق التحرير المحترف والفعال فترة حماية لمدة 6 أشهر للكتّاب المبتدئين، حيث يمكن أن تستفيد المخطوطات التي تم إنشاؤها من قناة ضمان الإرسال الأخضر.  ",
    "ai_why_choose_item_label_2": "أدوات إنشاء فعالة  ",
    "ai_why_choose_item_value_2": "سهل الإنشاء، دورة إنشاء قصيرة، كفاءة تحقيق الدخل العالية  ",
    "ai_why_choose_item_label_3": "ضمان السلامة  ",
    "ai_why_choose_item_value_3": "تحميك فريق قانوني محترف من حقوق أعمالك  ",
    "ai_create_story_btn": "اصنع قصصك  ",
    "ai_contact_btn": "اتصل بفريق التحرير  ",
    "ai_dialog_title": "مرحبًا بك في StoryGo!  ",
    "ai_dialog_desc": "باستخدام أدوات إنشاء StoryGo، يمكنك أيضًا أن تصبح كاتب قصص ناجح، تعال وانضم إلينا!  ",
    "ai_dialog_part1_title": "تجربة مجانية  ",
    "ai_dialog_part1_item1": "يرجى تسجيل الدخول إلى موقع الكمبيوتر الخاص بنا https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "انضم إلى مجموعة WhatsApp واتصل بالمدير للحصول على تجربة مجانية لأداة الإنشاء.  ",
    "ai_dialog_part2_title": "مسابقة إبداعية  ",
    "ai_dialog_part2_item1": "يرجى تسجيل الدخول إلى موقع الكمبيوتر الخاص بنا https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "شارك في المسابقة الإبداعية، واربح جوائز نقدية، وفرص جديدة.  ",
    "ai_dialog_part2_item3": "إذا كانت لديك أي أسئلة، يرجى الاتصال بنا على Contact@storygo.cc  ",
    "ai_dialog_copy_email": "انسخ رابط الموقع الرسمي  ",
    "ai_url_copy_tips": "تم نسخ الرابط بنجاح  "
}