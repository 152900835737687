import {novel} from "@storygo/types/novel_baseTars.d";
import {useStorage} from "../stores/storage"

export const PAYER_COUNTRYCODE = [
    'ID', 'VN', 'TR', 'PT', 'PH', 'BR', 'MX', 'KZ',
    'AE', 'BH', 'DZ', 'EG', 'IL', 'IQ', 'JO', 'KW',
    'MA', 'OM', 'QA', 'SA', 'YE', 'US'
]
export const PAY_METHODS = {
    'ID': [
        {name: 'Dana', org: 'DANA', icon: '/images/payItem/DANA_ID.png'},
        {name: 'Ovo', org: 'OVO', icon: '/images/payItem/OVO_ID.png'},
    ],
    'VN': [
        {name: 'MoMo', org: 'MOMO_VIETQR', icon: '/images/payItem/MoMo_VN.png'},
        {name: 'VietQR', org: 'VIETQR', icon: '/images/payItem/VietQR_VN.png'},
    ],
    'TR': [
        {name: 'Papara', org: 'PAPARA', icon: '/images/payItem/Papara_TR.png'},
        {name: 'BKM Epress', org: 'BKMEXPRESS', icon: '/images/payItem/BKM_Express_TR.png'},
    ],
    'KZ': [
        {name: 'Visa/MasterCard', org: '', icon: '/images/payItem/Visa.png'},
    ],
    'PH': [
        {name: 'GrabPay', org: 'GRABPAY', icon: '/images/payItem/Grabpay_PH.png'},
        {name: 'Paymaya', org: 'PAYMAYA', icon: '/images/payItem/Paymaya_PH.png'},
    ],
}
export const getPayMethods = (countryCode: keyof typeof PAY_METHODS) => {
    if(PAY_METHODS[countryCode]){
        return [...PAY_METHODS[countryCode]]
    }
    return []
}

// 全部套餐,，包括月卡, 排序
const getAllPacks = () => {
    const storage = useStorage()
    const packs = [
        ...storage.monthCardPacks,
        storage.firstRechargePack,
        ...storage.rechargePacks
    ]
    packs.sort((a, b) => {
        return a.currency - b.currency
    })
    return packs
}

// 全部套餐,，不包括月卡, 排序
const getAllPacksNoMonth = () => {
    const storage = useStorage()
    const packs = [
        storage.firstRechargePack,
        ...storage.rechargePacks
    ]
    packs.sort((a, b) => {
        return a.currency - b.currency
    })
    return packs
}
const getNextPack = (pack: novel.RechargePackage) => {
    const packs = getAllPacks()
    return packs.find(item => item.currency > pack.currency)
}
const getNextPackNoMonth = (pack: novel.RechargePackage) => {
    const packs = getAllPacksNoMonth()
    return packs.find(item => item.currency > pack.currency)
}
// 充值面板的手势概率
export const rechargeGestureProbability = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    const firstRechargePack = storage.firstRechargePack
    const maxRechargePack = storage.maxRechargePack
    const num = storage.probability
    console.log('概率', num)
    if (storage.noRecharge) {
        // 当算到时限时礼包，但是没有限时礼包时，499兜底
        if (!firstRechargePack.packageId && num <= 50 && pack.currency === 499) {
            return true
        }
        // 没充值过
        switch (true) {
            // 限时礼包 50%
            case pack.packageId === firstRechargePack.packageId:
                if (num <= 50) {
                    return true
                }
                break

            // 4.99套餐 30%
            case pack.currency === 499:
                if (num > 50 && num <= 80) {
                    return true
                }
                break
        }
    } else {
        // 最高档位 + 1
        const nextPack = getNextPackNoMonth(maxRechargePack)

        // 当算到时限时礼包，但是没有限时礼包时，最高档位+1兜底
        if (!firstRechargePack.packageId && num <= 28 && nextPack && pack.packageId === nextPack.packageId) {
            return true
        }
        if (storage.hadMonthCard && num > 28 && num <= 58 && nextPack && pack.packageId === nextPack.packageId) {
            console.log(nextPack)
            return true
        }

        // 充值过
        switch (true) {
            // 限时礼包 28%
            case pack.packageId === firstRechargePack.packageId:
                if (num <= 28) {
                    return true
                }
                break

            // // 4.99套餐 30%
            // case pack.currency === 499:
            //     if(num > 28 && num <= 58) {
            //         return true
            //     }
            //     break

            // 充值过的最高充值档位 21%
            case pack.currency === maxRechargePack.currency:
                if (num > 58 && num <= 79) {
                    return true
                }
                break
            //  充值过的最高充值档位+1 21%
            case nextPack && nextPack.packageId === pack.packageId:
                if (num > 79 && num <= 100) {
                    console.log('19.99')
                    return true
                }
                break
        }
    }
    return false
}

// 充值面板月卡的手势概率
export const monthCardGestureProbability = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    const num = storage.probability
    const maxRechargePack = storage.maxRechargePack
    // 最高档位 + 1

    console.log('概率', num)
    if (!storage.noRecharge) {
        const nextPack = getNextPack(maxRechargePack)
        // 当算到月卡，但是已经购买过月卡，最高档位+1兜底
        if (storage.hadMonthCard && num > 28 && num <= 58 && nextPack && pack.packageId === nextPack.packageId) {
            return true
        }

        // 有充值
        switch (true) {
            // 9.9月卡 30%
            case pack.currency === 999:
                if (num > 28 && num <= 58) {
                    return true
                }
                break
        }
    } else {
        // 没有充值
        switch (true) {
            // 9.9月卡 20%
            case pack.currency === 999:
                if (num > 80 && num <= 100) {
                    return true
                }
                break

        }
    }
    return false
}

// 日常任务的月卡手势
export const dailyMonthCardGestureProbability = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    const num = storage.probability
    console.log('概率', num)
    if (storage.hadMonthCard) {
        // // 已有月卡
        // switch (true) {
        //     // 9.9月卡 30%
        //     case pack.currency === 999:
        //         if (num > 28 && num <= 58) {
        //             return true
        //         }
        //         break
        // }
    } else {
        // 没有月卡
        switch (true) {
            // 9.9月卡 25%
            case pack.currency === 999:
                if (num > 60 && num <= 85) {
                    return true
                }
                break
            // 19.9月卡 15%
            case pack.currency === 1999:
                if (num > 85 && num <= 100) {
                    return true
                }
                break

        }
    }
    return false
}

// 日常任务的充值套餐手势概率
export const dailyRechargeGestureProbability = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    const num = storage.probability
    console.log('概率', num)
    switch (true) {
        // 9.99档位 30%
        case pack.currency === 999:
            if (num <= 30) {
                return true
            }
            break

        // 4.99档位 30%
        case pack.currency === 499:
            if (num > 30 && num <= 60) {
                return true
            }
            break
    }

    return false
}

export const getSpuDurationDay = (spu: novel.GoodsSpu) => {
    const resources = spu.resources
    if(resources){
        const {durationDay} = JSON.parse(resources)
        return Number(durationDay)
    }else {
        return 0
    }
}
