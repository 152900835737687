<template>
  <div v-if="visible">
    <div :class="['download-wrapper', {dark: theme === 'dark'}]" :style="style">
      <div class="justify-between download-bar">
        <div class="items-center">
          <img class="logo" src="/images/logo-1.png" alt="">
          <div>
            <div class="title">StoryGo</div>
            <div class="description">{{$t('download_tips')}}</div>
          </div>
        </div>

        <div class="center btn" @click="downloadApp">{{$t('download')}}</div>

        <div class="close" v-if="closable" @click="closeDownload">
          <img src="/images/b-close.png" alt="">
        </div>
      </div>
    </div>
    <div class="download-holder"></div>
  </div>

</template>
<script lang="ts" setup>
import {ref, watch} from "vue"
import {handleDownload, importScript} from "@storygo/utils";
import {$t} from "@storygo/lang"

interface Props {
  closable: boolean
  position: string
  offset?: string
  theme: 'light' | 'dark'
  schema: string
}

const style = ref({})
const visible = ref(true)
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  closable = true,
  position = 'bottom',
  offset = '0px',
  theme = 'light',
  schema = 'main?tab=1'
} = defineProps<Props>()
watch(() => offset, (value) => {
  if (position === 'bottom') {
    style.value = `bottom: ${offset};`
  }
  if (position === 'top') {
    style.value = `top: ${offset};`
  }
}, {
  immediate: true,
})


const closeDownload = () => {
  visible.value = false
}
const downloadApp = () => {
    // handleDownload(schema)
  importScript('https://www.storygo.cc/lib/onelink.js').then(res => {
    var oneLinkURL = "https://storygo.onelink.me/dDXW";
    var mediaSource = {keys:["utm_source"],overrideValues:{"facebookwebee":"facebook2"},defaultValue:"click_download_btn"};
    var campaign = {keys:["utm_campaign"]};
    var adSet = {keys:["utm_adset"]};
    var ad = {keys:["utm_ad"]};
    var custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};
    var af_dp = {
      paramKey: "af_dp",
      keys: ["custom_scheme"],
      // defaultValue: `storygo%3A%2F%2F%2Fbook%3Fbid%3D1192`
      defaultValue: encodeURIComponent(`storygo:///${schema}`)
    };
    var result = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: oneLinkURL,
      afParameters: {
        mediaSource: mediaSource,
        afCustom: [
          af_dp,
          custom_ss_ui,
        ],
        campaign: campaign,
        adSet: adSet,
        ad: ad
      }
    });
    console.log(result)
    window.open(result.clickURL)
    // window.location.href = result.clickURL
    // console.log(e)
  }).catch(e => {
    console.log(e)
  })
}

</script>
<style scoped lang="less">
.download-holder {
  height: 120px;
}

.download-wrapper {
  //transform: translateZ(1000px);
  position: fixed;
  z-index: 999;
  background: #fff;
  height: 150px;
  width: 100%;
  border: 1px solid #eee;

  .download-bar {
    position: relative;
    padding: 30px;

    .logo {
      width: 84px;
      height: 84px;
      margin-right: 20px;
    }
    .title {
      font-size: 32px;
      font-weight: 600;
      color: #202020;
      line-height: 32px;
    }
    .description {
      font-size: 26px;
      font-weight: 400;
      color: #999999;
    }

    .btn {
      width: 177px;
      height: 72px;
      background: linear-gradient(270deg, #D400F1 0%, #FF4A50 100%);
      border-radius: 36px;
      font-size: 24px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      img {
        width: 32px;
      }
    }
  }
  &.dark {
    background: #333333;
    .title {
      color: #FFFFFF;
    }
    .description {
      color: #FFFFFF;
    }
  }
}
</style>
