import xhr from '@storygo/api/xhr';
import { getSign, getAuth, codeMap } from '@storygo/utils';
import {novel} from "@storygo/types/novel_baseTars.d";

// export utils listMemberFlows = (offset: number, size: number):Promise<novel.MemberFlowList> =>
//     xhr({
//         moduleName: 'novel',
//         funcName: 'listMemberFlows',
//         reqName: 'ListMemberFlowsReq',
//         resName: 'MemberFlowList',
//         reqData: {
//             offset,
//             size
//         },
//         auth: getAuth()
//     });

export const getBookById = (bookId: number, useCache: boolean):Promise<novel.Book> =>
    xhr({
        moduleName: 'novel',
        funcName: 'getBookById',
        reqName: 'GetBookByIdReq',
        resName: 'Book',
        reqData: {
            tId: getAuth(),
            bookId
        },
        useCache,
        ttl: 120
    });

export const listBooksBySubject = (subjectId: string, offset: number, size: number, cache = true):Promise<novel.BookList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listBooksBySubject',
        reqName: 'ListBooksBySubjectReq',
        resName: 'BookList',
        reqData: {
            subjectId,
            tId: getAuth(),
            offset,
            size
        },
        useCache: cache,
        ttl: 60
    });
export const listBookCategoriesBySubject = (subjectId: string, offset: number, size: number):Promise<novel.BookCategoryList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listBookCategoriesBySubject',
        reqName: 'ListBookCategoriesBySubjectReq',
        resName: 'BookCategoryList',
        reqData: {
            tId: getAuth(),
            subjectId,
            offset,
            size
        },
        useCache: true,
        ttl: 60
    });

export const listAllBookCategories = (offset: number, size: number):Promise<novel.BookCategoryList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listAllBookCategories',
        reqName: 'ListAllBookCategoriesReq',
        resName: 'BookCategoryList',
        reqData: {
            tId: getAuth(),
            offset,
            size,
            type: 0
        },
        useCache: true,
        ttl: 60
    });


export const listBooksByCategory = (categoryIds: string[], sortType: novel.ESortType, completeState: number, offset: number, size: number):Promise<novel.BookList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listBooksByCategory',
        reqName: 'ListBooksByCategoryReq',
        resName: 'BookList',
        reqData: {
            tId: getAuth(),
            categoryIds,
            offset,
            size,
            sortType,
            completeState: completeState || 0
        },
        useCache: true,
        ttl: 60
    });
export const getBookChapterList = (bookId: number,  offset: number, size: number):Promise<novel.ChapterList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'getBookChapterList',
        reqName: 'GetBookChapterListReq',
        resName: 'ChapterList',
        reqData: {
            tId: getAuth(),
            offset,
            size,
            bookId
        },
        useCache: true,
        ttl: 60
    });
export const getChapterLines = (bookId: number, chapterId:number,  offset: number, size: number):Promise<novel.ChapterLines> =>
    xhr({
        moduleName: 'novel',
        funcName: 'getChapterLines',
        reqName: 'GetBookChapterLinesReq',
        resName: 'ChapterLines',
        reqData: {
            tId: getAuth(),
            offset,
            size,
            chapterId,
            bookId,
            sign: getSign()
        },
        useCache: true,
        ttl: 60
    });
export const searchBooks = (word: string,  offset: number, size: number):Promise<novel.BookList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'searchBooks',
        reqName: 'SearchBooksReq',
        resName: 'BookList',
        reqData: {
            tId: getAuth(),
            offset,
            size,
            word,
        },
        useCache: true,
        ttl: 20
    });

export const getBookList = (sortType: number, page = 1, size = 10, cache = true):Promise<novel.BookList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'bookList',
        reqName: 'BookListReq',
        resName: 'BookList',
        reqData: {
            tId: getAuth(),
            offset: (page - 1) * size,
            size,
            sortType
        },
        useCache: cache,
        ttl: 60
    });

export const listBooksRankByCategory = (page = 1, size = 10):Promise<novel.BookList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listBooksRankByCategory',
        reqName: 'ListBooksRankByCategoryReq',
        resName: 'BookList',
        reqData: {
            tId: getAuth(),
            categoryId: 0,
            offset: (page - 1) * size,
            size,
            rankType: 2,
            excludeBooks: []
        },
        // useCache: true,
        // ttl: 60
    });


export const getConfigByKey = (configId: string,  confKey: string):Promise<novel.GetLatestConfigRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'getLatestConfig',
        reqName: 'GetLatestConfigReq',
        resName: 'GetLatestConfigRsp',
        reqData: {
            tId: getAuth(),
            configId,
            confKey
        },
        useCache: true,
        ttl: 60
    });
export const syncBookReadProgress = (data: Partial<novel.SyncBookReadProgressReq>):Promise<Response> =>
    xhr({
        moduleName: 'novel',
        funcName: 'syncBookReadProgress',
        reqName: 'SyncBookReadProgressReq',
        resName: '',
        reqData: {
            ...data,
            tId: getAuth(),
        }
    });
export const listUserBookHistory = (data: Partial<novel.ListUserBookHistoryReq>):Promise<novel.BookList> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listUserBookHistory',
        reqName: 'ListUserBookHistoryReq',
        resName: 'BookList',
        reqData: {
            ...data,
            tId: getAuth(),
        },
        // useCache: true,
        // ttl: 10
    });
export const removeBookReadHistory = (data: Partial<novel.RemoveBookReadHistoryReq>):Promise<Response> =>
    xhr({
        moduleName: 'novel',
        funcName: 'removeBookReadHistory',
        reqName: 'RemoveBookReadHistoryReq',
        resName: '',
        reqData: {
            ...data,
            tId: getAuth(),
        },
    });
export const reportBookInteractiveData = (data: Partial<novel.ReportBookInteractiveDataReq>):Promise<Response> =>
    xhr({
        moduleName: 'novel',
        funcName: 'reportBookInteractiveData',
        reqName: 'ReportBookInteractiveDataReq',
        resName: '',
        reqData: {
            ...data,
            tId: getAuth(),
        },
    });

export const listDailyTask = ():Promise<novel.ListDailyTaskRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listDailyTask',
        reqName: 'ListDailyTaskReq',
        resName: 'ListDailyTaskRsp',
        reqData: {
            tId: getAuth(),
        },
    });

export const drawRewardByTask = (data: Partial<novel.DrawRewardByTaskReq>):Promise<novel.DrawRewardByTaskRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'drawRewardByTask',
        reqName: 'DrawRewardByTaskReq',
        resName: 'DrawRewardByTaskRsp',
        reqData: {
            ...data,
            tId: getAuth(),
        },
    });


export const balance = ():Promise<novel.BalanceRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'balance',
        reqName: 'BalanceReq',
        resName: 'BalanceRsp',
        reqData: {
            tId: getAuth(),
        },
    });


export const listContinuousLogin7DaysTask = ():Promise<novel.ListContinuousLogin7DaysTaskRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'listContinuousLogin7DaysTask',
        reqName: 'ListContinuousLogin7DaysTaskReq',
        resName: 'ListContinuousLogin7DaysTaskRsp',
        reqData: {
            tId: getAuth(),
        },
    });


export const getInviteCodeBeActivatedTask = ():Promise<novel.InviteCodeBeActivatedTask> =>
    xhr({
        moduleName: 'novel',
        funcName: 'getInviteCodeBeActivatedTask',
        reqName: 'GetInviteCodeBeActivatedTaskReq',
        resName: 'InviteCodeBeActivatedTask',
        reqData: {
            tId: getAuth(),
        },
    });


export const activeInviteCode = (data: Partial<novel.ActiveInviteCodeReq>):Promise<Response> =>
    xhr({
        moduleName: 'novel',
        funcName: 'activeInviteCode',
        reqName: 'ActiveInviteCodeReq',
        resName: '',
        reqData: {
            ...data,
            tId: getAuth(),
        },
    });

export const getActiveInviteCodeTask = ():Promise<novel.ActiveInviteCodeTaskRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'getActiveInviteCodeTask',
        reqName: 'GetActiveInviteCodeTaskReq',
        resName: 'ActiveInviteCodeTaskRsp',
        reqData: {
            tId: getAuth(),
        },
    });


export const rechargePackage = ():Promise<novel.RechargePackageRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'rechargePackage',
        reqName: 'CommonReq',
        resName: 'RechargePackageRsp',
        reqData: {
            tId: getAuth(),
        },
        useCache: true,
        ttl: 60 * 5
    });


export const genPayOrder = (data: Partial<novel.GenPayOrderReq>):Promise<novel.GenPayOrderRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'genPayOrder',
        reqName: 'GenPayOrderReq',
        resName: 'GenPayOrderRsp',
        reqData: {
            ...data,
            tId: getAuth(),
            sign: getSign()
        },
    });

export const queryPayOrder = (data: Partial<novel.QueryPayOrderReq>):Promise<novel.QueryPayOrderRsp> =>
    xhr({
        moduleName: 'novel',
        funcName: 'queryPayOrder',
        reqName: 'QueryPayOrderReq',
        resName: 'QueryPayOrderRsp',
        reqData: {
            ...data,
            tId: getAuth(),
            sign: getSign()
        },
    });

export const getPrizeWheelConfig = (): Promise<novel.PrizeWheelConfig> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'getPrizeWheelConfig',
        reqName: 'GetPrizeWheelReq',
        resName: 'PrizeWheelConfig',
        reqData: {
            tId: getAuth(),
        },
    })
}
export const listLatestPrizeWheelBigBonus = (page: number, size = 10): Promise<novel.LatestPrizeWheelBigBonusList> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'listLatestPrizeWheelBigBonus',
        reqName: 'ListLatestPrizeWheelBigBonusReq',
        resName: 'LatestPrizeWheelBigBonusList',
        reqData: {
            tId: getAuth(),
            offset: size * (page - 1),
            size
        },
    })
}
export const listUserLatestPrizeWheelHitFlow = (page: number, size = 10): Promise<novel.UserPrizeWheelHitFlowList> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'listUserLatestPrizeWheelHitFlow',
        reqName: 'ListUserLatestPrizeWheelHitFlowReq',
        resName: 'UserPrizeWheelHitFlowList',
        reqData: {
            tId: getAuth(),
            offset: size * (page - 1),
            size
        },
    })
}
export const bet4PrizeWheel = (betAmount:number): Promise<novel.UserPrizeWheelHitFlowList> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'bet4PrizeWheel',
        reqName: 'BetPrizeWheelReq',
        resName: 'UserPrizeWheelHitFlowList',
        reqData: {
            tId: getAuth(),
            betAmount
        },
    })
}

export const bannerList = (showAt: number): Promise<novel.BannerRsp> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'bannerList',
        reqName: 'BannerReq',
        resName: 'BannerRsp',
        reqData: {
            tId: getAuth(),
            showAt
        },
        useCache: true,
        ttl: 30
    })
}

export const showPayerMax = (withoutRecharge = 0): Promise<novel.ShowPayerMaxRsp> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'showPayerMax',
        reqName: 'ShowPayerMaxReq',
        resName: 'ShowPayerMaxRsp',
        reqData: {
            tId: getAuth(),
            withoutRecharge
        },
        useCache: true,
        ttl: 60 * 2
    })
}

export const getGiftRechargePackage = (): Promise<novel.RechargePackageRsp> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'giftRechargePackage',
        reqName: 'CommonReq',
        resName: 'RechargePackageRsp',
        reqData: {
            tId: getAuth(),
        },
        // useCache: true,
        // ttl: 120
    })
}

export const getVipRechargePackages = (): Promise<novel.RechargePackageRsp> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'vipRechargePackage',
        reqName: 'CommonReq',
        resName: 'RechargePackageRsp',
        reqData: {
            tId: getAuth(),
        },
        useCache: true,
        ttl: 180
    })
}

export const genVipOrder = (data: Partial<novel.GenVipOrderReq>): Promise<novel.GenVipOrderRsp> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'genVipOrder',
        reqName: 'GenVipOrderReq',
        resName: 'GenVipOrderRsp',
        reqData: {
            ...data,
            sign: getSign(),
            tId: getAuth(),
        },
    })
}

export const getLast24HoursExpireGems = (): Promise<novel.Last24HoursExpireGemsRsp> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'getLast24HoursExpireGems',
        reqName: 'GetLast24HoursExpireGemsReq',
        resName: 'Last24HoursExpireGemsRsp',
        reqData: {
            tId: getAuth(),
        },
    })
}


export const listUserGemBill = (page = 1): Promise<novel.UserGemBillList> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'listUserGemBill',
        reqName: 'ListUserGemBillReq',
        resName: 'UserGemBillList',
        reqData: {
            tId: getAuth(),
            offset: (page -1) * 12,
            size: 12
        },
    })
}

export const listCoinRechargeFlow = (coinType = 1, page = 1): Promise<novel.CoinRechargeFlowList> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'listCoinRechargeFlow',
        reqName: 'ListCoinRechargeFlowReq',
        resName: 'CoinRechargeFlowList',
        reqData: {
            coinType,
            tId: getAuth(),
            offset: (page -1) * 12,
            size: 12
        },
    })
}

export const queryUserMaxRechargePayOrder = (): Promise<novel.PayOrder> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'queryUserMaxRechargePayOrder',
        reqName: 'QueryUserMaxRechargePayOrderReq',
        resName: 'PayOrder',
        reqData: {
            tId: getAuth(),
        },
    })
}

export const queryUserInviteTaskOverview = (): Promise<novel.UserInviteTaskOverview> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'queryUserInviteTaskOverview',
        reqName: 'QueryUserInviteTaskOverviewReq',
        resName: 'UserInviteTaskOverview',
        reqData: {
            tId: getAuth(),
        },
    })
}




export const listUserGrowableRank = (period: number): Promise<novel.UserGrowableRankList> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'listUserGrowableRank',
        reqName: 'ListUserGrowableRankReq',
        resName: 'UserGrowableRankList',
        reqData: {
            tId: getAuth(),
            rankType: 2,
            period,
            listSize: 20,
            maxSeq: 20
        },
    })
}


export const queryInviteUserActivityTotal = (): Promise<novel.InviteUserActivityTotal> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'queryInviteUserActivityTotal',
        reqName: 'QueryInviteUserActivityTotalReq',
        resName: 'InviteUserActivityTotal',
        reqData: {
            tId: getAuth(),
            period: 0,
        },
    })
}


export const queryCurrentActivityInfo = (activity: number): Promise<novel.ActivityInfo> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'queryCurrentActivityInfo',
        reqName: 'QueryCurrentActivityInfoReq',
        resName: 'ActivityInfo',
        reqData: {
            tId: getAuth(),
            activity,
        },
        useCache: true,
        ttl: 60
    })
}


export const reportTurnOnPush = (): Promise<Response> => {
    return xhr({
        moduleName: 'novel',
        funcName: 'reportTurnOnPush',
        reqName: 'ReportTurnOnPushReq',
        resName: '',
        reqData: {
            tId: getAuth(),
        },
    })
}




