<template>
  <van-popup v-model:show="visible" class="pay-method-popup" :lock-scroll="false" teleport="body" position="bottom" round :z-index="99">
    <div class="title">{{ $t('select_payment') }}</div>
    <div class="select-pay">
      <div v-for="(item, i) in getPayTypes(payTypes, pack)"
           :key="i"
           :class="['item', {active: i === selectPayTypeIndex}]"
      >
<!--        v-if="showPayerPay"-->
        <div class="center item-box"
             @click="selectPayType(i, item)"
        >

          <!--        <div class="center">-->
          <img :src="item.img" alt="" class="pay-img not-flip">
          <div class="center name-info">
            <div class="bonus" v-if="item.type === 'payermax'">
              <img src="/images/wallet/gift.png" alt="" class="percentage">
              {{$t('bonus')}}
            </div>
            <!--          <div class="name">{{ item.name() }}</div>-->
            <!--            <div class="name">Bonus</div>-->
          </div>
          <van-icon v-if="i === selectPayTypeIndex" class="radio" name="checked"/>
          <div class="radio" v-else></div>
          <!--        </div>-->

        </div>
      </div>

      <div v-if="!loaded">
            <div class="pay-item" v-for="(item, i) in 2" :key="i" >
              <van-skeleton class="center coin" style="width: 50%;margin: 0 auto" row="1"/>
              <van-skeleton class="tips" style="width: 60%;margin: 10px auto 0" row="1"/>
              <van-skeleton class="center money" row="1"/>
            </div>
      </div>
    </div>
    <div class="center pay-btn" @click="pay">{{ $t('pay_now') }}</div>
  </van-popup>

</template>
<script setup lang="ts">
import {ref, reactive, onMounted, watch, computed} from 'vue';
// import _ from 'lodash'
import { showPayerMax} from "@storygo/api/app";
import {novel} from "@storygo/types/novel_baseTars.d";
import {eventBus} from "@storygo/utils/emitter";
import {$t} from "@storygo/lang"
import {Popup as VanPopup, Icon as VanIcon} from 'vant'
// import {getPayMethods, PAYER_COUNTRYCODE} from "@/const";
import {useStorage} from "@/stores/storage";
import { usePay, useShowPayer} from "@/hooks";
import type { PayTypeItem} from "@/hooks";
import {getPayTypes, inPayerBlackList} from "@/components/payItems/common";
// utils selectPayTypeIndex = ref(-1);
const emits = defineEmits({})
const packageId = ref('')
const selectPayTypeItem = ref({} as PayTypeItem)
interface PayPopupParams {
  packageId: string
  vip: boolean
}

const {
  orderFrom = 0
} = defineProps<{
  orderFrom?: number
}>()

const visible = ref(false)
const selectPayTypeIndex = ref(0)
// utils showPayer = ref(false)
const type = ref('recharge')
const storage = useStorage()
const pack = ref({} as novel.RechargePackage)
const loaded = ref(false)
const payText = defineModel('payText', {type: String, default: ''})
const {payTypes, showPayer, getShowPayerMax} = useShowPayer(() => {
  selectPayTypeIndex.value = 0
  emits('update:payItem', 0)
  loaded.value = true
  selectPayTypeItem.value = payTypes.value[0]
})
const { pay: toPay } = usePay({
  orderFrom,
  prePayDone:() => {
  },
  payDone: () => {
    visible.value = false
  }
})
// count.value = 0

const showPayerPay = computed(() => {
  return !inPayerBlackList(pack.value, payTypes.value[selectPayTypeIndex.value])
})

// utils payItemsList = ref([{}, {}, {}, {}, {}, {}] as novel.RechargePackage[])
// // utils items = _.chunk(payItemsList, 2)
// utils visibility = useDocumentVisibility()


const selectPayType = (index: number, payTypeItem: PayTypeItem) => {
  selectPayTypeIndex.value = index
  emits('update:payItem', index)
  payText.value = $t('pay_now')
  selectPayTypeItem.value = payTypeItem
}

const pay = async () => {
  if (payTypes.value.length === 1) {
    selectPayTypeIndex.value = 0
  }
  const payItem = selectPayTypeItem.value
  if(!packageId.value) return;
  toPay({
    payItem,
    packageId: Number(packageId.value),
    payType: type.value,
    method: payItem.type
  })
}



defineExpose({
  pay
})

const getCardShow = async () => {
  if(!window.appUserInfo?.token) return;
  const res = await showPayerMax(1)
  if (res.show) {
    storage.setShowLayerCard(true)
  }else {
    storage.setShowLayerCard(false)
  }
}

onMounted(() => {
  // payTypes.value = [...osPays]
  getShowPayerMax()
  getCardShow()
  eventBus.$on('openPayPopup', (params: PayPopupParams) => {
    if(!params.packageId) return;
    packageId.value = params.packageId
    const currentPack = storage.getPackByPackageId(Number(params.packageId))
    if(currentPack){
      pack.value = currentPack
    }
    if(params.vip){
      type.value = 'vip'
    }else {
      type.value = 'normal'
    }
    if (showPayer.value) {
      visible.value = true
    } else {
      pay()
    }
  })
  eventBus.$on('closePayPopup', () => {
    visible.value = false
  })
  eventBus.$on('reload', () => {
    // payTypes.value = [...osPays]
    getShowPayerMax()
    getCardShow()
  })
})
</script>
<style scoped lang="less" src="./index.less">

</style>
<style lang="less">
.pay-method-popup {
  --van-popup-background: #fff;
}
</style>