export default {
    "next": "Susunod",
    "login": "Mag-login",
    "login_tips": "Umaasa kami na masiyahan ka sa romantikong paglalakbay dito",
    "set_password": "Itakda ang password",
    "enter_password": "Mangyaring ipasok ang iyong password",
    "verify_code": "Code ng pagpapatunay",
    "enter_code": "Mangyaring ipasok ang 6 na digital na code ng pagpapatunay sa email",
    "let_start": "Simulan na natin",
    "let_start_tips": "Umaasa kami na masiyahan ka sa romantikong paglalakbay dito",
    "sign_facebook": "Mag-sign in gamit ang FaceBook",
    "sign_google": "Mag-sign in gamit ang Google",
    "sign_email": "Mag-sign in gamit ang Email",
    "language_title": "Itakda ang mga kagustuhan sa wika ng pagbabasa",
    "current_lang": "kasalukuyang wika",
    "en": "EN",
    "indonesian": "Indonesian",
    "personal_data": "Personal na data",
    "save": "I-save",
    "nickname": "Palayaw",
    "sign_out": "Mag-sign out",
    "loading": "naglo-load",
    "success": "Matagumpay",
    "reading_record": "Talaan ng pagbabasa",
    "empty": "Walang laman",
    "last_read": "Huling binasa",
    "chapter": "kabanata",
    "remove": "Alisin",
    "search": "Maghanap",
    "search_empty": "Walang makitang mga nobela na may kaugnayan sa \"{{var}}\"",
    "login_tips_2": "Mag-login para makita ang mas maraming tampok.",
    "register_or_login": "Magrehistro/Mag-login",
    "more": "Higit pa",
    "reads": "Mga Pagbasa",
    "read": "Basahin",
    "last_chapter": "Huling Kabanata",
    "all_chapter": "Lahat ng Kabanata",
    "contents": "Mga Nilalaman",
    "all": "Lahat",
    "completed": "Nakumpleto",
    "ongoing": "Patuloy",
    "most_popular": "Pinakapopular",
    "new_arrival": "Bagong Dumating",
    "invite_friends": "Ibahagi ang code na ito sa iyong mga kaibigan. Kapag na-activate nila ito sa pahinang ito, pareho kayong makakakuha ng {{coins}} mga gem. Maaaring gamitin ang bawat code ng imbitasyon hanggang sa 50 beses.",
    "invite_friends_nums": "<span class=\"blue\">Inimbitahan ko ang {{n}} na mga kaibigan at nagawa</span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>",
    "guide_title": "Pamagat ng Gabay",
    "guide_content": 0,
    "active_code": "Aktibahin ang code ng imbitasyon",
    "active_tips": "Kumuha ng isang invitation code mula sa iyong mga kaibigan, at kapag matagumpay na na-activate, makakatanggap ka ng {{coins}} mga gintong bato. Tip: Hindi mo magagamit ang iyong sariling invitation code; dapat kang kumuha ng isa mula sa iba!",
    "login_now": "Mag-login ngayon",
    "task_1_title": "Regalong Pagtanggap",
    "task_1_desc": "Isang regalo ng pagtanggap para sa mga bagong dating, na awtomatikong idineposito sa iyong wallet",
    "view_detail": "Tingnan ang mga detalye",
    "claim": "Mag-claim",
    "invite_friend": "Imbitahan ang mga Kaibigan",
    "my_invite_code": "Aking code ng imbitasyon",
    "copy": "Kopyahin",
    "task_2_title": "Araw-araw na Pag-check-in",
    "task_2_desc": "Mag-check in araw-araw, at makuha ang bonus.",
    "task_3_title": "Magbasa ng mahigit sa {{min}} minuto",
    "task_3_desc": "Magbasa ng kabuuang {{min}} minuto ngayong araw para kumita ng mga gantimpala.",
    "task_page_label": "Mga Gantimpala",
    "label_wallet_purchase_coins": "Bumili ng mga barya",
    "go": "Pumunta",
    "done": "Tapos na",
    "copy_text": "Ang aking StoryGo invite code ay [ {{code}} ],i-activate ito at kumita ng 50 Coins: [https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "Ang invite-code ay nakopya na.",
    "active_success": "Matagumpay na aktibasyon. Ang gantimpala na 50 coin ay naipadala na sa iyong wallet.",
    "error_code_limit": "Ang activation code ay naabot na ang limit ng paggamit.",
    "error_had_activated": "Ang account na ito ay na-activate na gamit ang code",
    "error_invalid_code": "Di-wasto ang activation code",
    "download_tips": "Walang hanggang mga Kuwento sa APP",
    "download": "I-download",
    "label_book_praise": "Rating",
    "label_book_prologue": "Prologo",
    "login_success": "Tagumpay ang pag-login",
    "msg_reset_password_repeat": "Mangyaring ulitin ang bagong password",
    "msg_reset_password_email": "Mangyaring ipasok ang iyong email address upang i-reset ang iyong password",
    "label_reset_password": "I-reset ang password",
    "label_send_email": "Magpadala ng email",
    "msg_login_email_code_sent": "Ang verification code ay matagumpay na naipadala sa iyong email",
    "msg_feedback_error": "Nabigo ang pagpapadala, mangyaring subukan muli mamaya",
    "save_userinfo": "Matagumpay na nai-save",
    "label_load_image_fail": "Nabigo ang pag-load ng larawan",
    "paid_chapters": "Mga Bayad na Kabanata",
    "unlock_price": "Ang pag-unlock ng kabanata ay nangangailangan ng {{coin}}",
    "download_claim_coin": "I-download ang app upang mag-claim ng {{coin}} at buksan ang higit pang nakakabighaning mga kabanata.",
    "label_book_chapters": "{{num}}  Kabanata",
    "label_cancel": "Kanselahin",
    "msg_common_state_empty": "Walang laman dito",
    "clear_reading_record": "Sigurado ka bang nais mong burahin ang rekord ng pagbasa?",
    "clear_successful": "Matagumpay na inalis ang talaan.",
    "label_search_trending": "Nagte-trending na mga Paghahanap",
    "task_login_tips": "Mag-login upang makakuha ng Bonus na Coins",
    "label_new_arrival": "Mainit na Bagong Labas",
    "had_activated": "Ang account na ito ay na-activate na gamit ang code",
    "activate": "I-activate",
    "task_5_title": "Ibahagi sa Facebook",
    "task_5_desc": "Ibahagi ang anumang aklat sa plataporma ng Facebook",
    "bigwheel_redeem_label": "Mag-load",
    "coin_tips_1": "Kulang ang mga coins, mangyaring mag-load.",
    "coin_tips_2": "Account: {{n}} coins (kailangan {{m}} coins)",
    "bigwheel_rule_1": "1. Sumali sa Lucky Wheel draw na may garantiyang 100% na pagkakataon ng pagkapanalo ng mga premyo.",
    "bigwheel_rule_2": "2. Ang isang draw ng gulong ay nagkakahalaga ng 10 gold coins, ang sampung draw ay nagkakahalaga ng 100 gold coins, at ang limampung draw ay nagkakahalaga ng 500 gold coins. Kapag mas maraming sunod na draw, mas mataas ang tsansa ng pagkakaroon ng mahahalagang premyo!",
    "bigwheel_rule_3": "3. Ang mga premyo na nakakamtan sa laro ay awtomatikong ipinamamahagi. Ang mga gold coins ay direkta nai-credit sa iyong gold coin wallet.",
    "bigwheel_rule_4": "4. Ang laro na ito ay hindi konektado sa Apple Inc. Lahat ng karapatan sa pangwakas na pagsasalin ay nauukit kay StoryGo.",
    "bigwheel_balance_label": "Balans",
    "no_data_1": "Walang data",
    "msg_room_coin_notice": "Congrats! Ang [{{who}}] nanalo ng {{coin}} coins!",
    "top_up": "Top-up",
    "sign": "Mag-sign up",
    "sign_desc": "Isang regalo ng pagtanggap para sa mga bagong dating, na awtomatikong idineposito sa iyong wallet",
    "first_recharge": "May limitadong alok na oras",
    "first_recharge_desc": "Tapusin ang Limitadong alok na oras para makakuha ng bonus",
    "unlock_chapter": "I-unlock ang {{n}} kabanata",
    "unlock_chapter_desc": " I-unlock ang isang premium na kabanata upang makatanggap ng premyo.",
    "comment_book": "Magbigay ng komento sa isang aklat",
    "comment_book_desc": "Sumulat ng pagsusuri para sa iyong paboritong libro at magkaruon ng pagkakataon na manalo ng mga premyo!",
    "my_coins": "Aking mga Coins",
    "recharge_title": "Inirerekomendang pack ng regalo",
    "bonus": "Bonus",
    "buy_coins": "Bumili ng Coins",
    "my_balance": "Aking balans",
    "apple_pay": "Apple Pay",
    "other_pay": "Top Piliin",
    "google_pay": "Google Pay",
    "pay_now": "Bayaran Ngayon",
    "label_balance": "mga barya",
    "select_payment": "Mangyaring pumili ng isang paraan ng pagbabayad",
    "select_package": "Mangyaring pumili ng isang package ng pag-load",
    "wallet_title": "Wallet",
    "coins_balance": "Balans",
    "new_comer": "Bagong dating",
    "extra_coins": "Extra {{n}} coins",
    "daily_task_label": "Araw-araw na Gawain",
    "label_pay_success": "Matagumpay na Pagbabayad!",
    "app_update_title": "Mangyaring i-update sa pinakabagong bersyon.",
    "restore_label": "Ibawi",
    "history_label": "Kasaysayan",
    "newcomer_pack": "May limitadong alok na oras",
    "recommend_pack": "Inirerekomendang package",
    "collection_month": "Buong/Buwan",
    "now_get_icons": "Kuha ng {{n}}  agad",
    "monthly_get_icons": "Kuha ng {{n}} mga Gems/Buwan",
    "recharge_feedback_msg": "May problema sa pagrecharge?",
    "feedback_label": "Feedback",
    "coin_label": "Mga barya",
    "gem_label": "Mga Gems",
    "restore_tips": "Walang maibabalik na mga subscription",
    "recharge_label": "Mag-top up",
    "sign_days": "Nacheck-in {{n}} araw",
    "vip_daily_task": "Bonus sa buwanang package",
    "other": "Iba",
    "limited_recharge_pack": "May limitadong alok na oras",
    "expire_tips": "Mag-expire sa {{time}}",
    "hot_pack": "Sikat na package",
    "continuous_checkin_day": "Nacheck-in {{n}} araw",
    "svip_login_reward": "Bonus premium na $9.9",
    "vip_login_reward": "Bonus premium na $19.9",
    "dailysign_popup_btn": "Kumuha ng mas marami mula sa Rewards Center",
    "checked_in": "Nacheck",
    "limit_time_recharge_title": "May limitadong alok na oras",
    "recharge_pack_tips": "Tapusin ang Limitadong alok na oras para makakuha ng bonus",
    "balance_nsufficient_tips": "Ang iyong balanse ay hindi sapat, mangyaring magtop up",
    "had_coins_tips": "Mayroon ka: {{n}} Mga Barya | {{m}} Mga Gems",
    "dailysign_popup_title": "Congrats",
    "pay_method_label": "Pamamaraang Pagbabayad",
    "at_once": "Agad",
    "daily_login": "Araw-araw na login",
    "card_coin_suffix": "Buong/Buwan",
    "card_gems_suffix": "Buwan",
    "vips_rule_title": "Tungkol sa Package sa Buwan:",
    "vips_rule_1": "1.Kapag matagumpay na binili ang $9.9-buwang-package, agad kang tatanggap ng 1,000 gold coins, at maaari kang makatanggap ng 20 gems bawat araw para sa 30 sunud-sunod na araw (600 gems sa kabuuan); Kapag matagumpay na binili ang $19.9-buwang-package, agad kang tatanggap ng 2,000 gold coins, at maaari kang makatanggap ng 40 gems bawat araw (600 gems sa kabuuan) para sa 30 sunud-sunod na araw.",
    "vips_rule_2": "2.Kung bumili ka ng iba't ibang mga package ng buwan, ang mga gantimpala ng gem na natatanggap mo araw-araw ay magiging magkasunod; kung bumili ka ng parehong mga package ng buwan, ang mga gantimpala ng gem na natatanggap mo araw-araw ay hindi magiging magkasunod, ngunit magpapahaba lamang ito ng tagal ng katumbas na card ng buwan.",
    "vips_rule_3": "3.Ang mga gems na natatanggap araw-araw ay may bisa sa loob ng 30 araw, mangyaring mag-ingat.",
    "vips_rule_4": "",
    "vips_rule_5": "Ang buwanang package ay maaaring magamit sa loob ng 24 oras mula sa pagbili at maaari mong tamasahin ang mga kaugnay na benepisyo.",
    "check_in": "Check in",
    "day_index": "Hindi.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Mga Gems </span> ay malapit nang mag-expire",
    "balance_label": "Balansya",
    "wallet_rule_title": "Tungkol sa Mga Gems",
    "wallet_rule_1": "Ang mga gems ay maaaring gamitin upang i-unlock ang mga kabanata. Kapag nag-uunlock ng mga kabanata, ang coins at gems ay may parehong halaga, 1 coin = 1 gem.",
    "wallet_rule_2": "Ang mga gems ay mag-eexpire. Mangyaring tandaan na ang mga gems na natanggap mula sa top-up at buwanang-package ay may bisa sa loob ng 30 araw at ang mga gems na nakuhang sa pamamagitan ng mga araw-araw na gawain ay may bisa sa loob ng 7 araw.",
    "wallet_rule_3": "Kapag nag-uunlock ka ng mga kabanata, ang mga gems ay una munang ginagamit.",
    "read_task_title": "Gawain sa Pagbasa",
    "share_task_title": "Gawain sa Pagbabahagi",
    "comment_task_title": "Gawain sa Pagkomento",
    "vip_task_title": "Bonus premium na $9.99",
    "svip_task_title": "Bonus premium na $19.99",
    "vip_page_title": "Package ng Buwan",
    "pay_bonus_label": "Bonus",
    "daily_gems": "Araw-araw na {{n}} Mga Gems",
    "history_page_title": "Kasaysayan",
    "recharge_title_1": "Magtop up",
    "gems_will_expire_btn": "Mga Detalye",
    "gems_history_tips": "Iayos ayon sa petsa ng pag-expire",
    "unlock_coins": "Kailangan: {{n}} Piso o Gintong Bato",
    "history_recharge_title": "Magpaload",
    "history_game_title": "Suwerte Draw",
    "history_recharge_given_title": "Bonus sa Pagpaload",
    "extar_gems_btn": "Extra Mga Gems",
    "watch_ad_task_title": "Manood ng mga video",
    "watch_ad_task_desc": "Matanggap ang gantimpala para sa bawat video na pinanood ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Ang gantimpala ay matagumpay na nakuha.",
    "task_finish_title": "Ang gawain ay natapos na.",
    "draw_width_ad_toast": "Congratulations! Nakakuha ka ng  {{n}} at karagdagang  {{m}}.",
    "draw_toast": "Pagbati! Nakakuha ka na ng  {{n}}.",
    "draw_dialog_btn1": "Mag-claim lamang ng  {{n}}.",
    "draw_dialog_btn2": "Mag-claim ng  {{n}} at karagdagang  {{m}}.",
    "history_ads_given_title": "Manood ng mga video",
    "ad_extra_rewards_title": "Bonus pagkatapos ng gawain.",
    "label_editor_picks": "Pili ng Editor",
    "label_genre": "Henero",
    "label_main_tab_home": "Tuklasin",
    "label_reader_choice": "Mataas na Ranggo",
    "invite_bonuses": "Mag-anyaya ng bagong gumagamit at kumuha ng hanggang {{n}} mga bonus.",
    "invite_friend_btn": "Mag-anyaya ng iyong kaibigan",
    "how_get_bonuses": "Paano makakuha ng mas maraming mga bonus",
    "invite_tips_1": "Ang iyong kaibigan ay nagbukas at nagbasa ng 5 kabanata",
    "invite_tips_2": "Kapag nag-recharge ang iyong mga kaibigan, makakatanggap ka ng 10% balik sa gems, hanggang sa 150 gems bawat kaibigan.",
    "invite_tips_3": "Sa tuwing nagtatapos ang iyong kaibigan ng isang aksyon, makakatanggap ka ng agarang gantimpala.",
    "how_invite_friend": "Paano mag-imbita ng kaibigan",
    "invite_tips_5": "Mag-click sa pindutan sa ibaba upang lumikha ng iyong natatanging link ng imbitasyon at ibahagi ito sa iyong mga kaibigan",
    "invite_tips_4": "Ang iyong kaibigan ay nag-download ng StoryGo sa pamamagitan ng link na iyong ibinahagi",
    "reward_history_title": "Kasaysayan ng gantimpala",
    "reward_history_tips_1": "Kumpleto kang nakakuha ng {{n}} mga gyems mula sa pag-imbita na aktibidad.",
    "reward_history_tips_2": "Matagumpay kang nag-imbita ng {{n}} mga kaibigan at {{m}} mga gintong bato.",
    "reward_history_tips_3": "Kumita ka ng {{n}} mga gyems mula sa gawain ng iyong inanyayahan na mga kaibigan na nagbubukas ng 5 chapters.",
    "reward_history_tips_4": "Nakatanggap ka ng {{n}} mga gyems bilang gantimpala mula sa pagrecharge ng iyong mga kaibigan",
    "daily_invite_friend_title": "Mag-imbita ng mga kaibigan",
    "daily_invite_friend_desc": "Imbitahin ang iyong mga kaibigan na sumali sa StoryGo at kumita ng malalaking gantimpala.",
    "recharge_panel_ad_tips": "I-punuan ang iyong StoryGo package para sa maraming book coins at gems. Madaling i-unlock ang premium na mga kabanata at mag-enjoy ng libreng pagbabasa para sa 7 hanggang 30 araw pagkatapos ng pagbili, na may instant activation.",
    "invite_more_friend": "Mag-imbita ng higit pang mga kaibigan at magkaroon ng higit pang mga Gems!",
    "history_invite_unlock_title": "Ang inimbitahang gumamit ay nagbukas ng 5 kabanata",
    "history_invite_recharge_title": "Ang inimbitahang gumamit ay nag-recharge",
    "days": "Araw",
    "continue_read_app": "Magpatuloy sa Pagbabasa sa App",
    "download_app_tips": "Para sa mas kapana-panabik na nilalaman, mangyaring mag-download ng StoryGo",
    "invite_tips_6": "Mag-imbita ng isang bagong mambabasa upang humingi ng 15 gems. Maaari kang kumita ng hanggang sa 300 gems bilang gantimpala.",
    "history_invite_success_title": "Matagumpay na inimbita ang isang user",
    "evaluation_task_title": "I-rate ang StoryGo",
    "evaluation_task_desc": "3 Hakbang upang makuha ang mga gantimpala",
    "invite_page_title": "Mag-imbita ng mga bagong gumagamit na gamitin ito nang magkasama at tumanggap ng mga gantimpalang gem!",
    "invite_page_title_2": "Para sa bawat bagong user na iniimbita mo, makakakuha ka.",
    "invite_page_title_3": "Para sa mga bagong user na bayaran, maaari kang makatanggap din ng {{n}} gantimpala ng gem. Mas marami kang inimbita, mas marami kang makukuha.",
    "my_invite_record": "Ang Aking Mga Talaan ng Paanyaya",
    "successfully_invited": "Tagumpay na Pag-anyaya",
    "earnings_gem": "Aking Kita (Bato)",
    "how_invite_title_1": "Mag-anyaya ng mga Kaibigan",
    "how_invite_title_2": "Ang kaibigan ay nakumpleto ang pagpaparehistro",
    "how_invite_title_3": "Ang mga gantimpala ay dumating",
    "invite_h5_title": "Iimbitahan ka naming gamitin ang StoryGo kasama-sama",
    "got_gem_label": "Benepisyo para sa mga baguhan",
    "accept_invitation": "Tanggapin ang imbitasyon",
    "join_people": " Ang mga tao ay nakilahok sa kaganapan",
    "recommended_content": " Inirerekomendang nilalaman",
    "to_app_tip": "Mas kapana-panabik na nilalaman sa StoryGo",
    "turn_on_notify_title": "Buksan ang mga notification",
    "turn_on_notify_desc": "Buksan ang mga notification ng mensahe upang makatanggap ng mga gantimpala",
    "label_help_center": "Sentro ng Tulong",
    "ai_become_writer_btn": "Maging Isang Manunulat  ",
    "ai_offer_title": "Ano ang Inaalok Namin  ",
    "ai_offer_item_label_1": "Mga Tool sa Paglikha  ",
    "ai_offer_item_value_1": "Libreng Pagsubok na Quota para sa Mga Tool sa Paglikha  ",
    "ai_offer_item_label_2": "Mga Gantimpalang Pera  ",
    "ai_offer_item_value_2": "Lumahok sa mga kumpetisyon sa paglikha upang manalo ng gantimpalang $200  ",
    "ai_offer_item_label_3": "Nakatalagang Manunulat  ",
    "ai_offer_item_value_3": "Maging isang nakatalagang manunulat sa platform at tumanggap ng malaking bahagi ng buwanang subscription  ",
    "ai_authors_say_title": "Ano ang Sinasabi ng Aming Mga May-akda  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"Ang tool sa pagsusulat ng AI na ito ay parang isang makapangyarihang katulong, simple at madaling gamitin, na nagpapahintulot sa bawat gumagamit na madaling makapagsimula. Ang mataas na kalidad na output ay ginagawang kasiya-siya ang paglikha, at ang karanasan ng gumagamit ay tiyak na ang pinakalubos na kasiyahan sa pagsusulat.\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "Napaka kamangha-manghang tool sa pagsusulat ng AI na ito! Madali itong gamitin, simpleng patakbuhin, at nakagawa ng mataas na kalidad na mga gawa. Pinapayagan akong madaling ipahayag ang aking pagkamalikhain at tamasahin ito!  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "Ang tool sa pagsusulat ng AI na ito ay isang biyaya para sa mga lumikha. Ang simpleng interface nito ay nagpapadali para sa mga baguhan na gamitin. Ang kalidad ng mga kwentong nilikha ay nakakagulat. Ang pagsusulat gamit ito ay talagang isang kasiya-siyang paglalakbay ng inspirasyon.  ",
    "ai_why_choose_title": "Bakit Pumili sa Amin  ",
    "ai_why_choose_item_label_1": "Masigasig na koponan patnugot  ",
    "ai_why_choose_item_value_1": "Isang propesyonal at mahusay na pangkat patnugot ang nagbibigay sa iyo ng 6 na buwang proteksyon para sa mga bagong manunulat, kung saan ang mga manuskrito na nilikha ay maaaring mag-enjoy ng green submission guarantee channel.  ",
    "ai_why_choose_item_label_2": "Mabisang mga tool sa paglikha  ",
    "ai_why_choose_item_value_2": "Madaling likhain, maikling siklo ng paglikha, mataas na kahusayan sa monetization  ",
    "ai_why_choose_item_label_3": "Garanti ng Kaligtasan  ",
    "ai_why_choose_item_value_3": "Isang propesyonal na legal team ang nagpoprotekta sa mga karapatan ng iyong mga likha  ",
    "ai_create_story_btn": "Lumikha ng iyong mga kwento  ",
    "ai_contact_btn": "Makipag-ugnayan sa koponan ng mga patnugot  ",
    "ai_dialog_title": "Maligayang pagdating sa StoryGo!  ",
    "ai_dialog_desc": "Gamitin ang mga tool sa paglikha ng StoryGo, maaari ka ring maging isang sikat na manunulat ng kwento, halika at sumali sa amin!  ",
    "ai_dialog_part1_title": "Libreng Pagsubok  ",
    "ai_dialog_part1_item1": "Mangyaring mag-login sa aming website ng PC https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "Sumali sa WhatsApp group at makipag-ugnayan sa administrador upang makakuha ng libreng pagsubok ng tool sa paglikha.  ",
    "ai_dialog_part2_title": "Kreatibong Kompetisyon  ",
    "ai_dialog_part2_item1": "Mangyaring mag-login sa aming website ng PC https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "Makilahok sa kompetisyon ng paglikha, manalo ng premyo sa pera, bagong mga oportunidad.  ",
    "ai_dialog_part2_item3": "Kung mayroon kang anumang mga tanong, mangyaring makipag-ugnayan sa amin sa Contact@storygo.cc  ",
    "ai_dialog_copy_email": "Kopyahin ang opisyal na link ng website  ",
    "ai_url_copy_tips": "Matagumpay na nakopya ang link  "
}