export default {
    errors: {
        unknown_exception: 'Unknown exception',
        validation_failed: 'Parameter validation failed',
        too_frequent: 'Operation too frequent, please retry later',
        reauthorization: 'Token verification failed, reauthorization required',
        verification_failed: 'Method signature verification failed',
        phone_not_supported: 'Current phone number is not supported',
        phone_too_failed: 'Too many failed verification attempts for this phone number, please retry after 24 hours',
        sms_incorrect: 'SMS verification code is incorrect',
        phone_not_available: 'Login with this phone number is not available at the moment',
        invalid_zroleid: 'Invalid ID',
        zroleid_exists: 'ID already exists',
        prohibited_words: 'Content contains prohibited words, please modify and try again',
        insufficient_balance: 'Insufficient account balance',
        account_deactivated: 'Account has been deactivated',
        already_friends: 'You are already friends',
        friend_request: 'The other party has sent a friend request to you',
        task_incomplete: 'Task is incomplete'
    },
    "year_ago": "years ago",
    "month_ago": "months ago",
    "week_ago": "weeks ago",
    "day_ago": "days ago",
    "hour_ago": "hours ago",
    "minute_ago": "minutes ago",
    "second_ago": "seconds ago",
    "just_now": "just now",
}