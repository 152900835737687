import levelup from 'levelup'
// import leveljs from 'level-js'
import leveljs from 'level-js'

const db = levelup(leveljs('storygo'))
interface ExpireParams {
  expire_time: number
  data: any
  key: string
}

interface GetOption {
  asBuffer: boolean
}

interface SetOption {
  json: boolean
}

export default {
  parse(str: string){
    if(str){
      const value = JSON.parse(str)
      return value
    }
    return ''
  },

  stringify(value: any){
    if(value){
      return JSON.stringify(value)
    }
  },


  async get(key: string, option:GetOption = {asBuffer: false}){
    try {
      const payload: string = await db.get(key, { asBuffer: option.asBuffer })
      const {
        expire_time,
        data
      } = this.parse(payload) || {}
      const value = await this.expire({
        expire_time,
        key,
        data
      })
      console.log('使用缓存')
      return value
    }catch (e){
      if(e instanceof Error){
        console.log('没有缓存',e.message)
      }
      return ''
    }
  },

  async set(key: string, value: any, seconds = 0, option: SetOption = {json: true}){
    try {
      const payload = {
        expire_time: (() => {
          if(!seconds) return 0
          const now = Date.now()
          return now + seconds * 1000
        })(),
        data: value
      }
      await db.put(key, this.stringify(payload))
    }catch (e){
      if(e instanceof Error){
        console.log('缓存写入失败',e.message)
      }
    }
  },

  async del(key:string){
    await db.del(key)
    return
  },

  async expire({expire_time = 0, data, key}: ExpireParams){
    if(!expire_time) return data
    const now = Date.now()
    if(now > expire_time){
      console.log('缓存过期')
      await this.del(key)
      return ''
    }
    return data
  }
}
