import 'vant/lib/index.css';
import './assets/main.less'
import 'core-js/actual/array/flat-map'
// import {Toast} from 'vant'
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import i18n, {initI18n} from '@storygo/lang'
import App from './App.vue'
import router from './router'
import mixins from "@/mixins";
import {
    Lazyload,
    Skeleton,
    SkeletonTitle,
    SkeletonImage,
    SkeletonAvatar,
    SkeletonParagraph,
    Dialog,
    Locale
} from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import {eventBus} from "@storygo/utils/emitter";
import {showToast} from '@/components/toast/index'
// @ts-ignore
// import {ToastType} from "vant/lib/toast/types";
import {checkOs, getAppVersion, getBrowserLang, getLang, getURLParamValue, sdkPromise} from "@storygo/utils";
import {changeLanguage} from "i18next";
import {analytics} from "@storygo/utils/analytics";
import SContainer from "./components/container/index.vue"
import {DEFAULT_LCID, LOWER_CASE_LANG_MAP_LCID} from "@/const";
import {initSentry} from "@storygo/utils/sentry";
// import GlobalPlugin from "./plugins/global"
initI18n()
// window.$t = i18n.t
if (typeof window.global === 'undefined') {
    window.global = window;
}

const app = createApp(App)
if(process.env.VUE_APP_DEBUG === 'false'){
    initSentry(app)
}
Locale.use('en-US', enUS);
// app.use(initI18n)
// app.use(GlobalPlugin)
// app.mixin(mixins)
app.use(Dialog);
app.use(Skeleton);
app.use(SkeletonTitle);
app.use(SkeletonImage);
app.use(SkeletonAvatar);
app.use(SkeletonParagraph);
app.use(createPinia())
app.use(router)
app.use(Lazyload);
window.lang = ''

window.cacheData = {}
console.log(navigator.language)
eventBus.$on('toast', (message: string, type: string) => {
    showToast(message, 'warning')
})

const init = () => {
    sdkPromise('getUserInfo').then(async (userInfo) => {
        if (userInfo) {
            console.log('app内')
            console.log(userInfo)
            window.isApp = true
            window.appUserInfo = userInfo
            getAppVersion(userInfo.userAgent)
            window.lcid = userInfo.lcid
            const lang = getLang(userInfo.lcid)
            localStorage.setItem('lang', lang)
            window.countryCode = userInfo.countryCode
            if(userInfo.lcid === 1025) {
                    document.querySelector('html')?.classList.add('arab-layout')
            }
            const versionStr = getAppVersion(userInfo.userAgent)
            if (window.mockVersion || (versionStr === '9.9.9' && !window.VConsole)) {
                import('vconsole').then((res) => {
                    new res.default()
                })
            }
            changeLanguage(lang)
            window.os = checkOs()
            app.mount('#app')
        } else {
            console.log('非app')
            window.isApp = false
            if(process.env.VUE_APP_DEBUG === 'false'){
                analytics()
            }
            // if (
            //     document.documentElement.clientWidth > 750 &&
            //     document.location.href.indexOf('userAgreement') === -1 &&
            //     document.location.href.indexOf('privacyPolicy') === -1 &&
            //     document.location.href.indexOf('uploadGuidance') === -1
            // ) {
            //     return window.location.replace('/pc.html')
            // }
            // changeLanguage('vi')
            // const lang = getBrowserLang()
            const lcid = window.localStorage.getItem('lcid')
            const urlLang = getURLParamValue('lang')
            let lang = urlLang
            console.log(12312, urlLang)
            if(!urlLang){
                if(lcid){
                    lang = getLang(Number(lcid))
                }else {
                    lang = getBrowserLang()
                }
            }else {
                window.lcid = LOWER_CASE_LANG_MAP_LCID[lang] || DEFAULT_LCID
            }
            console.log(lang)
            // const lang = 'ar'

            if(lang === 'ar') {
                // @ts-ignore
                document.querySelector('html').classList.add('arab-layout');
                // @ts-ignore
                document.querySelector('html').lang = lang
            }
            window.os = checkOs()
            changeLanguage(lang)
            console.log(navigator.language)
            if (/(zh-CN|_cn|zh_)/i.test(navigator.language)
                && location.href.indexOf('/userAgreement') === -1
                && location.href.indexOf('/privacyPolicy') === -1
                && location.href.indexOf('/uploadGuidance') === -1
            ) {
                window.location.replace('/error.html')
            } else {
                app.mount('#app')
            }
        }
        app.component('s-container', SContainer)

    }).catch(e=> {
        console.log(e)
    })


}
// if (process.env.VUE_APP_DEBUG === 'true') {
//     import('vconsole').then(e => {
//         new e.default()
//         init()
//     })
// } else {
    init()
// }

document.addEventListener("visibilitychange", function () {
    if(window.isApp) return;
    if (!document.hidden) {
        const lastTime = localStorage.getItem('last_time')
        if (lastTime && Date.now() - Number(lastTime) > 1000 * 60 * 5) {
            window.location.reload()
        }
    } else {
        localStorage.setItem('last_time', String(Date.now()))
    }
})


app.config.errorHandler = (err, instance, info) => {
    // window.location.reload()
    console.error(err)
}
