export default {
    "index": "ترتيب",
    "nickname": "اسم",
    "invite_num": "عدد القراء المدعوين الجدد",
    "expect_give": "إجمالي جواهر المكافأة المتوقعة",
    "total_reward": " مجموع الجوائز الحالي ",
    "recommend_rank_title": "ترتيب سفراء StoryGo",
    "my_success_invite": "Başarıyla davet ettim",
    "my_rank": "ترتيبي",
    "undefined": "جواهر المكافأة المتوقعة لي",
    "recommend_rank_rule_title": "تفاصيل القاعدة",
    "recommend_rank_rule_1": "\n1.شارك كتبك المفضلة أو ادعو أصدقاءك باستخدام رابط الإحالة الخاص بك على وسائل التواصل الاجتماعي. إذا قام مستخدم جديد، الذي لم يستخدم StoryGo من قبل، بالتسجيل من خلال رابطك، وفتح 5 فصول، فقد نجحت في دعوة قارئ جديد. ملاحظة: الإصدار 1.8+ من التطبيق مطلوب.",
    "rule_how_to_share": "كيفية مشاركة كتبك المفضلة--〉〉",
    "rule_how_to_invite": "كيفية عرض/العثور على رابط الدعوة الخاص بك--〉〉",
    "recommend_rank_rule_2": "2.يبدأ الحدث بمجموع الجوائز يبلغ 1400 جوهرة. لكل مستخدم جديد يتم دعوته بنجاح، يزداد المجموع بمقدار 10 جوهرة، حتى الحد الأقصى لـ 50،000 جوهرة. ",
    "recommend_rank_rule_3": "\n3.في هذا الحدث، سيتم ترتيب السفراء بناءً على عدد المستخدمين الجدد الذين يدعونهم. سيقوم أفضل 20 سفيرًا بتقسيم مجموع الجوائز كما يلي :\nالمركز الأول: يتلقى 30٪ من الجواهر الواردة من مجموع الجوائز.\nالمركز الثاني: يتلقى 20٪ من الجواهر الواردة من مجموع الجوائز.\nالمركز الثالث: يتلقى 10٪ من الجواهر الواردة من مجموع الجوائز.\nالمراكز 4-10: يتلقى كل شخص 4٪ من الجواهر الواردة من مجموع الجوائز.\nالمراكز 11-20: يتلقى كل شخص 1.2٪ من الجواهر الواردة من مجموع الجوائز.\nسيتم توزيع الجوائز المذكورة أعلاه بعد انتهاء الحدث. ",
    "recommend_rank_rule_4": "\n4.يُمنع بشدة أي شكل من أشكال التزوير أو الغش أو التلاعب بالبيانات في هذا الحدث. بمجرد اكتشافها، سيتم إلغاء مشاركة الفرد. التفسير النهائي لهذا الحدث يعود إلى StoryGo.",
    "act_rule_btn": "تحقق من تفاصيل القاعدة",
    "act_time": "الوقت",
    "recommend_rank_page_title": "ترتيب سفراء StoryGo",
    "recommend_part1_title": "الجزء 1: اقترح كتب رائعة، اربح الجواهر!",
    "recommend_part1_desc": "شارك الكتب التي توصي بها أكثر إلى أصدقائك على فيسبوك وتويتر. أضف توصيتك الشخصية، مع استخدام  \"official@\"، وإذا تم ذلك وفقاً لمعاييرنا، ستكسب جواهر المكافآت!",
    "how_to_join": " ما كيفية المشاركة؟",
    "recommend_part1_tips1": "1.افتح كتابك المفضل، ثم اضغط فقط على زر المشاركة في الزاوية اليمنى العليا واختر Facebook أو Twitter.",
    "recommend_part1_tips2": "2.شارك واستخدم official@ في منشورك أو تعليقك. (ملاحظة: الجوائز متاحة للمنشورات العلنية فقط.)",
    "recommend_part1_tips3": "3.خلال الحدث، إذا حصلت منشورتك على أكثر من 30 إعجابًا، ستكسب 50 جوهرة. كل كتاب أو منشور متاحة للمكافآت مرة واحدة فقط، بحد أقصى من 200 جوهرة لشخص واحد. سيتم توزيع المكافآت خلال 3 أيام الدوام بعد انتهاء الحدث.",
    "recommend_part2_title": "الجزء 2: اجلب قراء جدد، واقسم الجائزة الكبيرة!",
    "recommend_part2_desc": "إذا قمت بجلب مستخدم جديد إلى StoryGo من خلال الكتاب الذي شاركته أو رابط دعوة التسجيل الخاص بك، وفتحوا أي 5 فصول مدفوعة، يمكنك المشاركة في ترتيب سفراء StoryGo وتقسيم  جواهر الجائزة الكبيرة!",
    "recommend_part2_tips1": "1.شارك كتبك المفضلة أو رابط الدعوة لأصدقائك على وسائل التواصل الاجتماعي. يتطلب ترقية التطبيق إلى الإصدار 1.8 أو أعلى.",
    "recommend_part2_tips2": "2.إذا قام مستخدم جديد لم يستخدم StoryGo من قبل، بتنزيل وتسجيل الدخول على StoryGo من خلال رابطك، وفتح أي 5 فصول، فسنعتبر أنك قد قمت بدعوة قارئ جديد بنجاح.",
    "recommend_part2_tips3": "3.يمكن لكل مستخدم المشاركة في الترتيب استنادًا إلى عدد القراء الجدد الذين تمت دعوتهم بنجاح. سيشارك أفضل 20 في الحصول على الجائزة الكبيرة من الجواهر.",
    "recommend_part2_tips4": "4.تحتفظ StoryGo بالحق في التفسير النهائي للحدث. في حالة الخدع أو سلوك الغش لتلاعب بالترتيب، تحتفظ StoryGo بالحق في إلغاء مؤهل الأشخاص من الحدث.",
    "see_rank": "تحقق من ترتيب السفراء",
    "share_act": "شارك الحدث",
    "join_watsapp": "انضم إلى WhatsApp",
    "recommend_page_title": "كن سفراء StoryGo ، اربح مكافآت كبيرة!",
    "recommend_banner_title": "كن سفراء StoryGo ، اربح مكافآت كبيرة!",
    "how_to_share_title": "كيفية مشاركة كتبك المفضلة",
    "how_to_share_content_1": "افتح كتابك المفضل ومجرد النقر فوق زر المشاركة الموجود في الزاوية العلوية اليمنى.",
    "how_to_share_content_2": "أو افتح كتابًا ، ثم اضغط على النص لفترة طويلة لتحديده ، وشاركه على وسائل التواصل الاجتماعي."
}