export default {
    "next": "İleri",
    "login": "Giriş Yap",
    "login_tips": "Umarız burada romantik bir yolculuk yaşarsınız",
    "set_password": "Şifre belirle",
    "enter_password": "Lütfen şifrenizi girin",
    "verify_code": "Doğrulama kodu",
    "enter_code": "Lütfen 6 haneli e-posta doğrulama kodunu girin",
    "let_start": "Başlayalım",
    "let_start_tips": "Umarız burada romantik bir yolculuk yaşarsınız",
    "sign_facebook": "Facebook ile giriş yap",
    "sign_google": "Google ile giriş yap",
    "sign_email": "E-posta ile giriş yap",
    "language_title": "Okuma dil tercihlerini ayarla",
    "current_lang": "mevcut dil",
    "en": "EN",
    "indonesian": "Endonezya",
    "personal_data": "Kişisel veriler",
    "save": "Kaydet",
    "nickname": "Rumuz",
    "sign_out": "Çıkış Yap",
    "loading": "yükleniyor",
    "success": "Başarılı",
    "reading_record": "Okuma kaydı",
    "empty": "Boş",
    "last_read": "Son okunan",
    "chapter": "bölüm",
    "remove": "Kaldır",
    "search": "Ara",
    "search_empty": "İlgili roman bulunamadı \"{{var}}\"",
    "login_tips_2": "Daha fazla özellik için giriş yapın.",
    "register_or_login": "Kayıt Ol/Giriş Yap",
    "more": "Daha fazla",
    "reads": "Okumalar",
    "read": "Oku",
    "last_chapter": "Son Bölüm",
    "all_chapter": "Tüm Bölümler",
    "contents": "İçerikler",
    "all": "Tamamı",
    "completed": "Tamamlandı",
    "ongoing": "Devam ediyor",
    "most_popular": "En Popüler",
    "new_arrival": "Yeni Gelen",
    "invite_friends": "Bu davet kodunu arkadaşlarınla paylaş. Onlar bunu bu sayfada etkinleştirdiğinde, her ikiniz de {{coins}} mücevher alırsınız. Her davet kodu 50 kez kullanılabilir.",
    "invite_friends_nums": "<span class=\"blue\">{{n}} arkadaşı davet ettim ve</span> <span class=\"items-center coin\"><img\n src=\"/images/dialyTask/coin.png\" alt=\"\"> * {{m}} kazandım</span>",
    "guide_title": "StoryGo Yazar Projesi",
    "active_code": "Davet kodunu aktive et",
    "active_tips": "Arkadaşlarınızdan bir davetiye kodu alın ve başarılı bir şekilde etkinleştirildikten sonra {{coins}} mücevher alacaksınız. İpucu: Kendi davetiye kodunuzu etkinleştiremezsiniz; başkalarından bir tane almalısınız!",
    "login_now": "Şimdi giriş yap",
    "task_1_title": "Hoş Geldin Hediyesi",
    "task_1_desc": "Yeni gelenlere hoş geldin hediyesi, otomatik olarak cüzdanınıza yatırıldı",
    "view_detail": "Detayları gör",
    "claim": "Talep et",
    "invite_friend": "Arkadaşlarını Davet Et",
    "my_invite_code": "Benim davet kodum",
    "copy": "Kopyala",
    "task_2_title": "Her gün Giriş Yap",
    "task_2_desc": "Her gün giriş yapın ve bonusu alın.",
    "task_3_title": "{{min}} dakika boyunca oku",
    "task_3_desc": "Bugün toplam {{min}} dakika okuyarak ödül kazanın.",
    "task_page_label": "Ödüller",
    "label_wallet_purchase_coins": "Jeton satın al",
    "go": "Git",
    "done": "Tamam",
    "copy_text": "Davet kodum [ {{code}} ], onu aktifleştir ve 50 Jeton kazan: [https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "Davet kodu kopyalandı.",
    "active_success": "Aktivasyon başarılı. 50 jeton ödülü cüzdanınıza gönderildi.",
    "error_code_limit": "Aktivasyon kodunun kullanım limitine ulaşıldı.",
    "error_had_activated": "Bu hesap zaten bir kodla aktifleştirildi.",
    "error_invalid_code": "Geçersiz aktivasyon kodu",
    "download_tips": "Uygulamada Sınırsız Hikayeler",
    "download": "İndir",
    "label_book_praise": "Değerlendirme",
    "label_book_prologue": "Önsöz",
    "login_success": "Giriş başarılı",
    "msg_reset_password_repeat": "Lütfen yeni şifreyi tekrarlayın",
    "msg_reset_password_email": "Şifrenizi sıfırlamak için lütfen e-posta adresinizi girin",
    "label_reset_password": "Şifreyi sıfırla",
    "label_send_email": "E-posta gönder",
    "msg_login_email_code_sent": "Doğrulama kodu başarıyla e-postanıza gönderildi",
    "msg_feedback_error": "Gönderilemedi, lütfen daha sonra tekrar deneyin",
    "save_userinfo": "Başarıyla kaydedildi",
    "label_load_image_fail": "Resim yükleme başarısız",
    "paid_chapters": "Ücretli Bölümler",
    "unlock_price": "Bölümün kilidini açmak {{coin}} gerektirir",
    "download_claim_coin": "{{coin}} talep etmek ve daha heyecanlı bölümleri açmak için uygulamayı indirin.",
    "label_book_chapters": "{{num}} Bölümler",
    "label_cancel": "İptal",
    "msg_common_state_empty": "Burası boş",
    "clear_reading_record": "Okuma kaydını silmek istediğinizden emin misiniz?",
    "clear_successful": "Kayıt başarıyla temizlendi.",
    "label_search_trending": "Trend aramalar",
    "task_login_tips": "Jeton Bonusunu almak için giriş yapın",
    "label_new_arrival": "Yeni Çok Beğenilen",
    "had_activated": "Bu hesap zaten bir kodla aktifleştirildi.",
    "activate": "Aktive etmek",
    "task_5_title": "Facebook'ta Paylaş",
    "task_5_desc": "Herhangi bir kitabı Facebook platformuna paylaş",
    "bigwheel_redeem_label": "Yeniden doldur",
    "coin_tips_1": "Yetersiz jeton, lütfen yeniden doldurun.",
    "coin_tips_2": "Hesap: {{n}} jeton ({{m}} jeton gerekir)",
    "bigwheel_rule_1": "1. Ödül kazanma şansı %100 olan Şanslı Tekerlek çekilişine katılın.",
    "bigwheel_rule_2": "2. Tek tekerlek çekilişi 10 altın jetona mal olur, on çekiliş 100 altın jeton, elli çekiliş ise 500 altın jeton mal olur. Ardışık çekilişler arttıkça değerli ödülleri kazanma şansı artar!",
    "bigwheel_rule_3": "3. Oyun sırasında elde edilen ödüller otomatik olarak dağıtılır. Altın jetonlar doğrudan altın jeton cüzdanınıza yatırılır.",
    "bigwheel_rule_4": "4. Bu oyun, Apple Inc. ile ilişkili değildir. Son yorum hakkı StoryGo'ya aittir.",
    "bigwheel_balance_label": "Bakiye",
    "no_data_1": "Veri yok",
    "msg_room_coin_notice": "Tebrikler! [{{who}}] {{coin}} jeton kazandı!",
    "top_up": "Yeniden doldur",
    "sign": "Kayıt ol",
    "sign_desc": "Yeni gelenlere hoş geldin hediyesi, otomatik olarak cüzdanınıza yatırıldı",
    "first_recharge": "Sınırlı süreli teklif",
    "first_recharge_desc": "Bonus almak için Sınırlı süreli teklifi bitirin",
    "unlock_chapter": "{{n}} bölümün kilidini açın",
    "unlock_chapter_desc": "Bir premium bölümü açarak ödül kazanın.",
    "comment_book": "Bir kitap yorumu gönderin",
    "comment_book_desc": "Favori kitabınız için bir değerlendirme yazın ve ödül kazanma şansı elde edin!",
    "my_coins": "Benim jetonlarım",
    "recharge_title": "Tavsiye edilen hediye paketi",
    "bonus": "Bonus",
    "buy_coins": "Jeton Satın Al",
    "my_balance": "Benim bakiyem",
    "apple_pay": "Apple Pay",
    "other_pay": "Diğer yollar",
    "google_pay": "Google Pay",
    "pay_now": "Şimdi Öde",
    "label_balance": "Jetonlar",
    "select_payment": "Lütfen bir ödeme yöntemi seçin",
    "select_package": "Lütfen bir yükleme paketi seçin",
    "wallet_title": "Cüzdan",
    "coins_balance": "Bakiye",
    "new_comer": "Yeni Gelen",
    "extra_coins": "Ekstra {{n}} madeni para",
    "daily_task_label": "Günlük Görev",
    "label_pay_success": "Ödeme başarılı!",
    "app_update_title": "Lütfen en son sürüme güncelleyin.",
    "restore_label": "Geri Yükle",
    "history_label": "Geçmiş",
    "newcomer_pack": "Sınırlı süreli teklif",
    "recommend_pack": "Tavsiye edilen paket",
    "collection_month": "Toplam/Ay",
    "now_get_icons": "Hemen {{n}} alın",
    "monthly_get_icons": "Ayda {{n}} Mücevher alın",
    "recharge_feedback_msg": "Bakiye yüklemede sorun mu yaşıyorsunuz?",
    "feedback_label": "Geribildirim",
    "coin_label": "Jetonlar",
    "gem_label": "Mücevherler",
    "restore_tips": "Geri yüklenemeyen abonelikler",
    "recharge_label": "Yükle",
    "sign_days": "{{n}} gün boyunca kontrol edildi",
    "vip_daily_task": "Aylık paket bonusu",
    "other": "Diğer",
    "limited_recharge_pack": "Sınırlı süreli teklif",
    "expire_tips": "{{time}} tarihinde sona eriyor",
    "hot_pack": "Popüler paket",
    "continuous_checkin_day": "{{n}} gün boyunca kontrol edildi",
    "svip_login_reward": "$9.9 premium bonus",
    "vip_login_reward": "$19.9 premium bonus",
    "dailysign_popup_btn": "Ödüller Merkezinden daha fazla alın",
    "checked_in": "Kontrol edildi",
    "limit_time_recharge_title": "Sınırlı süreli teklif",
    "recharge_pack_tips": "Bonus almak için Sınırlı süreli teklifi bitirin",
    "balance_nsufficient_tips": "Bakiyeniz yetersiz, lütfen yükleme yapın",
    "had_coins_tips": "Sahip olduğunuz: {{n}} Jeton | {{m}} Mücevher",
    "dailysign_popup_title": "Tebrikler",
    "pay_method_label": "Ödeme Yöntemi",
    "at_once": "Şimdi ",
    "daily_login": "Günlük giriş",
    "card_coin_suffix": "Toplam/Ay",
    "card_gems_suffix": "Ay",
    "vips_rule_title": "Aylık paket hakkında:",
    "vips_rule_1": "1. Başarıyla $9.9-aylık-paket satın alındığında, 1,000 altın jeton hemen hesabınıza yüklenir ve ardından her 30 ardışık gün boyunca 20 mücevher alabilirsiniz (toplamda 600 mücevher); $19.9-aylık-paket başarıyla satın alındığında, 2,000 altın jeton hemen hesabınıza yüklenir ve ardından her 30 ardışık gün boyunca 40 mücevher alabilirsiniz (toplamda 600 mücevher).",
    "vips_rule_2": "2. Farklı aylık paketler satın alırsanız, her gün aldığınız mücevher ödülleri birbirine eklenir; aynı aylık paketleri satın alırsanız, her gün aldığınız mücevher ödülleri birbirine eklenmez, ancak yalnızca ilgili aylık kartın süresini uzatır.",
    "vips_rule_3": "3. Günlük alınan mücevherler 30 gün boyunca geçerlidir, lütfen dikkat edin.",
    "vips_rule_4": "4. Satın alındıktan sonra, sistem varsayılan olarak <a href=\"https://www.storygo.cc/userAgreement?hideNav=1\"> Kullanıcı Sözleşmesi </a> ve <a href=\"https://www.storygo.cc/privacyPolicy?hideNav=1\"> Gizlilik Sözleşmesi </a> ni kabul ettiğinizi varsayar.",
    "vips_rule_5": "1. Ay paketi satın alındıktan sonraki 24 saat içinde geçerli olacaktır ve ilgili avantajların tadını çıkarabilirsiniz.",
    "check_in": "Giriş",
    "day_index": "No.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Mücevher </span> yakında sona erecek",
    "balance_label": "Bakiye",
    "wallet_rule_title": "Mücevherler Hakkında",
    "wallet_rule_1": "1. Mücevherler bölümleri açmak için kullanılabilir. Bölümleri açarken, jeton ve mücevherler aynı değere sahiptir, 1 jeton = 1 mücevher.",
    "wallet_rule_2": "2. Mücevherlerin süresi dolabilir. Lütfen bakiye yükleme ve aylık paketlerden alınan mücevherlerin 30 gün boyunca geçerli olduğunu ve günlük görevlerle alınan mücevherlerin 7 gün boyunca geçerli olduğunu unutmayın.",
    "wallet_rule_3": "3. Bölümleri kilitlerini açarken, önce mücevherler kullanılır.",
    "read_task_title": "Okuma görevi",
    "share_task_title": "Paylaşma görevleri",
    "comment_task_title": "Yorum görevi",
    "vip_task_title": "$9.99 premium bonus",
    "svip_task_title": "$19.99 premium bonus",
    "vip_page_title": "Ay paketi",
    "pay_bonus_label": "Bonus",
    "daily_gems": "Günlük {{n}} Mücevher",
    "history_page_title": "Geçmiş",
    "recharge_title_1": "Ekle",
    "gems_will_expire_btn": "Ayrıntılar",
    "gems_history_tips": "Son kullanma tarihine göre sırala",
    "unlock_coins": "Gerekli: {{n}} Jeton veya Mücevher",
    "history_recharge_title": "Bakiye yükleme",
    "history_game_title": "Şanslı Çekiliş",
    "history_recharge_given_title": "Bakiye Yükleme Bonusu",
    "extar_gems_btn": "Ekstra Mücevher",
    "watch_ad_task_title": "Videoları izle",
    "watch_ad_task_desc": "Her izlenen video için ödül alın ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Ödül başarıyla talep edildi.",
    "task_finish_title": "Görev tamamlandı.",
    "draw_width_ad_toast": "Tebrikler! {{n}} ve ek olarak {{m}} aldınız.",
    "draw_toast": "Tebrikler! {{n}} aldınız.",
    "draw_dialog_btn1": "Sadece {{n}} talep et.",
    "draw_dialog_btn2": " {{n}} ve ek olarak {{m}} talep et.",
    "history_ads_given_title": "Videoları izle",
    "ad_extra_rewards_title": "Görev tamamlandıktan sonra bonus.",
    "label_editor_picks": "Editörün Seçtikleri",
    "label_genre": "Tür",
    "label_main_tab_home": "Keşfet",
    "label_reader_choice": "En İyi Dereceli",
    "invite_bonuses": "Yeni bir kullanıcıyı davet edin ve {{n}} bonus kazanın.",
    "invite_friend_btn": "Arkadaşını davet et",
    "how_get_bonuses": "Daha fazla bonus nasıl alınır",
    "invite_tips_1": "Arkadaşın 5 bölümü açtı ve okudu",
    "invite_tips_2": "Arkadaşlarınız yeniden yükleme yaptığında, arkadaş başına en fazla 150 mücevher olmak üzere yüzde 10 geri alırsınız.",
    "invite_tips_3": "Her arkadaşınız bir eylemi tamamladığında, anında bir ödül alırsınız.",
    "how_invite_friend": "Arkadaş davet etme",
    "invite_tips_5": "Arkadaşlarınızla paylaşmak için benzersiz davet bağlantınızı oluşturmak için aşağıdaki düğmeye tıklayın",
    "invite_tips_4": "Arkadaşınız, paylaştığınız bağlantı aracılığıyla StoryGo'yu indirdi",
    "reward_history_title": "Ödül geçmişi",
    "reward_history_tips_1": "Davet etkinliğinden toplam {{n}} mücevher kazandınız.",
    "reward_history_tips_2": "{{n}} arkadaş ve {{m}} mücevher davet ettiniz.",
    "reward_history_tips_3": "Davet ettiğiniz arkadaşlarınızın 5 bölümü kilidini açma görevinden {{n}} mücevher kazandınız.",
    "reward_history_tips_4": "Arkadaşlarınızın yeniden doldurmasından {{n}} mücevher kazandınız",
    "daily_invite_friend_title": "Arkadaşları Davet Et",
    "daily_invite_friend_desc": "Arkadaşlarınızı StoryGo'ya davet edin ve büyük ödüller kazanın.",
    "recharge_panel_ad_tips": "StoryGo paketinizi doldurun ve bol miktarda kitap jetonu ve mücevher elde edin. Premium bölümleri kolayca kilitleyin ve satın alma işleminden sonra 7 ila 30 gün boyunca reklamsız okuma keyfini anında başlatın.",
    "invite_more_friend": "Daha fazla arkadaş davet edin ve daha fazla Elmas alın!",
    "history_invite_unlock_title": "Davet edilen kullanıcı 5 bölümü açtı",
    "history_invite_recharge_title": "Davet edilen kullanıcı bir şarj yapmıştır",
    "days": "Günler",
    "continue_read_app": "Uygulamada Okumaya Devam Et",
    "download_app_tips": "Daha heyecanlı içerik için StoryGo'u indirin",
    "invite_tips_6": "Yeni bir okuyucuyu davet ederek 15 mücevher talep edin. Ödül olarak en fazla 300 mücevher kazanabilirsiniz.",
    "history_invite_success_title": "Bir kullanıcı başarıyla davet edildi",
    "evaluation_task_title": "StoryGo'yu Değerlendirin",
    "evaluation_task_desc": "Ödülleri almak için 3 adım",
    "invite_page_title": "Yeni kullanıcıları birlikte kullanmaya davet edin ve değerli taş ödüllerini alın!",
    "invite_page_title_2": "Davet ettiğiniz her yeni kullanıcı için alırsınız.",
    "invite_page_title_3": "Yeni kullanıcı ödemeleri için, {{n}} mücevher ödülü de alabilirsiniz. Daha fazla davet ettiğinizde, daha çok kazanırsınız.",
    "my_invite_record": "Davet Kayıtlarım",
    "successfully_invited": "Davet Başarılı",
    "earnings_gem": "Kazancım (Değerli Taş)",
    "how_invite_title_1": "Arkadaşları Davet Et",
    "how_invite_title_2": "Arkadaş kaydı tamamladı",
    "how_invite_title_3": "Ödüller var",
    "invite_h5_title": "Seni StoryGo'yu birlikte kullanmaya davet ediyoruz",
    "got_gem_label": "Yeni başlayanlar için faydalar",
    "accept_invitation": "Daveti kabul et",
    "join_people": " İnsanlar etkinliğe katıldı",
    "recommended_content": "Tavsiye edilen içerik",
    "to_app_tip": " StoryGo'da daha heyecan verici içerikler",
    "turn_on_notify_title": "Bildirimleri aç",
    "turn_on_notify_desc": "Ödülleri almak için mesaj bildirimlerini açın",
    "label_help_center": "Yardım Merkezi",
    "ai_become_writer_btn": "Yazar Olmak  ",
    "ai_offer_title": "Sunduğumuz Neler Var  ",
    "ai_offer_item_label_1": "Yaratım Araçları  ",
    "ai_offer_item_value_1": "Yaratım Araçları için Ücretsiz Deneme Kotası  ",
    "ai_offer_item_label_2": "Nakit Ödüller  ",
    "ai_offer_item_value_2": "Yaratım yarışmalarına katılın ve 200 $ nakit ödül kazanın  ",
    "ai_offer_item_label_3": "Sözleşmeli Yazar  ",
    "ai_offer_item_value_3": "Platformda sözleşmeli yazar olun ve cömert aylık abonelik payları alın  ",
    "ai_authors_say_title": "Yazarlarımız Ne Diyor  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"Bu AI yazım aracı, güçlü bir asistan gibi, basit ve kullanımı kolaydır, her kullanıcının kolayca başlamasını sağlar. Yüksek kaliteli çıktı, yaratımı keyifli hale getirir ve kullanıcı deneyimi şüphesiz yazmanın en üst düzey zevkidir.\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "Bu AI yazım aracı harika! Kullanımı kolay, çalışması basit ve yüksek kaliteli eserler üretiyor. Yaratıcılığımı kolayca ifade etmemi sağlıyor ve bundan keyif alıyorum!  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "Bu AI yazım aracı, yaratıcılar için bir nimettir. Basit ve sezgisel arayüzü, acemilerin kullanmasını kolaylaştırır. Çıktı hikayelerinin kalitesi şaşırtıcıdır. Bununla yazmak, gerçekten ilham verici bir yolculuktur.  ",
    "ai_why_choose_title": "Neden Bizi Seçmelisiniz  ",
    "ai_why_choose_item_label_1": "Tutkulu editör ekibi  ",
    "ai_why_choose_item_value_1": "Profesyonel ve verimli bir editör ekibi, yeni yazarlar için 6 aylık koruma süresi sunar; bu süre zarfında oluşturulan taslaklar güvenli gönderim garantisi kanalından yararlanabilir.  ",
    "ai_why_choose_item_label_2": "Verimli yaratım araçları  ",
    "ai_why_choose_item_value_2": "Kolay oluşturma, kısa yaratım döngüsü, yüksek monetizasyon verimliliği  ",
    "ai_why_choose_item_label_3": "Güvenlik garantisi  ",
    "ai_why_choose_item_value_3": "Profesyonel bir hukuk ekibi eserlerinizin haklarını korur  ",
    "ai_create_story_btn": "Hikayelerinizi oluşturun  ",
    "ai_contact_btn": "Editör ekibi ile iletişime geçin  ",
    "ai_dialog_title": "StoryGo'ya hoş geldiniz!  ",
    "ai_dialog_desc": "StoryGo yaratım araçlarını kullanarak, hit hikaye yazarı olabilirsiniz, gelin ve bize katılın!  ",
    "ai_dialog_part1_title": "Ücretsiz Deneme  ",
    "ai_dialog_part1_item1": "Lütfen PC web sitemize giriş yapın https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "WhatsApp grubuna katılın ve ücretsiz deneme için yöneticilerle iletişime geçin.  ",
    "ai_dialog_part2_title": "Yaratıcı Yarışma  ",
    "ai_dialog_part2_item1": "Lütfen PC web sitemize giriş yapın https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "Yaratıcı yarışmaya katılın, nakit ödüller kazanın, yeni fırsatlar elde edin.  ",
    "ai_dialog_part2_item3": "Herhangi bir sorunuz varsa, lütfen bizimle iletişime geçin Contact@storygo.cc  ",
    "ai_dialog_copy_email": "Resmi web sitesinin bağlantısını kopyalayın  ",
    "ai_url_copy_tips": "Bağlantı başarıyla kopyalandı  "
}