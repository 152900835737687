import {novel} from "@storygo/types/novel_baseTars.d";
import {useStorage} from "@/stores/storage";
import type {PayTypeItem} from "@/hooks";

export const inPayerBlackList = (pack: novel.RechargePackage, payType?: PayTypeItem) => {
    if(!pack.payermaxBlackCountries) return false
    if(payType && payType.type !== 'payermax') return false
    const payermaxBlackCountries = pack.payermaxBlackCountries.value as string[]
    if(payermaxBlackCountries.includes(window.countryCode)){
        return true
    }
    return false
}
export const getPayTypes = (payTypes: PayTypeItem[],pack: novel.RechargePackage) => {
    const isBlack = inPayerBlackList(pack)
    if(isBlack){
        return payTypes.filter(item => item.type !== 'payermax')
    }else {
       return payTypes
    }
}



export const getZhekou = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    if(storage.showLayerPay  && !inPayerBlackList(pack)){
        return Number(pack.cornerWords) + Number(pack.payermaxDiscount)
    }else {
        return Number(pack.cornerWords)
    }
}

// 不包括充值金币
export const getAllCoins = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    if(storage.showLayerPay && !inPayerBlackList(pack)){
        return Number(pack.extCoinAmount) + Number(pack.payerMaxExtCoin)
    }else {
        return Number(pack.extCoinAmount)
    }
}

// 包括充值金币
export const getTotalCoins = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    if(storage.showLayerPay  && !inPayerBlackList(pack)){
        return Number(pack.coinAmount) + Number(pack.extCoinAmount) + Number(pack.payerMaxExtCoin)
    }else {
        return Number(pack.coinAmount) + Number(pack.extCoinAmount)
    }
}



export const getAllGems = (pack: novel.RechargePackage) => {
    const storage = useStorage()
    if(storage.showLayerPay  && !inPayerBlackList(pack)){
        return Number(pack.extGemAmount) + Number(pack.payerMaxExtGem)
    }else {
        return Number(pack.extGemAmount)
    }
}
