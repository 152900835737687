// import {ref, computed} from 'vue'
import {defineStore} from 'pinia'
import {novel} from "@storygo/types/novel_baseTars.d";

export const useStorage = defineStore('storage', {
    state: () => {
        return {
            showLayerCard: false,
            showLayerPay: false,
            firstRechargePack: {} as novel.RechargePackage,
            noRecharge: true,
            hadMonthCard: false,
            maxRechargePack: {} as novel.RechargePackage, // 充值过的最高档位
            monthCardPacks: [] as novel.RechargePackage[], // 月卡套餐
            rechargePacks: [] as novel.RechargePackage[], // 普通充值套餐
            probability: 0, // 手势概率
        }
    },
    actions: {
        setShowLayerCard(val: boolean) {
            this.showLayerCard = val
        },
        setShowLayerPay(val: boolean) {
            this.showLayerPay = val
        },
        setNoRecharge(val: boolean) {
            this.noRecharge = val
        },
        setHadMonthCard(val: boolean) {
            this.hadMonthCard = val
        },

        setFirstRechargePack(val: novel.RechargePackage) {
            this.firstRechargePack = val
        },
        setMaxRechargePack(val: novel.RechargePackage) {
            this.maxRechargePack = val
        },

        setMonthCardPacks(val: novel.RechargePackage[]) {
            this.monthCardPacks = val
        },
        setRechargePacks(val: novel.RechargePackage[]) {
            this.rechargePacks = val
        },
        computedProbability(){
            this.probability = Math.ceil(Math.random() * 100)
        },
        getPackByPackageId(packageId: number){
            const packs:novel.RechargePackage[] = [
                ...this.monthCardPacks,
                ...this.rechargePacks
            ]
            return packs.find(item => item.packageId === packageId)
        }
    },
})


