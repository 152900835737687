<template>
  <div v-if="show">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import {computed} from "vue"
import { v1gtv2 } from '@storygo/utils'
const {
  version
} = defineProps<{
  version?: string
}>()
const show = computed(() => {
  if(version){
    return v1gtv2(window.appVersion, version)
  }else {
    return true
  }
})
</script>
<style scoped lang="less">

</style>