export default {
    "next": "ถัดไป",
    "login": "เข้าสู่ระบบ",
    "login_tips": "เราหวังว่าคุณจะสนุกกับการเดินทางแห่งความรักที่นี่",
    "set_password": "ตั้งรหัสผ่าน",
    "enter_password": "กรุณาใส่รหัสผ่านของคุณ",
    "verify_code": "รหัสยืนยัน",
    "enter_code": "กรุณาใส่รหัสยืนยันดิจิตอล 6 หลักทางอีเมล",
    "let_start": "เริ่มเลย",
    "let_start_tips": "เราหวังว่าคุณจะสนุกกับการเดินทางแห่งความรักที่นี่",
    "sign_facebook": "เข้าสู่ระบบด้วย FaceBook",
    "sign_google": "เข้าสู่ระบบด้วย Google",
    "sign_email": "เข้าสู่ระบบด้วย Email",
    "language_title": "ตั้งค่าการอ่านภาษา",
    "current_lang": "ภาษาปัจจุบัน",
    "en": "EN",
    "indonesian": "อินโดนีเซีย",
    "personal_data": "ข้อมูลส่วนบุคคล",
    "save": "บันทึก",
    "nickname": "ชื่อเล่น",
    "sign_out": "ออกจากระบบ",
    "loading": "กำลังโหลด",
    "success": "สำเร็จ",
    "reading_record": "บันทึกการอ่าน",
    "empty": "ว่างเปล่า",
    "last_read": "อ่านครั้งล่าสุด",
    "chapter": "บท",
    "remove": "ลบ",
    "search": "ค้นหา",
    "search_empty": "ไม่พบนิยายที่เกี่ยวข้องกับ \"{{var}}\"",
    "login_tips_2": "เข้าสู่ระบบเพื่อเข้าถึงคุณสมบัติเพิ่มเติม",
    "register_or_login": "ลงทะเบียน/เข้าสู่ระบบ",
    "more": "เพิ่มเติม",
    "reads": "อ่าน",
    "read": "อ่าน",
    "last_chapter": "บทสุดท้าย",
    "all_chapter": "ทุกบท",
    "contents": "เนื้อหา",
    "all": "ทั้งหมด",
    "completed": "เสร็จสิ้น",
    "ongoing": "กำลังดำเนินการ",
    "most_popular": "ยอดนิยมที่สุด",
    "new_arrival": "เพิ่มเข้ามาใหม่",
    "invite_friends": "แชร์รหัสเชิญนี้กับเพื่อนของคุณ หลังจากที่พวกเขาเปิดใช้งานบนหน้านี้ คุณทั้งสองจะได้รับ {{coins}} แห่ง แต่ละรหัสเชิญสามารถใช้ได้สูงสุด 50 ครั้ง",
    "invite_friends_nums": "<span class=\"blue\">ฉันเชิญเพื่อน {{n}} คนและได้</span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>",
    "guide_title": "โครงการผู้เขียน StoryGo",
    "guide_content": 0,
    "active_code": "เปิดใช้งานรหัสเชิญ",
    "active_tips": "รับรหัสเชิญจากเพื่อนของคุณ และหลังจากเปิดใช้งานสำเร็จ คุณจะได้รับ {{coins}} เพราะ. เคล็ดลับ: คุณไม่สามารถเปิดใช้งานรหัสเชิญของคุณเองได้; คุณต้องรับรหัสจากผู้อื่น!",
    "login_now": "เข้าสู่ระบบทันที",
    "task_1_title": "ของขวัญต้อนรับ",
    "task_1_desc": "ของขวัญต้อนรับสำหรับผู้ที่เพิ่งมาใหม่ ซึ่งได้ถูกฝากเข้ากระเป๋าเงินของคุณโดยอัตโนมัติ",
    "view_detail": "ดูรายละเอียด",
    "claim": "เคลม",
    "invite_friend": "เชิญเพื่อน",
    "my_invite_code": "รหัสเชิญของฉัน",
    "copy": "คัดลอก",
    "task_2_title": "หัวข้องานที่ 2, ทุกวันเช็คอิน",
    "task_2_desc": "รายละเอียดงานที่ 2, \"เช็คอินทุกวันและรับโบนัส\"",
    "task_3_title": "หัวข้องานที่ 3, อ่านเกิน {{min}} นาที",
    "task_3_desc": "รายละเอียดงานที่ 3, อ่านรวม {{min}} นาทีวันนี้เพื่อรับรางวัล",
    "task_page_label": "ป้ายกำกับหน้างาน, รางวัล",
    "label_wallet_purchase_coins": "ซื้อเหรียญ",
    "go": "ไป",
    "done": "เสร็จสิ้น",
    "copy_text": "คัดลอกข้อความ, \"รหัสเชิญของฉันใน StoryGo คือ [ {{code}} ], ใช้งานมันและได้รับ 50 เหรียญ:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "คัดลอกสำเร็จ, รหัสเชิญได้รับการคัดลอก",
    "active_success": "การเปิดใช้งานสำเร็จ, รางวัลเหรียญ 50 เหรียญได้ถูกส่งไปยังกระเป๋าเงินของคุณ",
    "error_code_limit": "รหัสการเปิดใช้งานถึงขีดจำกัดการใช้งาน",
    "error_had_activated": "บัญชีนี้ได้รับการเปิดใช้งานด้วยรหัสแล้ว",
    "error_invalid_code": "รหัสการเปิดใช้งานไม่ถูกต้อง",
    "download_tips": "เคล็ดลับการดาวน์โหลด, นิทานไม่จำกัดใน APP",
    "download": "ดาวน์โหลด",
    "label_book_praise": "การให้คะแนน",
    "label_book_prologue": "บทนำ",
    "login_success": "เข้าสู่ระบบสำเร็จ",
    "msg_reset_password_repeat": "กรุณาทำซ้ำรหัสผ่านใหม่ของคุณ",
    "msg_reset_password_email": "กรุณาใส่ที่อยู่อีเมลของคุณเพื่อรีเซ็ตรหัสผ่านของคุณ",
    "label_reset_password": "รีเซ็ตรหัสผ่าน",
    "label_send_email": "ส่งอีเมล",
    "msg_login_email_code_sent": "รหัสยืนยันได้ถูกส่งไปยังอีเมลของคุณเรียบร้อยแล้ว",
    "msg_feedback_error": "การส่งล้มเหลว, กรุณาลองอีกครั้งในภายหลัง",
    "save_userinfo": "บันทึกสำเร็จ",
    "label_load_image_fail": "การโหลดรูปภาพล้มเหลว",
    "paid_chapters": "บทชำระเงิน",
    "unlock_price": "ปลดล็อคบทต้องใช้ {{coin}}",
    "download_claim_coin": "ดาวน์โหลดแอปเพื่อเรียกรับ {{coin}} และปลดล็อคบทที่น่าตื่นเต้นมากขึ้น",
    "label_book_chapters": "{{num}}  บท",
    "label_cancel": "ยกเลิก",
    "msg_common_state_empty": "ว่างเปล่าที่นี่",
    "clear_reading_record": "คุณแน่ใจหรือไม่ว่าต้องการลบบันทึกการอ่าน?",
    "clear_successful": "บันทึกถูกลบเรียบร้อยแล้ว",
    "label_search_trending": "การค้นหาที่เป็นที่นิยม",
    "task_login_tips": "เข้าสู่ระบบเพื่อรับโบนัสเหรียญ",
    "label_new_arrival": "สินค้าใหม่แรง",
    "had_activated": "บัญชีนี้ได้รับการเปิดใช้งานด้วยรหัสแล้ว",
    "activate": "เปิดใช้งาน",
    "task_5_title": "แชร์ไปยัง Facebook",
    "task_5_desc": "แชร์หนังสือใด ๆ ไปยังแพลตฟอร์ม Facebook",
    "bigwheel_redeem_label": "เติมเงิน",
    "coin_tips_1": "เหรียญไม่เพียงพอ โปรดเติมเงิน.",
    "coin_tips_2": "บัญชี: {{n}} เหรียญ (ต้องการ {{m}} เหรียญ)",
    "bigwheel_rule_1": "1. เข้าร่วมการจับรอบลุ้นท้ายด้วยการรับรอง 100% ของรางวัล.",
    "bigwheel_rule_2": "2. หนึ่งรอบลุ้นมีค่า 10 เหรียญทอง, สิบรอบมีค่า 100 เหรียญทอง, และห้าสิบรอบมีค่า 500 เหรียญทอง. มีการลุ้นต่อเนื่องมากเท่าไร โอกาสชนะรางวัลมีความสูงขึ้น!",
    "bigwheel_rule_3": "3. รางวัลที่ได้รับในเกมจะได้รับการกระจายอัตโนมัติ เหรียญทองจะเข้าบัญชีเหรียญทองของคุณโดยตรง",
    "bigwheel_rule_4": "4. เกมนี้ไม่เกี่ยวข้องกับบริษัท Apple Inc. สิทธิ์ทั้งหมดในการตีความสุดท้ายเป็นของ StoryGo.",
    "bigwheel_balance_label": "ยอดคงเหลือ",
    "no_data_1": "ไม่มีข้อมูล",
    "msg_room_coin_notice": "ยินดีด้วย! [{{who}}] ชนะ {{coin}} เหรียญ!",
    "top_up": "เติมเงิน",
    "sign": "ลงทะเบียน",
    "sign_desc": "ของขวัญต้อนรับสำหรับผู้ที่เพิ่งมาใหม่ ซึ่งได้ถูกฝากเข้ากระเป๋าเงินของคุณโดยอัตโนมัติ",
    "first_recharge": "ข้อเสนอเวลาจำกัด",
    "first_recharge_desc": "เสร็จสิ้นข้อเสนอเวลาจำกัดเพื่อรับโบนัส",
    "unlock_chapter": "ปลดล็อค {{n}} บท",
    "unlock_chapter_desc": "ปลดล็อกบทที่เสียค่าเพื่อรับรางวัล",
    "comment_book": "แสดงความคิดเห็นของหนังสือ",
    "comment_book_desc": "เขียนรีวิวสำหรับหนังสือที่คุณชื่นชอบและมีโอกาสที่จะได้รับรางวัล!",
    "my_coins": "เหรียญของฉัน",
    "recharge_title": "แพ็คของขวัญแนะนำ",
    "bonus": "โบนัส",
    "buy_coins": "ซื้อเหรียญ",
    "my_balance": "ยอดคงเหลือของฉัน",
    "apple_pay": "Apple Pay",
    "other_pay": "ตัวเลือกที่ดีที่สุด",
    "google_pay": "Google Pay",
    "pay_now": "จ่ายตอนนี้",
    "label_balance": "เหรียญ",
    "select_payment": "โปรดเลือกวิธีการชำระเงิน",
    "select_package": "โปรดเลือกแพ็คเกจการเติมเงิน",
    "wallet_title": "กระเป๋าเงิน",
    "coins_balance": "ยอดคงเหลือ",
    "new_comer": "ผู้ใหม่",
    "extra_coins": "เหรียญเพิ่มเติม {{n}}",
    "daily_task_label": "งานประจำวัน",
    "label_pay_success": "ชำระเงินสำเร็จ!",
    "app_update_title": "โปรดอัปเดตเป็นเวอร์ชันล่าสุด",
    "restore_label": "เรียกคืน",
    "history_label": "ประวัติ",
    "newcomer_pack": "ข้อเสนอเวลาจำกัด",
    "recommend_pack": "แพคเกจที่แนะนำ",
    "collection_month": "รวม/เดือน",
    "now_get_icons": "รับ {{n}} เหรียญทันที",
    "monthly_get_icons": "รับ {{n}} พลอย/เดือน",
    "recharge_feedback_msg": "มีปัญหาในการเติมเงินใหม่?",
    "feedback_label": "ข้อเสนอแสดงความคิดเห็น",
    "coin_label": "เหรียญ",
    "gem_label": "พลอย",
    "restore_tips": "ไม่มีการสมัครสมาชิกที่สามารถเรียกคืนได้",
    "recharge_label": "เติมเงินขึ้นบน",
    "sign_days": "เช็คอิน {{n}} วัน",
    "vip_daily_task": "โบนัสแพ็คเกจเดือน",
    "other": "อื่น ๆ",
    "limited_recharge_pack": "ข้อเสนอเวลาจำกัด",
    "expire_tips": "หมดอายุเมื่อ {{time}}",
    "hot_pack": "แพ็คเกจยอดนิยม",
    "continuous_checkin_day": "เช็คอิน {{n}} วัน",
    "svip_login_reward": "โบนัสพรีเมียม $9.9",
    "vip_login_reward": "โบนัสพรีเมียม $19.9",
    "dailysign_popup_btn": "รับมากกว่าจากศูนย์รางวัล",
    "checked_in": "เช็ค",
    "limit_time_recharge_title": "ข้อเสนอเวลาจำกัด",
    "recharge_pack_tips": "เสร็จสิ้นข้อเสนอเวลาจำกัดเพื่อรับโบนัส",
    "balance_nsufficient_tips": "ยอดเงินของคุณไม่เพียงพอ กรุณาเติมเงิน",
    "had_coins_tips": "คุณมี: {{n}} เหรียญ | {{m}} พลอย",
    "dailysign_popup_title": "ยินดีด้วย",
    "pay_method_label": "วิธีการชำระเงิน",
    "at_once": "ทันที",
    "daily_login": "เข้าสู่ระบบทุกวัน",
    "card_coin_suffix": "รวม/เดือน",
    "card_gems_suffix": "เดือน",
    "vips_rule_title": "เกี่ยวกับแพ็คเกจเดือน:",
    "vips_rule_1": "1. เมื่อซื้อแพคเกจเดือน $9.9 สำเร็จ คุณจะได้รับ 1,000 เหรียญทองทันที และคุณสามารถรับ 20 อัญมณีต่อวันเป็นระยะเวลา 30 วันติดต่อกัน (รวม 600 อัญมณี); เมื่อซื้อแพคเกจเดือน $19.9 สำเร็จ คุณจะได้รับ 2,000 เหรียญทองทันที และคุณสามารถรับ 40 อัญมณีต่อวัน (รวม 600 อัญมณี) เป็นระยะเวลา 30 วันติดต่อกัน",
    "vips_rule_2": "2. หากคุณซื้อแพคเกจเดือนที่แตกต่างกัน รางวัลอัญมณีที่คุณได้รับทุกวันจะถูกวางทับซ้อนกัน; หากคุณซื้อแพคเกจเดือนเดียวกัน รางวัลอัญมณีที่คุณได้รับทุกวันจะไม่ถูกวางทับซ้อนกัน แต่จะเพิ่มเฉพาะระยะเวลาของบัตรเดือนที่เกี่ยวข้องเท่านั้น",
    "vips_rule_3": "3. อัญมณีที่ได้รับทุกวันจะมีความถูกต้องเป็นเวลา 30 วัน โปรดสังเกต",
    "vips_rule_4": "",
    "vips_rule_5": "1. แพคเกจเดือนจะมีความถูกต้องภายใน 24 ชั่วโมงหลังจากการซื้อและคุณสามารถเพลิดเพลินกับสิทธิ์ที่สอดคล้องกันได้",
    "check_in": "เช็คอิน",
    "day_index": "ลำดับที่{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} อัญมณี </span> ใกล้หมดอายุ",
    "balance_label": "ยอดเงินคงเหลือ",
    "wallet_rule_title": "เกี่ยวกับอัญมณี",
    "wallet_rule_1": "1. อัญมณีสามารถใช้เพื่อปลดล็อคบทความ เมื่อปลดล็อคบทความเหรียญและอัญมณีมีค่าเดียวกัน 1 เหรียญ = 1 อัญมณี",
    "wallet_rule_2": "2. อัญมณีจะหมดอายุ โปรดทราบว่าอัญมณีที่ได้รับจากการเติมเงินและแพคเกจเดือนมีความถูกต้องเป็นเวลา 30 วัน และอัญมณีที่ได้รับจากงานประจำวันมีความถูกต้องเป็นเวลา 7 วัน",
    "wallet_rule_3": "3. เมื่อคุณปลดล็อคบทความ อัญมณีจะถูกใช้ก่อน",
    "read_task_title": "งานอ่าน",
    "share_task_title": "งานแบ่งปัน",
    "comment_task_title": "งานแสดงความคิดเห็น",
    "vip_task_title": "โบนัสพรีเมียม $9.99",
    "svip_task_title": "โบนัสพรีเมียม $19.99",
    "vip_page_title": "แพคเกจเดือน",
    "pay_bonus_label": "โบนัส",
    "daily_gems": "รายวัน {{n}} อัญมณี",
    "history_page_title": "ประวัติ",
    "recharge_title_1": "เติมเงิน",
    "gems_will_expire_btn": "รายละเอียด",
    "gems_history_tips": "เรียงตามวันหมดอายุ",
    "unlock_coins": "ต้องการ: {{n}} เหรียญหรือเพราะ",
    "history_recharge_title": "เติมเงิน",
    "history_game_title": "จับรางวัลโชคดี",
    "history_recharge_given_title": "โบนัสเติมเงิน",
    "extar_gems_btn": "เพิ่มเพชร",
    "watch_ad_task_title": "ดูวิดีโอ",
    "watch_ad_task_desc": "รับรางวัลสำหรับแต่ละวิดีโอที่ดู ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "รับรางวัลเรียบร้อยแล้ว",
    "task_finish_title": "งานได้รับการเสร็จสมบูรณ์แล้ว",
    "draw_width_ad_toast": "ขอแสดงความยินดี! คุณได้รับ  {{n}} และเพิ่มอีก  {{m}} ด้วยค่ะ/ครับ.",
    "draw_toast": "ยินดีด้วย! คุณได้รับ  {{n}} แล้ว",
    "draw_dialog_btn1": "เพียงเรียกร้อง  {{n}} เท่านั้น.",
    "draw_dialog_btn2": "เรียกร้อง  {{n}} และ  {{m}} เพิ่มเติม.",
    "history_ads_given_title": "ดูวิดีโอ",
    "ad_extra_rewards_title": "โบนัสหลังจากทำภารกิจเสร็จ.",
    "label_editor_picks": "เลือกโดยบรรณาธิการ",
    "label_genre": "ประเภท",
    "label_main_tab_home": "ค้นพบ",
    "label_reader_choice": "ยอดนิยม",
    "invite_bonuses": "เชิญชวนผู้ใช้ใหม่และรับโบนัสสูงสุด {{n}} โบนัส",
    "invite_friend_btn": "เชิญเพื่อนของคุณ",
    "how_get_bonuses": "วิธีการรับโบนัสเพิ่มเติม",
    "invite_tips_1": "เพื่อนของคุณได้ปลดล็อกและอ่าน 5 บท",
    "invite_tips_2": "เมื่อเพื่อนเติมเงิน คุณจะได้รับเงินคืน 10% เป็นเพชร สูงสุด 150 เพชรต่อเพื่อน",
    "invite_tips_3": "ทุกครั้งที่เพื่อนของคุณทำรายการสำเร็จ คุณจะได้รับรางวัลทันที",
    "how_invite_friend": "วิธีเชิญเพื่อน",
    "invite_tips_5": "คลิกปุ่มด้านล่างเพื่อสร้างลิงก์เชิญชวนที่เฉพาะของคุณและแชร์กับเพื่อน",
    "invite_tips_4": "เพื่อนของคุณดาวน์โหลด StoryGo ผ่านลิงก์ที่คุณแชร์",
    "reward_history_title": "ประวัติรางวัล",
    "reward_history_tips_1": "คุณได้รับรวม {{n}} อันมูลค่าจากกิจกรรมเชิญชวน",
    "reward_history_tips_2": "คุณได้เชิญเพื่อน {{n}} คนและได้รับเพชร {{m}} เรียบร้อยแล้ว",
    "reward_history_tips_3": "คุณได้รับ {{n}} จากการเปิดล็อก 5 บทที่เพื่อนของคุณที่คุณเชิญเปิด.",
    "reward_history_tips_4": "คุณได้รับ {{n}} แห่งเป็นรางวัลจากการเติมเงินของเพื่อนของคุณ",
    "daily_invite_friend_title": "เชิญเพื่อน",
    "daily_invite_friend_desc": "เชิญเพื่อนของคุณมาร่วม StoryGo และได้รับรางวัลใหญ่",
    "recharge_panel_ad_tips": "เติมเงินแพ็คเกจ StoryGo ของคุณเพื่อรับเหรียญหนังสือและอัญมณีมากมาย ปลดล็อกบทพรีเมียมได้ง่ายดายและเพลิดเพลินกับการอ่านโฆษณาฟรีเป็นเวลา 7 ถึง 30 วันหลังจากการซื้อ พร้อมกับการเปิดใช้งานทันที",
    "invite_more_friend": "เชิญเพิ่มเพื่อนและรับเพิ่มเพชร!",
    "history_invite_unlock_title": "ผู้ใช้ที่ถูกเชิญปลดล็อค 5 บท",
    "history_invite_recharge_title": "ผู้ใช้ที่ถูกเชิญได้ทำการเติมเงินแล้ว",
    "days": "วัน",
    "continue_read_app": "ดำเนินการอ่านในแอป",
    "download_app_tips": "สำหรับเนื้อหาที่น่าตื่นเต้นมากขึ้นโปรดดาวน์โหลด StoryGo",
    "invite_tips_6": "เชิญนักอ่านใหม่มาเรียกขอรับเพชร 15 ดวง คุณสามารถรับเพชรสูงสุด 300 ดวงเป็นรางวัลได้",
    "history_invite_success_title": "เชิญใช้งานเรียบร้อยแล้ว",
    "evaluation_task_title": "ให้คะแนน StoryGo",
    "evaluation_task_desc": "3 ขั้นตอนในการรับรางวัล",
    "invite_page_title": "เชิญชวนผู้ใช้ใหม่มาร่วมใช้งานและรับรางวัลมุข!",
    "invite_page_title_2": "สำหรับผู้ใช้ใหม่ทุกคนที่คุณเชิญเข้าร่วม คุณจะได้รับ",
    "invite_page_title_3": "สำหรับการชำระเงินของผู้ใช้ใหม่ คุณยังสามารถรับรางวัล{{n}} ชิปได้ เรียกเชิญมากขึ้น เก็บได้มากขึ้น",
    "my_invite_record": "ประวัติเชิญชวนของฉัน",
    "successfully_invited": "เชิญสำเร็จ",
    "earnings_gem": "รายได้ของฉัน (เพชร)",
    "how_invite_title_1": "เชิญเพื่อน",
    "how_invite_title_2": "เพื่อนทำการลงทะเบียนเรียบร้อย",
    "how_invite_title_3": "รางวัลมาถึง",
    "invite_h5_title": "เชิญคุณใช้ StoryGo ร่วมกัน",
    "got_gem_label": " ประโยชน์สำหรับมือใหม่",
    "accept_invitation": "ยอมรับเชิญ",
    "join_people": "คนได้เข้าร่วมในเหตุการณ์",
    "recommended_content": "เนื้อหาแนะนำ",
    "to_app_tip": " เนื้อหาที่น่าตื่นเต้นมากขึ้นบน StoryGo",
    "turn_on_notify_title": "เปิดการแจ้งเตือน",
    "turn_on_notify_desc": "เปิดการแจ้งเตือนข้อความเพื่อรับรางวัล",
    "label_help_center": "ศูนย์ช่วยเหลือ",
    "ai_become_writer_btn": "เป็นนักเขียน  ",
    "ai_offer_title": "สิ่งที่เราเสนอ  ",
    "ai_offer_item_label_1": "เครื่องมือสร้างสรรค์  ",
    "ai_offer_item_value_1": "โควตาลองใช้งานฟรีสำหรับเครื่องมือสร้างสรรค์  ",
    "ai_offer_item_label_2": "รางวัลเงินสด  ",
    "ai_offer_item_value_2": "เข้าร่วมการแข่งขันสร้างสรรค์เพื่อลุ้นรางวัลเงินสด 200 ดอลลาร์  ",
    "ai_offer_item_label_3": "นักเขียนที่มีสัญญา  ",
    "ai_offer_item_value_3": "เป็นนักเขียนที่มีสัญญาบนแพลตฟอร์มและรับส่วนแบ่งการสมัครสมาชิกที่ดีต่อเดือน  ",
    "ai_authors_say_title": "สิ่งที่นักเขียนของเราพูด  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"เครื่องมือเขียน AI นี้เหมือนกับผู้ช่วยที่ทรงพลัง ใช้งานง่ายและเรียบง่าย ทำให้ผู้ใช้ทุกคนสามารถเริ่มต้นได้อย่างง่ายดาย ผลลัพธ์คุณภาพสูงทำให้การสร้างสรรค์เป็นเรื่องที่สนุกสนาน และประสบการณ์ผู้ใช้เป็นความเพลิดเพลินที่ดีที่สุดในการเขียนอย่างไม่ต้องสงสัย\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "เครื่องมือเขียน AI นี้น่าทึ่งมาก! ใช้งานง่าย ทำงานง่าย และผลิตผลงานคุณภาพสูง มันช่วยให้ฉันสามารถแสดงความคิดสร้างสรรค์ได้อย่างง่ายดายและสนุกสนาน!  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "เครื่องมือเขียน AI นี้เป็นพรสำหรับผู้สร้าง อินเทอร์เฟซที่เรียบง่ายและใช้งานง่ายทำให้มือใหม่สามารถใช้ได้ง่าย คุณภาพของเรื่องราวที่สร้างขึ้นทำให้ประหลาดใจ การเขียนด้วยมันเป็นการเดินทางที่น่าพอใจอย่างแท้จริงของแรงบันดาลใจ  ",
    "ai_why_choose_title": "ทำไมถึงเลือกเรา  ",
    "ai_why_choose_item_label_1": "ทีมบรรณาธิการที่มีความหลงใหล  ",
    "ai_why_choose_item_value_1": "ทีมบรรณาธิการที่เป็นมืออาชีพและมีประสิทธิภาพมอบช่วงเวลาป้องกัน 6 เดือนสำหรับนักเขียนหน้าใหม่ ในช่วงนี้ต้นฉบับที่สร้างขึ้นสามารถใช้ช่องทางการส่งที่ปลอดภัยได้  ",
    "ai_why_choose_item_label_2": "เครื่องมือสร้างสรรค์ที่มีประสิทธิภาพ  ",
    "ai_why_choose_item_value_2": "สร้างง่าย รอบการสร้างสั้น ประสิทธิภาพการสร้างรายได้สูง  ",
    "ai_why_choose_item_label_3": "การรับประกันความปลอดภัย  ",
    "ai_why_choose_item_value_3": "ทีมกฎหมายมืออาชีพปกป้องสิทธิ์ของงานของคุณ  ",
    "ai_create_story_btn": "สร้างเรื่องราวของคุณ  ",
    "ai_contact_btn": "ติดต่อทีมบรรณาธิการ  ",
    "ai_dialog_title": "ยินดีต้อนรับสู่ StoryGo!  ",
    "ai_dialog_desc": "ใช้เครื่องมือสร้างสรรค์ของ StoryGo คุณยังสามารถเป็นนักเขียนเรื่องเด่น มาร่วมเป็นส่วนหนึ่งกับเรา!  ",
    "ai_dialog_part1_title": "ทดลองใช้ฟรี  ",
    "ai_dialog_part1_item1": "กรุณาเข้าสู่เว็บไซต์ PC ของเรา https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "เข้าร่วมกลุ่ม WhatsApp และติดต่อผู้ดูแลระบบเพื่อขอทดลองใช้ฟรีของเครื่องมือสร้างสรรค์  ",
    "ai_dialog_part2_title": "การแข่งขันสร้างสรรค์  ",
    "ai_dialog_part2_item1": "กรุณาเข้าสู่เว็บไซต์ PC ของเรา https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "เข้าร่วมการแข่งขันสร้างสรรค์ รับรางวัลเงินสด โอกาสใหม่  ",
    "ai_dialog_part2_item3": "หากคุณมีคำถามใด ๆ โปรดติดต่อเราที่ Contact@storygo.cc  ",
    "ai_dialog_copy_email": "คัดลอกลิงก์เว็บไซต์ทางการ  ",
    "ai_url_copy_tips": "ลิงก์คัดลอกสำเร็จ  "
}