<template>
  <section >

    <div class="justify-between header">

      <img class="logo" src="/images/logo.png" alt="">
      <div class="center">
        <div class="items-center language" @click="selectLang">
          <img src="/images/lang-icon.png" alt=""> <span>{{language}}</span>
        </div>
        <van-skeleton  avatar :loading="loading">
          <Image @click="toUser"
                 class="user-img"
                 radius="50%"
                 fit="cover"
                 :src="user.baseInfo.avatarUrl"
                 error-icon="/images/avatar.png"
                 loading-icon="/images/avatar.png"
          >
          </Image>
        </van-skeleton>
      </div>

    </div>
    <Search class="search" shape="round" :placeholder="$t('search')" @focus="toSearch">
      <template #left-icon>
        <img class="left-icon" src="/images/search-icon.png" alt="">
      </template>
    </Search>

    <MultiLinesCard2 :items="hardPickBooks" :title="$t('label_editor_picks')" :loading="loading1" :placeHolderSize="10" @click-item="toDetail">
      <template #header-right>
        <div @click="getHandPickedBooks" class="items-center recommend-right">
          <img  src="/images/refresh.png" alt="">
          <span class="not-flip">Switch</span>
        </div>
      </template>
    </MultiLinesCard2>
    <MultiRowsCard :items="genres" :title="$t('label_genre')" :loading="loading" :placeHolderSize="10" @click-item="toSubject"></MultiRowsCard>

    <!--    <MultiLinesCard :items="hardPickBooks" :title="hardPick.title" :loading="loading" :placeHolderSize="10" @click-item="toDetail"></MultiLinesCard>-->
<!--    <MultiLinesCard :items="books2" :title="$t('label_reader_choice')" :loading="loading" :placeHolderSize="10" @click-item="toDetail"></MultiLinesCard>-->
<!--    <MultiLinesCard :items="books3" :title="$t('label_new_arrival')" :loading="loading" :placeHolderSize="10" @click-item="toDetail"></MultiLinesCard>-->
    <SingleLineCard :items="books2" :title="$t('label_reader_choice')" :placeHolderSize="10" :loading="loading" @click-item="toDetail"></SingleLineCard>
    <SingleLineCard :items="newPublishBooks" :title="$t('label_new_arrival')" :placeHolderSize="10" :loading="loading" @click-item="toDetail"></SingleLineCard>
<!--    <ListCard :items="newPublishBooks"-->
<!--              :title="$t('label_main_tab_home')"-->
<!--              :loading="loading"-->
<!--              :placeHolderSize="10"-->
<!--              @click-item="toDetail"-->

<!--    ></ListCard>-->
    <ListCard :items="highestBooks"
              :title="$t('label_main_tab_home')"
              :loading="loading"
              :placeHolderSize="10"
              @click-item="toDetail"

    ></ListCard>
    <Download position="bottom"></Download>
<!--    @on-load="getMore"-->

    <Popup :lock-scroll="false" v-model:show="langPopup"  round position="bottom" z-index="9999">
      <div class="languages">
        <div class="top"></div>
        <div class="title">{{$t('language_title')}}</div>
        <div class="language no-scrollbar">
          <div class="justify-between item" v-for="(language, index) in languages" :key="index" @click="selectLanguage(language)">
            <div>
              <div class="name">{{language.name}}</div>
              <div class="description" v-show="language.checked">{{$t('current_lang')}}</div>
            </div>
            <img v-if="language.checked" class="not-flip" src="/images/checked-icon.png" alt="" >
            <img v-else src="/images/uncheck-icon.png" alt="">
          </div>
        </div>
      </div>
    </Popup>

  </section>
</template>
<script setup lang="ts">
import {Image, Search, Popup} from 'vant'
import { ref, onMounted, onActivated } from 'vue'
import MultiLinesCard from '@/components/multiLinesCard/index.vue'
import MultiLinesCard2 from '@/components/muitilLinesCard2/index.vue'
import SingleLineCard from '@/components/singleLineCard/index.vue'
import MultiRowsCard from '@/components/multiRowsCard/index.vue'
import ListCard from '@/components/listCard/index.vue'
import Download from '@/components/download/index.vue'
import {chunk} from 'lodash'
import {useRouter} from 'vue-router'
import {
  getBookList,
  listAllBookCategories,
  listBookCategoriesBySubject,
  listBooksBySubject,
  listBooksRankByCategory
} from "@storygo/api/app"
import {novel} from "@storygo/types/novel_baseTars.d";
import {useUserStore} from "@/stores/user";
import {useLanguageStore} from "@/stores/language";
import {removeBlankSpace, splitArray} from "@storygo/utils";
import {eventBus} from "@storygo/utils/emitter";
import {$t} from "@storygo/lang"
const user = ref({
  baseInfo: {}
} as novel.UserInfo)
const loading = ref(true)
const loading1 = ref(true)
const subjectOnePage = ref(0)
const {userInfo} = useUserStore()
const books1 = ref([[{}, {}, {}],[{}, {}, {},]])
const books2 = ref([{}, {}, {}, {}, {}, {}])
const books3 = ref([[{}, {}, {}],[{}, {}, {},],[{}, {}, {}]])
const {language, changeLanguage, lcid} = useLanguageStore()
const genres = ref( [
  [{}, {}, {}],[{}, {}, {}]
] as Array<novel.BookCategory[]>)

const subjectOne = ref({})
const subjectTwo = ref({})
const subjectThree = ref({})
const hardPick = ref({})



// utils lastestBooks = ref([])
const newPublishBooks = ref([{}, {}, {}, {}, {}, {},{}])
const highestBooks = ref([{}, {}, {}, {}, {}, {},{}])
const hardPickBooks = ref([[{}, {}, {}],[{}, {}, {}]])
const langPopup = ref(false)
const hardPickBooksPage = ref(0)

const languages = ref([
  {name: 'Español', value: '1034', checked: lcid === '1034', code: 'ES'},
  // {name: 'EN', value: '1033', checked: lcid === '1033', code: 'EN'},
  {name: 'Indonesian', value: '1057', checked: lcid === '1057', code: 'ID'},
  {name: 'Tiếng Việt', value: '1066', checked: lcid === '1066', code: 'VI'},
  {name: 'ภาษาไทย', value: '1054', checked: lcid === '1054', code: 'TH'},
  {name: 'Wikang Filipino', value: '1124', checked: lcid === '1124', code: 'PH'},
  {name: 'العربية', value: '1025', checked: lcid === '1025', code: 'AR'},
  // {name: 'العربية', value: '1056', checked: lcid === '1056', code: 'AR'},

  {name: 'Français', value: '1036', checked: lcid === '1036', code: 'FR'},
  {name: 'Português', value: '2070', checked: lcid === '2070', code: 'PT'},
  {name: 'Deutsch', value: '1031', checked: lcid === '1031', code: 'DE'},
  // {name: 'русский язык', value: '1031', checked: lcid === '1031', code: 'DE'},
  {name: 'Russian', value: '1049', checked: lcid === '1049', code: 'RU'},
])

const router = useRouter()

const toDetail = (book: novel.Book) => {
  window.cacheData = book
  router.push(`/books/${removeBlankSpace(book.name)}_${book.bookId}`)
}

const toSearch = () => {
  eventBus.$emit('initSearch')
  router.push('/search')
}

const selectLang = () => {
  langPopup.value = true
}

const selectLanguage = (item: typeof languages.value[0]) => {
  languages.value.map(lang => {
    lang.checked = false
  })
  item.checked = true
  changeLanguage(item.value)
  console.log(language, item.value)
}

const toUser = () => {
  // router.push('/user?id=xx')
  router.push('/user')
}

const toSubject = (item: novel.BookCategory) => {
  router.push(`/genre/${item.name}_${item.id}`)
}

const getSubjectBoods = async () => {
  Promise.all([
    // listBooksBySubject('module_one', 0, 6),
    // listBooksBySubject('module_two', 0, 20),
    // listBooksBySubject('module_three', 0, 20)
    getBookList(4,1, 20),
    // getBookList(2,1, 20),
  ]).then(([res2]) => {
    // subjectOnePage.value++
    // subjectOne.value = res1.subject
    // subjectTwo.value = res2.subject
    // subjectThree.value = res3.subject
    // books1.value = chunk(res1.books.value,3)
    // books2.value = chunk(res2.books.value, 3)
    books2.value = res2.books.value
    // books3.value = chunk(res3.books.value, 3)
    loading1.value = false
  })
  // utils res1 = await listBooksBySubject('module_one', 0, 30)
  // utils res2 = await listBooksBySubject('module_two', 0, 30)
  // utils res3 = await listBooksBySubject('module_three', 0, 30)

}

const getSubjectOne = async () => {
  loading1.value = true
  const res = await listBooksBySubject('module_one', subjectOnePage.value * 6, 6)
  const lastBooks = books1.value
  if(res.books.value.length < 6){
    books1.value = chunk(res.books.value.concat(lastBooks[0]).slice(0, 6), 3)
    subjectOnePage.value = 0
  }else {
    books1.value = chunk(res.books.value, 3)
    subjectOnePage.value++
  }

  loading1.value = false
}

const getHandPickedBooks = async () => {
  loading1.value = true
  // utils res = await listBooksBySubject('hand_picked', hardPickBooksPage.value * 6, 6, false)
  const res = await listBooksBySubject('hand_picked', 0, 6, false)
  hardPick.value  = res.subject
  if(res.books.value.length < 6){
    const lastBooks = hardPickBooks.value
    if(lastBooks[0]){
      hardPickBooks.value = chunk(res.books.value.concat(lastBooks[0]).slice(0, 6), 3)
      hardPickBooksPage.value = 0
    }
  }else {
    hardPickBooks.value = chunk(res.books.value, 3)
    console.log(hardPickBooks.value)
    hardPickBooksPage.value ++
  }
  loading1.value = false
}


const getCategories = async () => {
  const res = await listAllBookCategories(0, 10)
  genres.value = splitArray(res.categories.value, 2, 5)
}

const getLastestBooks = async ( page = 1) => {
  const res = await getBookList(0,page)
}
let moreIng = 0
let highestPage = 1
const getHighestBooks = async (page = 1) => {
  const res = await listBooksRankByCategory(page, 20)
  console.log(page)
  if(page === 1){
    highestBooks.value = res.books.value
  }else {
    highestBooks.value = highestBooks.value.concat(res.books.value)
  }
  moreIng = 0
}

const getMore = async () => {
  if(moreIng) return
  getHighestBooks(++highestPage)
  moreIng = 1
}

const getNewPublishBooks = async (page = 1) => {
  const res = await getBookList(5,page, 12, false)
  newPublishBooks.value = res.books.value
}

onActivated(() => {
  const userInfoStr = window.localStorage.getItem('userInfo')
  if(userInfoStr){
    user.value = JSON.parse(userInfoStr)
  }else {
    user.value = { baseInfo: {}} as novel.UserInfo
  }

  eventBus.$emit('clearCache')
})

onMounted(() => {
  getSubjectBoods()
  getCategories()
  getNewPublishBooks()
  getHighestBooks()
  getHandPickedBooks()
  setTimeout(() => {
    loading.value = false
  }, 1500)
})

</script>
<style scoped lang="less" src="./index.less">

</style>
