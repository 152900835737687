import {eventBus} from "./emitter";

/**
 * CommonNative/CommonJs
 * @onUserInfo Object 返回用户信息
 * @onLogin Object 成功登陆返回用户信息
 * @emitShareLink Object{title,content,image,link} 分享标题、内容、缩略图、链接等资源
 * @onShareLink Boolean 分享状态
 * @frameSize Object{width,height}
 * @forwardLink Object{url}
 */
const sdkMap: Record<keyof SdkRequest, any> = {
    getPushSetting: 'onGetPushSetting',
    emitPushSetting: '',
    emitCustomPopup: '',
    emitShareInvitation: '',
    emitEarnAdReward: 'onAdRewardEarned',
    emitH5RechargePackageStart: '',
    emitPayFeedback: '',
    emitH5RechargePackageDone: '',
    emitPayMonthlyCard: 'onMonthlyCardPaid',
    emitMonthlyCardPaid: '',
    emitH5MonthlyCardPaid: '',
    emitRechargePackage: 'onRechargePackageDone',
    emitRechargePackageDone: '',
    emitClipboard: '',
    emitRestorePay: '',
    emitSignin: 'onSigninDismiss',
    getUserInfo: 'onUserInfo', // 获取用户信息
    emitLogin: 'onLogin', // 通知app触发登录逻辑
    emitShareLink: 'onShareLink', // 通知app触发分享链接逻辑
    emitNativeSchema: '',
    frameSize: '',
    forwardLink: '',
    close: '',
    emitMessage: '',
    emitOpenBrowser: '',
    emitBack: '',
    emitComment: 'onArticleUpdate',
    emitMoreOptions: 'onArticleUpdate',
    emitShareArticle: '',
    emitUpdateOc: 'onUpdateOc',
    emitSaveImage: '',
    emitEvent: '',
    emitPublishArticle: '',
    emitPrivacyChat: '',
    emitOpenIsland: '', //岛
    emitOpenRoles: '',
    getUserOc: 'onUserOc',
    // RoleJs
    getRoleInfo: 'onRoleInfo',
    postSaveData: '',
    setModified: '',
    emitPayVip: 'onPayVipDone',
    emitTaskFinished: ''
};

export const sdkPromise = <T extends keyof SdkRequest>(key: T, data = {} as SdkRequest[T]): Promise<SdkResponse[T]> =>
    new Promise((resolve, reject) => {
        if (!key) {
            reject(new Error('Missing parameters key'));
        }
        const cb = sdkMap[key];
        const win = window;
        if (cb) {
            if (!win.CommonJs) {
                win.CommonJs = {};
            }
            win.CommonJs[cb] = (resString: string) => {
                console.log(cb, resString);
                if (!resString) {
                    // @ts-ignore
                    return resolve(null);
                }
                    if ([
                        'getUserInfo',
                        'getUserOc',
                        'emitUpdateOc',
                        'emitPayVip',
                        'emitRechargePackage',
                        'emitPayMonthlyCard',
                        'emitEarnAdReward',
                        'getPushSetting'
                    ].indexOf(key) > -1) {
                        resolve(JSON.parse(decodeURIComponent(decodeURI(resString))));
                    } else {
                        resolve(JSON.parse(resString));
                    }

            };
        }
        const uri = encodeURIComponent(
            JSON.stringify({...data, ...{__functionName: key}})
        );
        if (win.CommonNative && win.CommonNative[key]) {
            console.log(`use CommonNative ${key}`, data);
            win.CommonNative[key](uri);
            if (!cb) {
                // @ts-ignore
                resolve(null);
            }
        } else if (
            win.webkit &&
            win.webkit.messageHandlers &&
            win.webkit.messageHandlers.CommonNative
        ) {
            console.log(`use webkit ${key}`, data);
            win.webkit.messageHandlers.CommonNative.postMessage(uri);
            if (!cb) {
                // @ts-ignore
                resolve(null);
            }
        } else {
            console.log(`${key} not in app`, data);
            // reject(new Error(`${key} not in app`))
            // @ts-ignore
            resolve(null);
        }
    });

interface FunctionNames {
    onNativeUpdated: {
        mask: number
    }
    onRechargePackageEnd: {
        pid: number
        action: number
    }
    onMonthlyCardPayEnd: {
        pid: number
        action: number
    }
}
export const onSDKListener = <T extends keyof FunctionNames>(functionName: T, callback: (payload: FunctionNames[T]) => void): Promise<FunctionNames[T]> => {
    return new Promise((resolve) => {
        const win = window;
        if (!win.CommonJs) {
            win.CommonJs = {};
        }
        // if(!win.CommonJs[functionName]) {
            win.CommonJs[functionName] = (resString: string) => {
                console.log(functionName, resString)
                try {
                    const data = JSON.parse(decodeURIComponent(decodeURI(resString)))
                    resolve(data as FunctionNames[T])
                    callback && callback(data as FunctionNames[T])
                }catch (e){
                    const data = JSON.parse(resString);
                    resolve(data as FunctionNames[T])
                    callback && callback(data as FunctionNames[T])
                }
            };
        // }else {
        //     eventBus.$on(functionName, (res:FunctionNames[T]) => {
        //         resolve(res)
        //     })
        // }
    })
}

