import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import {novel} from "@storygo/types/novel_baseTars.d";

export const useUserStore = defineStore('user', () => {
  const userInfo = ref<novel.UserInfo>({
    // id: 'xxxx'
    baseInfo: {}
  } as Partial<novel.UserInfo>)
  let userInfoStr = window.localStorage.getItem('userInfo')
  if(userInfoStr){
    userInfo.value = JSON.parse(userInfoStr)
  }

  function setUserInfo(data: Partial<novel.UserInfo>) {
    window.localStorage.setItem('userInfo', JSON.stringify(data))
    userInfo.value = data
  }
  function getUserInfo() {
    userInfoStr = window.localStorage.getItem('userInfo')
    if(userInfoStr){
      userInfo.value = JSON.parse(userInfoStr)
    }
    return userInfo.value
  }


  function clearUserInfo() {
    userInfoStr = ''
    window.localStorage.removeItem('userInfo')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('isNewUser')
    window.localStorage.removeItem('hasBindPhone')
    userInfo.value = {
      baseInfo: {}
    } as novel.UserInfo
  }

  return { userInfo, setUserInfo, clearUserInfo, getUserInfo}
})



