import {createApp, createVNode, mergeProps, getCurrentInstance, reactive, ref} from 'vue';
import Toast from './index.vue'
export const showFeedbackToast = (duration = 5000) => {
    const el = document.createElement('div');
    const state = reactive({
        visible: true
    })

    const app = createApp({
        setup() {
            const render = () => {
                return createVNode(Toast, mergeProps(state, {
                    onAfterLeave(){
                        app.unmount()
                        el.remove()
                    }
                }))
            }
            const instance = getCurrentInstance()
            if(!instance) return ;
            //@ts-ignore
            instance.render = render
            return {}
        }
    }, {

    })
    app.mount(el)
    document.body.append(el)

    setTimeout(() => {
        state.visible = false
    }, duration)
}
