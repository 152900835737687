import * as Sentry from "@sentry/vue";

export const initSentry = (app: any) => {
    Sentry.init({
        app,
        dsn: "https://6e6aaf1db480637e4ec50ad7fea6a8c9@o1250041.ingest.us.sentry.io/4507218453725184",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["www.storygo.cc", "wap.storygo.cc", "app.storygo.cc"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
