export default {
    "next": "Berikutnya",
    "login": "Masuk",
    "login_tips": "Kami berharap Anda dapat menikmati perjalanan romantis di sini",
    "set_password": "Atur kata sandi",
    "enter_password": "Silakan masukkan kata sandi Anda",
    "verify_code": "Kode verifikasi",
    "enter_code": "Silakan masukkan 6 kode verifikasi digital email",
    "let_start": "Mari Mulai",
    "let_start_tips": "Kami berharap Anda dapat menikmati perjalanan romantis di sini",
    "sign_facebook": "Masuk dengan FaceBook",
    "sign_google": "Masuk dengan Google",
    "sign_email": "Masuk dengan Email",
    "language_title": "Atur preferensi bahasa bacaan",
    "current_lang": "bahasa saat ini",
    "en": "EN",
    "indonesian": "Indonesia",
    "personal_data": "Data pribadi",
    "save": "Simpan",
    "nickname": "Nama panggilan",
    "sign_out": "Keluar",
    "loading": "memuat",
    "success": "Berhasil",
    "reading_record": "Rekaman membaca",
    "empty": "Kosong",
    "last_read": "Bacaan terakhir",
    "chapter": "bab",
    "remove": "Hapus",
    "search": "Cari",
    "search_empty": "Tidak ada novel terkait \"{{var}}\" yang dapat ditemukan",
    "login_tips_2": "Masuk untuk mengakses lebih banyak fitur.",
    "register_or_login": "Daftar/Masuk",
    "more": "Lebih banyak",
    "reads": "Membaca",
    "read": "Baca",
    "last_chapter": "Bab Terakhir",
    "all_chapter": "Semua Bab",
    "contents": "Isi",
    "all": "Semua",
    "completed": "Selesai",
    "ongoing": "Berlangsung",
    "most_popular": "Paling Populer",
    "new_arrival": "Baru Datang",
    "invite_friends": "Bagikan kode undangan ini kepada teman-teman Anda. Setelah mereka mengaktifkannya di halaman ini, Anda berdua akan mendapatkan {{coins}} permata. Setiap kode undangan dapat digunakan hingga 50 kali.",
    "invite_friends_nums": "<span class=\"blue\">Saya mengundang {{n}} teman dan mendapatkan</span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>",
    "guide_title": "Judul Panduan StoryGo Author Project",
    "guide_content": 0,
    "active_code": "Aktifkan kode undangan",
    "active_tips": "Dapatkan kode undangan dari teman Anda, dan setelah berhasil diaktifkan, Anda akan menerima {{coins}} permata. Tips: Anda tidak dapat mengaktifkan kode undangan Anda sendiri; Anda harus mendapatkannya dari orang lain!",
    "login_now": "Masuk sekarang",
    "task_1_title": "Hadiah Selamat Datang",
    "task_1_desc": "Hadiah selamat datang untuk pendatang baru, yang telah secara otomatis disetorkan ke dompet Anda",
    "view_detail": "Lihat detail",
    "claim": "Klaim",
    "invite_friend": "Undang Teman",
    "my_invite_code": "Kode undangan saya",
    "copy": "Salin",
    "task_2_title": "Setiap Hari Check-in",
    "task_2_desc": "Check in setiap hari, dan dapatkan bonusnya.",
    "task_3_title": "Baca selama lebih dari {{min}} menit",
    "task_3_desc": "Baca total {{min}} menit hari ini untuk mendapatkan hadiah.",
    "task_page_label": "Hadiah",
    "label_wallet_purchase_coins": "Beli koin",
    "go": "Pergi",
    "done": "Selesai",
    "copy_text": "Kode undangan StoryGo saya adalah [ {{code}} ], aktifkan dan dapatkan 50 Koin:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "Kode undangan telah disalin.",
    "active_success": "Aktivasi berhasil. Hadiah 50 koin telah dikirim ke dompet Anda.",
    "error_code_limit": "Kode aktivasi telah mencapai batas penggunaannya.",
    "error_had_activated": "Akun ini sudah diaktifkan dengan kode",
    "error_invalid_code": "Kode aktivasi tidak valid",
    "download_tips": "Cerita Tanpa Batas di APP",
    "download": "Unduh",
    "label_book_praise": "Peringkat",
    "label_book_prologue": "Prolog",
    "login_success": "Login berhasil",
    "msg_reset_password_repeat": "Silakan ulangi kata sandi baru Anda",
    "msg_reset_password_email": "Silakan masukkan alamat email Anda untuk mengatur ulang kata sandi Anda",
    "label_reset_password": "Atur Ulang Kata Sandi",
    "label_send_email": "Kirim email",
    "msg_login_email_code_sent": "Kode verifikasi telah berhasil dikirim ke email Anda",
    "msg_feedback_error": "Gagal mengirim, silakan coba lagi nanti",
    "save_userinfo": "Berhasil disimpan",
    "label_load_image_fail": "Gagal memuat gambar",
    "paid_chapters": "Bab Berbayar",
    "unlock_price": "Membuka bab memerlukan {{coin}}",
    "download_claim_coin": "Unduh aplikasi untuk klaim {{coin}} dan buka lebih banyak bab yang menarik.",
    "label_book_chapters": "{{num}} Bab",
    "label_cancel": "Batal",
    "msg_common_state_empty": "Kosong di sini",
    "clear_reading_record": "Anda yakin ingin menghapus catatan bacaan?",
    "clear_successful": "Catatan berhasil dihapus.",
    "label_search_trending": "Pencarian Tren",
    "task_login_tips": "Login untuk mendapatkan Bonus Koin",
    "label_new_arrival": "Rilisan Baru Terpopuler",
    "had_activated": "Akun ini sudah diaktifkan dengan kode",
    "activate": "Mengaktifkan",
    "task_5_title": "Bagikan ke Facebook",
    "task_5_desc": "Bagikan buku apa pun ke platform Facebook",
    "bigwheel_redeem_label": "Isi ulang",
    "coin_tips_1": "Koin tidak mencukupi, harap isi ulang.",
    "coin_tips_2": "Akun: {{n}} koin (memerlukan {{m}} koin)",
    "bigwheel_rule_1": "1. Ikut serta dalam putaran Roda Keberuntungan dengan jaminan 100% mendapatkan hadiah.",
    "bigwheel_rule_2": "2. Satu kali putaran roda biaya 10 koin emas, sepuluh kali putaran biaya 100 koin emas, dan lima puluh kali putaran biaya 500 koin emas. Semakin banyak putaran beruntun, semakin tinggi peluang mendapatkan hadiah berharga!",
    "bigwheel_rule_3": "3. Hadiah yang didapatkan selama permainan secara otomatis didistribusikan. Koin emas langsung dikreditkan ke dompet koin emas Anda.",
    "bigwheel_rule_4": "4. Permainan ini tidak berafiliasi dengan Apple Inc. Semua hak untuk interpretasi akhir dimiliki oleh StoryGo.",
    "bigwheel_balance_label": "Saldo",
    "no_data_1": "Tidak ada data",
    "msg_room_coin_notice": "Selamat! [{{who}}] menang {{coin}} koin!",
    "top_up": "Isi ulang",
    "sign": "Daftar",
    "sign_desc": "Hadiah selamat datang untuk pendatang baru, yang telah secara otomatis disetorkan ke dompet Anda",
    "first_recharge": "Penawaran waktu terbatas",
    "first_recharge_desc": "Selesaikan Penawaran waktu terbatas untuk mendapatkan bonus",
    "unlock_chapter": "Buka kunci {{n}} bab",
    "unlock_chapter_desc": "Buka kunci bab premium untuk mendapatkan hadiah.",
    "comment_book": "Kirim komentar buku",
    "comment_book_desc": "Tulis ulasan untuk buku favoritmu dan dapatkan kesempatan untuk memenangkan hadiah!",
    "my_coins": "Koin saya",
    "recharge_title": "Paket hadiah yang direkomendasikan",
    "bonus": "Bonus",
    "buy_coins": "Beli koin",
    "my_balance": "Saldo saya",
    "apple_pay": "Apple Pay",
    "other_pay": "Pilihan Utama",
    "google_pay": "Google Pay",
    "pay_now": "Bayar Sekarang",
    "label_balance": "koin",
    "select_payment": "Silakan pilih metode pembayaran",
    "select_package": "Silakan pilih paket isi ulang",
    "wallet_title": "Dompet",
    "coins_balance": "Saldo",
    "new_comer": "Pengguna Baru",
    "extra_coins": "Ekstra {{n}} koin",
    "daily_task_label": "Tugas Harian",
    "label_pay_success": "Pembayaran berhasil!",
    "app_update_title": "Harap perbarui ke versi terbaru.",
    "restore_label": "Kembalikan",
    "history_label": "Sejarah",
    "newcomer_pack": "Penawaran waktu terbatas",
    "recommend_pack": "Paket yang direkomendasikan",
    "collection_month": "Total/Bulan",
    "now_get_icons": "Dapatkan {{n}}  sekaligus",
    "monthly_get_icons": "Dapatkan {{n}} Permata/Bulan",
    "recharge_feedback_msg": "Mengalami kesulitan dalam mengisi ulang?",
    "feedback_label": "Umpan balik",
    "coin_label": "Koin",
    "gem_label": "Permata",
    "restore_tips": "Tidak ada langganan yang dapat dipulihkan",
    "recharge_label": "Top up",
    "sign_days": "Check-in {{n}} hari",
    "vip_daily_task": "Bonus paket bulanan",
    "other": "Lainnya",
    "limited_recharge_pack": "Penawaran waktu terbatas",
    "expire_tips": "Kadaluarsa pada {{time}}",
    "hot_pack": "Paket populer",
    "continuous_checkin_day": "Check-in {{n}} hari",
    "svip_login_reward": "Bonus premium $9.9",
    "vip_login_reward": "Bonus premium $19.9",
    "dailysign_popup_btn": "Dapatkan lebih banyak dari Pusat Hadiah",
    "checked_in": "Diperiksa",
    "limit_time_recharge_title": "Penawaran waktu terbatas",
    "recharge_pack_tips": "Selesaikan Penawaran waktu terbatas untuk mendapatkan bonus",
    "balance_nsufficient_tips": "Saldo Anda tidak mencukupi, silakan isi ulang",
    "had_coins_tips": "Anda memiliki: {{n}} Koin | {{m}} Permata",
    "dailysign_popup_title": "Selamat",
    "pay_method_label": "Metode Pembayaran",
    "at_once": "Sekali",
    "daily_login": "Login harian",
    "card_coin_suffix": "Total/Bulan",
    "card_gems_suffix": "Bulan",
    "vips_rule_title": "Tentang paket bulanan:",
    "vips_rule_1": "1. Berhasil membeli $9.9-bonus paket bulanan, Anda akan segera menerima 1.000 koin emas, dan Anda dapat menerima 20 permata setiap hari selama 30 hari berturut-turut (600 permata secara total); Berhasil membeli $19.9-bonus paket bulanan, Anda akan segera menerima 2.000 koin emas, dan Anda dapat menerima 40 permata setiap hari (600 permata secara total) selama 30 hari berturut-turut.",
    "vips_rule_2": "2. Jika Anda membeli paket bulanan yang berbeda, imbalan permata yang Anda dapatkan setiap hari akan bertumpuk; jika Anda membeli paket bulanan yang sama, imbalan permata yang Anda dapatkan setiap hari tidak akan bertumpuk, tetapi hanya akan memperpanjang durasi kartu bulan yang sesuai.",
    "vips_rule_3": "3. Permata yang diperoleh setiap hari berlaku selama 30 hari, harap perhatikan.",
    "vips_rule_4": "",
    "vips_rule_5": "1. Paket bulanan akan berlaku dalam 24 jam setelah pembelian dan Anda dapat menikmati manfaat yang sesuai.",
    "check_in": "Check in",
    "day_index": "No.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Permata </span> akan segera kadaluarsa",
    "balance_label": "Saldo",
    "wallet_rule_title": "Tentang Permata",
    "wallet_rule_1": "1. Permata dapat digunakan untuk membuka bab. Ketika membuka bab, koin dan permata memiliki nilai yang sama, 1 koin = 1 permata.",
    "wallet_rule_2": "2. Permata akan kadaluarsa. Harap dicatat bahwa permata yang diterima dari pengisian ulang dan paket bulanan berlaku selama 30 hari dan permata yang diklaim oleh tugas harian berlaku selama 7 hari.",
    "wallet_rule_3": "3. Ketika Anda membuka bab, permata digunakan terlebih dahulu.",
    "read_task_title": "Tugas membaca",
    "share_task_title": "Tugas berbagi",
    "comment_task_title": "Tugas komentar",
    "vip_task_title": "Bonus premium $9.99",
    "svip_task_title": "Bonus premium $19.99",
    "vip_page_title": "Paket bulanan",
    "pay_bonus_label": "Bonus",
    "daily_gems": "Harian {{n}} Permata",
    "history_page_title": "Sejarah",
    "recharge_title_1": "Isi ulang",
    "gems_will_expire_btn": "Detail",
    "gems_history_tips": "Urutkan berdasarkan tanggal kadaluarsa",
    "unlock_coins": "Butuh: {{n}} Koin atau Permata",
    "history_recharge_title": "Isi Ulang",
    "history_game_title": "Lucky Draw",
    "history_recharge_given_title": "Bonus Isi Ulang",
    "extar_gems_btn": "Gelang Ekstra",
    "watch_ad_task_title": "Menonton video",
    "watch_ad_task_desc": "Menerima hadiah untuk setiap video yang ditonton ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Hadiah berhasil diklaim.",
    "task_finish_title": "Tugas telah selesai.",
    "draw_width_ad_toast": "Selamat! Anda telah mendapatkan  {{n}} dan tambahan  {{m}}.",
    "draw_toast": "Selamat! Kamu sudah mendapatkan  {{n}}.",
    "draw_dialog_btn1": "Hanya klaim  {{n}}.",
    "draw_dialog_btn2": "Klaim  {{n}} dan tambahan  {{m}}.",
    "history_ads_given_title": "Menonton video",
    "ad_extra_rewards_title": "Bonus setelah selesai tugas.",
    "label_editor_picks": "Pilihan Editor",
    "label_genre": "Genre",
    "label_main_tab_home": "Menemukan",
    "label_reader_choice": "Teratas",
    "invite_bonuses": "Undang pengguna baru dan dapatkan hingga {{n}} bonus.",
    "invite_friend_btn": "Undang teman Anda",
    "how_get_bonuses": "Cara mendapatkan lebih banyak bonus",
    "invite_tips_1": "Teman Anda telah membuka dan membaca 5 bab",
    "invite_tips_2": "Saat temanmu melakukan pengisian ulang, kamu akan mendapatkan 10% kembali dalam bentuk permata, hingga 150 permata per teman.",
    "invite_tips_3": "Setiap kali teman Anda menyelesaikan sebuah tindakan, Anda akan menerima hadiah langsung.",
    "how_invite_friend": "Cara mengundang teman",
    "invite_tips_5": "Klik tombol di bawah untuk menghasilkan tautan undangan eksklusif Anda dan bagikan dengan teman-teman",
    "invite_tips_4": "Teman Anda mengunduh StoryGo melalui tautan yang Anda bagikan",
    "reward_history_title": "Riwayat hadiah",
    "reward_history_tips_1": "Kamu telah mendapatkan total {{n}} permata dari kegiatan undangan.",
    "reward_history_tips_2": "Anda telah berhasil mengundang {{n}} teman dan {{m}} permata.",
    "reward_history_tips_3": "Kamu telah mendapatkan {{n}} permata dari tugas membuka 5 bab yang di-unlock oleh teman-teman yang kamu undang.",
    "reward_history_tips_4": "Anda telah menerima {{n}} permata sebagai hadiah dari pengisian ulang teman-teman Anda",
    "daily_invite_friend_title": "Undang teman-teman",
    "daily_invite_friend_desc": "Ajak teman-temanmu untuk bergabung dengan StoryGo dan dapatkan hadiah besar.",
    "recharge_panel_ad_tips": "Isi ulang paket StoryGo Anda untuk mendapatkan koin buku dan permata yang melimpah. Mudah membuka bab premium dan nikmati membaca tanpa iklan selama 7 hingga 30 hari setelah pembelian, dengan aktivasi instan.",
    "invite_more_friend": "Undang lebih banyak teman dan dapatkan lebih banyak Permata!",
    "history_invite_unlock_title": "Pengguna yang diundang membuka 5 bab",
    "history_invite_recharge_title": "Pengguna yang diundang melakukan pengisian ulang",
    "days": "Hari",
    "continue_read_app": "Lanjutkan Membaca di Aplikasi",
    "download_app_tips": "Untuk konten yang lebih menarik, silakan unduh StoryGo",
    "invite_tips_6": "Undang pembaca baru untuk mengklaim 15 permata. Anda dapat mendapatkan hingga 300 permata sebagai hadiah.",
    "history_invite_success_title": "Berhasil mengundang seorang pengguna",
    "evaluation_task_title": "Beri Nilai StoryGo",
    "evaluation_task_desc": "3 Langkah untuk mendapatkan hadiah",
    "invite_page_title": "Undang pengguna baru untuk menggunakannya bersama dan terima hadiah permata!",
    "invite_page_title_2": "Untuk setiap pengguna baru yang Anda undang, Anda akan mendapatkan.",
    "invite_page_title_3": "Untuk pembayaran pengguna baru, Anda juga bisa mendapatkan {{n}} hadiah permata. Semakin banyak yang Anda undang, semakin banyak yang Anda dapatkan.",
    "my_invite_record": "Catatan Undangan Saya",
    "successfully_invited": "Undangan Berhasil",
    "earnings_gem": "Pendapatan Saya (Permata)",
    "how_invite_title_1": "Undang Teman",
    "how_invite_title_2": "Teman menyelesaikan pendaftaran",
    "how_invite_title_3": "Hadiah tiba",
    "invite_h5_title": "Undang Anda untuk menggunakan StoryGo bersama-sama",
    "got_gem_label": "Manfaat bagi pemula",
    "accept_invitation": "Menerima undangan",
    "join_people": "Orang telah berpartisipasi dalam acara tersebut",
    "recommended_content": "Konten yang direkomendasikan",
    "to_app_tip": "Lebih banyak konten menarik di StoryGo",
    "turn_on_notify_title": "Buka notifikasi",
    "turn_on_notify_desc": "Buka notifikasi pesan untuk menerima hadiah",
    "label_help_center": "Pusat Bantuan",
    "ai_become_writer_btn": "Menjadi Penulis  ",
    "ai_offer_title": "Apa yang Kami Tawarkan  ",
    "ai_offer_item_label_1": "Alat Kreator  ",
    "ai_offer_item_value_1": "Kuota Uji Coba Gratis untuk Alat Kreator  ",
    "ai_offer_item_label_2": "Hadiah Tunai  ",
    "ai_offer_item_value_2": "Ikut serta dalam kompetisi kreasi untuk memenangkan hadiah uang tunai $200  ",
    "ai_offer_item_label_3": "Penulis Terikat  ",
    "ai_offer_item_value_3": "Jadilah penulis terikat di platform dan terima pembagian langganan bulanan yang menguntungkan  ",
    "ai_authors_say_title": "Apa Kata Penulis Kami  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"Alat penulisan AI ini seperti asisten yang kuat, sederhana, dan mudah digunakan, memungkinkan setiap pengguna untuk dengan mudah memulai. Keluaran berkualitas tinggi membuat kreasi menjadi menyenangkan, dan pengalaman pengguna jelas merupakan kenikmatan tertinggi dalam menulis.\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "Alat penulisan AI ini luar biasa! Ini mudah digunakan, sederhana untuk dioperasikan, dan menghasilkan karya berkualitas tinggi. Ini memungkinkan saya untuk mengekspresikan kreativitas saya dengan mudah dan menikmatinya!  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "Alat penulisan AI ini adalah berkah bagi para kreator. Antarmuka yang sederhana dan intuitif membuatnya mudah digunakan oleh pemula. Kualitas cerita yang dihasilkan sangat mengejutkan. Menulis dengannya benar-benar menjadi perjalanan inspirasi yang menyenangkan.  ",
    "ai_why_choose_title": "Mengapa Memilih Kami  ",
    "ai_why_choose_item_label_1": "Tim editorial yang penuh semangat  ",
    "ai_why_choose_item_value_1": "Tim editorial yang profesional dan efisien memberikan Anda periode perlindungan selama 6 bulan untuk penulis pemula, selama mana naskah yang dibuat dapat menikmati saluran jaminan pengiriman yang aman.  ",
    "ai_why_choose_item_label_2": "Alat kreasi yang efisien  ",
    "ai_why_choose_item_value_2": "Mudah untuk dibuat, siklus penciptaan singkat, efisiensi monetisasi tinggi  ",
    "ai_why_choose_item_label_3": "Jaminan keamanan  ",
    "ai_why_choose_item_value_3": "Tim hukum profesional melindungi hak atas karya Anda  ",
    "ai_create_story_btn": "Ciptakan cerita Anda  ",
    "ai_contact_btn": "Hubungi tim editor  ",
    "ai_dialog_title": "Selamat datang di StoryGo!  ",
    "ai_dialog_desc": "Dengan alat kreasi StoryGo, Anda juga bisa menjadi penulis cerita hits, ayo bergabung dengan kami!  ",
    "ai_dialog_part1_title": "Uji Coba Gratis  ",
    "ai_dialog_part1_item1": "Silakan masuk ke situs web PC kami https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "Bergabunglah dengan grup WhatsApp dan hubungi administrator untuk mendapatkan uji coba gratis alat kreasi.  ",
    "ai_dialog_part2_title": "Kompetisi Kreatif  ",
    "ai_dialog_part2_item1": "Silakan masuk ke situs web PC kami https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "Ikuti kompetisi kreatif, menangkan hadiah uang tunai, peluang baru.  ",
    "ai_dialog_part2_item3": "Jika Anda memiliki pertanyaan, silakan hubungi kami di Contact@storygo.cc  ",
    "ai_dialog_copy_email": "Salin tautan situs web resmi  ",
    "ai_url_copy_tips": "Tautan berhasil disalin  "
}