export default {
    "index": "Ranggo",
    "nickname": "Pangalan",
    "invite_num": "Inimbitah na mga mambabasa",
    "expect_give": "Inaasahang gem gantimpala",
    "total_reward": "Kasalukuyang gem prize pool",
    "recommend_rank_title": "StoryGo Tagabantay Ranggo",
    "my_success_invite": "Matagumpay kong imbita",
    "my_rank": "Aking ranggo",
    "undefined": "Inaasahang gem ko",
    "recommend_rank_rule_title": "Mga detalye ng patakaran",
    "recommend_rank_rule_1": "1.Ibahagi ang iyong mga paboritong libro o imbitahin ang iyong mga kaibigan gamit ang iyong referral link sa social media. Kung ang isang bagong gumagamit, na hindi pa gumamit ng StoryGo noon, ay sumali sa pamamagitan ng iyong link, mag-unlock ng 5 kabanata, matagumpay mong inimbita ang isang bagong mambabasa. Tandaan: Kinakailangan ang bersyon ng aplikasyon 1.8+.",
    "rule_how_to_share": "Paano Magbahagi ng Iyong Mga Paboritong Libro--〉〉",
    "rule_how_to_invite": "Paano Tingnan/Mahanap ang Iyong Link na Panimula--〉〉",
    "recommend_rank_rule_2": "2.Ang kaganapan ay nagsisimula sa isang pool ng premyo na may 1400 gem. Para sa bawat bagong tagagamit na matagumpay na inimbita, nadaragdagan ang pool ng 10 gem, hanggang sa maksimum na 50,000 gem.",
    "recommend_rank_rule_3": "3.Ang kaganapan ay magraranke ng mga tagapagbantay batay sa bilang ng mga bagong tagagamit na kanilang inimbita. Ang mga nasa top 20 na tagapagbantay ay maghahati-hati sa premyo pool sa mga sumusunod:\n·1st Puwesto: Tatanggap ng 30% ng premyo pool sa mga gem.\n·2nd Puwesto: Tatanggap ng 20% ng premyo pool sa mga gem.\n·3rd Puwesto: Tatanggap ng 10% ng premyo pool sa mga gem.\n·4th-10th Puwesto: Bawat isa ay tatanggap ng 4% ng premyo pool sa mga gem.\n·11th-20th Puwesto: Bawat isa ay tatanggap ng 1.2% ng premyo pool sa mga gem.\nAng mga nabanggit na gantimpala ay ipamamahagi pagkatapos ng kaganapan. ",
    "recommend_rank_rule_4": "4.Ang anumang uri ng pandaraya, pagsisinungaling, o manipulasyon ng data ay mahigpit na ipinagbabawal sa kaganapang ito. Kapag natuklasan, ang kalahok ay ma-di-disqualify. Ang huling interpretasyon ng kaganapang ito ay nauukol sa StoryGo.",
    "act_rule_btn": "Tingnan ang patakaran",
    "act_time": "Oras",
    "recommend_rank_page_title": "StoryGo Tagabantay Ranggo",
    "recommend_part1_title": "Bahagi 1: Rekomenda at Manalo ng Gem!",
    "recommend_part1_desc": "Ibahagi ang mga libro na pinakarekomenda mo sa mga kaibigan sa Facebook. Magdagdag ng iyong personal na pagsang-ayon, i-tag ang @Storygo Publish, at kung ito ay sumusunod sa aming kriteya, kumita ng mga gantimpalang gem!",
    "how_to_join": "Paano Sumali?",
    "recommend_part1_tips1": "1.Buksan ang iyong paboritong libro, pagkatapos ay pindutin lamang ang button na ibahagi sa taas-kanang sulok at piliin ang Facebook o Twitter.",
    "recommend_part1_tips2": "2.Ibahagi at i-tag ang @Storygo Publish sa iyong post o mga komento. (Pansin: Ang mga gantimpala ay maaari lamang para sa mga pampublikong ibinahaging post.)",
    "recommend_part1_tips3": "3.Sa panahon ng kaganapan, kung ang iyong post ay nakatanggap ng higit sa 30 likes, makakakuha ka ng 50 gems. Ang bawat libro o post ay karapat-dapat lamang sa mga gantimpala ng isang beses, may maximum na 200 gems bawat tao. Ang mga gantimpala ay ipamamahagi sa loob ng 3 araw na trabaho pagkatapos ng pagtatapos ng kaganapan.",
    "recommend_part2_title": "Bahagi 2: Magdala, Hatiin ang Premyo!",
    "recommend_part2_desc": "Magdala ng bagong user sa StoryGo gamit ang iyong ibinahaging libro o link ng imbitasyon, at kapag sila ay nag-unlock ng 5 premium na kabanata, maaari kang sumali sa Ranggo ng mga Tagapagbantay ng StoryGo at hatiin ang malaking premyo ng mga gem! ",
    "recommend_part2_tips1": "1.Ibahagi ang iyong mga paboritong libro o ang iyong link ng imbitasyon sa iyong mga kaibigan sa social media. Kailangan ng pag-upgrade ng app sa bersyon 1.8 o mas mataas.",
    "recommend_part2_tips2": "2.Kung ang isang bagong user, na hindi pa gumagamit ng StoryGo dati, ay nag-download at nagrehistro sa StoryGo sa pamamagitan ng iyong link, at nag-unlock ng anumang 5 kabanata, ituturing namin na matagumpay mo ng iniimbitahan ang isang bagong mambabasa.",
    "recommend_part2_tips3": "3.Ang bawat user ay maaaring lumahok sa ranggo batay sa bilang ng mga bagong mambabasa na matagumpay na inimbitahan. Ang top 20 ay magbabahagi ng malaking premyong gem.",
    "recommend_part2_tips4": "4.Nakalaan ang karapatan ng StoryGo para sa pangwakas na interpretasyon ng kaganapan. Sa kaso ng pandaraya o pandarayang gawi upang manipulahin ang mga ranggo, nakalaan ang karapatan ng StoryGo na i-diskwalipika ang mga kalahok mula sa kaganapan.",
    "see_rank": "Tingnan ang Ranggo ng mga Bantay",
    "share_act": "Ibahagi ang kaganapan",
    "join_watsapp": "Sumali sa WhatsApp",
    "recommend_page_title": "Maging Duta ng StoryGo, Manalo ng Premyo!",
    "recommend_banner_title": "Maging Duta ng StoryGo, Manalo ng Premyo!",
    "how_to_share_title": "Paano ibahagi ang iyong paboritong mga aklat",
    "how_to_share_content_1": "Buksan ang paborito mong aklat at i-tap lamang ang pindutin na ibinababa sa kanan-ibabaw na sulok.",
    "how_to_share_content_2": "O, buksan ang isang aklat, pagkatapos ay mag-long-press sa isang talata upang piliin ito, at ibahagi ito sa social media"
}