<template>
  <Transition name="fade" @after-leave="onAfterLeave">
    <div class="storygo-toast" v-if="visible">
      <div class="message">
        {{$t('recharge_feedback_msg')}} <span @click="feedback">{{$t('feedback_label')}}</span>
      </div>
    </div>
  </Transition>

</template>

<script setup lang="ts">
// import {ref} from 'vue'
// import {eventBus} from "@storygo/utils/emitter";
import {sdkPromise} from "@storygo/utils";

const $t = window.$t
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  visible = false,
  onAfterLeave = () => {}
} = defineProps<{
  visible: boolean
  onAfterLeave: () => void
}>()

const feedback = () => {
  sdkPromise('emitPayFeedback')
}
// onMounted(() => {
//   eventBus.$on("toast", (msg:string, type: ToastType ) => {
//   })
// })
//

</script>
<style scoped lang="less" src="./index.less">

</style>
