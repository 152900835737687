// **********************************************************************
// Parsed By TarsParser(2.4.5), Generated By tars2node(20200707)
// TarsParser Maintained By <TARS> and tars2node Maintained By <superzheng>
// Generated from "novel_base.tars" by Structure Mode
// **********************************************************************

/* tslint:disable */
/* eslint-disable */

import * as TarsStream from "@tars/stream";

export namespace novel {
    enum RspCode {
        kMRC_OK = 0,
        kMRC_FAILED = 1,
        kMRC_PARAM_ERR = 2,
        kMRC_RETRY_LATER = 3,
        kMRC_TOKEN_ILLEGAL = 4,
        kMRC_SIGN_ILLEGAL = 5,
        kMRC_PRIVACY_ILLEGAL = 6,
        KMRC_DUPLICATE_KEY_ERR = 7,
        kMRC_DECRYPT_ILLEGAL = 8,
        kMRC_IDCARD_NEED = 9,
        kMRC_CONTENT_NOT_AVAILABLE = 10,
        kMRC_OPERATION_DENY = 11,
        kMRC_CONTENT_BLOCK = 12,
        kMRC_PHONE_UNSUPPORT = 1000,
        kMRC_SMS_ERR_TOO_FREQ = 1001,
        kMRC_SMS_ERR = 1002,
        kMRC_LOGIN_NOT_ALLOWED = 1003,
        kMRC_NEED_PHONE_BINDING = 1004,
        kMRC_ACCOUNT_RELEASED = 1005,
        kMRC_HAS_BINDED_SNS = 1006,
        kMRC_HAS_BE_BINDED = 1007,
        kMRC_ACCOUNT_OR_PASSWORD_ERR = 1010,
        kMRC_INVITE_CODE_ILLEGAL = 1011,
        kMRC_INVITE_CODE_LIMIT = 1012,
        kMRC_INVITE_CODE_USED = 1013,
        kMRC_MAX_ACCOUNT_WITH_ONE_DEVICE = 1014,
        kMRC_CHAPTER_LOCKED = 2001,
        kMRC_CHAPTER_UNPUBLISHED = 2002,
        kMRC_COIN_NOT_ENOUGH = 9000,
        kMRC_ORDER_EXISTS = 9001,
        kMRC_COIN_CONSUME_LIMIT = 9002,
        kMRC_COMMENT_EXIST = 7010,
        kMRC_STOCK_SHORTAGE = 8000,
        kMRC_ORDER_ACCEPT = 8001,
        kMRC_ORDER_FAIL = 8002,
        kMRC_GOODS_UNAVAILABLE = 8003,
        kMRC_SKU_ORDERED = 8004,
        kMRC_SPU_MODIFY_UNSUPPORT = 8110,
        kMRC_BAG_UNAVAILABLE = 8200,
        kMRC_ORDER_LIMIT = 8201,
        kMRC_GOODS_OWNED = 8202,
        kMRC_REWARD_ALREADY_DREW = 4000,
        kMRC_TASK_NOT_FINISH = 4001,
        kMRC_TASK_NOT_COMPENSATE = 4002,
        kMRC_TASK_EXPIRED = 4003,
        kMRC_NOT_BETTING_STAGE = 300100,
        kMRC_GAME_DISABLE = 300101
    }
    enum ESnsType {
        QQ = 0,
        WECHAT = 1,
        WEIBO = 2,
        PHONE = 3,
        APPLE = 4,
        QQWEB = 5,
        WECHATWEB = 6,
        GOOGLE = 7,
        FACEBOOK = 8,
        IDCARD = 9,
        EMAIL = 10,
        DEVICEID = 11
    }
    enum ETokenType {
        APP = 0,
        WEB = 1
    }
    enum EGender {
        FEMALE = 0,
        MALE = 1,
        UNKNOWN = -1
    }
    enum EAccountType {
        NORMAL = 0,
        OFFICIAL = 1,
        AI_CREATOR = 2
    }
    enum EAccountState {
        NORMAL = 0,
        FORBIDDEN = 1,
        FORBIDDEN4EVER = 2,
        RELEASED = 3
    }
    enum EContentType {
        IMAGE_JPEG = 0,
        IMAGE_PNG = 1,
        FILE_ZIP = 2,
        JSON = 3,
        BINARY = 4,
        TTF = 5,
        SVG = 6,
        IMAGE_WEBP = 7,
        AUDIO_MP3 = 8,
        WEBP = 9,
        IMAGE_GIF = 10,
        VIDEO_MP4 = 11
    }
    enum ECommentType {
        BOOK = 1,
        PARAGRAPH = 2,
        STORY = 3,
        STORY_CHAPTER = 4
    }
    enum ELikeType {
        BOOK = 1,
        BOOK_COMMENT = 2,
        PARAGRAPH_COMMENT = 3,
        STORY = 4,
        STORY_CHAPTER = 5
    }
    enum ESortType {
        LATEST = 0,
        HOTEST = 1,
        EAREST = 2,
        HIGHEST = 3
    }
    enum EBookSortType {
        LATEST_UPDATE = 0,
        HIGHEST = 1,
        NEW_PUBLISH = 2,
        MOST_POPULAR = 3,
        QUALITY_SCORE = 4,
        RANDOM_0_READS = 5,
        LISTENS = 6,
        NEW_QUALITY = 7,
        NEW_QUALITY_REVERSE = 8
    }
    enum ESubType {
        USER = 1,
        BOOK = 2
    }
    enum EFeedbackState {
        PROCESSING = 1,
        PROCESSED = 2,
        NOT_PROCESSED = 3,
        REPLIED = 4
    }
    enum EFeedbackType {
        APP = 0,
        BOOK_CONTENT = 1,
        COMMENT = 2,
        USER = 3,
        PAY = 4,
        AIGC = 5,
        APP_AUTO = 99
    }
    enum ETagType {
        USER_TAG = 1
    }
    enum EZRoleMaterialType {
        MATERIAL = 1,
        RECIPE = 2
    }
    enum EInteractiveType {
        CLICK = 0,
        SHARE = 1,
        MAKE = 2
    }
    enum EUserStateBit {
    }
    enum ECoinType {
        DIAMOND = 1,
        CREDIT = 2,
        GEM = 9,
        AIP = 10
    }
    enum EPayChannel {
        SYSTEM = 0,
        WECHAT = 1,
        ALIPAY = 2,
        QQ = 3,
        APPLE_IAP = 4,
        GOOGLE_PAY = 5,
        PAYERMAX = 6
    }
    enum EBillStatus {
        SUCCESS = 0,
        FAILED = 1,
        WAITING_PAY = 2,
        USER_CANCEL = 3,
        TIMEOUT_CANCEL = 4
    }
    enum EBizChannel {
        TASK = 1,
        TASK_AD_BONUS = 1001,
        DRAW = 2,
        MALL = 3,
        SYSTEM = 4,
        AI = 5,
        BETTING_GAME = 8,
        WECHAT = 100,
        QQ = 101,
        ALIPAY = 102,
        APPLE = 103,
        GOOGLE = 104,
        PAYERMAX = 105,
        BAG_GIFT = 200,
        TASK_COMPENSATION = 300,
        OPER_GIVE = 400,
        EXPIRE = 500
    }
    enum EForbiddenType {
        FORBIDDEN_LOGIN = 1,
        FORBIDDEN_ROOM_LIVE = 2,
        FORBIDDEN_ROOM_WORDS = 3,
        FORBIDDEN_WORDS_BY_ROOM_HOST = 4,
        FORBIDDEN_IM = 5
    }
    enum EBannerShowAt {
        INDEX_PAGE = 1,
        LIBRARY = 2,
        SPLASH = 3,
        TASK_INDEX = 4,
        CATEGORY_TAB = 5,
        RANK = 6,
        TOPIC = 7,
        LIBRARY2 = 8,
        AIGC_INDEX = 9
    }
    enum ELanguage {
        PTH = 1,
        ENG = 2,
        YUEYU = 3
    }
    enum EEndType {
        UNKNOWN = 0,
        IOS = 1,
        ANDROID = 2
    }
    enum EPrivacyType {
        TAKE_PHOTO = 0
    }
    enum EPrivacyState {
        OPEN = 0,
        UNVISIABLE = 1,
        FRIENDS_ONLY = 2
    }
    enum EBanType {
        BLACK_LIST = 0,
        BLOCK = 1
    }
    enum ETopicState {
        ON = 1,
        OFF = 0
    }
    enum ETopicCategoryState {
        ON = 1,
        OFF = 0
    }
    enum EBookState {
        ON = 1,
        OFF = 0
    }
    enum EBookCompleteState {
        COMPLETED = 1,
        UPDATING = 2
    }
    enum EMemberType {
        REGULAR = 1,
        MONTH = 2,
        SEASON = 3,
        YEAR = 4
    }
    enum EUserTaskState {
        ENABLE = 1,
        DISABLE = 2
    }
    enum EUserTaskPeriodType {
        ONCE = 0,
        CONTINUOUS_DAYS = 1,
        DAY = 2,
        WEEK = 3,
        MONTH = 4
    }
    enum EUserTaskDrawState {
        TASK_NOT_FINISH = 0,
        DREW = 1,
        FINISH_UNDRAW = 2,
        STEP_UNDRAW = 3,
        STEP_DREW = 4
    }
    enum EUserTaskType {
        LOGIN = 1,
        CONTINUOUS_LOGIN_7DAYS = 2,
        READING = 3,
        ACTIVE_INVITE_CODE = 4,
        INVITE_CODE_BE_ACTIVATED = 5,
        RECHARGE = 6,
        CONSUME = 7,
        SHARE = 8,
        COMMENT = 9,
        VIP_LOGIN = 10,
        SVIP_LOGIN = 11,
        WATCH_AD = 12,
        INVITE_USER_BUY_CHAPTER = 13,
        INVITE_USER_RECHARGE = 14,
        INVITE_USER_SUCCESS = 15,
        TURN_ON_PUSH = 16,
        AI_CREATOR_LOGIN = 17
    }
    enum EOfficialMessageType {
        OPERATOR = 1,
        LOGIN_TASK_FINISH = 2,
        INVITE_CODE_ACTIVATED = 3,
        INVITE_USER_REMIND = 4
    }
    enum EBookCategoryType {
        CATEGORY = 0,
        TAG = 1
    }
    enum ERechargePackageType {
        COIN = 0,
        REG_3DAYS_GIFT = 1,
        VIP = 2,
        AIP = 3
    }
    enum EVipType {
        VIP = 0,
        SVIP = 1
    }
    enum EVoiceType {
        FEMALE = 0,
        MALE = 1
    }
    enum EBookProgressType {
        TEXT = 0,
        AUDIO = 1
    }
    enum EAiFunction {
        GEN_STORY = 1,
        GEN_COVER = 2,
        GEN_TITLE = 3,
        GEN_CONTENT = 4,
        GEN_DESCRIPTION = 5,
        REFINE = 6,
        REWRITE = 7,
        EXPAND = 8,
        GEN_WTORY = 9
    }
    enum EHonorTagType {
        TOP_READ_10 = 1,
        TOP_READ_50 = 2,
        HIGH_SCORE_10 = 3,
        HIGH_SCORE_50 = 4
    }
    enum ECommentState {
        OK = 0,
        DELETED = 1
    }
    enum ECommentContent {
        TXT = 1,
        IMG = 2
    }
    enum EBannerContentType {
        TEXT = 0,
        BOOK = 1
    }
    enum RechargeProductType {
        DIAMOND = 0,
        MEMBER = 1
    }
    enum ERedirectType {
        REDIRECT = 0,
        NOT_REDIRECT = 1
    }
    enum EMessageState {
        DISABLE = 0,
        ENABLE = 1
    }
    enum EMessageType {
        OFFICIAL = 1,
        PERSNOAL = 2,
        IM = 3
    }
    enum EPersonalMessageType {
        COMMENT = 1,
        LIKE = 2
    }
    enum ECommentMessageSubType {
        BOOK = 100,
        PARAGRAPH = 101
    }
    enum ELikeMessageSubType {
        BOOK_COMMENT = 200,
        PARAGRAPH_COMMENT = 201
    }
    enum EGoodsCategory {
        VIP = 1,
        BOOK_CHAPTER = 2,
        DIAMOND = 3,
        CREDIT = 4,
        GEM = 5,
        NO_AD = 6,
        AIP = 7
    }
    enum EGoodsSkuState {
        INIT = 0,
        ON = 1,
        OFF = 2
    }
    enum EOrderState {
        INIT = 0,
        PAY_FAIL = 11,
        PAY_EXCEPTION = 12,
        DELIVER_FAIL = 21,
        DELIVER_EXCEPTION = 22,
        COMPLETE = 1,
        SHUTDOWN = 2
    }
    enum EGoodsSpuState {
        ENABLE = 1,
        DISABLE = 0
    }
    enum EAccountsType {
        IN = 0,
        CASHOUT = 1
    }
    enum EOrderType {
        CHAPTER = 1,
        DIAMOND = 2,
        GEM = 3,
        VIP = 4,
        NO_AD = 5,
        AIP = 6
    }
    enum ECashoutState {
        APPLY = 1,
        PROCESSING = 2,
        COMPLETE = 3,
        SHUTDOWN = 4,
        NONE = 0,
        DISABLE = 10
    }
    enum ECashoutFlowType {
        SUBMIT_APPLY = 1,
        EDIT_APPLY = 2,
        CASHOUT_SUCCESS = 3,
        APPLY_EXPIRED = 4
    }
    enum ECashoutWay {
        ALIPAY = 1,
        WECHAT = 2
    }
    enum EBagOrderState {
        SUCCESS = 1,
        FAIL = 2,
        EXCEPTION = 3,
        SHUTDOWN = 4
    }
    enum ESkuGetWay {
        PURCHASE = 0,
        SYS_GIVE = 1
    }
    enum ESkuPeriod {
        DAY = 1,
        WEEK = 2
    }
    enum ESkuShelf {
        FREE_ROOM_SCENE = 1,
        FREE_IM_SCENE = 2,
        ROOM_GIFT = 3
    }
    enum EBookInteractiveDataType {
        READ = 1,
        LISTEN = 2
    }
    enum EBookSpecialSubjectType {
        BOOK = 1,
        BOOK_CATEGORY = 2
    }
    enum ESpecialSubjectStyle {
        SINGLE_LINE = 0,
        MULTI_LINE = 1,
        ANIMATED = 2,
        DOUBLE_LINES = 3
    }
    enum EBooksRankType {
        MOST_READ = 1,
        HIGH_SCORE = 2,
        NEW_BOOK = 3,
        COMPLETED = 4,
        UPDATING = 5,
        MOST_READ_WEEKLY = 6,
        MOST_BUY_CHAPTER = 7,
        NEW_HOT = 8
    }
    enum EBookQualityScoreType {
        SUM = 0,
        R1 = 1,
        R2 = 2,
        R3 = 3,
        R4 = 4,
        R1R2 = 5
    }
    enum EAppSectionShowAt {
        INDEX = 1,
        TAB = 2
    }
    enum EAppSectionType {
        SUBJECT = 1,
        RANK = 2,
        CATEGORIES = 3,
        TAGS = 4,
        BOOK_LIST = 5,
        CATEGORY_SUBJECT = 6
    }
    enum EAppTabType {
        CATEGORY = 0,
        CUSTOM = 1
    }
    enum EStoryTagType {
        GENRE = 1,
        PLOT = 2,
        EMOTION = 3,
        SPACE_TIME = 4,
        LOCATION = 5
    }
    enum EStoryChapterContentFrom {
        AI_GEN = 0,
        WTORY = 1
    }
    enum EConnectEvent {
        CONNECT_LOGIN = 1,
        DISCONNECT = 2,
        DISCONNECT_IN_ROOM = 3
    }
    enum ETaskDisplayList {
        ANDROID_DAILY_TASK_LIST = 1,
        IOS_DAILY_TASK_LIST = 2,
        AI_CREATOR_TASK_LIST = 3
    }
    enum EGameStatus {
        ENABLE = 1,
        DISABLE = 0
    }
    enum EGameRunStatus {
        BETTING = 2,
        WAITING_DRAWING = 3,
        SUCCESS = 1,
        FAIL = 0
    }
    enum EBettingFlowType {
        HIT = 1,
        BET = 2
    }
    enum EBettingGameId {
        GREEDY_CAT = 1,
        PRIZE_WHEEL = 2
    }
    enum EActivityType {
        INVITE_USER = 1
    }
    enum EGrowableRankPeriodType {
        CUSTOM = 0,
        FOREVER = 1,
        DAY = 2,
        WEEK = 3
    }
    enum EGrowableRankType {
        GC_BET_GOLD = 1,
        INVITE_USERS = 2
    }

    class CacheSync {
        command: number;
        ns: string;
        name: string;
        ks: TarsStream.List<string>;

        toObject(): CacheSync.Object;
        readFromObject(json: CacheSync.Object): CacheSync;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CacheSync;
        static create(is: TarsStream.TarsInputStream): CacheSync;
    }

    namespace CacheSync {
        interface Object {
            command?: number;
            ns?: string;
            name?: string;
            ks?: Array<string>;
        }
    }

    class AliOSSToken {
        presignUrl: string;
        accessUrl: string;
        fileKey: string;

        toObject(): AliOSSToken.Object;
        readFromObject(json: AliOSSToken.Object): AliOSSToken;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AliOSSToken;
        static create(is: TarsStream.TarsInputStream): AliOSSToken;
    }

    namespace AliOSSToken {
        interface Object {
            presignUrl?: string;
            accessUrl?: string;
            fileKey?: string;
        }
    }

    class Tag {
        tagId: number;
        tagName: string;
        tagType: number;
        display: number;
        idx: number;
        parentId: number;

        toObject(): Tag.Object;
        readFromObject(json: Tag.Object): Tag;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Tag;
        static create(is: TarsStream.TarsInputStream): Tag;
    }

    namespace Tag {
        interface Object {
            tagId?: number;
            tagName?: string;
            tagType?: number;
            display?: number;
            idx?: number;
            parentId?: number;
        }
    }

    class MemberInfo {
        type: number;
        expireAt: number;
        getWay: number;
        ttl: number;
        svip: number;
        coinExpireAt: number;

        toObject(): MemberInfo.Object;
        readFromObject(json: MemberInfo.Object): MemberInfo;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MemberInfo;
        static create(is: TarsStream.TarsInputStream): MemberInfo;
    }

    namespace MemberInfo {
        interface Object {
            type?: number;
            expireAt?: number;
            getWay?: number;
            ttl?: number;
            svip?: number;
            coinExpireAt?: number;
        }
    }

    class UserBase {
        uid: number;
        nickName: string;
        zroleId: string;
        avatarUrl: string;
        gender: number;
        accountType: number;
        countryCode: string;
        lcid: number;
        accountState: number;
        privacySetting: number;
        regTime: number;
        inviteCode: string;
        snsType: number;
        vipExpireAt: number;
        svipExpireAt: number;
        inviteUid: number;

        toObject(): UserBase.Object;
        readFromObject(json: UserBase.Object): UserBase;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserBase;
        static create(is: TarsStream.TarsInputStream): UserBase;
    }

    namespace UserBase {
        interface Object {
            uid?: number;
            nickName?: string;
            zroleId?: string;
            avatarUrl?: string;
            gender?: number;
            accountType?: number;
            countryCode?: string;
            lcid?: number;
            accountState?: number;
            privacySetting?: number;
            regTime?: number;
            inviteCode?: string;
            snsType?: number;
            vipExpireAt?: number;
            svipExpireAt?: number;
            inviteUid?: number;
        }
    }

    class UserInfo {
        baseInfo: novel.UserBase;
        birthday: string;
        bio: string;
        state: number;
        points: number;
        district: string;
        userTag: string;
        email: string;

        toObject(): UserInfo.Object;
        readFromObject(json: UserInfo.Object): UserInfo;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserInfo;
        static create(is: TarsStream.TarsInputStream): UserInfo;
    }

    namespace UserInfo {
        interface Object {
            baseInfo?: novel.UserBase.Object;
            birthday?: string;
            bio?: string;
            state?: number;
            points?: number;
            district?: string;
            userTag?: string;
            email?: string;
        }
    }

    class PageReq {
        offset: number;
        size: number;

        toObject(): PageReq.Object;
        readFromObject(json: PageReq.Object): PageReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PageReq;
        static create(is: TarsStream.TarsInputStream): PageReq;
    }

    namespace PageReq {
        interface Object {
            offset?: number;
            size?: number;
        }
    }

    class NoAdGoodsResource {
        durationDay: number;

        toObject(): NoAdGoodsResource.Object;
        readFromObject(json: NoAdGoodsResource.Object): NoAdGoodsResource;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): NoAdGoodsResource;
        static create(is: TarsStream.TarsInputStream): NoAdGoodsResource;
    }

    namespace NoAdGoodsResource {
        interface Object {
            durationDay?: number;
        }
    }

    class GoodsSpu {
        id: number;
        name: string;
        describe: string;
        resources: string;
        category: number;
        subCategory: number;
        state: number;
        imgUrl: string;
        useCnt: number;
        ownNum: number;
        comboArr: TarsStream.List<number>;
        bookId: number;
        chapterId: number;

        toObject(): GoodsSpu.Object;
        readFromObject(json: GoodsSpu.Object): GoodsSpu;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GoodsSpu;
        static create(is: TarsStream.TarsInputStream): GoodsSpu;
    }

    namespace GoodsSpu {
        interface Object {
            id?: number;
            name?: string;
            describe?: string;
            resources?: string;
            category?: number;
            subCategory?: number;
            state?: number;
            imgUrl?: string;
            useCnt?: number;
            ownNum?: number;
            comboArr?: Array<number>;
            bookId?: number;
            chapterId?: number;
        }
    }

    class GoodsSku {
        id: number;
        goods: novel.GoodsSpu;
        price: number;
        currency: number;
        stock: number;
        endTime: number;
        state: number;
        name: string;
        createTime: number;
        getWay: number;
        vipDiscount: number;

        toObject(): GoodsSku.Object;
        readFromObject(json: GoodsSku.Object): GoodsSku;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GoodsSku;
        static create(is: TarsStream.TarsInputStream): GoodsSku;
    }

    namespace GoodsSku {
        interface Object {
            id?: number;
            goods?: novel.GoodsSpu.Object;
            price?: number;
            currency?: number;
            stock?: number;
            endTime?: number;
            state?: number;
            name?: string;
            createTime?: number;
            getWay?: number;
            vipDiscount?: number;
        }
    }

    class MemberProduct {
        pid: string;
        type: number;
        days: number;
        name: string;
        priceFee: number;
        originalPrice: number;
        applePid: string;
        googlePid: string;

        toObject(): MemberProduct.Object;
        readFromObject(json: MemberProduct.Object): MemberProduct;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MemberProduct;
        static create(is: TarsStream.TarsInputStream): MemberProduct;
    }

    namespace MemberProduct {
        interface Object {
            pid?: string;
            type?: number;
            days?: number;
            name?: string;
            priceFee?: number;
            originalPrice?: number;
            applePid?: string;
            googlePid?: string;
        }
    }

    class BookCategory {
        id: number;
        name: string;
        cover: string;
        lcid: number;
        type: number;

        toObject(): BookCategory.Object;
        readFromObject(json: BookCategory.Object): BookCategory;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookCategory;
        static create(is: TarsStream.TarsInputStream): BookCategory;
    }

    namespace BookCategory {
        interface Object {
            id?: number;
            name?: string;
            cover?: string;
            lcid?: number;
            type?: number;
        }
    }

    class ChapterAudio {
        bookId: number;
        chapterId: number;
        voice: number;
        millis: number;
        size: number;
        owned: number;

        toObject(): ChapterAudio.Object;
        readFromObject(json: ChapterAudio.Object): ChapterAudio;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ChapterAudio;
        static create(is: TarsStream.TarsInputStream): ChapterAudio;
    }

    namespace ChapterAudio {
        interface Object {
            bookId?: number;
            chapterId?: number;
            voice?: number;
            millis?: number;
            size?: number;
            owned?: number;
        }
    }

    class Chapter {
        id: number;
        name: string;
        idx: number;
        lineCount: number;
        updateTime: number;
        sku: novel.GoodsSku;
        publishState: number;
        publishTime: number;
        audios: TarsStream.List<novel.ChapterAudio>;

        toObject(): Chapter.Object;
        readFromObject(json: Chapter.Object): Chapter;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Chapter;
        static create(is: TarsStream.TarsInputStream): Chapter;
    }

    namespace Chapter {
        interface Object {
            id?: number;
            name?: string;
            idx?: number;
            lineCount?: number;
            updateTime?: number;
            sku?: novel.GoodsSku.Object;
            publishState?: number;
            publishTime?: number;
            audios?: Array<novel.ChapterAudio.Object>;
        }
    }

    class ReadProgress {
        chapterId: number;
        chapter: novel.Chapter;
        updateTime: number;
        paragraph: number;
        offset: number;
        type: number;
        audioOffset: number;
        voice: number;

        toObject(): ReadProgress.Object;
        readFromObject(json: ReadProgress.Object): ReadProgress;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReadProgress;
        static create(is: TarsStream.TarsInputStream): ReadProgress;
    }

    namespace ReadProgress {
        interface Object {
            chapterId?: number;
            chapter?: novel.Chapter.Object;
            updateTime?: number;
            paragraph?: number;
            offset?: number;
            type?: number;
            audioOffset?: number;
            voice?: number;
        }
    }

    class BookInteractiveData {
        reads: number;
        commentCnt: number;
        star: number;
        parentCommentCnt: number;

        toObject(): BookInteractiveData.Object;
        readFromObject(json: BookInteractiveData.Object): BookInteractiveData;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookInteractiveData;
        static create(is: TarsStream.TarsInputStream): BookInteractiveData;
    }

    namespace BookInteractiveData {
        interface Object {
            reads?: number;
            commentCnt?: number;
            star?: number;
            parentCommentCnt?: number;
        }
    }

    class HonorTag {
        type: number;
        title: string;
        icon: string;
        textColor: string;
        backColor: string;

        toObject(): HonorTag.Object;
        readFromObject(json: HonorTag.Object): HonorTag;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HonorTag;
        static create(is: TarsStream.TarsInputStream): HonorTag;
    }

    namespace HonorTag {
        interface Object {
            type?: number;
            title?: string;
            icon?: string;
            textColor?: string;
            backColor?: string;
        }
    }

    class SpecialSubjectDiscount {
        discount: number;
        expireAt: number;

        toObject(): SpecialSubjectDiscount.Object;
        readFromObject(json: SpecialSubjectDiscount.Object): SpecialSubjectDiscount;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SpecialSubjectDiscount;
        static create(is: TarsStream.TarsInputStream): SpecialSubjectDiscount;
    }

    namespace SpecialSubjectDiscount {
        interface Object {
            discount?: number;
            expireAt?: number;
        }
    }

    class Book {
        bookId: number;
        name: string;
        description: string;
        author: string;
        cover: string;
        score: number;
        categories: TarsStream.List<novel.BookCategory>;
        progress: novel.ReadProgress;
        idata: novel.BookInteractiveData;
        lastUpdateChapter: novel.Chapter;
        subscribed: boolean;
        inShelf: boolean;
        chapterCount: number;
        firstChapterId: number;
        completeState: number;
        firstChapter: novel.Chapter;
        tags: TarsStream.List<novel.BookCategory>;
        honorTag: novel.HonorTag;
        wordCount: number;
        lcid: number;
        secondCover: string;
        subjectDiscount: novel.SpecialSubjectDiscount;
        withAudio: number;
        audioProgress: novel.ReadProgress;

        toObject(): Book.Object;
        readFromObject(json: Book.Object): Book;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Book;
        static create(is: TarsStream.TarsInputStream): Book;
    }

    namespace Book {
        interface Object {
            bookId?: number;
            name?: string;
            description?: string;
            author?: string;
            cover?: string;
            score?: number;
            categories?: Array<novel.BookCategory.Object>;
            progress?: novel.ReadProgress.Object;
            idata?: novel.BookInteractiveData.Object;
            lastUpdateChapter?: novel.Chapter.Object;
            subscribed?: boolean;
            inShelf?: boolean;
            chapterCount?: number;
            firstChapterId?: number;
            completeState?: number;
            firstChapter?: novel.Chapter.Object;
            tags?: Array<novel.BookCategory.Object>;
            honorTag?: novel.HonorTag.Object;
            wordCount?: number;
            lcid?: number;
            secondCover?: string;
            subjectDiscount?: novel.SpecialSubjectDiscount.Object;
            withAudio?: number;
            audioProgress?: novel.ReadProgress.Object;
        }
    }

    class ChapterList {
        chapters: TarsStream.List<novel.Chapter>;
        hasMore: boolean;
        total: number;

        toObject(): ChapterList.Object;
        readFromObject(json: ChapterList.Object): ChapterList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ChapterList;
        static create(is: TarsStream.TarsInputStream): ChapterList;
    }

    namespace ChapterList {
        interface Object {
            chapters?: Array<novel.Chapter.Object>;
            hasMore?: boolean;
            total?: number;
        }
    }

    class ChapterLine {
        pid: number;
        content: string;
        deleted: number;

        toObject(): ChapterLine.Object;
        readFromObject(json: ChapterLine.Object): ChapterLine;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ChapterLine;
        static create(is: TarsStream.TarsInputStream): ChapterLine;
    }

    namespace ChapterLine {
        interface Object {
            pid?: number;
            content?: string;
            deleted?: number;
        }
    }

    class ChapterLines {
        hasMore: boolean;
        lines: TarsStream.List<novel.ChapterLine>;
        retCode: number;

        toObject(): ChapterLines.Object;
        readFromObject(json: ChapterLines.Object): ChapterLines;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ChapterLines;
        static create(is: TarsStream.TarsInputStream): ChapterLines;
    }

    namespace ChapterLines {
        interface Object {
            hasMore?: boolean;
            lines?: Array<novel.ChapterLine.Object>;
            retCode?: number;
        }
    }

    class UserId {
        uid: number;
        guid: string;
        token: string;
        userAgent: string;
        tokenType: number;
        testMode: boolean;
        countryCode: string;
        lcid: number;

        toObject(): UserId.Object;
        readFromObject(json: UserId.Object): UserId;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserId;
        static create(is: TarsStream.TarsInputStream): UserId;
    }

    namespace UserId {
        interface Object {
            uid?: number;
            guid?: string;
            token?: string;
            userAgent?: string;
            tokenType?: number;
            testMode?: boolean;
            countryCode?: string;
            lcid?: number;
        }
    }

    class CommonReq {
        tId: novel.UserId;

        toObject(): CommonReq.Object;
        readFromObject(json: CommonReq.Object): CommonReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CommonReq;
        static create(is: TarsStream.TarsInputStream): CommonReq;
    }

    namespace CommonReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class GetSmsCodeReq {
        tId: novel.UserId;
        nationalCode: string;
        phoneNum: string;
        sign: string;
        useBindPhone: boolean;
        cipher: string;

        toObject(): GetSmsCodeReq.Object;
        readFromObject(json: GetSmsCodeReq.Object): GetSmsCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetSmsCodeReq;
        static create(is: TarsStream.TarsInputStream): GetSmsCodeReq;
    }

    namespace GetSmsCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            nationalCode?: string;
            phoneNum?: string;
            sign?: string;
            useBindPhone?: boolean;
            cipher?: string;
        }
    }

    class LoginViaSmsCodeReq {
        tId: novel.UserId;
        nationalCode: string;
        phoneNum: string;
        code: string;
        sign: string;
        cipher: string;
        inviteCode: string;

        toObject(): LoginViaSmsCodeReq.Object;
        readFromObject(json: LoginViaSmsCodeReq.Object): LoginViaSmsCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LoginViaSmsCodeReq;
        static create(is: TarsStream.TarsInputStream): LoginViaSmsCodeReq;
    }

    namespace LoginViaSmsCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            nationalCode?: string;
            phoneNum?: string;
            code?: string;
            sign?: string;
            cipher?: string;
            inviteCode?: string;
        }
    }

    class LoginViaMobileCodeReq {
        tId: novel.UserId;
        token: string;
        verifyId: string;
        sign: string;
        cipher: string;

        toObject(): LoginViaMobileCodeReq.Object;
        readFromObject(json: LoginViaMobileCodeReq.Object): LoginViaMobileCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LoginViaMobileCodeReq;
        static create(is: TarsStream.TarsInputStream): LoginViaMobileCodeReq;
    }

    namespace LoginViaMobileCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            token?: string;
            verifyId?: string;
            sign?: string;
            cipher?: string;
        }
    }

    class LoginRsp {
        token: string;
        userInfo: novel.UserInfo;
        isNewUser: boolean;
        hasBindPhone: boolean;

        toObject(): LoginRsp.Object;
        readFromObject(json: LoginRsp.Object): LoginRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LoginRsp;
        static create(is: TarsStream.TarsInputStream): LoginRsp;
    }

    namespace LoginRsp {
        interface Object {
            token?: string;
            userInfo?: novel.UserInfo.Object;
            isNewUser?: boolean;
            hasBindPhone?: boolean;
        }
    }

    class LoginViaSNSReq {
        tId: novel.UserId;
        snsType: number;
        token: string;
        sign: string;
        cipher: string;
        inviteCode: string;
        snsName: string;

        toObject(): LoginViaSNSReq.Object;
        readFromObject(json: LoginViaSNSReq.Object): LoginViaSNSReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LoginViaSNSReq;
        static create(is: TarsStream.TarsInputStream): LoginViaSNSReq;
    }

    namespace LoginViaSNSReq {
        interface Object {
            tId?: novel.UserId.Object;
            snsType?: number;
            token?: string;
            sign?: string;
            cipher?: string;
            inviteCode?: string;
            snsName?: string;
        }
    }

    class LoginViaEmailPasswordReq {
        tId: novel.UserId;
        email: string;
        password: string;
        code: string;
        cipher: string;
        inviteCode: string;

        toObject(): LoginViaEmailPasswordReq.Object;
        readFromObject(json: LoginViaEmailPasswordReq.Object): LoginViaEmailPasswordReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LoginViaEmailPasswordReq;
        static create(is: TarsStream.TarsInputStream): LoginViaEmailPasswordReq;
    }

    namespace LoginViaEmailPasswordReq {
        interface Object {
            tId?: novel.UserId.Object;
            email?: string;
            password?: string;
            code?: string;
            cipher?: string;
            inviteCode?: string;
        }
    }

    class ValidEmailCodeReq {
        tId: novel.UserId;
        email: string;
        code: string;
        cipher: string;

        toObject(): ValidEmailCodeReq.Object;
        readFromObject(json: ValidEmailCodeReq.Object): ValidEmailCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ValidEmailCodeReq;
        static create(is: TarsStream.TarsInputStream): ValidEmailCodeReq;
    }

    namespace ValidEmailCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            email?: string;
            code?: string;
            cipher?: string;
        }
    }

    class QueryEmailAccountExistReq {
        tId: novel.UserId;
        email: string;
        cipher: string;

        toObject(): QueryEmailAccountExistReq.Object;
        readFromObject(json: QueryEmailAccountExistReq.Object): QueryEmailAccountExistReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryEmailAccountExistReq;
        static create(is: TarsStream.TarsInputStream): QueryEmailAccountExistReq;
    }

    namespace QueryEmailAccountExistReq {
        interface Object {
            tId?: novel.UserId.Object;
            email?: string;
            cipher?: string;
        }
    }

    class SendEmailCodeReq {
        tId: novel.UserId;
        email: string;
        cipher: string;

        toObject(): SendEmailCodeReq.Object;
        readFromObject(json: SendEmailCodeReq.Object): SendEmailCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SendEmailCodeReq;
        static create(is: TarsStream.TarsInputStream): SendEmailCodeReq;
    }

    namespace SendEmailCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            email?: string;
            cipher?: string;
        }
    }

    class RefreshTokenReq {
        tId: novel.UserId;
        sign: string;

        toObject(): RefreshTokenReq.Object;
        readFromObject(json: RefreshTokenReq.Object): RefreshTokenReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RefreshTokenReq;
        static create(is: TarsStream.TarsInputStream): RefreshTokenReq;
    }

    namespace RefreshTokenReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
        }
    }

    class RefreshTokenRsp {
        token: string;
        userInfo: novel.UserInfo;

        toObject(): RefreshTokenRsp.Object;
        readFromObject(json: RefreshTokenRsp.Object): RefreshTokenRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RefreshTokenRsp;
        static create(is: TarsStream.TarsInputStream): RefreshTokenRsp;
    }

    namespace RefreshTokenRsp {
        interface Object {
            token?: string;
            userInfo?: novel.UserInfo.Object;
        }
    }

    class Binding3rdAccountReq {
        tId: novel.UserId;
        snsType: number;
        nationalCode: string;
        phoneNum: string;
        code: string;
        cipher: string;
        token: string;
        name: string;
        password: string;
        email: string;

        toObject(): Binding3rdAccountReq.Object;
        readFromObject(json: Binding3rdAccountReq.Object): Binding3rdAccountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Binding3rdAccountReq;
        static create(is: TarsStream.TarsInputStream): Binding3rdAccountReq;
    }

    namespace Binding3rdAccountReq {
        interface Object {
            tId?: novel.UserId.Object;
            snsType?: number;
            nationalCode?: string;
            phoneNum?: string;
            code?: string;
            cipher?: string;
            token?: string;
            name?: string;
            password?: string;
            email?: string;
        }
    }

    class Binding3rdAccountRsp {
        rspCode: number;
        base: novel.UserBase;

        toObject(): Binding3rdAccountRsp.Object;
        readFromObject(json: Binding3rdAccountRsp.Object): Binding3rdAccountRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Binding3rdAccountRsp;
        static create(is: TarsStream.TarsInputStream): Binding3rdAccountRsp;
    }

    namespace Binding3rdAccountRsp {
        interface Object {
            rspCode?: number;
            base?: novel.UserBase.Object;
        }
    }

    class Unbinding3rdAccountReq {
        tId: novel.UserId;
        snsType: number;
        nationalCode: string;
        phoneNum: string;
        code: string;
        cipher: string;
        opendId: string;

        toObject(): Unbinding3rdAccountReq.Object;
        readFromObject(json: Unbinding3rdAccountReq.Object): Unbinding3rdAccountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Unbinding3rdAccountReq;
        static create(is: TarsStream.TarsInputStream): Unbinding3rdAccountReq;
    }

    namespace Unbinding3rdAccountReq {
        interface Object {
            tId?: novel.UserId.Object;
            snsType?: number;
            nationalCode?: string;
            phoneNum?: string;
            code?: string;
            cipher?: string;
            opendId?: string;
        }
    }

    class VerifyPhoneReq {
        tId: novel.UserId;
        nationalCode: string;
        phoneNum: string;
        cipher: string;

        toObject(): VerifyPhoneReq.Object;
        readFromObject(json: VerifyPhoneReq.Object): VerifyPhoneReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): VerifyPhoneReq;
        static create(is: TarsStream.TarsInputStream): VerifyPhoneReq;
    }

    namespace VerifyPhoneReq {
        interface Object {
            tId?: novel.UserId.Object;
            nationalCode?: string;
            phoneNum?: string;
            cipher?: string;
        }
    }

    class Query3rdAccountReq {
        tId: novel.UserId;
        snsType: number;

        toObject(): Query3rdAccountReq.Object;
        readFromObject(json: Query3rdAccountReq.Object): Query3rdAccountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Query3rdAccountReq;
        static create(is: TarsStream.TarsInputStream): Query3rdAccountReq;
    }

    namespace Query3rdAccountReq {
        interface Object {
            tId?: novel.UserId.Object;
            snsType?: number;
        }
    }

    class ListBinding3rdAccountReq {
        tId: novel.UserId;
        types: TarsStream.List<number>;

        toObject(): ListBinding3rdAccountReq.Object;
        readFromObject(json: ListBinding3rdAccountReq.Object): ListBinding3rdAccountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBinding3rdAccountReq;
        static create(is: TarsStream.TarsInputStream): ListBinding3rdAccountReq;
    }

    namespace ListBinding3rdAccountReq {
        interface Object {
            tId?: novel.UserId.Object;
            types?: Array<number>;
        }
    }

    class BindingAccount {
        type: number;
        bindingValue: string;
        bindingName: string;

        toObject(): BindingAccount.Object;
        readFromObject(json: BindingAccount.Object): BindingAccount;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BindingAccount;
        static create(is: TarsStream.TarsInputStream): BindingAccount;
    }

    namespace BindingAccount {
        interface Object {
            type?: number;
            bindingValue?: string;
            bindingName?: string;
        }
    }

    class BindingAccountList {
        bindings: TarsStream.List<novel.BindingAccount>;

        toObject(): BindingAccountList.Object;
        readFromObject(json: BindingAccountList.Object): BindingAccountList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BindingAccountList;
        static create(is: TarsStream.TarsInputStream): BindingAccountList;
    }

    namespace BindingAccountList {
        interface Object {
            bindings?: Array<novel.BindingAccount.Object>;
        }
    }

    class Query3rdAccountRsp {
        account: string;

        toObject(): Query3rdAccountRsp.Object;
        readFromObject(json: Query3rdAccountRsp.Object): Query3rdAccountRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Query3rdAccountRsp;
        static create(is: TarsStream.TarsInputStream): Query3rdAccountRsp;
    }

    namespace Query3rdAccountRsp {
        interface Object {
            account?: string;
        }
    }

    class ReleaseAccountReq {
        tId: novel.UserId;
        code: string;
        sign: string;

        toObject(): ReleaseAccountReq.Object;
        readFromObject(json: ReleaseAccountReq.Object): ReleaseAccountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReleaseAccountReq;
        static create(is: TarsStream.TarsInputStream): ReleaseAccountReq;
    }

    namespace ReleaseAccountReq {
        interface Object {
            tId?: novel.UserId.Object;
            code?: string;
            sign?: string;
        }
    }

    class UpdateUserInfoReq {
        tId: novel.UserId;
        nickName: string;
        avatarUrl: string;
        gender: number;
        birthday: string;
        bio: string;
        zroleId: string;
        district: string;
        userTags: TarsStream.List<number>;
        province: string;
        email: string;

        toObject(): UpdateUserInfoReq.Object;
        readFromObject(json: UpdateUserInfoReq.Object): UpdateUserInfoReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UpdateUserInfoReq;
        static create(is: TarsStream.TarsInputStream): UpdateUserInfoReq;
    }

    namespace UpdateUserInfoReq {
        interface Object {
            tId?: novel.UserId.Object;
            nickName?: string;
            avatarUrl?: string;
            gender?: number;
            birthday?: string;
            bio?: string;
            zroleId?: string;
            district?: string;
            userTags?: Array<number>;
            province?: string;
            email?: string;
        }
    }

    class OSSTokenRsp {
        tokens: TarsStream.List<novel.AliOSSToken>;

        toObject(): OSSTokenRsp.Object;
        readFromObject(json: OSSTokenRsp.Object): OSSTokenRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OSSTokenRsp;
        static create(is: TarsStream.TarsInputStream): OSSTokenRsp;
    }

    namespace OSSTokenRsp {
        interface Object {
            tokens?: Array<novel.AliOSSToken.Object>;
        }
    }

    class UploadTokenReq {
        tId: novel.UserId;
        sign: string;
        contentTypes: TarsStream.List<number>;

        toObject(): UploadTokenReq.Object;
        readFromObject(json: UploadTokenReq.Object): UploadTokenReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UploadTokenReq;
        static create(is: TarsStream.TarsInputStream): UploadTokenReq;
    }

    namespace UploadTokenReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            contentTypes?: Array<number>;
        }
    }

    class UserBaseList {
        bases: TarsStream.List<novel.UserBase>;
        hasMore: boolean;

        toObject(): UserBaseList.Object;
        readFromObject(json: UserBaseList.Object): UserBaseList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserBaseList;
        static create(is: TarsStream.TarsInputStream): UserBaseList;
    }

    namespace UserBaseList {
        interface Object {
            bases?: Array<novel.UserBase.Object>;
            hasMore?: boolean;
        }
    }

    class UserInfoList {
        infos: TarsStream.List<novel.UserInfo>;
        hasMore: boolean;

        toObject(): UserInfoList.Object;
        readFromObject(json: UserInfoList.Object): UserInfoList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserInfoList;
        static create(is: TarsStream.TarsInputStream): UserInfoList;
    }

    namespace UserInfoList {
        interface Object {
            infos?: Array<novel.UserInfo.Object>;
            hasMore?: boolean;
        }
    }

    class GetUserInfoByIdReq {
        tId: novel.UserId;
        uid: number;

        toObject(): GetUserInfoByIdReq.Object;
        readFromObject(json: GetUserInfoByIdReq.Object): GetUserInfoByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUserInfoByIdReq;
        static create(is: TarsStream.TarsInputStream): GetUserInfoByIdReq;
    }

    namespace GetUserInfoByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            uid?: number;
        }
    }

    class GetUserForbiddenStateReq {
        tId: novel.UserId;
        type: number;
        checkUid: number;

        toObject(): GetUserForbiddenStateReq.Object;
        readFromObject(json: GetUserForbiddenStateReq.Object): GetUserForbiddenStateReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUserForbiddenStateReq;
        static create(is: TarsStream.TarsInputStream): GetUserForbiddenStateReq;
    }

    namespace GetUserForbiddenStateReq {
        interface Object {
            tId?: novel.UserId.Object;
            type?: number;
            checkUid?: number;
        }
    }

    class GetUserForbiddenStateRsp {
        isForbidden: boolean;
        remainSec: number;

        toObject(): GetUserForbiddenStateRsp.Object;
        readFromObject(json: GetUserForbiddenStateRsp.Object): GetUserForbiddenStateRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUserForbiddenStateRsp;
        static create(is: TarsStream.TarsInputStream): GetUserForbiddenStateRsp;
    }

    namespace GetUserForbiddenStateRsp {
        interface Object {
            isForbidden?: boolean;
            remainSec?: number;
        }
    }

    class QueryUserBySnsOpenIdReq {
        tId: novel.UserId;
        snsType: number;
        openids: TarsStream.List<string>;
        noRelationOnly: number;

        toObject(): QueryUserBySnsOpenIdReq.Object;
        readFromObject(json: QueryUserBySnsOpenIdReq.Object): QueryUserBySnsOpenIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryUserBySnsOpenIdReq;
        static create(is: TarsStream.TarsInputStream): QueryUserBySnsOpenIdReq;
    }

    namespace QueryUserBySnsOpenIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            snsType?: number;
            openids?: Array<string>;
            noRelationOnly?: number;
        }
    }

    class QueryUserBySnsOpenIdRsp {
        baseMap: TarsStream.Map<string, novel.UserBase>;

        toObject(): QueryUserBySnsOpenIdRsp.Object;
        readFromObject(json: QueryUserBySnsOpenIdRsp.Object): QueryUserBySnsOpenIdRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryUserBySnsOpenIdRsp;
        static create(is: TarsStream.TarsInputStream): QueryUserBySnsOpenIdRsp;
    }

    namespace QueryUserBySnsOpenIdRsp {
        interface Object {
            baseMap?: Record<string, novel.UserBase.Object>;
        }
    }

    class UpdateUserPrivacySettingReq {
        tId: novel.UserId;
        privacyType: number;
        privacyValue: number;

        toObject(): UpdateUserPrivacySettingReq.Object;
        readFromObject(json: UpdateUserPrivacySettingReq.Object): UpdateUserPrivacySettingReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UpdateUserPrivacySettingReq;
        static create(is: TarsStream.TarsInputStream): UpdateUserPrivacySettingReq;
    }

    namespace UpdateUserPrivacySettingReq {
        interface Object {
            tId?: novel.UserId.Object;
            privacyType?: number;
            privacyValue?: number;
        }
    }

    class GetUserPrivacySettingReq {
        tId: novel.UserId;
        types: TarsStream.List<number>;
        uid: number;

        toObject(): GetUserPrivacySettingReq.Object;
        readFromObject(json: GetUserPrivacySettingReq.Object): GetUserPrivacySettingReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUserPrivacySettingReq;
        static create(is: TarsStream.TarsInputStream): GetUserPrivacySettingReq;
    }

    namespace GetUserPrivacySettingReq {
        interface Object {
            tId?: novel.UserId.Object;
            types?: Array<number>;
            uid?: number;
        }
    }

    class UserPrivacySettings {
        settings: TarsStream.Map<number, number>;

        toObject(): UserPrivacySettings.Object;
        readFromObject(json: UserPrivacySettings.Object): UserPrivacySettings;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserPrivacySettings;
        static create(is: TarsStream.TarsInputStream): UserPrivacySettings;
    }

    namespace UserPrivacySettings {
        interface Object {
            settings?: Record<number, number>;
        }
    }

    class SyncUserPhoneNumberReq {
        tId: novel.UserId;
        numbers: TarsStream.List<string>;

        toObject(): SyncUserPhoneNumberReq.Object;
        readFromObject(json: SyncUserPhoneNumberReq.Object): SyncUserPhoneNumberReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SyncUserPhoneNumberReq;
        static create(is: TarsStream.TarsInputStream): SyncUserPhoneNumberReq;
    }

    namespace SyncUserPhoneNumberReq {
        interface Object {
            tId?: novel.UserId.Object;
            numbers?: Array<string>;
        }
    }

    class ActiveInviteCodeReq {
        tId: novel.UserId;
        code: string;

        toObject(): ActiveInviteCodeReq.Object;
        readFromObject(json: ActiveInviteCodeReq.Object): ActiveInviteCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ActiveInviteCodeReq;
        static create(is: TarsStream.TarsInputStream): ActiveInviteCodeReq;
    }

    namespace ActiveInviteCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            code?: string;
        }
    }

    class BindInviteCodeReq {
        tId: novel.UserId;
        code: string;

        toObject(): BindInviteCodeReq.Object;
        readFromObject(json: BindInviteCodeReq.Object): BindInviteCodeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BindInviteCodeReq;
        static create(is: TarsStream.TarsInputStream): BindInviteCodeReq;
    }

    namespace BindInviteCodeReq {
        interface Object {
            tId?: novel.UserId.Object;
            code?: string;
        }
    }

    class SubComment {
        id: number;
        idstr: string;
        user: novel.UserBase;
        toUser: novel.UserBase;
        content: string;
        likeCnt: number;
        createTime: number;
        deleted: number;
        parentId: number;
        commentType: number;
        liked: boolean;
        atUsers: TarsStream.List<novel.UserBase>;

        toObject(): SubComment.Object;
        readFromObject(json: SubComment.Object): SubComment;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SubComment;
        static create(is: TarsStream.TarsInputStream): SubComment;
    }

    namespace SubComment {
        interface Object {
            id?: number;
            idstr?: string;
            user?: novel.UserBase.Object;
            toUser?: novel.UserBase.Object;
            content?: string;
            likeCnt?: number;
            createTime?: number;
            deleted?: number;
            parentId?: number;
            commentType?: number;
            liked?: boolean;
            atUsers?: Array<novel.UserBase.Object>;
        }
    }

    class Comment {
        id: number;
        idstr: string;
        user: novel.UserBase;
        toUser: novel.UserBase;
        content: string;
        likeCnt: number;
        subCommentCnt: number;
        subComments: TarsStream.List<novel.SubComment>;
        createTime: number;
        deleted: number;
        parentId: number;
        commentType: number;
        liked: boolean;
        atUsers: TarsStream.List<novel.UserBase>;
        toCmtId: number;
        star: number;
        bookId: number;
        subjectId: number;
        paragraph: number;

        toObject(): Comment.Object;
        readFromObject(json: Comment.Object): Comment;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Comment;
        static create(is: TarsStream.TarsInputStream): Comment;
    }

    namespace Comment {
        interface Object {
            id?: number;
            idstr?: string;
            user?: novel.UserBase.Object;
            toUser?: novel.UserBase.Object;
            content?: string;
            likeCnt?: number;
            subCommentCnt?: number;
            subComments?: Array<novel.SubComment.Object>;
            createTime?: number;
            deleted?: number;
            parentId?: number;
            commentType?: number;
            liked?: boolean;
            atUsers?: Array<novel.UserBase.Object>;
            toCmtId?: number;
            star?: number;
            bookId?: number;
            subjectId?: number;
            paragraph?: number;
        }
    }

    class CommentReq {
        tId: novel.UserId;
        subjectId: number;
        parentId: number;
        toUid: number;
        content: string;
        commentType: number;
        toCommentId: number;
        contentType: number;
        atUids: TarsStream.List<number>;
        star: number;
        bookId: number;
        paragraph: number;

        toObject(): CommentReq.Object;
        readFromObject(json: CommentReq.Object): CommentReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CommentReq;
        static create(is: TarsStream.TarsInputStream): CommentReq;
    }

    namespace CommentReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectId?: number;
            parentId?: number;
            toUid?: number;
            content?: string;
            commentType?: number;
            toCommentId?: number;
            contentType?: number;
            atUids?: Array<number>;
            star?: number;
            bookId?: number;
            paragraph?: number;
        }
    }

    class EditCommentReq {
        tId: novel.UserId;
        commentId: number;
        content: string;
        star: number;
        commentIdstr: string;

        toObject(): EditCommentReq.Object;
        readFromObject(json: EditCommentReq.Object): EditCommentReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): EditCommentReq;
        static create(is: TarsStream.TarsInputStream): EditCommentReq;
    }

    namespace EditCommentReq {
        interface Object {
            tId?: novel.UserId.Object;
            commentId?: number;
            content?: string;
            star?: number;
            commentIdstr?: string;
        }
    }

    class CreateCommentRsp {
        comment: novel.Comment;
        subComment: novel.SubComment;

        toObject(): CreateCommentRsp.Object;
        readFromObject(json: CreateCommentRsp.Object): CreateCommentRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CreateCommentRsp;
        static create(is: TarsStream.TarsInputStream): CreateCommentRsp;
    }

    namespace CreateCommentRsp {
        interface Object {
            comment?: novel.Comment.Object;
            subComment?: novel.SubComment.Object;
        }
    }

    class CommentListReq {
        tId: novel.UserId;
        subjectId: number;
        orderType: number;
        offset: number;
        size: number;
        commentType: number;
        withSubCmts: boolean;
        parentId: number;
        bookId: number;
        paragraph: number;

        toObject(): CommentListReq.Object;
        readFromObject(json: CommentListReq.Object): CommentListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CommentListReq;
        static create(is: TarsStream.TarsInputStream): CommentListReq;
    }

    namespace CommentListReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectId?: number;
            orderType?: number;
            offset?: number;
            size?: number;
            commentType?: number;
            withSubCmts?: boolean;
            parentId?: number;
            bookId?: number;
            paragraph?: number;
        }
    }

    class ListUserCommentsReq {
        tId: novel.UserId;
        subjectId: number;
        commentType: number;
        offset: number;
        size: number;

        toObject(): ListUserCommentsReq.Object;
        readFromObject(json: ListUserCommentsReq.Object): ListUserCommentsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserCommentsReq;
        static create(is: TarsStream.TarsInputStream): ListUserCommentsReq;
    }

    namespace ListUserCommentsReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectId?: number;
            commentType?: number;
            offset?: number;
            size?: number;
        }
    }

    class CommentListRsp {
        data: TarsStream.List<novel.Comment>;
        hasMore: boolean;

        toObject(): CommentListRsp.Object;
        readFromObject(json: CommentListRsp.Object): CommentListRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CommentListRsp;
        static create(is: TarsStream.TarsInputStream): CommentListRsp;
    }

    namespace CommentListRsp {
        interface Object {
            data?: Array<novel.Comment.Object>;
            hasMore?: boolean;
        }
    }

    class SubCommentList {
        data: TarsStream.List<novel.SubComment>;
        hasMore: boolean;

        toObject(): SubCommentList.Object;
        readFromObject(json: SubCommentList.Object): SubCommentList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SubCommentList;
        static create(is: TarsStream.TarsInputStream): SubCommentList;
    }

    namespace SubCommentList {
        interface Object {
            data?: Array<novel.SubComment.Object>;
            hasMore?: boolean;
        }
    }

    class DeleteCommentReq {
        tId: novel.UserId;
        id: number;
        subjectId: number;
        idstr: string;

        toObject(): DeleteCommentReq.Object;
        readFromObject(json: DeleteCommentReq.Object): DeleteCommentReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DeleteCommentReq;
        static create(is: TarsStream.TarsInputStream): DeleteCommentReq;
    }

    namespace DeleteCommentReq {
        interface Object {
            tId?: novel.UserId.Object;
            id?: number;
            subjectId?: number;
            idstr?: string;
        }
    }

    class GetCommentByIdReq {
        tId: novel.UserId;
        id: number;

        toObject(): GetCommentByIdReq.Object;
        readFromObject(json: GetCommentByIdReq.Object): GetCommentByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetCommentByIdReq;
        static create(is: TarsStream.TarsInputStream): GetCommentByIdReq;
    }

    namespace GetCommentByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            id?: number;
        }
    }

    class LikeData {
        subjectId: number;
        objectId: number;
        likeType: number;
        user: novel.UserBase;

        toObject(): LikeData.Object;
        readFromObject(json: LikeData.Object): LikeData;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LikeData;
        static create(is: TarsStream.TarsInputStream): LikeData;
    }

    namespace LikeData {
        interface Object {
            subjectId?: number;
            objectId?: number;
            likeType?: number;
            user?: novel.UserBase.Object;
        }
    }

    class LikeDataList {
        datas: TarsStream.List<novel.LikeData>;
        hasMore: boolean;

        toObject(): LikeDataList.Object;
        readFromObject(json: LikeDataList.Object): LikeDataList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LikeDataList;
        static create(is: TarsStream.TarsInputStream): LikeDataList;
    }

    namespace LikeDataList {
        interface Object {
            datas?: Array<novel.LikeData.Object>;
            hasMore?: boolean;
        }
    }

    class LikeReq {
        tId: novel.UserId;
        subjectId: number;
        subjectUid: number;
        objectId: number;
        like: boolean;
        likeType: number;

        toObject(): LikeReq.Object;
        readFromObject(json: LikeReq.Object): LikeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LikeReq;
        static create(is: TarsStream.TarsInputStream): LikeReq;
    }

    namespace LikeReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectId?: number;
            subjectUid?: number;
            objectId?: number;
            like?: boolean;
            likeType?: number;
        }
    }

    class HasLikedReq {
        tId: novel.UserId;
        subjectIds: TarsStream.List<number>;
        likeType: number;

        toObject(): HasLikedReq.Object;
        readFromObject(json: HasLikedReq.Object): HasLikedReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HasLikedReq;
        static create(is: TarsStream.TarsInputStream): HasLikedReq;
    }

    namespace HasLikedReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectIds?: Array<number>;
            likeType?: number;
        }
    }

    class HasLikedRsp {
        data: TarsStream.Map<number, boolean>;

        toObject(): HasLikedRsp.Object;
        readFromObject(json: HasLikedRsp.Object): HasLikedRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HasLikedRsp;
        static create(is: TarsStream.TarsInputStream): HasLikedRsp;
    }

    namespace HasLikedRsp {
        interface Object {
            data?: Record<number, boolean>;
        }
    }

    class SubjectLikeListReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): SubjectLikeListReq.Object;
        readFromObject(json: SubjectLikeListReq.Object): SubjectLikeListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SubjectLikeListReq;
        static create(is: TarsStream.TarsInputStream): SubjectLikeListReq;
    }

    namespace SubjectLikeListReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class GetLatestLikeDataReq {
        tId: novel.UserId;
        likeType: number;
        subjectId: number;
        offset: number;
        size: number;

        toObject(): GetLatestLikeDataReq.Object;
        readFromObject(json: GetLatestLikeDataReq.Object): GetLatestLikeDataReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetLatestLikeDataReq;
        static create(is: TarsStream.TarsInputStream): GetLatestLikeDataReq;
    }

    namespace GetLatestLikeDataReq {
        interface Object {
            tId?: novel.UserId.Object;
            likeType?: number;
            subjectId?: number;
            offset?: number;
            size?: number;
        }
    }

    class ParagraphCommentsCount {
        paragraphCommentsCount: TarsStream.Map<number, number>;

        toObject(): ParagraphCommentsCount.Object;
        readFromObject(json: ParagraphCommentsCount.Object): ParagraphCommentsCount;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ParagraphCommentsCount;
        static create(is: TarsStream.TarsInputStream): ParagraphCommentsCount;
    }

    namespace ParagraphCommentsCount {
        interface Object {
            paragraphCommentsCount?: Record<number, number>;
        }
    }

    class CountParagraphCommentsReq {
        tId: novel.UserId;
        bookId: number;
        chapterId: number;
        pids: TarsStream.List<number>;

        toObject(): CountParagraphCommentsReq.Object;
        readFromObject(json: CountParagraphCommentsReq.Object): CountParagraphCommentsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CountParagraphCommentsReq;
        static create(is: TarsStream.TarsInputStream): CountParagraphCommentsReq;
    }

    namespace CountParagraphCommentsReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            chapterId?: number;
            pids?: Array<number>;
        }
    }

    class SubscribeReq {
        tId: novel.UserId;
        subType: number;
        subAction: boolean;
        tid: number;

        toObject(): SubscribeReq.Object;
        readFromObject(json: SubscribeReq.Object): SubscribeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SubscribeReq;
        static create(is: TarsStream.TarsInputStream): SubscribeReq;
    }

    namespace SubscribeReq {
        interface Object {
            tId?: novel.UserId.Object;
            subType?: number;
            subAction?: boolean;
            tid?: number;
        }
    }

    class HasFollowReq {
        tId: novel.UserId;
        subType: number;
        tids: TarsStream.List<number>;

        toObject(): HasFollowReq.Object;
        readFromObject(json: HasFollowReq.Object): HasFollowReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HasFollowReq;
        static create(is: TarsStream.TarsInputStream): HasFollowReq;
    }

    namespace HasFollowReq {
        interface Object {
            tId?: novel.UserId.Object;
            subType?: number;
            tids?: Array<number>;
        }
    }

    class HasFollowRsp {
        data: TarsStream.Map<number, boolean>;

        toObject(): HasFollowRsp.Object;
        readFromObject(json: HasFollowRsp.Object): HasFollowRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HasFollowRsp;
        static create(is: TarsStream.TarsInputStream): HasFollowRsp;
    }

    namespace HasFollowRsp {
        interface Object {
            data?: Record<number, boolean>;
        }
    }

    class BannerReq {
        tId: novel.UserId;
        showAt: number;
        categoryId: number;

        toObject(): BannerReq.Object;
        readFromObject(json: BannerReq.Object): BannerReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BannerReq;
        static create(is: TarsStream.TarsInputStream): BannerReq;
    }

    namespace BannerReq {
        interface Object {
            tId?: novel.UserId.Object;
            showAt?: number;
            categoryId?: number;
        }
    }

    class Banner {
        title: string;
        image: string;
        link: string;
        book: novel.Book;

        toObject(): Banner.Object;
        readFromObject(json: Banner.Object): Banner;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Banner;
        static create(is: TarsStream.TarsInputStream): Banner;
    }

    namespace Banner {
        interface Object {
            title?: string;
            image?: string;
            link?: string;
            book?: novel.Book.Object;
        }
    }

    class BannerRsp {
        data: TarsStream.List<novel.Banner>;

        toObject(): BannerRsp.Object;
        readFromObject(json: BannerRsp.Object): BannerRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BannerRsp;
        static create(is: TarsStream.TarsInputStream): BannerRsp;
    }

    namespace BannerRsp {
        interface Object {
            data?: Array<novel.Banner.Object>;
        }
    }

    class GetLatestConfigReq {
        tId: novel.UserId;
        configId: number;
        confKey: string;

        toObject(): GetLatestConfigReq.Object;
        readFromObject(json: GetLatestConfigReq.Object): GetLatestConfigReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetLatestConfigReq;
        static create(is: TarsStream.TarsInputStream): GetLatestConfigReq;
    }

    namespace GetLatestConfigReq {
        interface Object {
            tId?: novel.UserId.Object;
            configId?: number;
            confKey?: string;
        }
    }

    class GetLatestConfigRsp {
        lastestConfigId: number;
        config: string;

        toObject(): GetLatestConfigRsp.Object;
        readFromObject(json: GetLatestConfigRsp.Object): GetLatestConfigRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetLatestConfigRsp;
        static create(is: TarsStream.TarsInputStream): GetLatestConfigRsp;
    }

    namespace GetLatestConfigRsp {
        interface Object {
            lastestConfigId?: number;
            config?: string;
        }
    }

    class FeedbackReq {
        tId: novel.UserId;
        type: number;
        subType: number;
        content: string;
        imgs: TarsStream.List<string>;
        logs: string;
        contract: string;
        sign: string;
        subjectId: number;
        country: string;
        lcid: number;

        toObject(): FeedbackReq.Object;
        readFromObject(json: FeedbackReq.Object): FeedbackReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): FeedbackReq;
        static create(is: TarsStream.TarsInputStream): FeedbackReq;
    }

    namespace FeedbackReq {
        interface Object {
            tId?: novel.UserId.Object;
            type?: number;
            subType?: number;
            content?: string;
            imgs?: Array<string>;
            logs?: string;
            contract?: string;
            sign?: string;
            subjectId?: number;
            country?: string;
            lcid?: number;
        }
    }

    class BookContentFeedbackReq {
        tId: novel.UserId;
        subType: number;
        content: string;
        imgs: TarsStream.List<string>;
        logs: string;
        contract: string;
        sign: string;
        bookId: number;
        chpaterId: number;
        lines: TarsStream.List<number>;

        toObject(): BookContentFeedbackReq.Object;
        readFromObject(json: BookContentFeedbackReq.Object): BookContentFeedbackReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookContentFeedbackReq;
        static create(is: TarsStream.TarsInputStream): BookContentFeedbackReq;
    }

    namespace BookContentFeedbackReq {
        interface Object {
            tId?: novel.UserId.Object;
            subType?: number;
            content?: string;
            imgs?: Array<string>;
            logs?: string;
            contract?: string;
            sign?: string;
            bookId?: number;
            chpaterId?: number;
            lines?: Array<number>;
        }
    }

    class TagListReq {
        tId: novel.UserId;
        tagType: number;
        parentTagId: number;
        innerModel: boolean;

        toObject(): TagListReq.Object;
        readFromObject(json: TagListReq.Object): TagListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): TagListReq;
        static create(is: TarsStream.TarsInputStream): TagListReq;
    }

    namespace TagListReq {
        interface Object {
            tId?: novel.UserId.Object;
            tagType?: number;
            parentTagId?: number;
            innerModel?: boolean;
        }
    }

    class TagListRsp {
        tags: TarsStream.List<novel.Tag>;

        toObject(): TagListRsp.Object;
        readFromObject(json: TagListRsp.Object): TagListRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): TagListRsp;
        static create(is: TarsStream.TarsInputStream): TagListRsp;
    }

    namespace TagListRsp {
        interface Object {
            tags?: Array<novel.Tag.Object>;
        }
    }

    class RegistryClientFCMTokenReq {
        tId: novel.UserId;
        oldToken: string;
        newToken: string;

        toObject(): RegistryClientFCMTokenReq.Object;
        readFromObject(json: RegistryClientFCMTokenReq.Object): RegistryClientFCMTokenReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RegistryClientFCMTokenReq;
        static create(is: TarsStream.TarsInputStream): RegistryClientFCMTokenReq;
    }

    namespace RegistryClientFCMTokenReq {
        interface Object {
            tId?: novel.UserId.Object;
            oldToken?: string;
            newToken?: string;
        }
    }

    class GetEndPatchReq {
        tId: novel.UserId;

        toObject(): GetEndPatchReq.Object;
        readFromObject(json: GetEndPatchReq.Object): GetEndPatchReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetEndPatchReq;
        static create(is: TarsStream.TarsInputStream): GetEndPatchReq;
    }

    namespace GetEndPatchReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class EndPatch {
        id: number;
        md5: string;
        url: string;
        versionNames: string;
        description: string;
        endType: number;
        createTime: number;

        toObject(): EndPatch.Object;
        readFromObject(json: EndPatch.Object): EndPatch;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): EndPatch;
        static create(is: TarsStream.TarsInputStream): EndPatch;
    }

    namespace EndPatch {
        interface Object {
            id?: number;
            md5?: string;
            url?: string;
            versionNames?: string;
            description?: string;
            endType?: number;
            createTime?: number;
        }
    }

    class AddBanListReq {
        tId: novel.UserId;
        tid: number;
        type: number;

        toObject(): AddBanListReq.Object;
        readFromObject(json: AddBanListReq.Object): AddBanListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AddBanListReq;
        static create(is: TarsStream.TarsInputStream): AddBanListReq;
    }

    namespace AddBanListReq {
        interface Object {
            tId?: novel.UserId.Object;
            tid?: number;
            type?: number;
        }
    }

    class DelBanListReq {
        tId: novel.UserId;
        tid: number;
        type: number;

        toObject(): DelBanListReq.Object;
        readFromObject(json: DelBanListReq.Object): DelBanListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DelBanListReq;
        static create(is: TarsStream.TarsInputStream): DelBanListReq;
    }

    namespace DelBanListReq {
        interface Object {
            tId?: novel.UserId.Object;
            tid?: number;
            type?: number;
        }
    }

    class BanInfo {
        user: novel.UserBase;
        createTime: number;
        type: number;

        toObject(): BanInfo.Object;
        readFromObject(json: BanInfo.Object): BanInfo;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BanInfo;
        static create(is: TarsStream.TarsInputStream): BanInfo;
    }

    namespace BanInfo {
        interface Object {
            user?: novel.UserBase.Object;
            createTime?: number;
            type?: number;
        }
    }

    class BanList {
        list: TarsStream.List<novel.BanInfo>;
        hasMore: boolean;

        toObject(): BanList.Object;
        readFromObject(json: BanList.Object): BanList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BanList;
        static create(is: TarsStream.TarsInputStream): BanList;
    }

    namespace BanList {
        interface Object {
            list?: Array<novel.BanInfo.Object>;
            hasMore?: boolean;
        }
    }

    class GetBanListReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        type: number;

        toObject(): GetBanListReq.Object;
        readFromObject(json: GetBanListReq.Object): GetBanListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetBanListReq;
        static create(is: TarsStream.TarsInputStream): GetBanListReq;
    }

    namespace GetBanListReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            type?: number;
        }
    }

    class HasBanReq {
        tId: novel.UserId;
        tids: TarsStream.List<number>;
        type: number;

        toObject(): HasBanReq.Object;
        readFromObject(json: HasBanReq.Object): HasBanReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HasBanReq;
        static create(is: TarsStream.TarsInputStream): HasBanReq;
    }

    namespace HasBanReq {
        interface Object {
            tId?: novel.UserId.Object;
            tids?: Array<number>;
            type?: number;
        }
    }

    class HasBanRsp {
        hasBan: TarsStream.Map<number, boolean>;

        toObject(): HasBanRsp.Object;
        readFromObject(json: HasBanRsp.Object): HasBanRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): HasBanRsp;
        static create(is: TarsStream.TarsInputStream): HasBanRsp;
    }

    namespace HasBanRsp {
        interface Object {
            hasBan?: Record<number, boolean>;
        }
    }

    class VivoAttributionData {
        cvType: string;
        extParam: string;

        toObject(): VivoAttributionData.Object;
        readFromObject(json: VivoAttributionData.Object): VivoAttributionData;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): VivoAttributionData;
        static create(is: TarsStream.TarsInputStream): VivoAttributionData;
    }

    namespace VivoAttributionData {
        interface Object {
            cvType?: string;
            extParam?: string;
        }
    }

    class VivoAttributionUpladReq {
        tId: novel.UserId;
        pkgName: string;
        dataList: TarsStream.List<novel.VivoAttributionData>;
        oaid: string;

        toObject(): VivoAttributionUpladReq.Object;
        readFromObject(json: VivoAttributionUpladReq.Object): VivoAttributionUpladReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): VivoAttributionUpladReq;
        static create(is: TarsStream.TarsInputStream): VivoAttributionUpladReq;
    }

    namespace VivoAttributionUpladReq {
        interface Object {
            tId?: novel.UserId.Object;
            pkgName?: string;
            dataList?: Array<novel.VivoAttributionData.Object>;
            oaid?: string;
        }
    }

    class AppsaDownloadAttributionUploadReq {
        tId: novel.UserId;
        token: string;
        osVersion: string;
        deviceModel: string;
        installTime: number;
        deviceId: string;

        toObject(): AppsaDownloadAttributionUploadReq.Object;
        readFromObject(json: AppsaDownloadAttributionUploadReq.Object): AppsaDownloadAttributionUploadReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppsaDownloadAttributionUploadReq;
        static create(is: TarsStream.TarsInputStream): AppsaDownloadAttributionUploadReq;
    }

    namespace AppsaDownloadAttributionUploadReq {
        interface Object {
            tId?: novel.UserId.Object;
            token?: string;
            osVersion?: string;
            deviceModel?: string;
            installTime?: number;
            deviceId?: string;
        }
    }

    class AppsaDownloadAttributionUploadRsp {
        orgId: number;
        campaignId: number;
        adGroupId: number;
        keywordId: number;
        adId: number;
        countryOrRegion: string;

        toObject(): AppsaDownloadAttributionUploadRsp.Object;
        readFromObject(json: AppsaDownloadAttributionUploadRsp.Object): AppsaDownloadAttributionUploadRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppsaDownloadAttributionUploadRsp;
        static create(is: TarsStream.TarsInputStream): AppsaDownloadAttributionUploadRsp;
    }

    namespace AppsaDownloadAttributionUploadRsp {
        interface Object {
            orgId?: number;
            campaignId?: number;
            adGroupId?: number;
            keywordId?: number;
            adId?: number;
            countryOrRegion?: string;
        }
    }

    class AppsaCustomAttributionUploadReq {
        tId: novel.UserId;
        eventName: string;
        eventValues: string;
        eventDate: string;
        eventTime: number;
        deviceId: string;

        toObject(): AppsaCustomAttributionUploadReq.Object;
        readFromObject(json: AppsaCustomAttributionUploadReq.Object): AppsaCustomAttributionUploadReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppsaCustomAttributionUploadReq;
        static create(is: TarsStream.TarsInputStream): AppsaCustomAttributionUploadReq;
    }

    namespace AppsaCustomAttributionUploadReq {
        interface Object {
            tId?: novel.UserId.Object;
            eventName?: string;
            eventValues?: string;
            eventDate?: string;
            eventTime?: number;
            deviceId?: string;
        }
    }

    class RechargePackage {
        packageId: number;
        currency: number;
        coinAmount: number;
        extCoinAmount: number;
        desc: string;
        appleProdId: string;
        googleProdId: string;
        cornerWords: string;
        recommended: number;
        payerMaxExtCoin: number;
        supportCountryLevels: TarsStream.List<number>;
        type: number;
        extGemAmount: number;
        gemDurationDays: number;
        vipType: number;
        days: number;
        totalGem: number;
        payermaxDiscount: string;
        payerMaxExtGem: number;
        noAd: novel.GoodsSpu;
        payermaxBlackCountries: TarsStream.List<string>;

        toObject(): RechargePackage.Object;
        readFromObject(json: RechargePackage.Object): RechargePackage;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RechargePackage;
        static create(is: TarsStream.TarsInputStream): RechargePackage;
    }

    namespace RechargePackage {
        interface Object {
            packageId?: number;
            currency?: number;
            coinAmount?: number;
            extCoinAmount?: number;
            desc?: string;
            appleProdId?: string;
            googleProdId?: string;
            cornerWords?: string;
            recommended?: number;
            payerMaxExtCoin?: number;
            supportCountryLevels?: Array<number>;
            type?: number;
            extGemAmount?: number;
            gemDurationDays?: number;
            vipType?: number;
            days?: number;
            totalGem?: number;
            payermaxDiscount?: string;
            payerMaxExtGem?: number;
            noAd?: novel.GoodsSpu.Object;
            payermaxBlackCountries?: Array<string>;
        }
    }

    class RechargePackageRsp {
        data: TarsStream.List<novel.RechargePackage>;

        toObject(): RechargePackageRsp.Object;
        readFromObject(json: RechargePackageRsp.Object): RechargePackageRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RechargePackageRsp;
        static create(is: TarsStream.TarsInputStream): RechargePackageRsp;
    }

    namespace RechargePackageRsp {
        interface Object {
            data?: Array<novel.RechargePackage.Object>;
        }
    }

    class PayParameters {
        payerMaxTargetOrg: string;
        app: number;

        toObject(): PayParameters.Object;
        readFromObject(json: PayParameters.Object): PayParameters;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PayParameters;
        static create(is: TarsStream.TarsInputStream): PayParameters;
    }

    namespace PayParameters {
        interface Object {
            payerMaxTargetOrg?: string;
            app?: number;
        }
    }

    class GenPayOrderReq {
        tId: novel.UserId;
        sign: string;
        payChannel: number;
        packageId: number;
        params: novel.PayParameters;

        toObject(): GenPayOrderReq.Object;
        readFromObject(json: GenPayOrderReq.Object): GenPayOrderReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GenPayOrderReq;
        static create(is: TarsStream.TarsInputStream): GenPayOrderReq;
    }

    namespace GenPayOrderReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            payChannel?: number;
            packageId?: number;
            params?: novel.PayParameters.Object;
        }
    }

    class GenVipOrderReq {
        tId: novel.UserId;
        sign: string;
        payChannel: number;
        packageId: number;
        params: novel.PayParameters;

        toObject(): GenVipOrderReq.Object;
        readFromObject(json: GenVipOrderReq.Object): GenVipOrderReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GenVipOrderReq;
        static create(is: TarsStream.TarsInputStream): GenVipOrderReq;
    }

    namespace GenVipOrderReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            payChannel?: number;
            packageId?: number;
            params?: novel.PayParameters.Object;
        }
    }

    class OutPayParams {
        nonce: string;
        timestamp: string;
        sign: string;
        alipayOrderInfo: string;
        payermaxRedirectUrl: string;

        toObject(): OutPayParams.Object;
        readFromObject(json: OutPayParams.Object): OutPayParams;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OutPayParams;
        static create(is: TarsStream.TarsInputStream): OutPayParams;
    }

    namespace OutPayParams {
        interface Object {
            nonce?: string;
            timestamp?: string;
            sign?: string;
            alipayOrderInfo?: string;
            payermaxRedirectUrl?: string;
        }
    }

    class PayOrder {
        payChannel: number;
        pkg: novel.RechargePackage;
        mcOrderId: string;
        outOrderId: string;
        sdkParams: novel.OutPayParams;
        status: number;

        toObject(): PayOrder.Object;
        readFromObject(json: PayOrder.Object): PayOrder;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PayOrder;
        static create(is: TarsStream.TarsInputStream): PayOrder;
    }

    namespace PayOrder {
        interface Object {
            payChannel?: number;
            pkg?: novel.RechargePackage.Object;
            mcOrderId?: string;
            outOrderId?: string;
            sdkParams?: novel.OutPayParams.Object;
            status?: number;
        }
    }

    class MemberOrder {
        payChannel: number;
        pkg: novel.MemberProduct;
        mcOrderId: string;
        outOrderId: string;
        sdkParams: novel.OutPayParams;
        status: number;
        prePayId: string;
        contractId: string;

        toObject(): MemberOrder.Object;
        readFromObject(json: MemberOrder.Object): MemberOrder;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MemberOrder;
        static create(is: TarsStream.TarsInputStream): MemberOrder;
    }

    namespace MemberOrder {
        interface Object {
            payChannel?: number;
            pkg?: novel.MemberProduct.Object;
            mcOrderId?: string;
            outOrderId?: string;
            sdkParams?: novel.OutPayParams.Object;
            status?: number;
            prePayId?: string;
            contractId?: string;
        }
    }

    class GenPayOrderRsp {
        newOrder: novel.PayOrder;
        waitingPayOrder: novel.PayOrder;

        toObject(): GenPayOrderRsp.Object;
        readFromObject(json: GenPayOrderRsp.Object): GenPayOrderRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GenPayOrderRsp;
        static create(is: TarsStream.TarsInputStream): GenPayOrderRsp;
    }

    namespace GenPayOrderRsp {
        interface Object {
            newOrder?: novel.PayOrder.Object;
            waitingPayOrder?: novel.PayOrder.Object;
        }
    }

    class GenVipOrderRsp {
        newOrder: novel.PayOrder;

        toObject(): GenVipOrderRsp.Object;
        readFromObject(json: GenVipOrderRsp.Object): GenVipOrderRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GenVipOrderRsp;
        static create(is: TarsStream.TarsInputStream): GenVipOrderRsp;
    }

    namespace GenVipOrderRsp {
        interface Object {
            newOrder?: novel.PayOrder.Object;
        }
    }

    class QueryPayOrderReq {
        tId: novel.UserId;
        sign: string;
        mcOrderId: string;

        toObject(): QueryPayOrderReq.Object;
        readFromObject(json: QueryPayOrderReq.Object): QueryPayOrderReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryPayOrderReq;
        static create(is: TarsStream.TarsInputStream): QueryPayOrderReq;
    }

    namespace QueryPayOrderReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            mcOrderId?: string;
        }
    }

    class QueryPayOrderRsp {
        order: novel.PayOrder;
        memberOrder: novel.MemberOrder;

        toObject(): QueryPayOrderRsp.Object;
        readFromObject(json: QueryPayOrderRsp.Object): QueryPayOrderRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryPayOrderRsp;
        static create(is: TarsStream.TarsInputStream): QueryPayOrderRsp;
    }

    namespace QueryPayOrderRsp {
        interface Object {
            order?: novel.PayOrder.Object;
            memberOrder?: novel.MemberOrder.Object;
        }
    }

    class CancelPayOrderReq {
        tId: novel.UserId;
        sign: string;
        mcOrderId: string;

        toObject(): CancelPayOrderReq.Object;
        readFromObject(json: CancelPayOrderReq.Object): CancelPayOrderReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CancelPayOrderReq;
        static create(is: TarsStream.TarsInputStream): CancelPayOrderReq;
    }

    namespace CancelPayOrderReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            mcOrderId?: string;
        }
    }

    class IAPReceiptVerifyReq {
        tId: novel.UserId;
        sign: string;
        mcOrderId: string;
        receipt: string;
        transactionId: string;

        toObject(): IAPReceiptVerifyReq.Object;
        readFromObject(json: IAPReceiptVerifyReq.Object): IAPReceiptVerifyReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): IAPReceiptVerifyReq;
        static create(is: TarsStream.TarsInputStream): IAPReceiptVerifyReq;
    }

    namespace IAPReceiptVerifyReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            mcOrderId?: string;
            receipt?: string;
            transactionId?: string;
        }
    }

    class GooglePayPurchasesVerifyReq {
        tId: novel.UserId;
        sign: string;
        mcOrderId: string;
        token: string;

        toObject(): GooglePayPurchasesVerifyReq.Object;
        readFromObject(json: GooglePayPurchasesVerifyReq.Object): GooglePayPurchasesVerifyReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GooglePayPurchasesVerifyReq;
        static create(is: TarsStream.TarsInputStream): GooglePayPurchasesVerifyReq;
    }

    namespace GooglePayPurchasesVerifyReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            mcOrderId?: string;
            token?: string;
        }
    }

    class BalanceRsp {
        balance: TarsStream.Map<number, number>;

        toObject(): BalanceRsp.Object;
        readFromObject(json: BalanceRsp.Object): BalanceRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BalanceRsp;
        static create(is: TarsStream.TarsInputStream): BalanceRsp;
    }

    namespace BalanceRsp {
        interface Object {
            balance?: Record<number, number>;
        }
    }

    class BalanceReq {
        tId: novel.UserId;

        toObject(): BalanceReq.Object;
        readFromObject(json: BalanceReq.Object): BalanceReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BalanceReq;
        static create(is: TarsStream.TarsInputStream): BalanceReq;
    }

    namespace BalanceReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class GenMemberPayOrderReq {
        tId: novel.UserId;
        sign: string;
        payChannel: number;
        memberProductId: string;

        toObject(): GenMemberPayOrderReq.Object;
        readFromObject(json: GenMemberPayOrderReq.Object): GenMemberPayOrderReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GenMemberPayOrderReq;
        static create(is: TarsStream.TarsInputStream): GenMemberPayOrderReq;
    }

    namespace GenMemberPayOrderReq {
        interface Object {
            tId?: novel.UserId.Object;
            sign?: string;
            payChannel?: number;
            memberProductId?: string;
        }
    }

    class GenMemberPayOrderRsp {
        newOrder: novel.MemberOrder;
        waitingOrder: novel.MemberOrder;

        toObject(): GenMemberPayOrderRsp.Object;
        readFromObject(json: GenMemberPayOrderRsp.Object): GenMemberPayOrderRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GenMemberPayOrderRsp;
        static create(is: TarsStream.TarsInputStream): GenMemberPayOrderRsp;
    }

    namespace GenMemberPayOrderRsp {
        interface Object {
            newOrder?: novel.MemberOrder.Object;
            waitingOrder?: novel.MemberOrder.Object;
        }
    }

    class GetMemberProductsReq {
        tId: novel.UserId;

        toObject(): GetMemberProductsReq.Object;
        readFromObject(json: GetMemberProductsReq.Object): GetMemberProductsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetMemberProductsReq;
        static create(is: TarsStream.TarsInputStream): GetMemberProductsReq;
    }

    namespace GetMemberProductsReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class MemberProductList {
        products: TarsStream.List<novel.MemberProduct>;

        toObject(): MemberProductList.Object;
        readFromObject(json: MemberProductList.Object): MemberProductList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MemberProductList;
        static create(is: TarsStream.TarsInputStream): MemberProductList;
    }

    namespace MemberProductList {
        interface Object {
            products?: Array<novel.MemberProduct.Object>;
        }
    }

    class PayWithPayerMaxReq {
        tId: novel.UserId;
        mcOrderId: string;
        payMethod: string;

        toObject(): PayWithPayerMaxReq.Object;
        readFromObject(json: PayWithPayerMaxReq.Object): PayWithPayerMaxReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PayWithPayerMaxReq;
        static create(is: TarsStream.TarsInputStream): PayWithPayerMaxReq;
    }

    namespace PayWithPayerMaxReq {
        interface Object {
            tId?: novel.UserId.Object;
            mcOrderId?: string;
            payMethod?: string;
        }
    }

    class PayWithPayerMaxRsp {
        mcOrderId: string;
        redirectUrl: string;
        status: string;

        toObject(): PayWithPayerMaxRsp.Object;
        readFromObject(json: PayWithPayerMaxRsp.Object): PayWithPayerMaxRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PayWithPayerMaxRsp;
        static create(is: TarsStream.TarsInputStream): PayWithPayerMaxRsp;
    }

    namespace PayWithPayerMaxRsp {
        interface Object {
            mcOrderId?: string;
            redirectUrl?: string;
            status?: string;
        }
    }

    class ShowPayerMaxReq {
        tId: novel.UserId;
        withoutRecharge: number;

        toObject(): ShowPayerMaxReq.Object;
        readFromObject(json: ShowPayerMaxReq.Object): ShowPayerMaxReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ShowPayerMaxReq;
        static create(is: TarsStream.TarsInputStream): ShowPayerMaxReq;
    }

    namespace ShowPayerMaxReq {
        interface Object {
            tId?: novel.UserId.Object;
            withoutRecharge?: number;
        }
    }

    class ShowPayerMaxRsp {
        show: boolean;

        toObject(): ShowPayerMaxRsp.Object;
        readFromObject(json: ShowPayerMaxRsp.Object): ShowPayerMaxRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ShowPayerMaxRsp;
        static create(is: TarsStream.TarsInputStream): ShowPayerMaxRsp;
    }

    namespace ShowPayerMaxRsp {
        interface Object {
            show?: boolean;
        }
    }

    class ListUserGemBillReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserGemBillReq.Object;
        readFromObject(json: ListUserGemBillReq.Object): ListUserGemBillReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserGemBillReq;
        static create(is: TarsStream.TarsInputStream): ListUserGemBillReq;
    }

    namespace ListUserGemBillReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class GetLast24HoursExpireGemsReq {
        tId: novel.UserId;

        toObject(): GetLast24HoursExpireGemsReq.Object;
        readFromObject(json: GetLast24HoursExpireGemsReq.Object): GetLast24HoursExpireGemsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetLast24HoursExpireGemsReq;
        static create(is: TarsStream.TarsInputStream): GetLast24HoursExpireGemsReq;
    }

    namespace GetLast24HoursExpireGemsReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class Last24HoursExpireGemsRsp {
        amount: number;

        toObject(): Last24HoursExpireGemsRsp.Object;
        readFromObject(json: Last24HoursExpireGemsRsp.Object): Last24HoursExpireGemsRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Last24HoursExpireGemsRsp;
        static create(is: TarsStream.TarsInputStream): Last24HoursExpireGemsRsp;
    }

    namespace Last24HoursExpireGemsRsp {
        interface Object {
            amount?: number;
        }
    }

    class ListCoinRechargeFlowReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        coinType: number;

        toObject(): ListCoinRechargeFlowReq.Object;
        readFromObject(json: ListCoinRechargeFlowReq.Object): ListCoinRechargeFlowReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListCoinRechargeFlowReq;
        static create(is: TarsStream.TarsInputStream): ListCoinRechargeFlowReq;
    }

    namespace ListCoinRechargeFlowReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            coinType?: number;
        }
    }

    class QueryUserMaxRechargePayOrderReq {
        tId: novel.UserId;

        toObject(): QueryUserMaxRechargePayOrderReq.Object;
        readFromObject(json: QueryUserMaxRechargePayOrderReq.Object): QueryUserMaxRechargePayOrderReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryUserMaxRechargePayOrderReq;
        static create(is: TarsStream.TarsInputStream): QueryUserMaxRechargePayOrderReq;
    }

    namespace QueryUserMaxRechargePayOrderReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListUserAipBillReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserAipBillReq.Object;
        readFromObject(json: ListUserAipBillReq.Object): ListUserAipBillReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserAipBillReq;
        static create(is: TarsStream.TarsInputStream): ListUserAipBillReq;
    }

    namespace ListUserAipBillReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class GetLast24HoursExpireAipsReq {
        tId: novel.UserId;

        toObject(): GetLast24HoursExpireAipsReq.Object;
        readFromObject(json: GetLast24HoursExpireAipsReq.Object): GetLast24HoursExpireAipsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetLast24HoursExpireAipsReq;
        static create(is: TarsStream.TarsInputStream): GetLast24HoursExpireAipsReq;
    }

    namespace GetLast24HoursExpireAipsReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class Last24HoursExpireAipsRsp {
        amount: number;

        toObject(): Last24HoursExpireAipsRsp.Object;
        readFromObject(json: Last24HoursExpireAipsRsp.Object): Last24HoursExpireAipsRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Last24HoursExpireAipsRsp;
        static create(is: TarsStream.TarsInputStream): Last24HoursExpireAipsRsp;
    }

    namespace Last24HoursExpireAipsRsp {
        interface Object {
            amount?: number;
        }
    }

    class CountPersonalUnreadMessageReq {
        tId: novel.UserId;

        toObject(): CountPersonalUnreadMessageReq.Object;
        readFromObject(json: CountPersonalUnreadMessageReq.Object): CountPersonalUnreadMessageReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CountPersonalUnreadMessageReq;
        static create(is: TarsStream.TarsInputStream): CountPersonalUnreadMessageReq;
    }

    namespace CountPersonalUnreadMessageReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class PersonalUnreadMessageCount {
        countMap: TarsStream.Map<number, number>;

        toObject(): PersonalUnreadMessageCount.Object;
        readFromObject(json: PersonalUnreadMessageCount.Object): PersonalUnreadMessageCount;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PersonalUnreadMessageCount;
        static create(is: TarsStream.TarsInputStream): PersonalUnreadMessageCount;
    }

    namespace PersonalUnreadMessageCount {
        interface Object {
            countMap?: Record<number, number>;
        }
    }

    class GetUnreadOfficialMessageCountReq {
        tId: novel.UserId;

        toObject(): GetUnreadOfficialMessageCountReq.Object;
        readFromObject(json: GetUnreadOfficialMessageCountReq.Object): GetUnreadOfficialMessageCountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUnreadOfficialMessageCountReq;
        static create(is: TarsStream.TarsInputStream): GetUnreadOfficialMessageCountReq;
    }

    namespace GetUnreadOfficialMessageCountReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class UnreadMessageCount {
        unreadCount: TarsStream.Map<number, number>;

        toObject(): UnreadMessageCount.Object;
        readFromObject(json: UnreadMessageCount.Object): UnreadMessageCount;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UnreadMessageCount;
        static create(is: TarsStream.TarsInputStream): UnreadMessageCount;
    }

    namespace UnreadMessageCount {
        interface Object {
            unreadCount?: Record<number, number>;
        }
    }

    class OfficialMessage {
        id: string;
        OfficialUser: novel.UserBase;
        type: number;
        subType: number;
        title: string;
        content: string;
        redirectType: novel.ERedirectType;
        redirectUrl: string;
        iconUrl: string;
        buttonText: string;
        publishTime: number;
        expireTime: number;
        hasRead: boolean;
        extra: string;

        toObject(): OfficialMessage.Object;
        readFromObject(json: OfficialMessage.Object): OfficialMessage;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OfficialMessage;
        static create(is: TarsStream.TarsInputStream): OfficialMessage;
    }

    namespace OfficialMessage {
        interface Object {
            id?: string;
            OfficialUser?: novel.UserBase.Object;
            type?: number;
            subType?: number;
            title?: string;
            content?: string;
            redirectType?: novel.ERedirectType;
            redirectUrl?: string;
            iconUrl?: string;
            buttonText?: string;
            publishTime?: number;
            expireTime?: number;
            hasRead?: boolean;
            extra?: string;
        }
    }

    class GetOfficialMessageReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): GetOfficialMessageReq.Object;
        readFromObject(json: GetOfficialMessageReq.Object): GetOfficialMessageReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetOfficialMessageReq;
        static create(is: TarsStream.TarsInputStream): GetOfficialMessageReq;
    }

    namespace GetOfficialMessageReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class OfficialMessageList {
        messages: TarsStream.List<novel.OfficialMessage>;
        hasMore: boolean;

        toObject(): OfficialMessageList.Object;
        readFromObject(json: OfficialMessageList.Object): OfficialMessageList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OfficialMessageList;
        static create(is: TarsStream.TarsInputStream): OfficialMessageList;
    }

    namespace OfficialMessageList {
        interface Object {
            messages?: Array<novel.OfficialMessage.Object>;
            hasMore?: boolean;
        }
    }

    class MarkMessageHasReadReq {
        tId: novel.UserId;
        msgIds: TarsStream.List<number>;

        toObject(): MarkMessageHasReadReq.Object;
        readFromObject(json: MarkMessageHasReadReq.Object): MarkMessageHasReadReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MarkMessageHasReadReq;
        static create(is: TarsStream.TarsInputStream): MarkMessageHasReadReq;
    }

    namespace MarkMessageHasReadReq {
        interface Object {
            tId?: novel.UserId.Object;
            msgIds?: Array<number>;
        }
    }

    class CountUnreadMessageReq {
        tId: novel.UserId;
        types: TarsStream.List<number>;

        toObject(): CountUnreadMessageReq.Object;
        readFromObject(json: CountUnreadMessageReq.Object): CountUnreadMessageReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CountUnreadMessageReq;
        static create(is: TarsStream.TarsInputStream): CountUnreadMessageReq;
    }

    namespace CountUnreadMessageReq {
        interface Object {
            tId?: novel.UserId.Object;
            types?: Array<number>;
        }
    }

    class CountUnreadPersonalMessageReq {
        tId: novel.UserId;
        types: TarsStream.List<number>;

        toObject(): CountUnreadPersonalMessageReq.Object;
        readFromObject(json: CountUnreadPersonalMessageReq.Object): CountUnreadPersonalMessageReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CountUnreadPersonalMessageReq;
        static create(is: TarsStream.TarsInputStream): CountUnreadPersonalMessageReq;
    }

    namespace CountUnreadPersonalMessageReq {
        interface Object {
            tId?: novel.UserId.Object;
            types?: Array<number>;
        }
    }

    class GetPersonalMessageByTypeReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): GetPersonalMessageByTypeReq.Object;
        readFromObject(json: GetPersonalMessageByTypeReq.Object): GetPersonalMessageByTypeReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetPersonalMessageByTypeReq;
        static create(is: TarsStream.TarsInputStream): GetPersonalMessageByTypeReq;
    }

    namespace GetPersonalMessageByTypeReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class CommentMessage {
        id: number;
        subType: number;
        comment: novel.Comment;
        toComment: novel.Comment;
        createTime: number;

        toObject(): CommentMessage.Object;
        readFromObject(json: CommentMessage.Object): CommentMessage;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CommentMessage;
        static create(is: TarsStream.TarsInputStream): CommentMessage;
    }

    namespace CommentMessage {
        interface Object {
            id?: number;
            subType?: number;
            comment?: novel.Comment.Object;
            toComment?: novel.Comment.Object;
            createTime?: number;
        }
    }

    class CommentMessageList {
        messages: TarsStream.List<novel.CommentMessage>;
        hasMore: boolean;
        lastPullTime: number;

        toObject(): CommentMessageList.Object;
        readFromObject(json: CommentMessageList.Object): CommentMessageList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CommentMessageList;
        static create(is: TarsStream.TarsInputStream): CommentMessageList;
    }

    namespace CommentMessageList {
        interface Object {
            messages?: Array<novel.CommentMessage.Object>;
            hasMore?: boolean;
            lastPullTime?: number;
        }
    }

    class AtMessage {
        id: number;

        toObject(): AtMessage.Object;
        readFromObject(json: AtMessage.Object): AtMessage;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AtMessage;
        static create(is: TarsStream.TarsInputStream): AtMessage;
    }

    namespace AtMessage {
        interface Object {
            id?: number;
        }
    }

    class AtMessageList {
        messages: TarsStream.List<novel.AtMessage>;
        hasMore: boolean;
        lastPullTime: number;

        toObject(): AtMessageList.Object;
        readFromObject(json: AtMessageList.Object): AtMessageList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AtMessageList;
        static create(is: TarsStream.TarsInputStream): AtMessageList;
    }

    namespace AtMessageList {
        interface Object {
            messages?: Array<novel.AtMessage.Object>;
            hasMore?: boolean;
            lastPullTime?: number;
        }
    }

    class LikeMessage {
        id: number;
        subType: novel.ELikeMessageSubType;
        userBase: novel.UserBase;
        createTime: number;
        comment: novel.Comment;

        toObject(): LikeMessage.Object;
        readFromObject(json: LikeMessage.Object): LikeMessage;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LikeMessage;
        static create(is: TarsStream.TarsInputStream): LikeMessage;
    }

    namespace LikeMessage {
        interface Object {
            id?: number;
            subType?: novel.ELikeMessageSubType;
            userBase?: novel.UserBase.Object;
            createTime?: number;
            comment?: novel.Comment.Object;
        }
    }

    class LikeMessageList {
        messages: TarsStream.List<novel.LikeMessage>;
        hasMore: boolean;
        lastPullTime: number;

        toObject(): LikeMessageList.Object;
        readFromObject(json: LikeMessageList.Object): LikeMessageList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LikeMessageList;
        static create(is: TarsStream.TarsInputStream): LikeMessageList;
    }

    namespace LikeMessageList {
        interface Object {
            messages?: Array<novel.LikeMessage.Object>;
            hasMore?: boolean;
            lastPullTime?: number;
        }
    }

    class QueryFriendApplyMessageByFromUidReq {
        tId: novel.UserId;
        toUid: number;

        toObject(): QueryFriendApplyMessageByFromUidReq.Object;
        readFromObject(json: QueryFriendApplyMessageByFromUidReq.Object): QueryFriendApplyMessageByFromUidReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryFriendApplyMessageByFromUidReq;
        static create(is: TarsStream.TarsInputStream): QueryFriendApplyMessageByFromUidReq;
    }

    namespace QueryFriendApplyMessageByFromUidReq {
        interface Object {
            tId?: novel.UserId.Object;
            toUid?: number;
        }
    }

    class RelationMessage {
        id: number;

        toObject(): RelationMessage.Object;
        readFromObject(json: RelationMessage.Object): RelationMessage;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RelationMessage;
        static create(is: TarsStream.TarsInputStream): RelationMessage;
    }

    namespace RelationMessage {
        interface Object {
            id?: number;
        }
    }

    class RelationMessageList {
        messages: TarsStream.List<novel.RelationMessage>;
        hasMore: boolean;
        lastPullTime: number;

        toObject(): RelationMessageList.Object;
        readFromObject(json: RelationMessageList.Object): RelationMessageList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RelationMessageList;
        static create(is: TarsStream.TarsInputStream): RelationMessageList;
    }

    namespace RelationMessageList {
        interface Object {
            messages?: Array<novel.RelationMessage.Object>;
            hasMore?: boolean;
            lastPullTime?: number;
        }
    }

    class BagGoods {
        id: number;
        uid: number;
        goods: novel.GoodsSpu;
        quantity: number;

        toObject(): BagGoods.Object;
        readFromObject(json: BagGoods.Object): BagGoods;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BagGoods;
        static create(is: TarsStream.TarsInputStream): BagGoods;
    }

    namespace BagGoods {
        interface Object {
            id?: number;
            uid?: number;
            goods?: novel.GoodsSpu.Object;
            quantity?: number;
        }
    }

    class MallOrderItem {
        sku: novel.GoodsSku;
        quantity: number;

        toObject(): MallOrderItem.Object;
        readFromObject(json: MallOrderItem.Object): MallOrderItem;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MallOrderItem;
        static create(is: TarsStream.TarsInputStream): MallOrderItem;
    }

    namespace MallOrderItem {
        interface Object {
            sku?: novel.GoodsSku.Object;
            quantity?: number;
        }
    }

    class MallOrder {
        orderId: string;
        state: number;
        amount: number;
        currency: number;
        createTime: number;
        description: string;
        sortType: number;
        items: TarsStream.List<novel.MallOrderItem>;

        toObject(): MallOrder.Object;
        readFromObject(json: MallOrder.Object): MallOrder;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MallOrder;
        static create(is: TarsStream.TarsInputStream): MallOrder;
    }

    namespace MallOrder {
        interface Object {
            orderId?: string;
            state?: number;
            amount?: number;
            currency?: number;
            createTime?: number;
            description?: string;
            sortType?: number;
            items?: Array<novel.MallOrderItem.Object>;
        }
    }

    class ListSkuByCategoryReq {
        tId: novel.UserId;
        category: number;
        subCategory: number;
        offset: number;
        size: number;

        toObject(): ListSkuByCategoryReq.Object;
        readFromObject(json: ListSkuByCategoryReq.Object): ListSkuByCategoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListSkuByCategoryReq;
        static create(is: TarsStream.TarsInputStream): ListSkuByCategoryReq;
    }

    namespace ListSkuByCategoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            category?: number;
            subCategory?: number;
            offset?: number;
            size?: number;
        }
    }

    class GoodsSkuList {
        skus: TarsStream.List<novel.GoodsSku>;
        hasMore: boolean;

        toObject(): GoodsSkuList.Object;
        readFromObject(json: GoodsSkuList.Object): GoodsSkuList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GoodsSkuList;
        static create(is: TarsStream.TarsInputStream): GoodsSkuList;
    }

    namespace GoodsSkuList {
        interface Object {
            skus?: Array<novel.GoodsSku.Object>;
            hasMore?: boolean;
        }
    }

    class GoodsSkuMap {
        skuMap: TarsStream.Map<number, novel.GoodsSku>;

        toObject(): GoodsSkuMap.Object;
        readFromObject(json: GoodsSkuMap.Object): GoodsSkuMap;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GoodsSkuMap;
        static create(is: TarsStream.TarsInputStream): GoodsSkuMap;
    }

    namespace GoodsSkuMap {
        interface Object {
            skuMap?: Record<number, novel.GoodsSku.Object>;
        }
    }

    class GetGoodsByIdReq {
        tId: novel.UserId;
        skuId: number;

        toObject(): GetGoodsByIdReq.Object;
        readFromObject(json: GetGoodsByIdReq.Object): GetGoodsByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetGoodsByIdReq;
        static create(is: TarsStream.TarsInputStream): GetGoodsByIdReq;
    }

    namespace GetGoodsByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            skuId?: number;
        }
    }

    class OrderGoodsItem {
        skuId: number;
        quantity: number;

        toObject(): OrderGoodsItem.Object;
        readFromObject(json: OrderGoodsItem.Object): OrderGoodsItem;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OrderGoodsItem;
        static create(is: TarsStream.TarsInputStream): OrderGoodsItem;
    }

    namespace OrderGoodsItem {
        interface Object {
            skuId?: number;
            quantity?: number;
        }
    }

    class OrderGoodsReq {
        tId: novel.UserId;
        items: TarsStream.List<novel.OrderGoodsItem>;
        sign: string;
        orderType: number;

        toObject(): OrderGoodsReq.Object;
        readFromObject(json: OrderGoodsReq.Object): OrderGoodsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OrderGoodsReq;
        static create(is: TarsStream.TarsInputStream): OrderGoodsReq;
    }

    namespace OrderGoodsReq {
        interface Object {
            tId?: novel.UserId.Object;
            items?: Array<novel.OrderGoodsItem.Object>;
            sign?: string;
            orderType?: number;
        }
    }

    class OrderGoodsRsp {
        orderId: string;
        amount: number;
        orderState: number;
        ownItems: TarsStream.List<novel.BagGoods>;

        toObject(): OrderGoodsRsp.Object;
        readFromObject(json: OrderGoodsRsp.Object): OrderGoodsRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OrderGoodsRsp;
        static create(is: TarsStream.TarsInputStream): OrderGoodsRsp;
    }

    namespace OrderGoodsRsp {
        interface Object {
            orderId?: string;
            amount?: number;
            orderState?: number;
            ownItems?: Array<novel.BagGoods.Object>;
        }
    }

    class MallOrderListReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): MallOrderListReq.Object;
        readFromObject(json: MallOrderListReq.Object): MallOrderListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MallOrderListReq;
        static create(is: TarsStream.TarsInputStream): MallOrderListReq;
    }

    namespace MallOrderListReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class MallOrderList {
        orders: TarsStream.List<novel.MallOrder>;
        hasMore: boolean;

        toObject(): MallOrderList.Object;
        readFromObject(json: MallOrderList.Object): MallOrderList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): MallOrderList;
        static create(is: TarsStream.TarsInputStream): MallOrderList;
    }

    namespace MallOrderList {
        interface Object {
            orders?: Array<novel.MallOrder.Object>;
            hasMore?: boolean;
        }
    }

    class GetSkuByIdReq {
        tId: novel.UserId;
        skuId: number;

        toObject(): GetSkuByIdReq.Object;
        readFromObject(json: GetSkuByIdReq.Object): GetSkuByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetSkuByIdReq;
        static create(is: TarsStream.TarsInputStream): GetSkuByIdReq;
    }

    namespace GetSkuByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            skuId?: number;
        }
    }

    class GetSpuByIdReq {
        tId: novel.UserId;
        spuId: number;

        toObject(): GetSpuByIdReq.Object;
        readFromObject(json: GetSpuByIdReq.Object): GetSpuByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetSpuByIdReq;
        static create(is: TarsStream.TarsInputStream): GetSpuByIdReq;
    }

    namespace GetSpuByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            spuId?: number;
        }
    }

    class ListBagGoodsByCategoryReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        category: number;

        toObject(): ListBagGoodsByCategoryReq.Object;
        readFromObject(json: ListBagGoodsByCategoryReq.Object): ListBagGoodsByCategoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBagGoodsByCategoryReq;
        static create(is: TarsStream.TarsInputStream): ListBagGoodsByCategoryReq;
    }

    namespace ListBagGoodsByCategoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            category?: number;
        }
    }

    class BagGoodsList {
        goods: TarsStream.List<novel.BagGoods>;
        hasMore: boolean;

        toObject(): BagGoodsList.Object;
        readFromObject(json: BagGoodsList.Object): BagGoodsList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BagGoodsList;
        static create(is: TarsStream.TarsInputStream): BagGoodsList;
    }

    namespace BagGoodsList {
        interface Object {
            goods?: Array<novel.BagGoods.Object>;
            hasMore?: boolean;
        }
    }

    class ConsumeBagGoodsReq {
        tId: novel.UserId;
        goodsId: number;
        quantity: number;
        toUids: TarsStream.List<number>;
        toRoomId: number;

        toObject(): ConsumeBagGoodsReq.Object;
        readFromObject(json: ConsumeBagGoodsReq.Object): ConsumeBagGoodsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ConsumeBagGoodsReq;
        static create(is: TarsStream.TarsInputStream): ConsumeBagGoodsReq;
    }

    namespace ConsumeBagGoodsReq {
        interface Object {
            tId?: novel.UserId.Object;
            goodsId?: number;
            quantity?: number;
            toUids?: Array<number>;
            toRoomId?: number;
        }
    }

    class ListSkuByShelf {
        tId: novel.UserId;
        shelfType: number;
        offset: number;
        size: number;

        toObject(): ListSkuByShelf.Object;
        readFromObject(json: ListSkuByShelf.Object): ListSkuByShelf;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListSkuByShelf;
        static create(is: TarsStream.TarsInputStream): ListSkuByShelf;
    }

    namespace ListSkuByShelf {
        interface Object {
            tId?: novel.UserId.Object;
            shelfType?: number;
            offset?: number;
            size?: number;
        }
    }

    class ListSkuByIdsReq {
        tId: novel.UserId;
        ids: TarsStream.List<number>;

        toObject(): ListSkuByIdsReq.Object;
        readFromObject(json: ListSkuByIdsReq.Object): ListSkuByIdsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListSkuByIdsReq;
        static create(is: TarsStream.TarsInputStream): ListSkuByIdsReq;
    }

    namespace ListSkuByIdsReq {
        interface Object {
            tId?: novel.UserId.Object;
            ids?: Array<number>;
        }
    }

    class GetUserNoAdStatusReq {
        tId: novel.UserId;

        toObject(): GetUserNoAdStatusReq.Object;
        readFromObject(json: GetUserNoAdStatusReq.Object): GetUserNoAdStatusReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUserNoAdStatusReq;
        static create(is: TarsStream.TarsInputStream): GetUserNoAdStatusReq;
    }

    namespace GetUserNoAdStatusReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class UserNoAdStatus {
        uid: number;
        expireAt: number;

        toObject(): UserNoAdStatus.Object;
        readFromObject(json: UserNoAdStatus.Object): UserNoAdStatus;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserNoAdStatus;
        static create(is: TarsStream.TarsInputStream): UserNoAdStatus;
    }

    namespace UserNoAdStatus {
        interface Object {
            uid?: number;
            expireAt?: number;
        }
    }

    class BookSpecialSubject {
        id: string;
        title: string;
        subjectType: number;
        style: number;
        forDiscount: number;
        expireAt: number;
        forAudio: number;

        toObject(): BookSpecialSubject.Object;
        readFromObject(json: BookSpecialSubject.Object): BookSpecialSubject;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookSpecialSubject;
        static create(is: TarsStream.TarsInputStream): BookSpecialSubject;
    }

    namespace BookSpecialSubject {
        interface Object {
            id?: string;
            title?: string;
            subjectType?: number;
            style?: number;
            forDiscount?: number;
            expireAt?: number;
            forAudio?: number;
        }
    }

    class BookCategoryList {
        categories: TarsStream.List<novel.BookCategory>;
        hasMore: boolean;
        subject: novel.BookSpecialSubject;

        toObject(): BookCategoryList.Object;
        readFromObject(json: BookCategoryList.Object): BookCategoryList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookCategoryList;
        static create(is: TarsStream.TarsInputStream): BookCategoryList;
    }

    namespace BookCategoryList {
        interface Object {
            categories?: Array<novel.BookCategory.Object>;
            hasMore?: boolean;
            subject?: novel.BookSpecialSubject.Object;
        }
    }

    class BookList {
        books: TarsStream.List<novel.Book>;
        hasMore: boolean;
        subject: novel.BookSpecialSubject;

        toObject(): BookList.Object;
        readFromObject(json: BookList.Object): BookList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookList;
        static create(is: TarsStream.TarsInputStream): BookList;
    }

    namespace BookList {
        interface Object {
            books?: Array<novel.Book.Object>;
            hasMore?: boolean;
            subject?: novel.BookSpecialSubject.Object;
        }
    }

    class BookListReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        sortType: number;
        excludeBooks: TarsStream.List<number>;
        complateState: number;

        toObject(): BookListReq.Object;
        readFromObject(json: BookListReq.Object): BookListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookListReq;
        static create(is: TarsStream.TarsInputStream): BookListReq;
    }

    namespace BookListReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            sortType?: number;
            excludeBooks?: Array<number>;
            complateState?: number;
        }
    }

    class GetBookCategoriesReq {
        tId: novel.UserId;
        categoryIds: TarsStream.List<number>;

        toObject(): GetBookCategoriesReq.Object;
        readFromObject(json: GetBookCategoriesReq.Object): GetBookCategoriesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetBookCategoriesReq;
        static create(is: TarsStream.TarsInputStream): GetBookCategoriesReq;
    }

    namespace GetBookCategoriesReq {
        interface Object {
            tId?: novel.UserId.Object;
            categoryIds?: Array<number>;
        }
    }

    class GetBookByIdReq {
        tId: novel.UserId;
        bookId: number;

        toObject(): GetBookByIdReq.Object;
        readFromObject(json: GetBookByIdReq.Object): GetBookByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetBookByIdReq;
        static create(is: TarsStream.TarsInputStream): GetBookByIdReq;
    }

    namespace GetBookByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
        }
    }

    class ListBookByIdsReq {
        tId: novel.UserId;
        ids: TarsStream.List<number>;

        toObject(): ListBookByIdsReq.Object;
        readFromObject(json: ListBookByIdsReq.Object): ListBookByIdsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBookByIdsReq;
        static create(is: TarsStream.TarsInputStream): ListBookByIdsReq;
    }

    namespace ListBookByIdsReq {
        interface Object {
            tId?: novel.UserId.Object;
            ids?: Array<number>;
        }
    }

    class ListBookCategoriesBySubjectReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        subjectId: string;

        toObject(): ListBookCategoriesBySubjectReq.Object;
        readFromObject(json: ListBookCategoriesBySubjectReq.Object): ListBookCategoriesBySubjectReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBookCategoriesBySubjectReq;
        static create(is: TarsStream.TarsInputStream): ListBookCategoriesBySubjectReq;
    }

    namespace ListBookCategoriesBySubjectReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            subjectId?: string;
        }
    }

    class ListBooksBySubjectReq {
        tId: novel.UserId;
        subjectId: string;
        offset: number;
        size: number;
        sortType: number;
        excludeBooks: TarsStream.List<number>;

        toObject(): ListBooksBySubjectReq.Object;
        readFromObject(json: ListBooksBySubjectReq.Object): ListBooksBySubjectReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBooksBySubjectReq;
        static create(is: TarsStream.TarsInputStream): ListBooksBySubjectReq;
    }

    namespace ListBooksBySubjectReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectId?: string;
            offset?: number;
            size?: number;
            sortType?: number;
            excludeBooks?: Array<number>;
        }
    }

    class ListBooksByCategoryReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        categoryIds: TarsStream.List<number>;
        sortType: number;
        completeState: number;
        excludeBooks: TarsStream.List<number>;

        toObject(): ListBooksByCategoryReq.Object;
        readFromObject(json: ListBooksByCategoryReq.Object): ListBooksByCategoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBooksByCategoryReq;
        static create(is: TarsStream.TarsInputStream): ListBooksByCategoryReq;
    }

    namespace ListBooksByCategoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            categoryIds?: Array<number>;
            sortType?: number;
            completeState?: number;
            excludeBooks?: Array<number>;
        }
    }

    class ListBooksByTagsReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        tagIds: TarsStream.List<number>;
        sortType: number;
        completeState: number;

        toObject(): ListBooksByTagsReq.Object;
        readFromObject(json: ListBooksByTagsReq.Object): ListBooksByTagsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBooksByTagsReq;
        static create(is: TarsStream.TarsInputStream): ListBooksByTagsReq;
    }

    namespace ListBooksByTagsReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            tagIds?: Array<number>;
            sortType?: number;
            completeState?: number;
        }
    }

    class GetBookChapterListReq {
        tId: novel.UserId;
        bookId: number;
        offset: number;
        size: number;
        reverse: number;

        toObject(): GetBookChapterListReq.Object;
        readFromObject(json: GetBookChapterListReq.Object): GetBookChapterListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetBookChapterListReq;
        static create(is: TarsStream.TarsInputStream): GetBookChapterListReq;
    }

    namespace GetBookChapterListReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            offset?: number;
            size?: number;
            reverse?: number;
        }
    }

    class GetBookChapterLinesReq {
        tId: novel.UserId;
        bookId: number;
        chapterId: number;
        offset: number;
        size: number;
        sign: string;

        toObject(): GetBookChapterLinesReq.Object;
        readFromObject(json: GetBookChapterLinesReq.Object): GetBookChapterLinesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetBookChapterLinesReq;
        static create(is: TarsStream.TarsInputStream): GetBookChapterLinesReq;
    }

    namespace GetBookChapterLinesReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            chapterId?: number;
            offset?: number;
            size?: number;
            sign?: string;
        }
    }

    class ListMyBooksFromShelfReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListMyBooksFromShelfReq.Object;
        readFromObject(json: ListMyBooksFromShelfReq.Object): ListMyBooksFromShelfReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListMyBooksFromShelfReq;
        static create(is: TarsStream.TarsInputStream): ListMyBooksFromShelfReq;
    }

    namespace ListMyBooksFromShelfReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class AddBookToShelfReq {
        tId: novel.UserId;
        bookIds: TarsStream.List<number>;

        toObject(): AddBookToShelfReq.Object;
        readFromObject(json: AddBookToShelfReq.Object): AddBookToShelfReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AddBookToShelfReq;
        static create(is: TarsStream.TarsInputStream): AddBookToShelfReq;
    }

    namespace AddBookToShelfReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookIds?: Array<number>;
        }
    }

    class RemoveBookToShelfReq {
        tId: novel.UserId;
        bookIds: TarsStream.List<number>;

        toObject(): RemoveBookToShelfReq.Object;
        readFromObject(json: RemoveBookToShelfReq.Object): RemoveBookToShelfReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RemoveBookToShelfReq;
        static create(is: TarsStream.TarsInputStream): RemoveBookToShelfReq;
    }

    namespace RemoveBookToShelfReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookIds?: Array<number>;
        }
    }

    class ReportBookInteractiveDataReq {
        tId: novel.UserId;
        bookId: number;
        type: number;

        toObject(): ReportBookInteractiveDataReq.Object;
        readFromObject(json: ReportBookInteractiveDataReq.Object): ReportBookInteractiveDataReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReportBookInteractiveDataReq;
        static create(is: TarsStream.TarsInputStream): ReportBookInteractiveDataReq;
    }

    namespace ReportBookInteractiveDataReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            type?: number;
        }
    }

    class SyncBookReadProgressReq {
        tId: novel.UserId;
        bookId: number;
        chapterId: number;
        paragraph: number;
        offset: number;

        toObject(): SyncBookReadProgressReq.Object;
        readFromObject(json: SyncBookReadProgressReq.Object): SyncBookReadProgressReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SyncBookReadProgressReq;
        static create(is: TarsStream.TarsInputStream): SyncBookReadProgressReq;
    }

    namespace SyncBookReadProgressReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            chapterId?: number;
            paragraph?: number;
            offset?: number;
        }
    }

    class SyncBookAudioProgressReq {
        tId: novel.UserId;
        bookId: number;
        chapterId: number;
        paragraph: number;
        audioOffset: number;
        voice: number;

        toObject(): SyncBookAudioProgressReq.Object;
        readFromObject(json: SyncBookAudioProgressReq.Object): SyncBookAudioProgressReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SyncBookAudioProgressReq;
        static create(is: TarsStream.TarsInputStream): SyncBookAudioProgressReq;
    }

    namespace SyncBookAudioProgressReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            chapterId?: number;
            paragraph?: number;
            audioOffset?: number;
            voice?: number;
        }
    }

    class ListUserBookHistoryReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserBookHistoryReq.Object;
        readFromObject(json: ListUserBookHistoryReq.Object): ListUserBookHistoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserBookHistoryReq;
        static create(is: TarsStream.TarsInputStream): ListUserBookHistoryReq;
    }

    namespace ListUserBookHistoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class RemoveBookReadHistoryReq {
        tId: novel.UserId;
        books: TarsStream.List<number>;

        toObject(): RemoveBookReadHistoryReq.Object;
        readFromObject(json: RemoveBookReadHistoryReq.Object): RemoveBookReadHistoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RemoveBookReadHistoryReq;
        static create(is: TarsStream.TarsInputStream): RemoveBookReadHistoryReq;
    }

    namespace RemoveBookReadHistoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            books?: Array<number>;
        }
    }

    class ListRankCategoriesReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListRankCategoriesReq.Object;
        readFromObject(json: ListRankCategoriesReq.Object): ListRankCategoriesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListRankCategoriesReq;
        static create(is: TarsStream.TarsInputStream): ListRankCategoriesReq;
    }

    namespace ListRankCategoriesReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class ListBooksRankByCategoryReq {
        tId: novel.UserId;
        categoryId: number;
        rankType: number;
        offset: number;
        size: number;
        excludeBooks: TarsStream.List<number>;

        toObject(): ListBooksRankByCategoryReq.Object;
        readFromObject(json: ListBooksRankByCategoryReq.Object): ListBooksRankByCategoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBooksRankByCategoryReq;
        static create(is: TarsStream.TarsInputStream): ListBooksRankByCategoryReq;
    }

    namespace ListBooksRankByCategoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            categoryId?: number;
            rankType?: number;
            offset?: number;
            size?: number;
            excludeBooks?: Array<number>;
        }
    }

    class ListAllBookCategoriesReq {
        tId: novel.UserId;
        type: number;
        offset: number;
        size: number;

        toObject(): ListAllBookCategoriesReq.Object;
        readFromObject(json: ListAllBookCategoriesReq.Object): ListAllBookCategoriesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListAllBookCategoriesReq;
        static create(is: TarsStream.TarsInputStream): ListAllBookCategoriesReq;
    }

    namespace ListAllBookCategoriesReq {
        interface Object {
            tId?: novel.UserId.Object;
            type?: number;
            offset?: number;
            size?: number;
        }
    }

    class ListSimilarBooksReq {
        tId: novel.UserId;
        book: number;
        offset: number;
        size: number;

        toObject(): ListSimilarBooksReq.Object;
        readFromObject(json: ListSimilarBooksReq.Object): ListSimilarBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListSimilarBooksReq;
        static create(is: TarsStream.TarsInputStream): ListSimilarBooksReq;
    }

    namespace ListSimilarBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
            book?: number;
            offset?: number;
            size?: number;
        }
    }

    class ListBooksByQualityReq {
        tId: novel.UserId;
        scoreType: number;
        offset: number;
        size: number;
        excludeBooks: TarsStream.List<number>;

        toObject(): ListBooksByQualityReq.Object;
        readFromObject(json: ListBooksByQualityReq.Object): ListBooksByQualityReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBooksByQualityReq;
        static create(is: TarsStream.TarsInputStream): ListBooksByQualityReq;
    }

    namespace ListBooksByQualityReq {
        interface Object {
            tId?: novel.UserId.Object;
            scoreType?: number;
            offset?: number;
            size?: number;
            excludeBooks?: Array<number>;
        }
    }

    class SearchBooksCategoriesTagsRsp {
        bookList: novel.BookList;
        categories: novel.BookCategoryList;
        tags: novel.BookCategoryList;

        toObject(): SearchBooksCategoriesTagsRsp.Object;
        readFromObject(json: SearchBooksCategoriesTagsRsp.Object): SearchBooksCategoriesTagsRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SearchBooksCategoriesTagsRsp;
        static create(is: TarsStream.TarsInputStream): SearchBooksCategoriesTagsRsp;
    }

    namespace SearchBooksCategoriesTagsRsp {
        interface Object {
            bookList?: novel.BookList.Object;
            categories?: novel.BookCategoryList.Object;
            tags?: novel.BookCategoryList.Object;
        }
    }

    class ListAppCategoryTabListReq {
        tId: novel.UserId;

        toObject(): ListAppCategoryTabListReq.Object;
        readFromObject(json: ListAppCategoryTabListReq.Object): ListAppCategoryTabListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListAppCategoryTabListReq;
        static create(is: TarsStream.TarsInputStream): ListAppCategoryTabListReq;
    }

    namespace ListAppCategoryTabListReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class AppSection {
        type: number;
        title: string;
        style: number;
        firstLimit: number;
        showReads: number;
        rankType: number;
        categoryIdForRank: number;
        subjectId: string;
        sortType: number;
        completedState: number;

        toObject(): AppSection.Object;
        readFromObject(json: AppSection.Object): AppSection;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppSection;
        static create(is: TarsStream.TarsInputStream): AppSection;
    }

    namespace AppSection {
        interface Object {
            type?: number;
            title?: string;
            style?: number;
            firstLimit?: number;
            showReads?: number;
            rankType?: number;
            categoryIdForRank?: number;
            subjectId?: string;
            sortType?: number;
            completedState?: number;
        }
    }

    class AppSectionList {
        sections: TarsStream.List<novel.AppSection>;

        toObject(): AppSectionList.Object;
        readFromObject(json: AppSectionList.Object): AppSectionList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppSectionList;
        static create(is: TarsStream.TarsInputStream): AppSectionList;
    }

    namespace AppSectionList {
        interface Object {
            sections?: Array<novel.AppSection.Object>;
        }
    }

    class AppCategoryTab {
        id: number;
        category: number;
        name: string;
        type: number;
        sections: TarsStream.List<novel.AppSection>;

        toObject(): AppCategoryTab.Object;
        readFromObject(json: AppCategoryTab.Object): AppCategoryTab;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppCategoryTab;
        static create(is: TarsStream.TarsInputStream): AppCategoryTab;
    }

    namespace AppCategoryTab {
        interface Object {
            id?: number;
            category?: number;
            name?: string;
            type?: number;
            sections?: Array<novel.AppSection.Object>;
        }
    }

    class AppCategoryTabList {
        tabs: TarsStream.List<novel.AppCategoryTab>;

        toObject(): AppCategoryTabList.Object;
        readFromObject(json: AppCategoryTabList.Object): AppCategoryTabList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AppCategoryTabList;
        static create(is: TarsStream.TarsInputStream): AppCategoryTabList;
    }

    namespace AppCategoryTabList {
        interface Object {
            tabs?: Array<novel.AppCategoryTab.Object>;
        }
    }

    class ListTopicSubjectsWithBooksReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListTopicSubjectsWithBooksReq.Object;
        readFromObject(json: ListTopicSubjectsWithBooksReq.Object): ListTopicSubjectsWithBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListTopicSubjectsWithBooksReq;
        static create(is: TarsStream.TarsInputStream): ListTopicSubjectsWithBooksReq;
    }

    namespace ListTopicSubjectsWithBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class ListCategoryTabSubjectsWithBooksReq {
        tId: novel.UserId;
        categoryId: number;
        offset: number;
        size: number;

        toObject(): ListCategoryTabSubjectsWithBooksReq.Object;
        readFromObject(json: ListCategoryTabSubjectsWithBooksReq.Object): ListCategoryTabSubjectsWithBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListCategoryTabSubjectsWithBooksReq;
        static create(is: TarsStream.TarsInputStream): ListCategoryTabSubjectsWithBooksReq;
    }

    namespace ListCategoryTabSubjectsWithBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
            categoryId?: number;
            offset?: number;
            size?: number;
        }
    }

    class BookSpecialSubjectWithBooksList {
        list: TarsStream.List<novel.BookList>;
        hasMore: boolean;

        toObject(): BookSpecialSubjectWithBooksList.Object;
        readFromObject(json: BookSpecialSubjectWithBooksList.Object): BookSpecialSubjectWithBooksList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BookSpecialSubjectWithBooksList;
        static create(is: TarsStream.TarsInputStream): BookSpecialSubjectWithBooksList;
    }

    namespace BookSpecialSubjectWithBooksList {
        interface Object {
            list?: Array<novel.BookList.Object>;
            hasMore?: boolean;
        }
    }

    class GetRandomSubjectBooksReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): GetRandomSubjectBooksReq.Object;
        readFromObject(json: GetRandomSubjectBooksReq.Object): GetRandomSubjectBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetRandomSubjectBooksReq;
        static create(is: TarsStream.TarsInputStream): GetRandomSubjectBooksReq;
    }

    namespace GetRandomSubjectBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class ListOperatorConfigHotBooksReq {
        tId: novel.UserId;

        toObject(): ListOperatorConfigHotBooksReq.Object;
        readFromObject(json: ListOperatorConfigHotBooksReq.Object): ListOperatorConfigHotBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListOperatorConfigHotBooksReq;
        static create(is: TarsStream.TarsInputStream): ListOperatorConfigHotBooksReq;
    }

    namespace ListOperatorConfigHotBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListOperatorConfigPotentialBooksReq {
        tId: novel.UserId;

        toObject(): ListOperatorConfigPotentialBooksReq.Object;
        readFromObject(json: ListOperatorConfigPotentialBooksReq.Object): ListOperatorConfigPotentialBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListOperatorConfigPotentialBooksReq;
        static create(is: TarsStream.TarsInputStream): ListOperatorConfigPotentialBooksReq;
    }

    namespace ListOperatorConfigPotentialBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListBookPublishChapterIdsReq {
        tId: novel.UserId;
        bookId: number;

        toObject(): ListBookPublishChapterIdsReq.Object;
        readFromObject(json: ListBookPublishChapterIdsReq.Object): ListBookPublishChapterIdsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBookPublishChapterIdsReq;
        static create(is: TarsStream.TarsInputStream): ListBookPublishChapterIdsReq;
    }

    namespace ListBookPublishChapterIdsReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
        }
    }

    class ChapterIdList {
        ids: TarsStream.List<number>;

        toObject(): ChapterIdList.Object;
        readFromObject(json: ChapterIdList.Object): ChapterIdList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ChapterIdList;
        static create(is: TarsStream.TarsInputStream): ChapterIdList;
    }

    namespace ChapterIdList {
        interface Object {
            ids?: Array<number>;
        }
    }

    class ListBookChaptersByIdReq {
        tId: novel.UserId;
        bookId: number;
        chapterIds: TarsStream.List<number>;

        toObject(): ListBookChaptersByIdReq.Object;
        readFromObject(json: ListBookChaptersByIdReq.Object): ListBookChaptersByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListBookChaptersByIdReq;
        static create(is: TarsStream.TarsInputStream): ListBookChaptersByIdReq;
    }

    namespace ListBookChaptersByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            chapterIds?: Array<number>;
        }
    }

    class GetRandomDiscountSubjectBooksReq {
        tId: novel.UserId;
        subjectId: string;
        offset: number;
        size: number;

        toObject(): GetRandomDiscountSubjectBooksReq.Object;
        readFromObject(json: GetRandomDiscountSubjectBooksReq.Object): GetRandomDiscountSubjectBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetRandomDiscountSubjectBooksReq;
        static create(is: TarsStream.TarsInputStream): GetRandomDiscountSubjectBooksReq;
    }

    namespace GetRandomDiscountSubjectBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
            subjectId?: string;
            offset?: number;
            size?: number;
        }
    }

    class GetChapterAudioPlayInfoReq {
        tId: novel.UserId;
        bookId: number;
        chapterId: number;
        voiceType: number;

        toObject(): GetChapterAudioPlayInfoReq.Object;
        readFromObject(json: GetChapterAudioPlayInfoReq.Object): GetChapterAudioPlayInfoReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetChapterAudioPlayInfoReq;
        static create(is: TarsStream.TarsInputStream): GetChapterAudioPlayInfoReq;
    }

    namespace GetChapterAudioPlayInfoReq {
        interface Object {
            tId?: novel.UserId.Object;
            bookId?: number;
            chapterId?: number;
            voiceType?: number;
        }
    }

    class AudioTimeline {
        pid: number;
        start: number;
        end: number;

        toObject(): AudioTimeline.Object;
        readFromObject(json: AudioTimeline.Object): AudioTimeline;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): AudioTimeline;
        static create(is: TarsStream.TarsInputStream): AudioTimeline;
    }

    namespace AudioTimeline {
        interface Object {
            pid?: number;
            start?: number;
            end?: number;
        }
    }

    class ChapterAudioPlayInfo {
        millis: number;
        size: number;
        timelines: TarsStream.List<novel.AudioTimeline>;
        playAuth: string;
        aid: string;

        toObject(): ChapterAudioPlayInfo.Object;
        readFromObject(json: ChapterAudioPlayInfo.Object): ChapterAudioPlayInfo;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ChapterAudioPlayInfo;
        static create(is: TarsStream.TarsInputStream): ChapterAudioPlayInfo;
    }

    namespace ChapterAudioPlayInfo {
        interface Object {
            millis?: number;
            size?: number;
            timelines?: Array<novel.AudioTimeline.Object>;
            playAuth?: string;
            aid?: string;
        }
    }

    class StoryTag {
        id: number;
        type: number;
        name: string;

        toObject(): StoryTag.Object;
        readFromObject(json: StoryTag.Object): StoryTag;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryTag;
        static create(is: TarsStream.TarsInputStream): StoryTag;
    }

    namespace StoryTag {
        interface Object {
            id?: number;
            type?: number;
            name?: string;
        }
    }

    class StoryTags {
        tags: TarsStream.List<novel.StoryTag>;

        toObject(): StoryTags.Object;
        readFromObject(json: StoryTags.Object): StoryTags;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryTags;
        static create(is: TarsStream.TarsInputStream): StoryTags;
    }

    namespace StoryTags {
        interface Object {
            tags?: Array<novel.StoryTag.Object>;
        }
    }

    class StoryIdea {
        id: number;
        name: string;
        image: string;
        description: string;
        genre: novel.StoryTag;
        plots: novel.StoryTags;
        emotions: novel.StoryTags;
        spacetimes: novel.StoryTags;
        locations: novel.StoryTags;

        toObject(): StoryIdea.Object;
        readFromObject(json: StoryIdea.Object): StoryIdea;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryIdea;
        static create(is: TarsStream.TarsInputStream): StoryIdea;
    }

    namespace StoryIdea {
        interface Object {
            id?: number;
            name?: string;
            image?: string;
            description?: string;
            genre?: novel.StoryTag.Object;
            plots?: novel.StoryTags.Object;
            emotions?: novel.StoryTags.Object;
            spacetimes?: novel.StoryTags.Object;
            locations?: novel.StoryTags.Object;
        }
    }

    class StoryIdeaList {
        ideas: TarsStream.List<novel.StoryIdea>;
        hasMore: boolean;

        toObject(): StoryIdeaList.Object;
        readFromObject(json: StoryIdeaList.Object): StoryIdeaList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryIdeaList;
        static create(is: TarsStream.TarsInputStream): StoryIdeaList;
    }

    namespace StoryIdeaList {
        interface Object {
            ideas?: Array<novel.StoryIdea.Object>;
            hasMore?: boolean;
        }
    }

    class StoryCharacter {
        id: number;
        image: string;
        name: string;
        gender: string;
        age: string;
        identity: string;
        personality: string;
        appearance: string;
        dialogue_style: string;
        setting: string;
        motivation_goals: string;
        growth: string;
        plot_affect: string;

        toObject(): StoryCharacter.Object;
        readFromObject(json: StoryCharacter.Object): StoryCharacter;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryCharacter;
        static create(is: TarsStream.TarsInputStream): StoryCharacter;
    }

    namespace StoryCharacter {
        interface Object {
            id?: number;
            image?: string;
            name?: string;
            gender?: string;
            age?: string;
            identity?: string;
            personality?: string;
            appearance?: string;
            dialogue_style?: string;
            setting?: string;
            motivation_goals?: string;
            growth?: string;
            plot_affect?: string;
        }
    }

    class StoryCharacterList {
        characters: TarsStream.List<novel.StoryCharacter>;
        hasMore: boolean;

        toObject(): StoryCharacterList.Object;
        readFromObject(json: StoryCharacterList.Object): StoryCharacterList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryCharacterList;
        static create(is: TarsStream.TarsInputStream): StoryCharacterList;
    }

    namespace StoryCharacterList {
        interface Object {
            characters?: Array<novel.StoryCharacter.Object>;
            hasMore?: boolean;
        }
    }

    class StoryInteractiveData {
        reads: number;
        likes: number;
        parentComments: number;
        stars: number;
        liked: boolean;
        chaptersLikes: number;

        toObject(): StoryInteractiveData.Object;
        readFromObject(json: StoryInteractiveData.Object): StoryInteractiveData;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryInteractiveData;
        static create(is: TarsStream.TarsInputStream): StoryInteractiveData;
    }

    namespace StoryInteractiveData {
        interface Object {
            reads?: number;
            likes?: number;
            parentComments?: number;
            stars?: number;
            liked?: boolean;
            chaptersLikes?: number;
        }
    }

    class StoryReadProgress {
        chapterId: number;

        toObject(): StoryReadProgress.Object;
        readFromObject(json: StoryReadProgress.Object): StoryReadProgress;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryReadProgress;
        static create(is: TarsStream.TarsInputStream): StoryReadProgress;
    }

    namespace StoryReadProgress {
        interface Object {
            chapterId?: number;
        }
    }

    class Story {
        id: number;
        uid: number;
        author: novel.UserBase;
        name: string;
        description: string;
        cover: string;
        storyIdea: novel.StoryIdea;
        plots: novel.StoryTags;
        emotions: novel.StoryTags;
        spaceTimes: novel.StoryTags;
        locations: novel.StoryTags;
        others: string;
        characters: TarsStream.List<novel.StoryCharacter>;
        words: number;
        chapters: number;
        lastUpdateTime: number;
        storyIdata: novel.StoryInteractiveData;
        readProgress: novel.StoryReadProgress;
        completedState: number;
        synopsis: string;
        pubState: number;
        genre: novel.StoryTag;
        contentFrom: number;
        officialPubState: number;
        withoutIdea: number;

        toObject(): Story.Object;
        readFromObject(json: Story.Object): Story;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Story;
        static create(is: TarsStream.TarsInputStream): Story;
    }

    namespace Story {
        interface Object {
            id?: number;
            uid?: number;
            author?: novel.UserBase.Object;
            name?: string;
            description?: string;
            cover?: string;
            storyIdea?: novel.StoryIdea.Object;
            plots?: novel.StoryTags.Object;
            emotions?: novel.StoryTags.Object;
            spaceTimes?: novel.StoryTags.Object;
            locations?: novel.StoryTags.Object;
            others?: string;
            characters?: Array<novel.StoryCharacter.Object>;
            words?: number;
            chapters?: number;
            lastUpdateTime?: number;
            storyIdata?: novel.StoryInteractiveData.Object;
            readProgress?: novel.StoryReadProgress.Object;
            completedState?: number;
            synopsis?: string;
            pubState?: number;
            genre?: novel.StoryTag.Object;
            contentFrom?: number;
            officialPubState?: number;
            withoutIdea?: number;
        }
    }

    class StoryList {
        list: TarsStream.List<novel.Story>;
        hasMore: boolean;

        toObject(): StoryList.Object;
        readFromObject(json: StoryList.Object): StoryList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryList;
        static create(is: TarsStream.TarsInputStream): StoryList;
    }

    namespace StoryList {
        interface Object {
            list?: Array<novel.Story.Object>;
            hasMore?: boolean;
        }
    }

    class StoryChapter {
        id: number;
        bid: number;
        name: string;
        idx: number;
        words: number;
        aiCompleted: number;
        likes: number;
        comments: number;
        reads: number;
        createTime: number;
        liked: boolean;

        toObject(): StoryChapter.Object;
        readFromObject(json: StoryChapter.Object): StoryChapter;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryChapter;
        static create(is: TarsStream.TarsInputStream): StoryChapter;
    }

    namespace StoryChapter {
        interface Object {
            id?: number;
            bid?: number;
            name?: string;
            idx?: number;
            words?: number;
            aiCompleted?: number;
            likes?: number;
            comments?: number;
            reads?: number;
            createTime?: number;
            liked?: boolean;
        }
    }

    class StoryChapterList {
        chapters: TarsStream.List<novel.StoryChapter>;
        hasMore: boolean;

        toObject(): StoryChapterList.Object;
        readFromObject(json: StoryChapterList.Object): StoryChapterList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryChapterList;
        static create(is: TarsStream.TarsInputStream): StoryChapterList;
    }

    namespace StoryChapterList {
        interface Object {
            chapters?: Array<novel.StoryChapter.Object>;
            hasMore?: boolean;
        }
    }

    class StoryChapterLines {
        sid: number;
        cid: number;
        lines: TarsStream.List<string>;

        toObject(): StoryChapterLines.Object;
        readFromObject(json: StoryChapterLines.Object): StoryChapterLines;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryChapterLines;
        static create(is: TarsStream.TarsInputStream): StoryChapterLines;
    }

    namespace StoryChapterLines {
        interface Object {
            sid?: number;
            cid?: number;
            lines?: Array<string>;
        }
    }

    class CreateStoryReq {
        tId: novel.UserId;
        name: string;
        storyIdea: novel.StoryIdea;
        plots: novel.StoryTags;
        emotions: novel.StoryTags;
        spaceTimes: novel.StoryTags;
        locations: novel.StoryTags;
        others: string;
        characters: TarsStream.List<novel.StoryCharacter>;
        withoutIdea: number;

        toObject(): CreateStoryReq.Object;
        readFromObject(json: CreateStoryReq.Object): CreateStoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CreateStoryReq;
        static create(is: TarsStream.TarsInputStream): CreateStoryReq;
    }

    namespace CreateStoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            name?: string;
            storyIdea?: novel.StoryIdea.Object;
            plots?: novel.StoryTags.Object;
            emotions?: novel.StoryTags.Object;
            spaceTimes?: novel.StoryTags.Object;
            locations?: novel.StoryTags.Object;
            others?: string;
            characters?: Array<novel.StoryCharacter.Object>;
            withoutIdea?: number;
        }
    }

    class CreateStoryRsp {
        story: novel.Story;

        toObject(): CreateStoryRsp.Object;
        readFromObject(json: CreateStoryRsp.Object): CreateStoryRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CreateStoryRsp;
        static create(is: TarsStream.TarsInputStream): CreateStoryRsp;
    }

    namespace CreateStoryRsp {
        interface Object {
            story?: novel.Story.Object;
        }
    }

    class ListUserStoriesReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserStoriesReq.Object;
        readFromObject(json: ListUserStoriesReq.Object): ListUserStoriesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserStoriesReq;
        static create(is: TarsStream.TarsInputStream): ListUserStoriesReq;
    }

    namespace ListUserStoriesReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class SseGenerateStoryReq {
        tId: novel.UserId;
        storyId: number;

        toObject(): SseGenerateStoryReq.Object;
        readFromObject(json: SseGenerateStoryReq.Object): SseGenerateStoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryReq;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryReq;
    }

    namespace SseGenerateStoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
        }
    }

    class SseGenerateStoryChapterLinesReq {
        tId: novel.UserId;
        storyId: number;
        chapterId: number;
        nextOption: string;

        toObject(): SseGenerateStoryChapterLinesReq.Object;
        readFromObject(json: SseGenerateStoryChapterLinesReq.Object): SseGenerateStoryChapterLinesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryChapterLinesReq;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryChapterLinesReq;
    }

    namespace SseGenerateStoryChapterLinesReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chapterId?: number;
            nextOption?: string;
        }
    }

    class SseGenerateWtoryReq {
        tId: novel.UserId;
        storyId: number;

        toObject(): SseGenerateWtoryReq.Object;
        readFromObject(json: SseGenerateWtoryReq.Object): SseGenerateWtoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateWtoryReq;
        static create(is: TarsStream.TarsInputStream): SseGenerateWtoryReq;
    }

    namespace SseGenerateWtoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
        }
    }

    class SseGenerateCoverByAiReq {
        tId: novel.UserId;
        storyId: number;

        toObject(): SseGenerateCoverByAiReq.Object;
        readFromObject(json: SseGenerateCoverByAiReq.Object): SseGenerateCoverByAiReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateCoverByAiReq;
        static create(is: TarsStream.TarsInputStream): SseGenerateCoverByAiReq;
    }

    namespace SseGenerateCoverByAiReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
        }
    }

    class SseGenerateCoverByAiRsp {
        type: number;
        cover: string;

        toObject(): SseGenerateCoverByAiRsp.Object;
        readFromObject(json: SseGenerateCoverByAiRsp.Object): SseGenerateCoverByAiRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateCoverByAiRsp;
        static create(is: TarsStream.TarsInputStream): SseGenerateCoverByAiRsp;
    }

    namespace SseGenerateCoverByAiRsp {
        interface Object {
            type?: number;
            cover?: string;
        }
    }

    class StoryChapterNextOptions {
        title: string;
        options: TarsStream.List<string>;

        toObject(): StoryChapterNextOptions.Object;
        readFromObject(json: StoryChapterNextOptions.Object): StoryChapterNextOptions;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): StoryChapterNextOptions;
        static create(is: TarsStream.TarsInputStream): StoryChapterNextOptions;
    }

    namespace StoryChapterNextOptions {
        interface Object {
            title?: string;
            options?: Array<string>;
        }
    }

    class SseGenerateStoryRsp {
        type: number;
        title: string;

        toObject(): SseGenerateStoryRsp.Object;
        readFromObject(json: SseGenerateStoryRsp.Object): SseGenerateStoryRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryRsp;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryRsp;
    }

    namespace SseGenerateStoryRsp {
        interface Object {
            type?: number;
            title?: string;
        }
    }

    class SseGenerateStoryChapterLinesRsp {
        type: number;
        chapterId: number;
        line: string;
        options: novel.StoryChapterNextOptions;
        title: string;

        toObject(): SseGenerateStoryChapterLinesRsp.Object;
        readFromObject(json: SseGenerateStoryChapterLinesRsp.Object): SseGenerateStoryChapterLinesRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryChapterLinesRsp;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryChapterLinesRsp;
    }

    namespace SseGenerateStoryChapterLinesRsp {
        interface Object {
            type?: number;
            chapterId?: number;
            line?: string;
            options?: novel.StoryChapterNextOptions.Object;
            title?: string;
        }
    }

    class SseGenerateWtoryRsp {
        type: number;
        ideaList: novel.StoryIdeaList;

        toObject(): SseGenerateWtoryRsp.Object;
        readFromObject(json: SseGenerateWtoryRsp.Object): SseGenerateWtoryRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateWtoryRsp;
        static create(is: TarsStream.TarsInputStream): SseGenerateWtoryRsp;
    }

    namespace SseGenerateWtoryRsp {
        interface Object {
            type?: number;
            ideaList?: novel.StoryIdeaList.Object;
        }
    }

    class ListStoryIdeaReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        genre: number;

        toObject(): ListStoryIdeaReq.Object;
        readFromObject(json: ListStoryIdeaReq.Object): ListStoryIdeaReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListStoryIdeaReq;
        static create(is: TarsStream.TarsInputStream): ListStoryIdeaReq;
    }

    namespace ListStoryIdeaReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            genre?: number;
        }
    }

    class ListDefaultStoryCharacterReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        gender: string;
        excludes: TarsStream.List<number>;

        toObject(): ListDefaultStoryCharacterReq.Object;
        readFromObject(json: ListDefaultStoryCharacterReq.Object): ListDefaultStoryCharacterReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListDefaultStoryCharacterReq;
        static create(is: TarsStream.TarsInputStream): ListDefaultStoryCharacterReq;
    }

    namespace ListDefaultStoryCharacterReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            gender?: string;
            excludes?: Array<number>;
        }
    }

    class ListUserStoryReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        contentFrom: number;

        toObject(): ListUserStoryReq.Object;
        readFromObject(json: ListUserStoryReq.Object): ListUserStoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserStoryReq;
        static create(is: TarsStream.TarsInputStream): ListUserStoryReq;
    }

    namespace ListUserStoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            contentFrom?: number;
        }
    }

    class ListAvailableIdeaGenreReq {
        tId: novel.UserId;

        toObject(): ListAvailableIdeaGenreReq.Object;
        readFromObject(json: ListAvailableIdeaGenreReq.Object): ListAvailableIdeaGenreReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListAvailableIdeaGenreReq;
        static create(is: TarsStream.TarsInputStream): ListAvailableIdeaGenreReq;
    }

    namespace ListAvailableIdeaGenreReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListDefaultTagsReq {
        tId: novel.UserId;

        toObject(): ListDefaultTagsReq.Object;
        readFromObject(json: ListDefaultTagsReq.Object): ListDefaultTagsReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListDefaultTagsReq;
        static create(is: TarsStream.TarsInputStream): ListDefaultTagsReq;
    }

    namespace ListDefaultTagsReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class DefaultTagsList {
        tagsList: TarsStream.List<novel.StoryTags>;

        toObject(): DefaultTagsList.Object;
        readFromObject(json: DefaultTagsList.Object): DefaultTagsList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DefaultTagsList;
        static create(is: TarsStream.TarsInputStream): DefaultTagsList;
    }

    namespace DefaultTagsList {
        interface Object {
            tagsList?: Array<novel.StoryTags.Object>;
        }
    }

    class ListStoryChaptersReq {
        tId: novel.UserId;
        storyId: number;
        offset: number;
        size: number;
        direction: number;

        toObject(): ListStoryChaptersReq.Object;
        readFromObject(json: ListStoryChaptersReq.Object): ListStoryChaptersReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListStoryChaptersReq;
        static create(is: TarsStream.TarsInputStream): ListStoryChaptersReq;
    }

    namespace ListStoryChaptersReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            offset?: number;
            size?: number;
            direction?: number;
        }
    }

    class ListStoryChapterLinesReq {
        tId: novel.UserId;
        storyId: number;
        chpaterId: number;

        toObject(): ListStoryChapterLinesReq.Object;
        readFromObject(json: ListStoryChapterLinesReq.Object): ListStoryChapterLinesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListStoryChapterLinesReq;
        static create(is: TarsStream.TarsInputStream): ListStoryChapterLinesReq;
    }

    namespace ListStoryChapterLinesReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chpaterId?: number;
        }
    }

    class EditUserStoryReq {
        tId: novel.UserId;
        storyId: number;
        title: string;
        cover: string;
        storyIdea: novel.StoryIdea;
        plots: novel.StoryTags;
        emotions: novel.StoryTags;
        spaceTimes: novel.StoryTags;
        locations: novel.StoryTags;
        others: string;
        description: string;
        completedState: number;
        pubState: number;
        withoutIdea: number;

        toObject(): EditUserStoryReq.Object;
        readFromObject(json: EditUserStoryReq.Object): EditUserStoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): EditUserStoryReq;
        static create(is: TarsStream.TarsInputStream): EditUserStoryReq;
    }

    namespace EditUserStoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            title?: string;
            cover?: string;
            storyIdea?: novel.StoryIdea.Object;
            plots?: novel.StoryTags.Object;
            emotions?: novel.StoryTags.Object;
            spaceTimes?: novel.StoryTags.Object;
            locations?: novel.StoryTags.Object;
            others?: string;
            description?: string;
            completedState?: number;
            pubState?: number;
            withoutIdea?: number;
        }
    }

    class EditStoryChapterReq {
        tId: novel.UserId;
        storyId: number;
        chapterId: number;
        title: string;

        toObject(): EditStoryChapterReq.Object;
        readFromObject(json: EditStoryChapterReq.Object): EditStoryChapterReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): EditStoryChapterReq;
        static create(is: TarsStream.TarsInputStream): EditStoryChapterReq;
    }

    namespace EditStoryChapterReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chapterId?: number;
            title?: string;
        }
    }

    class DeleteStoryChapterReq {
        tId: novel.UserId;
        storyId: number;
        chapterId: number;

        toObject(): DeleteStoryChapterReq.Object;
        readFromObject(json: DeleteStoryChapterReq.Object): DeleteStoryChapterReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DeleteStoryChapterReq;
        static create(is: TarsStream.TarsInputStream): DeleteStoryChapterReq;
    }

    namespace DeleteStoryChapterReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chapterId?: number;
        }
    }

    class EditStoryChapterLinesReq {
        tId: novel.UserId;
        storyId: number;
        chapterId: number;
        lines: TarsStream.List<string>;

        toObject(): EditStoryChapterLinesReq.Object;
        readFromObject(json: EditStoryChapterLinesReq.Object): EditStoryChapterLinesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): EditStoryChapterLinesReq;
        static create(is: TarsStream.TarsInputStream): EditStoryChapterLinesReq;
    }

    namespace EditStoryChapterLinesReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chapterId?: number;
            lines?: Array<string>;
        }
    }

    class ListStoryReq {
        tId: novel.UserId;
        offset: number;
        size: number;
        sort: number;

        toObject(): ListStoryReq.Object;
        readFromObject(json: ListStoryReq.Object): ListStoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListStoryReq;
        static create(is: TarsStream.TarsInputStream): ListStoryReq;
    }

    namespace ListStoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
            sort?: number;
        }
    }

    class GetStoryByIdReq {
        tId: novel.UserId;
        storyId: number;

        toObject(): GetStoryByIdReq.Object;
        readFromObject(json: GetStoryByIdReq.Object): GetStoryByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetStoryByIdReq;
        static create(is: TarsStream.TarsInputStream): GetStoryByIdReq;
    }

    namespace GetStoryByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
        }
    }

    class ImportWtoryChapter {
        title: string;
        content: string;

        toObject(): ImportWtoryChapter.Object;
        readFromObject(json: ImportWtoryChapter.Object): ImportWtoryChapter;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ImportWtoryChapter;
        static create(is: TarsStream.TarsInputStream): ImportWtoryChapter;
    }

    namespace ImportWtoryChapter {
        interface Object {
            title?: string;
            content?: string;
        }
    }

    class ImportWtoryReq {
        tId: novel.UserId;
        title: string;
        chapters: TarsStream.List<novel.ImportWtoryChapter>;
        dataKey: string;

        toObject(): ImportWtoryReq.Object;
        readFromObject(json: ImportWtoryReq.Object): ImportWtoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ImportWtoryReq;
        static create(is: TarsStream.TarsInputStream): ImportWtoryReq;
    }

    namespace ImportWtoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            title?: string;
            chapters?: Array<novel.ImportWtoryChapter.Object>;
            dataKey?: string;
        }
    }

    class ImportWtoryRsp {
        story: novel.Story;

        toObject(): ImportWtoryRsp.Object;
        readFromObject(json: ImportWtoryRsp.Object): ImportWtoryRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ImportWtoryRsp;
        static create(is: TarsStream.TarsInputStream): ImportWtoryRsp;
    }

    namespace ImportWtoryRsp {
        interface Object {
            story?: novel.Story.Object;
        }
    }

    class SyncStoryReadProgressReq {
        tId: novel.UserId;
        storyId: number;
        chapterId: number;

        toObject(): SyncStoryReadProgressReq.Object;
        readFromObject(json: SyncStoryReadProgressReq.Object): SyncStoryReadProgressReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SyncStoryReadProgressReq;
        static create(is: TarsStream.TarsInputStream): SyncStoryReadProgressReq;
    }

    namespace SyncStoryReadProgressReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chapterId?: number;
        }
    }

    class ListUserStoryHistoryReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserStoryHistoryReq.Object;
        readFromObject(json: ListUserStoryHistoryReq.Object): ListUserStoryHistoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserStoryHistoryReq;
        static create(is: TarsStream.TarsInputStream): ListUserStoryHistoryReq;
    }

    namespace ListUserStoryHistoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class RemoveStoryReadHistoryReq {
        tId: novel.UserId;
        storyIds: TarsStream.List<number>;

        toObject(): RemoveStoryReadHistoryReq.Object;
        readFromObject(json: RemoveStoryReadHistoryReq.Object): RemoveStoryReadHistoryReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): RemoveStoryReadHistoryReq;
        static create(is: TarsStream.TarsInputStream): RemoveStoryReadHistoryReq;
    }

    namespace RemoveStoryReadHistoryReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyIds?: Array<number>;
        }
    }

    class SseGenerateStoryPropertyByAiReq {
        tId: novel.UserId;
        storyId: number;
        property: number;

        toObject(): SseGenerateStoryPropertyByAiReq.Object;
        readFromObject(json: SseGenerateStoryPropertyByAiReq.Object): SseGenerateStoryPropertyByAiReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryPropertyByAiReq;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryPropertyByAiReq;
    }

    namespace SseGenerateStoryPropertyByAiReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            property?: number;
        }
    }

    class SseGenerateStoryPropertyByAiRsp {
        type: number;
        words: string;

        toObject(): SseGenerateStoryPropertyByAiRsp.Object;
        readFromObject(json: SseGenerateStoryPropertyByAiRsp.Object): SseGenerateStoryPropertyByAiRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryPropertyByAiRsp;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryPropertyByAiRsp;
    }

    namespace SseGenerateStoryPropertyByAiRsp {
        interface Object {
            type?: number;
            words?: string;
        }
    }

    class SseGenerateStoryWordsByAiReq {
        tId: novel.UserId;
        storyId: number;
        chapterId: number;
        words: string;
        before: string;
        after: string;
        custom: string;
        function: number;

        toObject(): SseGenerateStoryWordsByAiReq.Object;
        readFromObject(json: SseGenerateStoryWordsByAiReq.Object): SseGenerateStoryWordsByAiReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryWordsByAiReq;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryWordsByAiReq;
    }

    namespace SseGenerateStoryWordsByAiReq {
        interface Object {
            tId?: novel.UserId.Object;
            storyId?: number;
            chapterId?: number;
            words?: string;
            before?: string;
            after?: string;
            custom?: string;
            function?: number;
        }
    }

    class SseGenerateStoryWordsByAiRsp {
        type: number;
        words: string;

        toObject(): SseGenerateStoryWordsByAiRsp.Object;
        readFromObject(json: SseGenerateStoryWordsByAiRsp.Object): SseGenerateStoryWordsByAiRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SseGenerateStoryWordsByAiRsp;
        static create(is: TarsStream.TarsInputStream): SseGenerateStoryWordsByAiRsp;
    }

    namespace SseGenerateStoryWordsByAiRsp {
        interface Object {
            type?: number;
            words?: string;
        }
    }

    class GetUserWtoryByIdReq {
        tId: novel.UserId;
        wid: number;

        toObject(): GetUserWtoryByIdReq.Object;
        readFromObject(json: GetUserWtoryByIdReq.Object): GetUserWtoryByIdReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUserWtoryByIdReq;
        static create(is: TarsStream.TarsInputStream): GetUserWtoryByIdReq;
    }

    namespace GetUserWtoryByIdReq {
        interface Object {
            tId?: novel.UserId.Object;
            wid?: number;
        }
    }

    class UserWtory {
        story: novel.Story;
        matchIdeas: TarsStream.List<novel.StoryIdea>;

        toObject(): UserWtory.Object;
        readFromObject(json: UserWtory.Object): UserWtory;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserWtory;
        static create(is: TarsStream.TarsInputStream): UserWtory;
    }

    namespace UserWtory {
        interface Object {
            story?: novel.Story.Object;
            matchIdeas?: Array<novel.StoryIdea.Object>;
        }
    }

    class CountUserStoryByContentFromReq {
        tId: novel.UserId;

        toObject(): CountUserStoryByContentFromReq.Object;
        readFromObject(json: CountUserStoryByContentFromReq.Object): CountUserStoryByContentFromReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CountUserStoryByContentFromReq;
        static create(is: TarsStream.TarsInputStream): CountUserStoryByContentFromReq;
    }

    namespace CountUserStoryByContentFromReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class CountUserStoryContentFromRsp {
        storyCount: number;
        wtoryCount: number;

        toObject(): CountUserStoryContentFromRsp.Object;
        readFromObject(json: CountUserStoryContentFromRsp.Object): CountUserStoryContentFromRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CountUserStoryContentFromRsp;
        static create(is: TarsStream.TarsInputStream): CountUserStoryContentFromRsp;
    }

    namespace CountUserStoryContentFromRsp {
        interface Object {
            storyCount?: number;
            wtoryCount?: number;
        }
    }

    class FirstAigcCompetitionStoryListReq {
        tId: novel.UserId;

        toObject(): FirstAigcCompetitionStoryListReq.Object;
        readFromObject(json: FirstAigcCompetitionStoryListReq.Object): FirstAigcCompetitionStoryListReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): FirstAigcCompetitionStoryListReq;
        static create(is: TarsStream.TarsInputStream): FirstAigcCompetitionStoryListReq;
    }

    namespace FirstAigcCompetitionStoryListReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class OnlineUserInfo {
        sIp: string;
        iPort: number;
        sApp: string;
        uid: number;

        toObject(): OnlineUserInfo.Object;
        readFromObject(json: OnlineUserInfo.Object): OnlineUserInfo;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): OnlineUserInfo;
        static create(is: TarsStream.TarsInputStream): OnlineUserInfo;
    }

    namespace OnlineUserInfo {
        interface Object {
            sIp?: string;
            iPort?: number;
            sApp?: string;
            uid?: number;
        }
    }

    class UserTaskReward {
        spu: novel.GoodsSpu;
        quantity: number;
        insteadSpu: novel.GoodsSpu;
        insteadQuantity: number;
        useInstead: boolean;
        rewardFor: number;

        toObject(): UserTaskReward.Object;
        readFromObject(json: UserTaskReward.Object): UserTaskReward;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserTaskReward;
        static create(is: TarsStream.TarsInputStream): UserTaskReward;
    }

    namespace UserTaskReward {
        interface Object {
            spu?: novel.GoodsSpu.Object;
            quantity?: number;
            insteadSpu?: novel.GoodsSpu.Object;
            insteadQuantity?: number;
            useInstead?: boolean;
            rewardFor?: number;
        }
    }

    class UserTask {
        id: number;
        type: number;
        drawState: number;
        rewards: TarsStream.List<novel.UserTaskReward>;
        finishCount: number;
        currentProcess: number;
        compensateable: number;
        getDay: number;
        drawWay: number;
        readingMinutes: number;
        pkg: novel.RechargePackage;
        postAdTask: number;
        adRewards: TarsStream.List<novel.UserTaskReward>;
        adTaskDrawState: number;
        postAdConfig: string;

        toObject(): UserTask.Object;
        readFromObject(json: UserTask.Object): UserTask;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserTask;
        static create(is: TarsStream.TarsInputStream): UserTask;
    }

    namespace UserTask {
        interface Object {
            id?: number;
            type?: number;
            drawState?: number;
            rewards?: Array<novel.UserTaskReward.Object>;
            finishCount?: number;
            currentProcess?: number;
            compensateable?: number;
            getDay?: number;
            drawWay?: number;
            readingMinutes?: number;
            pkg?: novel.RechargePackage.Object;
            postAdTask?: number;
            adRewards?: Array<novel.UserTaskReward.Object>;
            adTaskDrawState?: number;
            postAdConfig?: string;
        }
    }

    class UserTaskList {
        list: TarsStream.List<novel.UserTask>;
        hasMore: boolean;

        toObject(): UserTaskList.Object;
        readFromObject(json: UserTaskList.Object): UserTaskList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserTaskList;
        static create(is: TarsStream.TarsInputStream): UserTaskList;
    }

    namespace UserTaskList {
        interface Object {
            list?: Array<novel.UserTask.Object>;
            hasMore?: boolean;
        }
    }

    class ListContinuousLogin7DaysTaskReq {
        tId: novel.UserId;

        toObject(): ListContinuousLogin7DaysTaskReq.Object;
        readFromObject(json: ListContinuousLogin7DaysTaskReq.Object): ListContinuousLogin7DaysTaskReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListContinuousLogin7DaysTaskReq;
        static create(is: TarsStream.TarsInputStream): ListContinuousLogin7DaysTaskReq;
    }

    namespace ListContinuousLogin7DaysTaskReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListContinuousLogin7DaysTaskRsp {
        list: novel.UserTaskList;
        continuousDay: number;

        toObject(): ListContinuousLogin7DaysTaskRsp.Object;
        readFromObject(json: ListContinuousLogin7DaysTaskRsp.Object): ListContinuousLogin7DaysTaskRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListContinuousLogin7DaysTaskRsp;
        static create(is: TarsStream.TarsInputStream): ListContinuousLogin7DaysTaskRsp;
    }

    namespace ListContinuousLogin7DaysTaskRsp {
        interface Object {
            list?: novel.UserTaskList.Object;
            continuousDay?: number;
        }
    }

    class GetUndrawTaskRewardCountReq {
        tId: novel.UserId;

        toObject(): GetUndrawTaskRewardCountReq.Object;
        readFromObject(json: GetUndrawTaskRewardCountReq.Object): GetUndrawTaskRewardCountReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetUndrawTaskRewardCountReq;
        static create(is: TarsStream.TarsInputStream): GetUndrawTaskRewardCountReq;
    }

    namespace GetUndrawTaskRewardCountReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class UndrawRewardCountRsp {
        count: number;

        toObject(): UndrawRewardCountRsp.Object;
        readFromObject(json: UndrawRewardCountRsp.Object): UndrawRewardCountRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UndrawRewardCountRsp;
        static create(is: TarsStream.TarsInputStream): UndrawRewardCountRsp;
    }

    namespace UndrawRewardCountRsp {
        interface Object {
            count?: number;
        }
    }

    class DrawRewardByTaskReq {
        tId: novel.UserId;
        taskId: number;

        toObject(): DrawRewardByTaskReq.Object;
        readFromObject(json: DrawRewardByTaskReq.Object): DrawRewardByTaskReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DrawRewardByTaskReq;
        static create(is: TarsStream.TarsInputStream): DrawRewardByTaskReq;
    }

    namespace DrawRewardByTaskReq {
        interface Object {
            tId?: novel.UserId.Object;
            taskId?: number;
        }
    }

    class DrawAnyVipLoginTaskRewardReq {
        tId: novel.UserId;

        toObject(): DrawAnyVipLoginTaskRewardReq.Object;
        readFromObject(json: DrawAnyVipLoginTaskRewardReq.Object): DrawAnyVipLoginTaskRewardReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DrawAnyVipLoginTaskRewardReq;
        static create(is: TarsStream.TarsInputStream): DrawAnyVipLoginTaskRewardReq;
    }

    namespace DrawAnyVipLoginTaskRewardReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class DrawRewardByTaskRsp {
        taskId: number;
        rewards: TarsStream.List<novel.UserTaskReward>;
        retCode: number;
        taskType: number;
        postAdTaskRewards: TarsStream.List<novel.UserTaskReward>;

        toObject(): DrawRewardByTaskRsp.Object;
        readFromObject(json: DrawRewardByTaskRsp.Object): DrawRewardByTaskRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DrawRewardByTaskRsp;
        static create(is: TarsStream.TarsInputStream): DrawRewardByTaskRsp;
    }

    namespace DrawRewardByTaskRsp {
        interface Object {
            taskId?: number;
            rewards?: Array<novel.UserTaskReward.Object>;
            retCode?: number;
            taskType?: number;
            postAdTaskRewards?: Array<novel.UserTaskReward.Object>;
        }
    }

    class DrawRewardByTaskRspList {
        rsps: TarsStream.List<novel.DrawRewardByTaskRsp>;

        toObject(): DrawRewardByTaskRspList.Object;
        readFromObject(json: DrawRewardByTaskRspList.Object): DrawRewardByTaskRspList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DrawRewardByTaskRspList;
        static create(is: TarsStream.TarsInputStream): DrawRewardByTaskRspList;
    }

    namespace DrawRewardByTaskRspList {
        interface Object {
            rsps?: Array<novel.DrawRewardByTaskRsp.Object>;
        }
    }

    class ListDailyTaskReq {
        tId: novel.UserId;

        toObject(): ListDailyTaskReq.Object;
        readFromObject(json: ListDailyTaskReq.Object): ListDailyTaskReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListDailyTaskReq;
        static create(is: TarsStream.TarsInputStream): ListDailyTaskReq;
    }

    namespace ListDailyTaskReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListDailyTaskRsp {
        list: novel.UserTaskList;

        toObject(): ListDailyTaskRsp.Object;
        readFromObject(json: ListDailyTaskRsp.Object): ListDailyTaskRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListDailyTaskRsp;
        static create(is: TarsStream.TarsInputStream): ListDailyTaskRsp;
    }

    namespace ListDailyTaskRsp {
        interface Object {
            list?: novel.UserTaskList.Object;
        }
    }

    class ReportReadingDurationReq {
        tId: novel.UserId;
        readingMinutes: number;

        toObject(): ReportReadingDurationReq.Object;
        readFromObject(json: ReportReadingDurationReq.Object): ReportReadingDurationReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReportReadingDurationReq;
        static create(is: TarsStream.TarsInputStream): ReportReadingDurationReq;
    }

    namespace ReportReadingDurationReq {
        interface Object {
            tId?: novel.UserId.Object;
            readingMinutes?: number;
        }
    }

    class GetInviteCodeBeActivatedTaskReq {
        tId: novel.UserId;

        toObject(): GetInviteCodeBeActivatedTaskReq.Object;
        readFromObject(json: GetInviteCodeBeActivatedTaskReq.Object): GetInviteCodeBeActivatedTaskReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetInviteCodeBeActivatedTaskReq;
        static create(is: TarsStream.TarsInputStream): GetInviteCodeBeActivatedTaskReq;
    }

    namespace GetInviteCodeBeActivatedTaskReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class InviteCodeBeActivatedTask {
        task: novel.UserTask;
        inviteCode: string;

        toObject(): InviteCodeBeActivatedTask.Object;
        readFromObject(json: InviteCodeBeActivatedTask.Object): InviteCodeBeActivatedTask;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): InviteCodeBeActivatedTask;
        static create(is: TarsStream.TarsInputStream): InviteCodeBeActivatedTask;
    }

    namespace InviteCodeBeActivatedTask {
        interface Object {
            task?: novel.UserTask.Object;
            inviteCode?: string;
        }
    }

    class GetActiveInviteCodeTaskReq {
        tId: novel.UserId;

        toObject(): GetActiveInviteCodeTaskReq.Object;
        readFromObject(json: GetActiveInviteCodeTaskReq.Object): GetActiveInviteCodeTaskReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetActiveInviteCodeTaskReq;
        static create(is: TarsStream.TarsInputStream): GetActiveInviteCodeTaskReq;
    }

    namespace GetActiveInviteCodeTaskReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ActiveInviteCodeTaskRsp {
        task: novel.UserTask;

        toObject(): ActiveInviteCodeTaskRsp.Object;
        readFromObject(json: ActiveInviteCodeTaskRsp.Object): ActiveInviteCodeTaskRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ActiveInviteCodeTaskRsp;
        static create(is: TarsStream.TarsInputStream): ActiveInviteCodeTaskRsp;
    }

    namespace ActiveInviteCodeTaskRsp {
        interface Object {
            task?: novel.UserTask.Object;
        }
    }

    class ReportShareTaskProcessReq {
        tId: novel.UserId;

        toObject(): ReportShareTaskProcessReq.Object;
        readFromObject(json: ReportShareTaskProcessReq.Object): ReportShareTaskProcessReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReportShareTaskProcessReq;
        static create(is: TarsStream.TarsInputStream): ReportShareTaskProcessReq;
    }

    namespace ReportShareTaskProcessReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ReportWatchAdTimesReq {
        tId: novel.UserId;
        times: number;

        toObject(): ReportWatchAdTimesReq.Object;
        readFromObject(json: ReportWatchAdTimesReq.Object): ReportWatchAdTimesReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReportWatchAdTimesReq;
        static create(is: TarsStream.TarsInputStream): ReportWatchAdTimesReq;
    }

    namespace ReportWatchAdTimesReq {
        interface Object {
            tId?: novel.UserId.Object;
            times?: number;
        }
    }

    class ReportPostAdTaskProcessReq {
        tId: novel.UserId;
        taskId: number;

        toObject(): ReportPostAdTaskProcessReq.Object;
        readFromObject(json: ReportPostAdTaskProcessReq.Object): ReportPostAdTaskProcessReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReportPostAdTaskProcessReq;
        static create(is: TarsStream.TarsInputStream): ReportPostAdTaskProcessReq;
    }

    namespace ReportPostAdTaskProcessReq {
        interface Object {
            tId?: novel.UserId.Object;
            taskId?: number;
        }
    }

    class QueryUserInviteTaskOverviewReq {
        tId: novel.UserId;

        toObject(): QueryUserInviteTaskOverviewReq.Object;
        readFromObject(json: QueryUserInviteTaskOverviewReq.Object): QueryUserInviteTaskOverviewReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryUserInviteTaskOverviewReq;
        static create(is: TarsStream.TarsInputStream): QueryUserInviteTaskOverviewReq;
    }

    namespace QueryUserInviteTaskOverviewReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class UserInviteTaskOverview {
        inviteUsers: number;
        buyChapterGems: number;
        rechargeGems: number;
        inviteSuccessGems: number;

        toObject(): UserInviteTaskOverview.Object;
        readFromObject(json: UserInviteTaskOverview.Object): UserInviteTaskOverview;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserInviteTaskOverview;
        static create(is: TarsStream.TarsInputStream): UserInviteTaskOverview;
    }

    namespace UserInviteTaskOverview {
        interface Object {
            inviteUsers?: number;
            buyChapterGems?: number;
            rechargeGems?: number;
            inviteSuccessGems?: number;
        }
    }

    class ReportTurnOnPushReq {
        tId: novel.UserId;

        toObject(): ReportTurnOnPushReq.Object;
        readFromObject(json: ReportTurnOnPushReq.Object): ReportTurnOnPushReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ReportTurnOnPushReq;
        static create(is: TarsStream.TarsInputStream): ReportTurnOnPushReq;
    }

    namespace ReportTurnOnPushReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class DrawAiCreatorLoginTaskRewardReq {
        tId: novel.UserId;

        toObject(): DrawAiCreatorLoginTaskRewardReq.Object;
        readFromObject(json: DrawAiCreatorLoginTaskRewardReq.Object): DrawAiCreatorLoginTaskRewardReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): DrawAiCreatorLoginTaskRewardReq;
        static create(is: TarsStream.TarsInputStream): DrawAiCreatorLoginTaskRewardReq;
    }

    namespace DrawAiCreatorLoginTaskRewardReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class SearchBooksReq {
        tId: novel.UserId;
        word: string;
        offset: number;
        size: number;

        toObject(): SearchBooksReq.Object;
        readFromObject(json: SearchBooksReq.Object): SearchBooksReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SearchBooksReq;
        static create(is: TarsStream.TarsInputStream): SearchBooksReq;
    }

    namespace SearchBooksReq {
        interface Object {
            tId?: novel.UserId.Object;
            word?: string;
            offset?: number;
            size?: number;
        }
    }

    class SearchReq {
        tId: novel.UserId;
        word: string;
        offset: number;
        size: number;

        toObject(): SearchReq.Object;
        readFromObject(json: SearchReq.Object): SearchReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): SearchReq;
        static create(is: TarsStream.TarsInputStream): SearchReq;
    }

    namespace SearchReq {
        interface Object {
            tId?: novel.UserId.Object;
            word?: string;
            offset?: number;
            size?: number;
        }
    }

    class GetLatestGreedyCatGameReq {
        tId: novel.UserId;

        toObject(): GetLatestGreedyCatGameReq.Object;
        readFromObject(json: GetLatestGreedyCatGameReq.Object): GetLatestGreedyCatGameReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetLatestGreedyCatGameReq;
        static create(is: TarsStream.TarsInputStream): GetLatestGreedyCatGameReq;
    }

    namespace GetLatestGreedyCatGameReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class GreedyCatBettingItem {
        idx: number;
        odds: number;
        customConfig: string;

        toObject(): GreedyCatBettingItem.Object;
        readFromObject(json: GreedyCatBettingItem.Object): GreedyCatBettingItem;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GreedyCatBettingItem;
        static create(is: TarsStream.TarsInputStream): GreedyCatBettingItem;
    }

    namespace GreedyCatBettingItem {
        interface Object {
            idx?: number;
            odds?: number;
            customConfig?: string;
        }
    }

    class GreedyCatGameConfig {
        gid: number;
        gameStatus: number;
        items: TarsStream.List<novel.GreedyCatBettingItem>;
        bettingAmounts: TarsStream.List<number>;
        bettingStageSeconds: number;
        waitingDrawingSeconds: number;
        attachActivityStart: number;
        attachActivityEnd: number;
        attachActivityPeriod: number;
        officialMessageThreshold: number;

        toObject(): GreedyCatGameConfig.Object;
        readFromObject(json: GreedyCatGameConfig.Object): GreedyCatGameConfig;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GreedyCatGameConfig;
        static create(is: TarsStream.TarsInputStream): GreedyCatGameConfig;
    }

    namespace GreedyCatGameConfig {
        interface Object {
            gid?: number;
            gameStatus?: number;
            items?: Array<novel.GreedyCatBettingItem.Object>;
            bettingAmounts?: Array<number>;
            bettingStageSeconds?: number;
            waitingDrawingSeconds?: number;
            attachActivityStart?: number;
            attachActivityEnd?: number;
            attachActivityPeriod?: number;
            officialMessageThreshold?: number;
        }
    }

    class GreedyCatGameRound {
        gid: number;
        day: string;
        dayRound: number;
        totalRound: number;
        runStatus: number;
        hitOdds: number;
        hitIdx: number;

        toObject(): GreedyCatGameRound.Object;
        readFromObject(json: GreedyCatGameRound.Object): GreedyCatGameRound;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GreedyCatGameRound;
        static create(is: TarsStream.TarsInputStream): GreedyCatGameRound;
    }

    namespace GreedyCatGameRound {
        interface Object {
            gid?: number;
            day?: string;
            dayRound?: number;
            totalRound?: number;
            runStatus?: number;
            hitOdds?: number;
            hitIdx?: number;
        }
    }

    class LatestGreedyCatGameRsp {
        config: novel.GreedyCatGameConfig;
        latestRound: novel.GreedyCatGameRound;

        toObject(): LatestGreedyCatGameRsp.Object;
        readFromObject(json: LatestGreedyCatGameRsp.Object): LatestGreedyCatGameRsp;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LatestGreedyCatGameRsp;
        static create(is: TarsStream.TarsInputStream): LatestGreedyCatGameRsp;
    }

    namespace LatestGreedyCatGameRsp {
        interface Object {
            config?: novel.GreedyCatGameConfig.Object;
            latestRound?: novel.GreedyCatGameRound.Object;
        }
    }

    class getGreedyCatGameConfigReq {
        tId: novel.UserId;

        toObject(): getGreedyCatGameConfigReq.Object;
        readFromObject(json: getGreedyCatGameConfigReq.Object): getGreedyCatGameConfigReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): getGreedyCatGameConfigReq;
        static create(is: TarsStream.TarsInputStream): getGreedyCatGameConfigReq;
    }

    namespace getGreedyCatGameConfigReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class Bet4GreedyCatReq {
        tId: novel.UserId;
        betAmount: number;
        betIdx: number;

        toObject(): Bet4GreedyCatReq.Object;
        readFromObject(json: Bet4GreedyCatReq.Object): Bet4GreedyCatReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): Bet4GreedyCatReq;
        static create(is: TarsStream.TarsInputStream): Bet4GreedyCatReq;
    }

    namespace Bet4GreedyCatReq {
        interface Object {
            tId?: novel.UserId.Object;
            betAmount?: number;
            betIdx?: number;
        }
    }

    class GameRoundOfGreedyCatList {
        rounds: TarsStream.List<novel.GreedyCatGameRound>;
        hasMore: boolean;

        toObject(): GameRoundOfGreedyCatList.Object;
        readFromObject(json: GameRoundOfGreedyCatList.Object): GameRoundOfGreedyCatList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GameRoundOfGreedyCatList;
        static create(is: TarsStream.TarsInputStream): GameRoundOfGreedyCatList;
    }

    namespace GameRoundOfGreedyCatList {
        interface Object {
            rounds?: Array<novel.GreedyCatGameRound.Object>;
            hasMore?: boolean;
        }
    }

    class ListLatestRoundOfGreedyCatReq {
        tId: novel.UserId;
        size: number;

        toObject(): ListLatestRoundOfGreedyCatReq.Object;
        readFromObject(json: ListLatestRoundOfGreedyCatReq.Object): ListLatestRoundOfGreedyCatReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListLatestRoundOfGreedyCatReq;
        static create(is: TarsStream.TarsInputStream): ListLatestRoundOfGreedyCatReq;
    }

    namespace ListLatestRoundOfGreedyCatReq {
        interface Object {
            tId?: novel.UserId.Object;
            size?: number;
        }
    }

    class ListUserBetFlowOfGreedyCatReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserBetFlowOfGreedyCatReq.Object;
        readFromObject(json: ListUserBetFlowOfGreedyCatReq.Object): ListUserBetFlowOfGreedyCatReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserBetFlowOfGreedyCatReq;
        static create(is: TarsStream.TarsInputStream): ListUserBetFlowOfGreedyCatReq;
    }

    namespace ListUserBetFlowOfGreedyCatReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class UserBetFlowOfGreedyCat {
        totalRound: number;
        dayRound: number;
        day: string;
        flowType: number;
        betAmount: number;
        betIdx: number;
        betOdds: number;
        hitAmount: number;
        createTime: number;

        toObject(): UserBetFlowOfGreedyCat.Object;
        readFromObject(json: UserBetFlowOfGreedyCat.Object): UserBetFlowOfGreedyCat;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserBetFlowOfGreedyCat;
        static create(is: TarsStream.TarsInputStream): UserBetFlowOfGreedyCat;
    }

    namespace UserBetFlowOfGreedyCat {
        interface Object {
            totalRound?: number;
            dayRound?: number;
            day?: string;
            flowType?: number;
            betAmount?: number;
            betIdx?: number;
            betOdds?: number;
            hitAmount?: number;
            createTime?: number;
        }
    }

    class UserBetFlowOfGreedyCatList {
        flows: TarsStream.List<novel.UserBetFlowOfGreedyCat>;
        hasMore: boolean;

        toObject(): UserBetFlowOfGreedyCatList.Object;
        readFromObject(json: UserBetFlowOfGreedyCatList.Object): UserBetFlowOfGreedyCatList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserBetFlowOfGreedyCatList;
        static create(is: TarsStream.TarsInputStream): UserBetFlowOfGreedyCatList;
    }

    namespace UserBetFlowOfGreedyCatList {
        interface Object {
            flows?: Array<novel.UserBetFlowOfGreedyCat.Object>;
            hasMore?: boolean;
        }
    }

    class PrizeWheelOption {
        amount: number;
        times: number;

        toObject(): PrizeWheelOption.Object;
        readFromObject(json: PrizeWheelOption.Object): PrizeWheelOption;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PrizeWheelOption;
        static create(is: TarsStream.TarsInputStream): PrizeWheelOption;
    }

    namespace PrizeWheelOption {
        interface Object {
            amount?: number;
            times?: number;
        }
    }

    class PrizeWheelItem {
        idx: number;
        spu: novel.GoodsSpu;
        value: number;
        labelValue: number;

        toObject(): PrizeWheelItem.Object;
        readFromObject(json: PrizeWheelItem.Object): PrizeWheelItem;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PrizeWheelItem;
        static create(is: TarsStream.TarsInputStream): PrizeWheelItem;
    }

    namespace PrizeWheelItem {
        interface Object {
            idx?: number;
            spu?: novel.GoodsSpu.Object;
            value?: number;
            labelValue?: number;
        }
    }

    class PrizeWheelConfig {
        gid: number;
        gameStatus: number;
        items: TarsStream.List<novel.PrizeWheelItem>;
        options: TarsStream.List<novel.PrizeWheelOption>;
        officialMessageThreshold: number;

        toObject(): PrizeWheelConfig.Object;
        readFromObject(json: PrizeWheelConfig.Object): PrizeWheelConfig;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PrizeWheelConfig;
        static create(is: TarsStream.TarsInputStream): PrizeWheelConfig;
    }

    namespace PrizeWheelConfig {
        interface Object {
            gid?: number;
            gameStatus?: number;
            items?: Array<novel.PrizeWheelItem.Object>;
            options?: Array<novel.PrizeWheelOption.Object>;
            officialMessageThreshold?: number;
        }
    }

    class GetPrizeWheelReq {
        tId: novel.UserId;

        toObject(): GetPrizeWheelReq.Object;
        readFromObject(json: GetPrizeWheelReq.Object): GetPrizeWheelReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): GetPrizeWheelReq;
        static create(is: TarsStream.TarsInputStream): GetPrizeWheelReq;
    }

    namespace GetPrizeWheelReq {
        interface Object {
            tId?: novel.UserId.Object;
        }
    }

    class ListLatestPrizeWheelBigBonusReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListLatestPrizeWheelBigBonusReq.Object;
        readFromObject(json: ListLatestPrizeWheelBigBonusReq.Object): ListLatestPrizeWheelBigBonusReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListLatestPrizeWheelBigBonusReq;
        static create(is: TarsStream.TarsInputStream): ListLatestPrizeWheelBigBonusReq;
    }

    namespace ListLatestPrizeWheelBigBonusReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class LatestPrizeWheelBigBonus {
        hitUser: novel.UserBase;
        hitItem: novel.PrizeWheelItem;
        quantity: number;

        toObject(): LatestPrizeWheelBigBonus.Object;
        readFromObject(json: LatestPrizeWheelBigBonus.Object): LatestPrizeWheelBigBonus;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LatestPrizeWheelBigBonus;
        static create(is: TarsStream.TarsInputStream): LatestPrizeWheelBigBonus;
    }

    namespace LatestPrizeWheelBigBonus {
        interface Object {
            hitUser?: novel.UserBase.Object;
            hitItem?: novel.PrizeWheelItem.Object;
            quantity?: number;
        }
    }

    class LatestPrizeWheelBigBonusList {
        list: TarsStream.List<novel.LatestPrizeWheelBigBonus>;

        toObject(): LatestPrizeWheelBigBonusList.Object;
        readFromObject(json: LatestPrizeWheelBigBonusList.Object): LatestPrizeWheelBigBonusList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): LatestPrizeWheelBigBonusList;
        static create(is: TarsStream.TarsInputStream): LatestPrizeWheelBigBonusList;
    }

    namespace LatestPrizeWheelBigBonusList {
        interface Object {
            list?: Array<novel.LatestPrizeWheelBigBonus.Object>;
        }
    }

    class ListUserLatestPrizeWheelHitFlowReq {
        tId: novel.UserId;
        offset: number;
        size: number;

        toObject(): ListUserLatestPrizeWheelHitFlowReq.Object;
        readFromObject(json: ListUserLatestPrizeWheelHitFlowReq.Object): ListUserLatestPrizeWheelHitFlowReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserLatestPrizeWheelHitFlowReq;
        static create(is: TarsStream.TarsInputStream): ListUserLatestPrizeWheelHitFlowReq;
    }

    namespace ListUserLatestPrizeWheelHitFlowReq {
        interface Object {
            tId?: novel.UserId.Object;
            offset?: number;
            size?: number;
        }
    }

    class PrizeWheelFlow {
        totalRound: number;
        dayRound: number;
        day: string;
        flowType: number;
        betAmount: number;
        betTimes: number;
        hitItem: novel.PrizeWheelItem;
        quantity: number;
        createTime: number;

        toObject(): PrizeWheelFlow.Object;
        readFromObject(json: PrizeWheelFlow.Object): PrizeWheelFlow;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): PrizeWheelFlow;
        static create(is: TarsStream.TarsInputStream): PrizeWheelFlow;
    }

    namespace PrizeWheelFlow {
        interface Object {
            totalRound?: number;
            dayRound?: number;
            day?: string;
            flowType?: number;
            betAmount?: number;
            betTimes?: number;
            hitItem?: novel.PrizeWheelItem.Object;
            quantity?: number;
            createTime?: number;
        }
    }

    class UserPrizeWheelHitFlowList {
        list: TarsStream.List<novel.PrizeWheelFlow>;
        hasMore: boolean;

        toObject(): UserPrizeWheelHitFlowList.Object;
        readFromObject(json: UserPrizeWheelHitFlowList.Object): UserPrizeWheelHitFlowList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserPrizeWheelHitFlowList;
        static create(is: TarsStream.TarsInputStream): UserPrizeWheelHitFlowList;
    }

    namespace UserPrizeWheelHitFlowList {
        interface Object {
            list?: Array<novel.PrizeWheelFlow.Object>;
            hasMore?: boolean;
        }
    }

    class BetPrizeWheelReq {
        tId: novel.UserId;
        betAmount: number;

        toObject(): BetPrizeWheelReq.Object;
        readFromObject(json: BetPrizeWheelReq.Object): BetPrizeWheelReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): BetPrizeWheelReq;
        static create(is: TarsStream.TarsInputStream): BetPrizeWheelReq;
    }

    namespace BetPrizeWheelReq {
        interface Object {
            tId?: novel.UserId.Object;
            betAmount?: number;
        }
    }

    class UserGemBill {
        id: number;
        uid: number;
        amount: number;
        retain: number;
        getTime: number;
        expireTime: number;
        bizId: number;
        taskBase: novel.UserTask;

        toObject(): UserGemBill.Object;
        readFromObject(json: UserGemBill.Object): UserGemBill;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserGemBill;
        static create(is: TarsStream.TarsInputStream): UserGemBill;
    }

    namespace UserGemBill {
        interface Object {
            id?: number;
            uid?: number;
            amount?: number;
            retain?: number;
            getTime?: number;
            expireTime?: number;
            bizId?: number;
            taskBase?: novel.UserTask.Object;
        }
    }

    class UserGemBillList {
        bills: TarsStream.List<novel.UserGemBill>;
        hasMore: boolean;

        toObject(): UserGemBillList.Object;
        readFromObject(json: UserGemBillList.Object): UserGemBillList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserGemBillList;
        static create(is: TarsStream.TarsInputStream): UserGemBillList;
    }

    namespace UserGemBillList {
        interface Object {
            bills?: Array<novel.UserGemBill.Object>;
            hasMore?: boolean;
        }
    }

    class CoinRechargeFlow {
        id: string;
        amount: number;
        billType: number;
        bizId: number;
        creatTime: number;
        gameId: number;
        taskBase: novel.UserTask;

        toObject(): CoinRechargeFlow.Object;
        readFromObject(json: CoinRechargeFlow.Object): CoinRechargeFlow;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CoinRechargeFlow;
        static create(is: TarsStream.TarsInputStream): CoinRechargeFlow;
    }

    namespace CoinRechargeFlow {
        interface Object {
            id?: string;
            amount?: number;
            billType?: number;
            bizId?: number;
            creatTime?: number;
            gameId?: number;
            taskBase?: novel.UserTask.Object;
        }
    }

    class CoinRechargeFlowList {
        flows: TarsStream.List<novel.CoinRechargeFlow>;
        hasMore: boolean;

        toObject(): CoinRechargeFlowList.Object;
        readFromObject(json: CoinRechargeFlowList.Object): CoinRechargeFlowList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): CoinRechargeFlowList;
        static create(is: TarsStream.TarsInputStream): CoinRechargeFlowList;
    }

    namespace CoinRechargeFlowList {
        interface Object {
            flows?: Array<novel.CoinRechargeFlow.Object>;
            hasMore?: boolean;
        }
    }

    class UserAipBill {
        id: number;
        uid: number;
        amount: number;
        retain: number;
        getTime: number;
        expireTime: number;
        bizId: number;
        taskBase: novel.UserTask;
        billType: number;
        function: number;
        consumeTime: number;

        toObject(): UserAipBill.Object;
        readFromObject(json: UserAipBill.Object): UserAipBill;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserAipBill;
        static create(is: TarsStream.TarsInputStream): UserAipBill;
    }

    namespace UserAipBill {
        interface Object {
            id?: number;
            uid?: number;
            amount?: number;
            retain?: number;
            getTime?: number;
            expireTime?: number;
            bizId?: number;
            taskBase?: novel.UserTask.Object;
            billType?: number;
            function?: number;
            consumeTime?: number;
        }
    }

    class UserAipBillList {
        bills: TarsStream.List<novel.UserAipBill>;
        hasMore: boolean;

        toObject(): UserAipBillList.Object;
        readFromObject(json: UserAipBillList.Object): UserAipBillList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserAipBillList;
        static create(is: TarsStream.TarsInputStream): UserAipBillList;
    }

    namespace UserAipBillList {
        interface Object {
            bills?: Array<novel.UserAipBill.Object>;
            hasMore?: boolean;
        }
    }

    class QueryCurrentActivityInfoReq {
        tId: novel.UserId;
        activity: number;

        toObject(): QueryCurrentActivityInfoReq.Object;
        readFromObject(json: QueryCurrentActivityInfoReq.Object): QueryCurrentActivityInfoReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QueryCurrentActivityInfoReq;
        static create(is: TarsStream.TarsInputStream): QueryCurrentActivityInfoReq;
    }

    namespace QueryCurrentActivityInfoReq {
        interface Object {
            tId?: novel.UserId.Object;
            activity?: number;
        }
    }

    class ActivityExtra4InviteUser {
        initGem: number;
        gemPerUser: number;
        maxGem: number;

        toObject(): ActivityExtra4InviteUser.Object;
        readFromObject(json: ActivityExtra4InviteUser.Object): ActivityExtra4InviteUser;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ActivityExtra4InviteUser;
        static create(is: TarsStream.TarsInputStream): ActivityExtra4InviteUser;
    }

    namespace ActivityExtra4InviteUser {
        interface Object {
            initGem?: number;
            gemPerUser?: number;
            maxGem?: number;
        }
    }

    class ActivityInfo {
        id: number;
        extra: string;
        currentPeriod: number;
        currentPeriodStart: number;
        currentPeriodEnd: number;
        poolAmount: number;

        toObject(): ActivityInfo.Object;
        readFromObject(json: ActivityInfo.Object): ActivityInfo;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ActivityInfo;
        static create(is: TarsStream.TarsInputStream): ActivityInfo;
    }

    namespace ActivityInfo {
        interface Object {
            id?: number;
            extra?: string;
            currentPeriod?: number;
            currentPeriodStart?: number;
            currentPeriodEnd?: number;
            poolAmount?: number;
        }
    }

    class ListUserGrowableRankReq {
        tId: novel.UserId;
        rankType: number;
        period: number;
        listSize: number;
        maxSeq: number;

        toObject(): ListUserGrowableRankReq.Object;
        readFromObject(json: ListUserGrowableRankReq.Object): ListUserGrowableRankReq;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): ListUserGrowableRankReq;
        static create(is: TarsStream.TarsInputStream): ListUserGrowableRankReq;
    }

    namespace ListUserGrowableRankReq {
        interface Object {
            tId?: novel.UserId.Object;
            rankType?: number;
            period?: number;
            listSize?: number;
            maxSeq?: number;
        }
    }

    class UserGrowableRankItem {
        user: novel.UserBase;
        increment: number;
        sequence: number;

        toObject(): UserGrowableRankItem.Object;
        readFromObject(json: UserGrowableRankItem.Object): UserGrowableRankItem;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserGrowableRankItem;
        static create(is: TarsStream.TarsInputStream): UserGrowableRankItem;
    }

    namespace UserGrowableRankItem {
        interface Object {
            user?: novel.UserBase.Object;
            increment?: number;
            sequence?: number;
        }
    }

    class UserGrowableRankList {
        rank: TarsStream.List<novel.UserGrowableRankItem>;
        myRank: novel.UserGrowableRankItem;

        toObject(): UserGrowableRankList.Object;
        readFromObject(json: UserGrowableRankList.Object): UserGrowableRankList;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): UserGrowableRankList;
        static create(is: TarsStream.TarsInputStream): UserGrowableRankList;
    }

    namespace UserGrowableRankList {
        interface Object {
            rank?: Array<novel.UserGrowableRankItem.Object>;
            myRank?: novel.UserGrowableRankItem.Object;
        }
    }

    class QuerySort {
        field: string;
        direction: number;

        toObject(): QuerySort.Object;
        readFromObject(json: QuerySort.Object): QuerySort;
        toBinBuffer(): TarsStream.BinBuffer;
        static new(): QuerySort;
        static create(is: TarsStream.TarsInputStream): QuerySort;
    }

    namespace QuerySort {
        interface Object {
            field?: string;
            direction?: number;
        }
    }

}


