export default {
    "index": "Rank",
    "nickname": "Name",
    "invite_num": "Invited readers count",
    "expect_give": "Expected gem rewards",
    "total_reward": "The current gem prize pool",
    "recommend_rank_title": "StoryGo Ambassadors Rank",
    "my_success_invite": "I have successfully invited",
    "my_rank": "My rank",
    "undefined": "My anticipated gem reward",
    "recommend_rank_rule_title": "Rule details",
    "recommend_rank_rule_1": "1.Share your favorite books or invite friends with your referral link on social media. If a new user, who hasn't used StoryGo before, signs up through your link, unlocks 5 chapters, you've successfully invited one new reader. Note: App version 1.8+ required.",
    "rule_how_to_share": "How to Share Your Favorite Books--〉〉",
    "rule_how_to_invite": "How to View/Find Your Invitation Link--〉〉",
    "recommend_rank_rule_2": "2.The event starts with a prize pool of 1400 gems. For each new user successfully invited, the pool increases by 10 gems, up to a maximum of 50,000 gems.",
    "recommend_rank_rule_3": "3.The event will rank ambassadors based on the number of new users they invite. The top 20 ambassadors will split the prize pool as follows:\n.1st Place: Receives 30% of the prize pool in gems.\n.2nd Place: Receives 20% of the prize pool in gems\n.3rd Place: Receives 10% of the prize pool in gems\n.4-10th Place: Each person receives 4% of the prize pool in gems.\n.11-20th Place: Each person receives 1.2% of the prize pool in gems.\nThe above rewards will be distributed after the event concludes",
    "recommend_rank_rule_4": "4. Any form of forgery, cheating, or data manipulation is strictly prohibited in this event. Once detected, the participant will be disqualified. The final interpretation of this event belongs to StoryGo.",
    "act_rule_btn": "Check the rule details",
    "act_time": "Time:",
    "recommend_rank_page_title": "StoryGo Ambassadors Rank",
    "recommend_part1_title": "Part 1:Recommend Great Books, Win Gems!",
    "recommend_part1_desc": "Share the books you'd recommend most to friends on Facebook. Add your personal endorsement, tag @Storygo Publish, and if it meets our criteria, earn gem rewards!",
    "how_to_join": "How to Participate?",
    "recommend_part1_tips1": "1.Open your favorite book, then just hit the share button in the top-right corner and pick Facebook or Twitter.",
    "recommend_part1_tips2": "2.Share and tag @Storygo Publish in your post or comments. (Note: Rewards are only eligible for publicly shared posts.)",
    "recommend_part1_tips3": "3.During the event, if your post receives over 30 likes, you'll earn 50 gems. Each book or post is eligible for rewards only once, with a maximum of 200 gems per person. Rewards will be distributed within 3 working days after the event concludes.",
    "recommend_part2_title": "Part 2:Bring in new readers, split the big prize!  ",
    "recommend_part2_desc": "If you bring a new user to StoryGo through your shared book or registration invite link, and they unlock any 5 premium chapters, you can participate in the StoryGo Ambassadors Rank and split the big gems prize!",
    "recommend_part2_tips1": "1.Share your favorite books or your friend invitation link on social media. Requires app upgrade to version 1.8 or above.",
    "recommend_part2_tips2": "2.If a new user, who has never used StoryGo before, downloads and registers on StoryGo through your link, and unlocks any 5 chapters, we will consider that you have successfully invited one new reader.",
    "recommend_part2_tips3": "3.Each user can participate in the ranking based on the number of new readers successfully invited. The top 20 will share the grand gem reward.",
    "recommend_part2_tips4": "4.StoryGo reserves the right for final interpretation of the event. In case of cheating or fraudulent behavior to manipulate rankings, StoryGo reserves the right to disqualify participants from the event.",
    "see_rank": "Check the Ambassadors Rank",
    "share_act": "Share the event",
    "join_watsapp": "Join WhatsApp group",
    "recommend_page_title": "Be StoryGo Ambassadors,Win Big Rewards! ",
    "recommend_banner_title": "Be StoryGo Ambassadors,Win Big Rewards! ",
    "how_to_share_title": "How to share your favorite books",
    "how_to_share_content_1": "Open your favorite book and simply tap the share button located in the top-right corner.",
    "how_to_share_content_2": "Or, open a book, then long-press a paragraph to select it, and share it on social media."
}