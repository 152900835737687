// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_42R3jlBYtJWGJ5oAobHAW9twWCfVR3U",
    authDomain: "storygo-web.firebaseapp.com",
    projectId: "storygo-web",
    storageBucket: "storygo-web.appspot.com",
    messagingSenderId: "353016585083",
    appId: "1:353016585083:web:cad78f5f613a175dd3fbde",
    measurementId: "G-2FY5J9LFB5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
window.firebaseLogEvent = (name: string, value: Record<string, any>) => {
    console.log('LogEvent=>', name, value)
}
export const analytics = () => {
    const analytics =  getAnalytics(app);

    window.firebaseLogEvent = (name: string, value: Record<string, any>) => {
        if(window.isApp) return;
        logEvent(analytics, name, {
            ...value
        })
    }
}

