import {createApp, createVNode, mergeProps, getCurrentInstance, reactive, ref} from 'vue';
import Toast from './index.vue'
type ToastType = "success" | "warning"
export const showToast = (message:string, type?: ToastType, duration = 3000) => {
    const el = document.createElement('div');
    const state = reactive({
        visible: false
    })

    const app = createApp({
        setup() {
            const render = () => {
                return createVNode(Toast, mergeProps(state, {
                    message,
                    type,
                    onAfterLeave(){
                        app.unmount()
                        el.remove()
                    }
                }))
            }
            const instance = getCurrentInstance()
            if(!instance) return ;
            //@ts-ignore
            instance.render = render
            return {}
        }
    }, {

    })
    app.mount(el)
    document.body.append(el)
    // 解决闪的问题
    setTimeout(() => {
        state.visible = true
    }, 100)
    setTimeout(() => {
        state.visible = false
    }, duration)
}
