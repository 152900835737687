<template>
  <div class="container" v-if="items[0].length">
    <div class="title">
      {{ title }}
    </div>
    <div class="content no-scrollbar">
      <div class="column" v-for="(chunk, index) in items" :key="index">
        <div v-for="(item, chunkChunk) in chunk" :key="chunkChunk" class="card-item"
             :class="['item-bg-'+ ((index * chunk.length) + chunkChunk)]"
             @click="clickItem(item)">
          <div class="items-center">
            <van-skeleton class="cover" :loading="loading">
              <a style="display: none;" rel="canonical" :href="'https://www.storygo.cc/genre/'+item.name+ '_' + item.id" :title="item.name"></a>
              <Image class="cover no-event not-flip" lazy-load fit="cover" rel="canonical" :src="getSrc(item.cover)"  :alt="item.name+'-StoryGo'"></Image>
            </van-skeleton>

            <div class="item-info">
              <van-skeleton   :row="1" style="width: 100%;"  :loading="loading">
                <div class="ellipsis2 card-title">{{ item.name }}</div>
              </van-skeleton>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Image} from "vant"
import {novel} from "@storygo/types/novel_baseTars.d";
import {getBookUrl, getSrc} from "@storygo/utils";

interface Props {
  items: Array<novel.BookCategory[]>
  title: string
  loading: boolean
  placeHolderSize: number
}

// import {} from "vue"
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  items = [] as Array<novel.BookCategory[]>,
  title = '',
  loading = false,
  placeHolderSize = 0
} = defineProps<Props>()
const clickItemEvent = defineEmits<{
  (e: 'clickItem', item: novel.BookCategory): void
}>()
const clickItem = (item: novel.BookCategory) => {
  clickItemEvent('clickItem', item)
}


</script>
<style scoped lang="less" src="./index.less"></style>
