export default {
    "index": "Clasificación",
    "nickname": "Nombre",
    "invite_num": "Recuento de Invitados",
    "expect_give": "Recompensa de Gemas Anticipada",
    "total_reward": "Piscina de Premios de Gemas",
    "recommend_rank_title": "Clasificación de Embajadores de StoryGo",
    "my_success_invite": "He Invitado",
    "my_rank": "Mi rango",
    "undefined": "Mi Recompensa Anticipada",
    "recommend_rank_rule_title": "Detalles de la regla",
    "recommend_rank_rule_1": "1.Comparte tus libros favoritos o invita a tus amigos con tu enlace de referencia en redes sociales. Si un nuevo usuario, que no ha utilizado StoryGo antes, se registra a través de tu enlace, desbloquea 5 capítulos, has invitado con éxito a un nuevo lector. Nota: Se requiere la versión de la aplicación 1.8+.",
    "rule_how_to_share": "Cómo Compartir tus Libros Favoritos--〉〉",
    "rule_how_to_invite": "Cómo Ver/Encontrar tu Enlace de Invitación--〉〉",
    "recommend_rank_rule_2": "2.El evento comienza con un pozo de premios de 1400 gemas. Por cada nuevo usuario invitado con éxito, el pozo aumenta en 10 gemas, hasta un máximo de 50,000 gemas. ",
    "recommend_rank_rule_3": "3.El evento clasificará a los embajadores según el número de nuevos usuarios que inviten. Los 20 principales embajadores dividirán el pozo de premios de la siguiente manera:\n·1er Lugar: Recibe el 30% del pozo de premios en gemas.\n·2do Lugar: Recibe el 20% del pozo de premios en gemas.\n·3er Lugar: Recibe el 10% del pozo de premios en gemas.\n·4-10 Lugar: Cada persona recibe el 4% del pozo de premios en gemas.\n·11-20 Lugar: Cada persona recibe el 1.2% del pozo de premios en gemas.\nLas recompensas mencionadas anteriormente se distribuirán después de que concluya el evento. ",
    "recommend_rank_rule_4": "4.Cualquier forma de falsificación, trampa o manipulación de datos está estrictamente prohibida en este evento. Una vez detectada, el participante será descalificado. La interpretación final de este evento pertenece a StoryGo.",
    "act_rule_btn": "Detalles de la Regla",
    "act_time": "Tiempo",
    "recommend_rank_page_title": "Clasificación de Embajadores de StoryGo",
    "recommend_part1_title": "Parte 1: Recomienda Libros, Gana Gemas",
    "recommend_part1_desc": "Comparte los libros que más recomendarías a tus amigos en Facebook. Añade tu recomendación personal, etiqueta a @Storygo Publish y, si cumple con nuestros criterios, ¡gana recompensas de gemas!",
    "how_to_join": "¿Cómo Participar?",
    "recommend_part1_tips1": "1.Abre tu libro favorito, luego simplemente presiona el botón de compartir en la esquina superior derecha y elige Facebook o Twitter.",
    "recommend_part1_tips2": "2.Comparte y etiqueta a @Storygo Publish en tu publicación o comentarios. (Nota: Las recompensas solo son elegibles para publicaciones compartidas públicamente.)",
    "recommend_part1_tips3": "3.Durante el evento, si tu publicación recibe más de 30 me gusta, ganarás 50 gemas. Cada libro o publicación es elegible para recompensas solo una vez, con un máximo de 200 gemas por persona. Las recompensas se distribuirán dentro de los 3 días hábiles posteriores al final del evento.",
    "recommend_part2_title": "Parte 2: Atrae Lectores, Reparte Premios",
    "recommend_part2_desc": "Si traes a un nuevo usuario a StoryGo a través de tu libro compartido o enlace de invitación de registro, y desbloquean cualquier 5 capítulos premium, ¡puedes participar en la Clasificación de Embajadores de StoryGo y repartir el gran premio de gemas!",
    "recommend_part2_tips1": "1.Comparte tus libros favoritos o el enlace de invitación de tus amigos en las redes sociales. Requiere una actualización de la aplicación a la versión 1.8 o superior.",
    "recommend_part2_tips2": "2.Si un nuevo usuario, que nunca ha utilizado StoryGo antes, descarga y se registra en StoryGo a través de tu enlace, y desbloquea cualquier 5 capítulos, consideraremos que has invitado con éxito a un nuevo lector.",
    "recommend_part2_tips3": "3.Cada usuario puede participar en el ranking basado en el número de nuevos lectores invitados con éxito. Los 20 primeros compartirán el gran premio de gemas.",
    "recommend_part2_tips4": "4.StoryGo se reserva el derecho de interpretación final del evento. En caso de trampas o comportamiento fraudulento para manipular las clasificaciones, StoryGo se reserva el derecho de descalificar a los participantes del evento.",
    "see_rank": "Verificar Ranking de Embajadores",
    "share_act": "Compartir el evento",
    "join_watsapp": "Únete al Grupo WhatsApp",
    "recommend_page_title": "Embajadores de StoryGo, Grandes Recompensas",
    "recommend_banner_title": "Embajadores de StoryGo, Grandes Recompensas",
    "how_to_share_title": "Cómo compartir tus libros favoritos",
    "how_to_share_content_1": "Abre tu libro favorito y simplemente toca el botón de compartir que se encuentra en la esquina superior derecha.",
    "how_to_share_content_2": "O, abre un libro, luego mantén presionado un párrafo para seleccionarlo, y compártelo en las redes sociales."
}