import i18n from "i18next";
// import { createI18n } from 'vue-i18n'
// import {initReactI18next} from "react-i18next";
// import moment from "moment";
import common from "./common";
// import zh_CH from "./zh_CH";
import  en from "./英文";
import  act_en from "./act/英文";
import  pyc from "./俄语";
import  idn from "./印尼";
import  act_idn from "./act/印尼";
import  tr from "./土耳其语";
import  act_tr from "./act/土耳其语";
import  de from "./德语";
import  fr from "./法语";
import  th from "./泰语";
import  fil from "./菲律宾语";
import  act_fil from "./act/菲律宾语";
import  pt from "./葡语";
import  act_pt from "./act/葡语";
import  esp from "./西班牙语";
import  act_esp from "./act/西班牙语";
import  vi from "./越南语";
import  ar from "./阿拉伯语";
import  act_ar from "./act/阿拉伯语";
// import zh_CN from "./zh_CN";
// import {getURLParamValue} from "@storygo/utils";
export const defaultNS = 'translation'

// export utils  lcidMap = {
//     '1033': 'en',
// }
export const resources = {
    en: {
        translation: {
            ...common,
            ...en,
            ...act_en
        }
    },
    рус: {
        translation: {
            ...common,
            ...pyc
        }
    },
    id: {
        translation: {
            ...common,
            ...idn,
            ...act_idn
        }
    },
    tr: {
        translation: {
            ...common,
            ...tr,
            ...act_tr
        }
    },
    de: {
        translation: {
            ...common,
            ...de
        }
    },
    fr: {
        translation: {
            ...common,
            ...fr
        }
    },
    th: {
        translation: {
            ...common,
            ...th
        }

    },
    fil: {
        translation: {
            ...common,
            ...fil,
            ...act_fil
        }
    },
    pt: {
        translation: {
            ...common,
            ...pt,
            ...act_pt
        }
    },
    esp: {
        translation: {
            ...common,
            ...esp,
            ...act_esp
        }
    },
    vi: {
        translation: {
            ...common,
            ...vi
        }
    },
    ar: {
        translation: {
            ...common,
            ...ar,
            ...act_ar
        }
    }
} as const;

// utils getDefaultLang = () => {
//     if(location.host.indexOf('zrolee') > -1){
//         return 'en'
//     }else if(location.host.indexOf('zrole') > -1) {
//         return 'zh_CN'
//     } else {
//         return 'zh_CN'
//     }
// }
//
// window.lang = getURLParamValue('lang') || getDefaultLang()
//


// if(i18n.services.formatter){
//     i18n.services.formatter.add('dateFormat', (value, lng, options) => {
//         // moment.locale(lng)
//
//         // console.log(value, lng, options)
//         // console.log(moment(value).format('LLLL'))
//         // if(lng === 'zh_CN'){
//         //     return moment(value).format('YYYY年MM月DD日')
//         // }else if(lng === 'zh_CH'){
//         //     return moment(value).format('YYYY年MM月DD日')
//         // }else if(lng === 'en'){
//             moment.locale('en')
//             return moment(value).format('LL')
//         // }
//         // return value
//     });
// }


export const initI18n = () => i18n.init({
    debug: process.env.NODE_ENV !== 'production',
    resources,
    defaultNS,
    // fallbackLng: getDefaultLang(),
    fallbackLng: 'en',
    returnObjects: true,
    // ns: [defaultNS],
    lng: 'en', //设置当前语言
    keySeparator: '.', // we do noat use keys in form messages.welcome
    returnNull: false,
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});
// export utils initI18n =  createI18n({
//     locale: 'en',
//     fallbackLocale: 'en',
//     legacy: true,
//     messages: resources
// })
//
export const $t: (key:string, opt?: Record<string, any>) => string = i18n.t;
window.$t = $t;
export default i18n;
