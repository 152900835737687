<template>
  <div class="user-page no-scrollbar" @scroll="scroll">
    <div class="novel-header" :class="{whiteBg: showBar}">
      <img class="back-icon" @click="onBack" src="/images/back-icon.png" alt="">
    </div>

    <div>
      <Image class="login-bg" src="/images/login-bg.png"></Image>
    </div>
    <div class="user-container">
      <div v-if="user.baseInfo.uid" class="userinfo">
        <div class="avatar">
          <Image round radius="50%" fit="cover"  :show-loading="false" :src="user.baseInfo.avatarUrl"></Image>
        </div>
        <div class="items-center username" @click="toPerson"><span>{{user.baseInfo.nickName}}</span><img src="/images/black-right-cross.png" alt=""></div>
      </div>
      <div v-else class="sign-in">
        <div class="justify-between login-button" @click="toLogin"><span class="not-flip">{{$t('register_or_login')}}</span> <img src="/images/right-icon.png" alt=""></div>
        <div class="tips">{{$t('login_tips_2')}}</div>
      </div>
      <div class="placeholder"  v-if="userInfo.baseInfo.uid"></div>
      <MultiLinesCard class="record" :items="books" :title="$t('reading_record')"  @click-item="toDetail"></MultiLinesCard>
      <div v-if="showMore" class="center see-more" @click="toHistory">{{$t('more')}}</div>
      <div class="cell-group">
        <div class="justify-between cell" v-for="(item, index) in menus" :key="index" @click="toPage(item)">
          <span class="label">{{item.name}}</span>
          <img class="flip" src="/images/rightcross-icon.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <Download></Download>

</template>
<script setup lang="ts">
import {Image, Field, CellGroup, FieldType, showToast} from "vant"
import {ref, getCurrentInstance, onMounted, onActivated} from "vue"
import {useRouter} from 'vue-router'
import MultiLinesCard from "@/components/muitilLinesCard3/index.vue";
import {useUserStore} from "@/stores/user";
import {novel} from "@storygo/types/novel_baseTars.d";
import {listUserBookHistory} from "@storygo/api/app";
import {chunk} from 'lodash'
import {$t} from "@storygo/lang"
import Download from "@/components/download/index.vue";
import {removeBlankSpace} from "@storygo/utils";
const passwordType = ref<FieldType>("password")
const histories = ref([])
const showMore = ref(false)
const showBar = ref(false)
const user = ref({
  baseInfo: {}
} as novel.UserInfo)
const books = ref([] as Array<novel.Book[]>)

const {userInfo, getUserInfo} = useUserStore()
const router = useRouter()
const menus = [
  {name: 'User Agreement', url: '/userAgreement'},
  {name: 'Privacy Policy', url: '/privacyPolicy'},
  // {name: 'About Us', url: '/about'},
]

const toFacebookLogin = () => {
  showToast('开发中')
}

const toGoogleLogin = () => {
  showToast('开发中')
}

const toEmailLogin = () => {
  router.push('/login/email')
}
const onBack = () => {
  router.back()
}
const toDetail = (item: novel.Book) => {
  window.cacheData = item
  if(item._part){
    router.push(`/books/${removeBlankSpace(item.name)}_${item.bookId}?chapter=${item._part}`)
    // router.push(`/detail?id=${item.bookId}&chapter=${item._part}`)
  }else {
    router.push(`/books/${removeBlankSpace(item.name)}_${item.bookId}`)
    // router.push(`/detail?id=${item.bookId}`)
  }

}
const toLogin = () => {
  router.push('/login')
}

const toPage = (menu: typeof menus[0]) => {
  if(!menu.url) return;
  router.push(menu.url)
}

const toPerson = () => {
  router.push('/person')
}

const toHistory = () => {
  router.push('/history')
}

const getHistories = async () => {
  const res = await listUserBookHistory({
    size: 10,
    offset: 0
  })
  if(res.books.value.length > 8) {
    showMore.value = true
  }
  books.value = chunk(res.books.value.slice(0,8), 4)
}

const scroll = (e) => {
  if(e.target.scrollTop > 70) {
    showBar.value = true
  }else {
    showBar.value = false
  }
}

onActivated(() => {
  const userInfo = getUserInfo()
  console.log(userInfo)
  if(userInfo.baseInfo.uid){
    user.value = userInfo
    getHistories()
    // window.localStorage.removeItem('history')

  }else {
    user.value = {
      baseInfo: {}
    } as novel.UserInfo
    const historiesCache = JSON.parse(window.localStorage.getItem('history') || '[]')
    histories.value = historiesCache
    console.log(historiesCache)
    const cacheBooks = historiesCache.map(item => {
      item.book._part = item.part
      return item.book
    }).reverse()
    console.log(11,cacheBooks)
    if(cacheBooks.length > 8) {
      showMore.value = true
    }
    books.value = chunk(cacheBooks.slice(0,8), 4)

  }
})

</script>
<style scoped lang="less" src="./index.less"></style>
