import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

const LanguageMap:Record<string, string> = {
  '1033': 'EN',
  '1057': 'ID',
  '1066': 'VI',
  '1054': 'TH',
  '1025': 'AR',
  '1124': 'PH',
  '1034': 'ES',
  '1036': 'FR',
  '2070': 'PT',
  '1031': 'DE',
  '1049': 'RU',
  '1055': 'TR',
}

export const useLanguageStore = defineStore('language', () => {
  const language = ref()
  const lcid = ref(window.localStorage.getItem('lcid'))
  // if(!language.value) {
  //   language.value = 'en'
  // }

  if(!lcid.value) {
    // lcid.value = '1033'
    lcid.value = '1034'
  }
  language.value = LanguageMap[lcid.value]

  const changeLanguage = (lcidValue: string) => {
    if(lcid.value !== lcidValue) {
      setTimeout(() => {
        window.location.reload()
      })
    }
    window.localStorage.setItem('lcid', lcidValue)
    // window.lang = lang
    // language.value = lang
    language.value = LanguageMap[lcidValue]
    lcid.value = lcidValue
  }

  return { language, lcid, changeLanguage }
})


