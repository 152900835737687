<template>
    <div class="single-line-container" v-if="items.length">
         <div class="title">
           {{title}}
         </div>
      <div class="content no-scrollbar">
            <div v-for="(item, index) in items" :key="index" class="card-item" @click="clickItem(item)">
              <van-skeleton class="cover" :loading="loading">
                <header style="display: none;">
                  <nav>
                    <a style="display: none;" rel="canonical" :href="getBookUrl(item)" :title="item.name"></a>
                  </nav>
                </header>
                <Image class="cover no-event" fit="cover" rel="canonical" lazy-load :src="getSrc(item.cover)"  :alt="item.name+'-StoryGo'"></Image>
              </van-skeleton>
              <div class="item-info">
                <van-skeleton   :row="1" style="width: 100%"  :loading="loading">
                  <div class="ellipsis2 card-title">{{item.name}}</div>
                </van-skeleton>
              </div>
            </div>
      </div>
    </div>
</template>
<script setup lang="ts">
import {Image} from "vant"
import {novel} from "@storygo/types/novel_baseTars.d";
import {getBookUrl, getSrc} from "@storygo/utils";
interface Props {
  items: novel.Book[],
  title: string
  loading: boolean
  placeHolderSize: number
}
// import {} from "vue"
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  items = [] as novel.Book[],
    title = '',
  loading = false,
  placeHolderSize = 0
} = defineProps<Props>()
console.log(items)
const clickItemEvent = defineEmits<{
  (e: 'clickItem', item: novel.Book ) :void
}>()
const clickItem = (item: novel.Book) => {
  clickItemEvent('clickItem', item)
}


</script>
<style scoped lang="less" src="./index.less"></style>
