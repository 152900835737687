import i18n from '@storygo/lang'

export default  {
    data(){
        return {
            window: window,
        }
    },
    methods: {
        $t: i18n.t
    }
}
