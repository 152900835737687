<script setup lang="ts">
import {RouterView} from 'vue-router'
import Loading from '@storygo/components/loading/index.vue'
import PayMethods from './components/payMethods/index.vue'
import {onSDKListener} from "@storygo/utils/sdk";
import {showFeedbackToast} from "@/components/feedbackToast/index";
import {onMounted} from "vue";
import { useScreenSafeArea } from '@vueuse/core'
import {checkOs} from "@storygo/utils";
import {queryUserMaxRechargePayOrder} from "@storygo/api/app";
import {useStorage} from "@/stores/storage";
const {
  top,
} = useScreenSafeArea()
const storage = useStorage()
const getMaxRechargeOrder = async () => {
  try {
    if(window.appUserInfo && window.appUserInfo.token){
      const res = await queryUserMaxRechargePayOrder()
      console.log('充值过最大的单----', res)
      if(res.mcOrderId && res.status === 0){
        // 充值过
        storage.setNoRecharge(false)
        storage.setMaxRechargePack(res.pkg)
      }else {
        // 没有充值过
        storage.setNoRecharge(true)
      }
    }
  }catch (e){
    console.log('没有最大的单', e)
  }

}
// import FeedbackToast from './components/feedbackToast/index.vue'
// showFeedbackToast(5000)
onMounted(() => {
  // if(top.value === '0px'){
    // document.querySelector('.novel-header').style.paddingTop = '40px'
      if(window.isApp){
        document.body.style.setProperty('--novel_header', '48px');

        if(document.querySelector('.novel-header')){
          // document.body.style.setProperty('--novel_header', '48px');
          if(top.value !== '0px' && checkOs() === 'iOS'){
            document.body.style.setProperty('--novel_header', top.value );
            // document.querySelector('.novel-header').style.paddingTop = '48px'
          }
          // document.querySelector('.novel-header').style.paddingTop = '48px'
          // return clearInterval(t1)
        }
      }else {
        document.body.style.setProperty('--novel_header', '10px');
      }


  // }else {
  //   document.body.style.setProperty('--novel_header', top.value );
  // }
  //

  if(window.isApp){
    onSDKListener('onRechargePackageEnd', () => {
      showFeedbackToast(5000)
    })
    onSDKListener('onMonthlyCardPayEnd', () => {
      showFeedbackToast(5000)
    })

    getMaxRechargeOrder()
  }
})
</script>

<template>
  <router-view v-slot="{ Component, route }">

    <transition name="slide-right">
      <keep-alive>
        <component
            :is="Component"
            :key="route.name"
            v-if="$route.meta.keepAlive"
        />
      </keep-alive>
    </transition>
    <transition name="slide-right">
      <component
          :is="Component"
          :key="route.name"
          v-if="!$route.meta.keepAlive"
      />
    </transition>
  </router-view>

  <Loading></Loading>

  <PayMethods></PayMethods>

<!--  <FeedbackToast></FeedbackToast>-->

</template>

<style scoped lang="less">
.slide-right-enter-from {
  width: 100vw;
  //transform: translate3d(100%, 0, 0);
}
.slide-right-enter-to {
  width: 100vw;
  transform: translate3d(0, 0, 0);
}
.slide-right-enter-active{
  //will-change: transform;
  //transition: all 300ms;
  //position: absolute;
  //z-index: 9998;
  //width: 100vw;
}
.slide-right-leave-active {
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  z-index: 9999;
  width: 100vw;
}
.slide-right-leave-from {
  width: 100vw;
  transform: translate3d(0, 0, 0);
}
.slide-right-leave-to {
  width: 100vw;
  transform: translate3d(100%, 0, 0);
}
//.slide-right-enter {
//  width: 100vw;
//  //opacity: 0.5;
//
//}
//.slide-right-leave-active {
//  //opacity: 0.5;
//  transform: translate3d(100%, 0, 0);
//}

</style>
