export default {
    "next": "Siguiente",
    "login": "Iniciar sesión",
    "login_tips": "Esperamos que puedas disfrutar de un viaje romántico aquí",
    "set_password": "Establecer contraseña",
    "enter_password": "Por favor ingresa tu contraseña",
    "verify_code": "Código de verificación",
    "enter_code": "Por favor ingresa el código de verificación digital de 6 dígitos del correo electrónico",
    "let_start": "Empecemos",
    "let_start_tips": "Esperamos que puedas disfrutar de un viaje romántico aquí",
    "sign_facebook": "Iniciar sesión con Facebook",
    "sign_google": "Iniciar sesión con Google",
    "sign_email": "Iniciar sesión con correo electrónico",
    "language_title": "Establecer preferencias de idioma de lectura",
    "current_lang": "idioma actual",
    "en": "EN",
    "indonesian": "Indonesio",
    "personal_data": "Datos personales",
    "save": "Guardar",
    "nickname": "Apodo",
    "sign_out": "Cerrar sesión",
    "loading": "cargando",
    "success": "Exitoso",
    "reading_record": "Registro de lectura",
    "empty": "Vacío",
    "last_read": "Última lectura",
    "chapter": "capítulo",
    "remove": "Eliminar",
    "search": "Buscar",
    "search_empty": "No se pueden encontrar novelas relacionadas con \"{{var}}\"",
    "login_tips_2": "Inicia sesión para acceder a más funciones.",
    "register_or_login": "Registro/Inicio de sesión",
    "more": "Más",
    "reads": "Lecturas",
    "read": "Leer",
    "last_chapter": "Último capítulo",
    "all_chapter": "Todos los Capítulos",
    "contents": "Contenidos",
    "all": "Todo",
    "completed": "Completado",
    "ongoing": "En curso",
    "most_popular": "Más popular",
    "new_arrival": "Nueva llegada",
    "invite_friends": "Comparte este código de invitación con tus amigos. Una vez que lo activen en esta página, ambos recibirán {{coins}} gemas. Cada código de invitación se puede usar hasta 50 veces.",
    "invite_friends_nums": "<span class=\"blue\">Invité a {{n}} amigos y gané </span> <span class=\"items-center coin\"><img\n            src=\"/images/dialyTask/coin.png\" alt=\"\"> *  {{m}}</span>",
    "guide_title": "Proyecto de Autor de StoryGo",
    "guide_content": 0,
    "active_code": "Activar el código de invitación",
    "active_tips": "Obtén un código de invitación de tus amigos y, al activarlo con éxito, recibirás {{coins}} gemas. Consejo: No puedes activar tu propio código de invitación; ¡debes obtener uno de otros!",
    "login_now": "Inicia sesión ahora",
    "task_1_title": "Regalo de bienvenida",
    "task_1_desc": "Un regalo de bienvenida para los recién llegados, que se ha depositado automáticamente en tu cartera",
    "view_detail": "Ver detalles",
    "claim": "Reclamar",
    "invite_friend": "Invitar a amigos",
    "my_invite_code": "Mi código de invitación",
    "copy": "Copiar",
    "task_2_title": "Registro diario",
    "task_2_desc": "Regístrate todos los días y obtén el bono.",
    "task_3_title": "Leer durante más de {{min}} minutos",
    "task_3_desc": "Lee un total de {{min}} minutos hoy para ganar recompensas.",
    "task_page_label": "Recompensas",
    "label_wallet_purchase_coins": "Comprar monedas",
    "go": "Ir",
    "done": "Hecho",
    "copy_text": "Mi código de invitación de StoryGo es [ {{código}} ], actívalo y gana 50 monedas:[https://storygo.onelink.me/dDXW/smfzomli]",
    "copy_success": "El código de invitación ha sido copiado.",
    "active_success": "Activación exitosa. Las 50 monedas de recompensa han sido enviadas a tu cartera.",
    "error_code_limit": "El código de activación ha alcanzado su límite de uso.",
    "error_had_activated": "Esta cuenta ya ha sido activada con un código",
    "error_invalid_code": "Código de activación inválido",
    "download_tips": "Historias ilimitadas en la APP",
    "download": "Descargar",
    "label_book_praise": "Calificación",
    "label_book_prologue": "Prólogo",
    "login_success": "Inicio de sesión exitoso",
    "msg_reset_password_repeat": "Por favor, repite la nueva contraseña",
    "msg_reset_password_email": "Por favor, introduce tu dirección de correo electrónico para restablecer tu contraseña",
    "label_reset_password": "Restablecer la contraseña",
    "label_send_email": "Enviar correo electrónico",
    "msg_login_email_code_sent": "El código de verificación ha sido enviado con éxito a tu correo electrónico",
    "msg_feedback_error": "Enviar falló, por favor intenta de nuevo más tarde",
    "save_userinfo": "Guardado con éxito",
    "label_load_image_fail": "Falló la carga de la imagen",
    "paid_chapters": "Capítulos Pagados",
    "unlock_price": "Desbloquear capítulo requiere {{coin}}",
    "download_claim_coin": "Descarga la aplicación para reclamar {{coin}} y desbloquear capítulos más emocionantes.",
    "label_book_chapters": "{{num}} Capítulos",
    "label_cancel": "Cancelar",
    "msg_common_state_empty": "Vacío aquí",
    "clear_reading_record": "¿Estás seguro de que quieres borrar el registro de lectura?",
    "clear_successful": "Registro borrado con éxito.",
    "label_search_trending": "Búsquedas de tendencia",
    "task_login_tips": "Inicia sesión para obtener un bono de monedas",
    "label_new_arrival": "Novedades Calientes",
    "had_activated": "Esta cuenta ya ha sido activada con un código",
    "activate": "Activar",
    "task_5_title": "Compartir en Facebook",
    "task_5_desc": "Compartir cualquier libro en la plataforma de Facebook",
    "bigwheel_redeem_label": "Recargar",
    "coin_tips_1": "Monedas insuficientes, por favor recargar.",
    "coin_tips_2": "Cuenta: {{n}} monedas (necesita {{m}} monedas)",
    "bigwheel_rule_1": "1. Participa en la ruleta de la suerte con un 100% de garantía de ganar premios.",
    "bigwheel_rule_2": "2. Un solo giro de la ruleta cuesta 10 monedas de oro, diez giros cuestan 100 monedas de oro y cincuenta giros cuestan 500 monedas de oro. Cuantos más giros consecutivos, mayor es la posibilidad de ganar premios valiosos.",
    "bigwheel_rule_3": "3. Los premios obtenidos durante el juego se distribuyen automáticamente. Las monedas de oro se acreditan directamente en tu billetera de monedas de oro.",
    "bigwheel_rule_4": "4. Este juego no está afiliado a Apple Inc. Todos los derechos de interpretación final pertenecen a StoryGo.",
    "bigwheel_balance_label": "Saldo",
    "no_data_1": "Sin datos",
    "msg_room_coin_notice": "¡Felicidades! [{{who}}] gana {{coin}} monedas!",
    "top_up": "Recarga",
    "sign": "Registrarse",
    "sign_desc": "Un regalo de bienvenida para los recién llegados, que se ha depositado automáticamente en tu cartera",
    "first_recharge": "Oferta por tiempo limitado",
    "first_recharge_desc": "Termina la Oferta por tiempo limitado para obtener un bono",
    "unlock_chapter": "Desbloquear {{n}} capítulo",
    "unlock_chapter_desc": "Desbloquea un capítulo premium para recibir una recompensa.",
    "comment_book": "Enviar un comentario de un libro",
    "comment_book_desc": "Escribe una reseña para tu libro favorito y ten la oportunidad de ganar recompensas.",
    "my_coins": "Mis monedas",
    "recharge_title": "Paquete de regalo recomendado",
    "bonus": "Bono",
    "buy_coins": "Comprar monedas",
    "my_balance": "Mi saldo",
    "apple_pay": "Apple Pay",
    "other_pay": "Mejor Elección",
    "google_pay": "Google Pay",
    "pay_now": "Pagar ahora",
    "label_balance": "Monedas",
    "select_payment": "Seleccione un método de pago",
    "select_package": "Seleccione un paquete de recarga",
    "wallet_title": "Billetera",
    "coins_balance": "Saldo",
    "new_comer": "Nuevo usuario",
    "extra_coins": "Extra {{n}} monedas",
    "daily_task_label": "Tarea Diaria",
    "label_pay_success": "¡Pago exitoso!",
    "app_update_title": "Por favor, actualice a la última versión.",
    "restore_label": "Restaurar",
    "history_label": "Historia",
    "newcomer_pack": "Oferta por tiempo limitado",
    "recommend_pack": "Paquete recomendado",
    "collection_month": "Total/Mes",
    "now_get_icons": "Obtener {{n}}  de una vez",
    "monthly_get_icons": "Obtener {{n}} Gemas/Mes",
    "recharge_feedback_msg": "¿Tiene problemas para recargar?",
    "feedback_label": "Comentarios",
    "coin_label": "Monedas",
    "gem_label": "Gemas",
    "restore_tips": "No hay suscripciones restaurables",
    "recharge_label": "Recargar",
    "sign_days": "Registrado {{n}} día(s)",
    "vip_daily_task": "Bono de paquete mensual",
    "other": "Otro",
    "limited_recharge_pack": "Oferta por tiempo limitado",
    "expire_tips": "Vence el {{time}}",
    "hot_pack": "Paquete popular",
    "continuous_checkin_day": "Registrado {{n}} día(s)",
    "svip_login_reward": "Bono premium de $9.9",
    "vip_login_reward": "Bono premium de $19.9",
    "dailysign_popup_btn": "Obtén más del Centro de Recompensas",
    "checked_in": "Revisado",
    "limit_time_recharge_title": "Oferta por tiempo limitado",
    "recharge_pack_tips": "Termina la Oferta por tiempo limitado para obtener un bono",
    "balance_nsufficient_tips": "Tu saldo es insuficiente, por favor recarga",
    "had_coins_tips": "Tienes: {{n}} Monedas | {{m}} Gemas",
    "dailysign_popup_title": "Felicitaciones",
    "pay_method_label": "Método de Pago",
    "at_once": "De una vez",
    "daily_login": "Inicio de sesión diario",
    "card_coin_suffix": "Total/Mes",
    "card_gems_suffix": "Mes",
    "vips_rule_title": "Acerca del paquete mensual:",
    "vips_rule_1": "1. Al comprar con éxito el paquete mensual de $9.9, recibirás inmediatamente 1,000 monedas de oro, y puedes recibir 20 gemas cada día durante 30 días consecutivos (600 gemas en total); Al comprar con éxito el paquete mensual de $19.9, recibirás inmediatamente 2,000 monedas de oro, y puedes recibir 40 gemas cada día (600 gemas en total) durante 30 días consecutivos.",
    "vips_rule_2": "2. Si compras diferentes paquetes mensuales, las recompensas de gemas que recibas cada día se superpondrán; si compras los mismos paquetes mensuales, las recompensas de gemas que recibas cada día no se superpondrán, sino que solo extenderán la duración de la tarjeta mensual correspondiente.",
    "vips_rule_3": "3. Las gemas obtenidas diariamente son válidas por 30 días, por favor presta atención.",
    "vips_rule_4": "",
    "vips_rule_5": "1. El paquete mensual será válido dentro de las 24 horas posteriores a la compra y puedes disfrutar de los beneficios correspondientes.",
    "check_in": "Registro",
    "day_index": "No.{{day}}",
    "gems_will_expire_tips": "<span class=\"gem-nums\">{{n}} Gemas </span> están a punto de caducar",
    "balance_label": "Saldo",
    "wallet_rule_title": "Sobre las gemas",
    "wallet_rule_1": "1. Las gemas se pueden usar para desbloquear capítulos. Al desbloquear capítulos, las monedas y las gemas tienen el mismo valor, 1 moneda = 1 gema.",
    "wallet_rule_2": "2. Las gemas caducarán. Ten en cuenta que las gemas recibidas por recarga y paquete mensual tienen una validez de 30 días y las gemas reclamadas por tareas diarias tienen una validez de 7 días.",
    "wallet_rule_3": "3. Al desbloquear capítulos, se utilizan primero las gemas.",
    "read_task_title": "Tarea de lectura",
    "share_task_title": "Tareas de compartición",
    "comment_task_title": "Tarea de comentarios",
    "vip_task_title": "Bono premium de $9.99",
    "svip_task_title": "Bono premium de $19.99",
    "vip_page_title": "Paquete mensual",
    "pay_bonus_label": "Bono",
    "daily_gems": "Diariamente {{n}} Gemas",
    "history_page_title": "Historia",
    "recharge_title_1": "Recargar",
    "gems_will_expire_btn": "Detalles",
    "gems_history_tips": "Ordenar por fecha de vencimiento",
    "unlock_coins": "Necesario: {{n}} Monedas o Gemas",
    "history_recharge_title": "Recargar",
    "history_game_title": "Sorteo de la Suerte",
    "history_recharge_given_title": "Bono de recarga",
    "extar_gems_btn": "Gemas Adicionales",
    "watch_ad_task_title": "Ver videos",
    "watch_ad_task_desc": "Recibir recompensa por cada video visto ({{currentProcess}}/{{finishCount}})",
    "draw_success_tips": "Recompensa reclamada con éxito.",
    "task_finish_title": "La tarea ha sido completada.",
    "draw_width_ad_toast": "¡Enhorabuena! Has conseguido  {{n}} y  {{m}} adicionales.",
    "draw_toast": "¡Enhorabuena! Has conseguido  {{n}}.",
    "draw_dialog_btn1": "Simplemente reclama  {{n}}.",
    "draw_dialog_btn2": "Reclamar  {{n}} y  {{m}} adicionales.",
    "history_ads_given_title": "Ver videos",
    "ad_extra_rewards_title": "Bonificación por anuncios tras tareas.",
    "label_editor_picks": "Selecciones del editor",
    "label_genre": "Género",
    "label_main_tab_home": "Descubrir",
    "label_reader_choice": "Mejor Valorado",
    "invite_bonuses": "Invita a un nuevo usuario y obtén hasta {{n}} bonificaciones.",
    "invite_friend_btn": "Invita a tu amigo",
    "how_get_bonuses": "Cómo obtener más bonificaciones",
    "invite_tips_1": "Tu amigo ha desbloqueado y leído 5 capítulos",
    "invite_tips_2": "Cuando tus amigos recarguen, recibirás un 10% en gemas, hasta 150 gemas por amigo.",
    "invite_tips_3": "Cada vez que tu amigo completa una acción, recibirás una recompensa inmediata.",
    "how_invite_friend": "Cómo invitar a un amigo",
    "invite_tips_5": "Haz clic en el botón de abajo para generar tu enlace de invitación exclusivo y compartirlo con amigos",
    "invite_tips_4": "Tu amigo descargó StoryGo a través del enlace que compartiste",
    "reward_history_title": "Historial de recompensas",
    "reward_history_tips_1": "Has obtenido un total de {{n}} gemas de la actividad de invitación.",
    "reward_history_tips_2": "Has invitado con éxito a {{n}} amigos y {{m}} gemas.",
    "reward_history_tips_3": "Has ganado {{n}} gemas de la tarea de desbloqueo de 5 capítulos realizada por tus amigos invitados.",
    "reward_history_tips_4": "Has recibido {{n}} gemas como recompensa por la recarga de tus amigos",
    "daily_invite_friend_title": "Invitar amigos",
    "daily_invite_friend_desc": "Invita a tus amigos a unirse a StoryGo y ganar grandes recompensas.",
    "recharge_panel_ad_tips": "Recarga tu paquete de StoryGo para obtener abundantes monedas y gemas de libro. Desbloquea fácilmente capítulos premium y disfruta de la lectura sin anuncios durante 7 a 30 días después de la compra, con activación instantánea.",
    "invite_more_friend": "¡Invita a más amigos y obtén más Gemas!",
    "history_invite_unlock_title": "El usuario invitado desbloqueó 5 capítulos",
    "history_invite_recharge_title": "El usuario invitado realizó una recarga",
    "days": "Días",
    "continue_read_app": "Continuar Leyendo en la Aplicación",
    "download_app_tips": "Para obtener contenido más emocionante, descarga StoryGo",
    "invite_tips_6": "Invita a un nuevo lector para reclamar 15 gemas. Puedes ganar hasta 300 gemas como recompensa.",
    "history_invite_success_title": "Se ha invitado exitosamente a un usuario",
    "evaluation_task_title": "Califica StoryGo",
    "evaluation_task_desc": "3 Pasos para obtener las recompensas",
    "invite_page_title": "¡Invita a nuevos usuarios a usarlo juntos y recibir recompensas de gemas!",
    "invite_page_title_2": "Por cada nuevo usuario que invites, recibirás.",
    "invite_page_title_3": "Para los pagos de nuevos usuarios, también puedes recibir una recompensa de {{n}} gemas. Cuanto más invites, más ganas.",
    "my_invite_record": "Mis Registros de Invitación",
    "successfully_invited": "Invitación Exitosa",
    "earnings_gem": "Mis Ganancias (Gemas)",
    "how_invite_title_1": "Invitar a Amigos",
    "how_invite_title_2": "El amigo completa el registro",
    "how_invite_title_3": "Las recompensas llegan",
    "invite_h5_title": "Te invitamos a usar StoryGo juntos",
    "got_gem_label": "Beneficios para novatos",
    "accept_invitation": "Aceptar la invitación",
    "join_people": " Las personas han participado en el evento",
    "recommended_content": "Contenido recomendado",
    "to_app_tip": "Más contenido emocionante en StoryGo",
    "turn_on_notify_title": "Abrir notificación",
    "turn_on_notify_desc": "Abre las notificaciones de mensajes para recibir recompensas",
    "label_help_center": "Centro de Ayuda",
    "ai_become_writer_btn": "Convertirse en Escritor  ",
    "ai_offer_title": "Qué Ofrecemos  ",
    "ai_offer_item_label_1": "Herramientas de Creación  ",
    "ai_offer_item_value_1": "Cuota de Prueba Gratuita para Herramientas de Creación  ",
    "ai_offer_item_label_2": "Recompensas en Efectivo  ",
    "ai_offer_item_value_2": "Participa en competiciones de creación para ganar un premio en efectivo de $200  ",
    "ai_offer_item_label_3": "Escritor Contratado  ",
    "ai_offer_item_value_3": "Conviértete en un escritor contratado en la plataforma y recibe generosas participaciones mensuales de suscripción  ",
    "ai_authors_say_title": "Lo Que Dicen Nuestros Autores  ",
    "ai_authors_item_name_1": "Mia Thompson",
    "ai_authors_item_content_1": "\"Esta herramienta de escritura con IA es como un asistente poderoso, simple y fácil de usar, que permite a cada usuario comenzar fácilmente. La salida de alta calidad hace que la creación sea un placer, y la experiencia del usuario es, sin duda, el máximo disfrute de la escritura.\"",
    "ai_authors_item_name_2": "Lily Adams",
    "ai_authors_item_content_2": "¡Esta herramienta de escritura con IA es increíble! Es fácil de usar, sencilla de operar y produce obras de alta calidad. ¡Me permite expresar mi creatividad con facilidad y disfrutarlo!  ",
    "ai_authors_item_name_3": "Zoe Carter",
    "ai_authors_item_content_3": "¡Esta herramienta de escritura con IA es una bendición para los creadores! Su interfaz simple e intuitiva facilita su uso para los novatos. La calidad de las historias generadas es sorprendente. Escribir con ella es, sin duda, un placentero viaje de inspiración.  ",
    "ai_why_choose_title": "Por Qué Elegirnos  ",
    "ai_why_choose_item_label_1": "Equipo editorial apasionado  ",
    "ai_why_choose_item_value_1": "Un equipo editorial profesional y eficiente te brinda un período de protección de 6 meses para escritores novatos, durante el cual los manuscritos creados pueden disfrutar del canal de garantía de envío seguro.  ",
    "ai_why_choose_item_label_2": "Herramientas de creación eficientes  ",
    "ai_why_choose_item_value_2": "Fácil de crear, ciclo de creación corto, alta eficiencia de monetización  ",
    "ai_why_choose_item_label_3": "Garantía de seguridad  ",
    "ai_why_choose_item_value_3": "Un equipo legal profesional protege los derechos de tus obras  ",
    "ai_create_story_btn": "Crea tus historias  ",
    "ai_contact_btn": "Contactar al equipo editorial  ",
    "ai_dialog_title": "¡Bienvenido a StoryGo!  ",
    "ai_dialog_desc": "¡Con las herramientas de creación de StoryGo, también puedes convertirte en un escritor de historias exitosas, ven y únete a nosotros!  ",
    "ai_dialog_part1_title": "Prueba Gratuita  ",
    "ai_dialog_part1_item1": "Por favor, inicia sesión en nuestro sitio web para PC https://ai.storygo.cc  ",
    "ai_dialog_part1_item2": "Únete al grupo de WhatsApp y contacta al administrador para obtener una prueba gratuita de la herramienta de creación.  ",
    "ai_dialog_part2_title": "Competencia Creativa  ",
    "ai_dialog_part2_item1": "Por favor, inicia sesión en nuestro sitio web para PC https://ai.storygo.cc  ",
    "ai_dialog_part2_item2": "Participa en la competencia creativa, gana premios en efectivo, nuevas oportunidades.  ",
    "ai_dialog_part2_item3": "Si tienes alguna pregunta, contáctanos en Contact@storygo.cc  ",
    "ai_dialog_copy_email": "Copia el enlace del sitio web oficial  ",
    "ai_url_copy_tips": "Enlace copiado con éxito  "
}