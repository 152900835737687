<template>
  <div>
    <div v-if="visible" class="loading-bg">
      <div class="loading-container">
        <img  src="./img/loading.png?1" alt=""/>
<!--        <div class="text">{{text}}</div>-->
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref} from "vue"
import {eventBus} from "@storygo/utils/emitter";
const visible = ref(false)
const text = ref('loading...')
eventBus.$on('loading', (val, msg) => {
  if(text !== null){
    text.value = msg
  }
  if(!val){
    text.value = ''
  }
  visible.value = val
})
</script>
<style scoped src="./index.less"></style>
